import React from "react";
import styled from "styled-components";
import { FaLeaf } from "react-icons/fa";
import Banner from "../components/Banner";
import ColorTab from "../components/colorTab";
import SectionWrapper from "../components/SectionWrapper";
import ButtonLink from "../components/ButtonLink";
import bannerImg from "../assets/sostenibilidad/banner-empaques-sostenibles.jpg";
import MayacertImg from "../assets/sostenibilidad/empaques sotenibles.png";

// Contenedor principal
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  font-size: ${(props) => props.theme.fontSizes.normal};
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 5% 0;
  position: relative;

  h2 {
    color: ${(props) => props.theme.colors.primary};
    font-size: ${(props) => props.theme.fontSizes.large};
    font-weight: bold;
  }
`;

// Contenedor para la descripción
const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 5% 2%;

  @media (min-width: 768px) {
    flex-direction: row;
    padding: 5% 5%;
    gap: 10%;
  }

  img {
    width: 100%;
    max-width: 250px;
    height: auto;
  }

  div {
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;

    @media (min-width: 768px) {
      text-align: left;
      max-width: 600px;
    }
  }
`;

const ImpactList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 20px 0;
  width: 90%;
  max-width: 800px;

  li {
    display: flex;
    align-items: flex-start;
    gap: 20px; /* Espaciado entre el ícono y el texto */
    margin-bottom: 20px;
    svg {
      color: ${(props) => props.theme.colors.green};
    }
    p {
      margin: 0;
      color: ${(props) => props.theme.colors.primary};
      font-size: ${(props) => props.theme.fontSizes.normal};
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  width: 100%;
`;

const EmpaquesSostenibles = () => {
  return (
    <Container>
      <Banner title="Empaques Sostenibles" backgroundImage={bannerImg} />
      <SectionWrapper>
        <Section>
          <div className="description">
            <p>
              En DISAN Agro hemos reducido nuestro impacto ambiental al
              minimizar la generación de residuos a través de nuestros empaques
              sostenibles que se alinean con nuestros valores medioambientales.
              <br />
              Nuestros empaques están diseñados con materiales y tecnologías que
              tienen un menor impacto en el medio ambiente y siguen cumpliendo
              con los estándares de calidad y funcionalidad necesarios, para
              proteger y preservar nuestros productos durante la producción y la
              distribución.
            </p>
          </div>
        </Section>
        <Section>
          <ColorTab />
          <Description>
            <img src={MayacertImg} alt="Logo Mayacert" />
            <div>
              <h2>¿Cómo estamos impactando?</h2>
              <ImpactList>
                <li>
                  <FaLeaf />
                  <p>
                    Reducimos el uso de plástico: Estamos comprometidos con la
                    reducción del uso de plásticos. Hemos logrado reducir el
                    consumo de plástico en un 24% con nuestro innovador empaque
                    Ecofilm.
                  </p>
                </li>
                <li>
                  <FaLeaf />
                  <p>
                    Fomentamos la economía circular: En DISAN Agro aplicamos la
                    economía circular, realizamos actividades de recolección de
                    sacos usados para reutilizarlos, contribuyendo así a cuidar
                    el medio ambiente y promover prácticas sostenibles.
                  </p>
                </li>
              </ImpactList>
            </div>
          </Description>
        </Section>
      </SectionWrapper>
      <SectionWrapper>
        <Section>
          <ColorTab />
          <ButtonWrapper>
            <ButtonLink to="/product-search">Ver Portafolio</ButtonLink>
          </ButtonWrapper>
        </Section>
      </SectionWrapper>
    </Container>
  );
};

export default EmpaquesSostenibles;
