import React, { useState } from "react";
import styled from "styled-components";
import { URL_SHEET } from "../utils/sheetGoogle";

const CardContainer = styled.div`
  color: white;
  background-color: ${(props) => props.theme.colors.yellow};
  width: 100%;
  padding: 5% 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CardTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 8px;
  text-align: center;
`;

const InputGroup = styled.div`
  margin-top: 5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  max-width: 500px;
  }
`;

const InputContainer = styled.div`
  width: 48%;
  margin-bottom: 16px;
`;

const InputField = styled.input`
  border: 0px solid transparent !important;
  border-radius: 50px !important;
  background-color: white;
  font-family: inherit;
  width: 100%;
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  ::placeholder {
    color: #999;
  }
`;

const TextAreaContainer = styled.div`
  width: 100%;
  max-width: 500px;
  margin-bottom: 16px;
`;

const TextArea = styled.textarea`
  width: 100%;
  background-color: white;
  text-align: center;
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 100px;
  border: 0px solid transparent !important;
  border-radius: 50px 0px 50px 0px !important;
  font-size: 14px;

  ::placeholder {
    color: #999;
  }
`;

const SubmitButton = styled.button`
  display: block;
  width: 100px;
  margin: 0 auto;
  padding: 10px;
  border: none;
  border-radius: 30px;
  background-color: white;
  color: ${(props) => props.theme.colors.yellow};
  font-family: inherit;
  font-size: ${(props) => props.theme.fontSizes.medium};
  cursor: pointer;

  &:hover {
    background-color: #f2f2f2;
  }
`;

const ContactCard = ({ title }) => {
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    country: "",
    email: "",
    phone: "",
    crop: "",
    message: "",
    type: "contacto",
  });

  // Validate form
  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Campo obligatrio";
    }

    if (!formData.company.trim()) {
      newErrors.company = "Campo obligatrio";
    }

    if (!formData.country.trim()) {
      newErrors.country = "Campo obligatrio";
    }

    if (!formData.phone.trim()) {
      newErrors.phone = "Campo obligatrio";
    }

    if (!formData.crop.trim()) {
      newErrors.crop = "Campo obligatrio";
    }

    if (!formData.email.trim()) {
      newErrors.email = "Campo obligatrio";
    } else if (
      /^[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,}$/.test(formData.email)
    ) {
      newErrors.email = "Ingrese una dirección de correo electrónico válida";
    }

    if (!formData.message.trim()) {
      newErrors.message = "Campo obligatrio";
    } else if (formData.message.trim().length < 10) {
      newErrors.message = "El mensaje debe tener al menos 10 caracteres";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: undefined }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      fetch(URL_SHEET, {
        method: "POST",
        body: JSON.stringify(formData),
      })
        .then((res) =>
          // Reiniciar el formulario
          setFormData({
            name: "",
            company: "",
            country: "",
            email: "",
            phone: "",
            crop: "",
            message: "",
          })
        )
        .catch((err) => console.log(err));
    }
  };

  return (
    <CardContainer>
      <CardTitle>{title}</CardTitle>
      <h2>
        CONSULTA TU <b>REPRESENTANTE DE VENTAS</b> AQUÍ
      </h2>
      <form onSubmit={handleSubmit}>
        <InputGroup>
          <InputContainer>
            <InputField
              type="text"
              placeholder="Nombre completo"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
            {errors && <p>{errors.name}</p>}
          </InputContainer>
          <InputContainer>
            <InputField
              type="text"
              placeholder="Empresa"
              name="company"
              value={formData.company}
              onChange={handleChange}
            />
            {errors && <p>{errors.company}</p>}
          </InputContainer>
          <InputContainer>
            <InputField
              type="text"
              placeholder="País"
              name="country"
              value={formData.country}
              onChange={handleChange}
            />
            {errors && <p>{errors.country}</p>}
          </InputContainer>
          <InputContainer>
            <InputField
              type="email"
              placeholder="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors && <p>{errors.email}</p>}
          </InputContainer>
          <InputContainer>
            <InputField
              type="tel"
              placeholder="Teléfono"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
            />
            {errors && <p>{errors.phone}</p>}
          </InputContainer>
          <InputContainer>
            <InputField
              type="text"
              placeholder="Cultivo de interés"
              name="crop"
              value={formData.crop}
              onChange={handleChange}
            />
            {errors && <p>{errors.crop}</p>}
          </InputContainer>
        </InputGroup>
        <TextAreaContainer>
          <TextArea
            placeholder="¿En qué podemos ayudarte?"
            name="message"
            value={formData.message}
            onChange={handleChange}
          />
          {errors && <p>{errors.message}</p>}
        </TextAreaContainer>
        <SubmitButton type="submit">Enviar</SubmitButton>
      </form>
    </CardContainer>
  );
};

export default ContactCard;
