import React from "react";
import styled from "styled-components";
import Banner from "../components/Banner";
import SectionWrapper from "../components/SectionWrapper";
import bannerImg from "../assets/nutricional/banner-planes.webp";
import nutritionPlans from "../data/nutritionPlans";
import { Link } from "react-router-dom";
import { Seo } from "../utils/Seo";
import { logEvent } from "../analytics";

// Contenedor principal
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  font-size: ${(props) => props.theme.fontSizes.normal};
`;

// Sección "Conoce más"
const Section = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  gap: 20px;
  padding: 5% 0;
`;

// Sección de los planes
const Planes = styled.div`
  display: grid;
  gap: 40px;
  grid-template-columns: repeat(3, 200px 6fr);
  grid-template-rows: repeat(2, auto);

  img {
    &:hover {
      transform: scale(1.1);
      cursor: pointer;
    }
  }

  /* Pantallas medianas */
  @media only screen and (max-width: 1300px) {
    grid-template-columns: repeat(2, 200px 4fr);
  }

  /* Pantallas de tablet */
  @media only screen and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  /* Pantallas de celulares */
  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`;

const PlanNutricional = () => {
  const handleCropClick = (cropname) => {
    logEvent("Soluciones", "Click en cultivo", `Cultivo: ${cropname}`);
  };

  return (
    <>
      <Seo
        title="Soluciones Tecnológicas para la Agricultura"
        type="product.group"
        description="Encuentra planes nutricionales adaptados a cada tipo de cultivo. En DISAN Agro, ofrecemos soluciones personalizadas para maximizar el rendimiento y garantizar cosechas de alta calidad."
      />
      <Container>
        <Banner
          title="Soluciones Tecnológicas para la Agricultura"
          subtitle="Planes Nutricionales"
          backgroundImage={bannerImg}
          backgroundPosition="top right"
        />
        <SectionWrapper>
          {/* <ColorTab /> */}
          <div style={{ marginTop: "30px" }}>
            <h2>Conoce nuestros planes de nutrición disponibles</h2>
          </div>

          <Section>
            <Planes>
              {nutritionPlans.map((plan, ind) => (
                <div key={ind}>
                  <Link
                    to={`/plan/${plan.name}`}
                    onClick={() => handleCropClick(plan.name)}
                  >
                    <img
                      src={plan.image}
                      alt={plan.name}
                      style={{ height: "auto", width: "150px" }}
                    />
                  </Link>
                  <h4 style={{ color: "gray" }}>{plan.name}</h4>
                </div>
              ))}
            </Planes>
          </Section>
        </SectionWrapper>
      </Container>
    </>
  );
};

export default PlanNutricional;
