import React from "react";
import styled from "styled-components";

// Contenedor principal del ícono
const IconContainer = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1); /* Efecto de zoom suave */
  }
`;

// Contenedor del SVG para asegurar tamaños uniformes
const IconSVG = styled.div`
  width: 64px;
  height: 64px; /* Asegura que todos los íconos tengan la misma altura */
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 100%;
    height: 100%;
    fill: ${(props) => props.theme.colors.white};
    transition: fill 0.3s ease-in-out;
  }
`;

// Título del ícono
const IconLabel = styled.span`
  margin-top: 0.5rem;
  font-size: ${(props) =>
    props.theme.fontSizes.normal}; /* Tamaño definido desde el tema */
  color: ${(props) => props.theme.colors.white}; /* Texto en blanco */
  text-align: center;
  font-family: Titillium Web, sans-serif;

  @media (min-width: 768px) {
    font-size: ${(props) =>
      props.theme.fontSizes
        .medium}; /* Tamaño ajustado para pantallas grandes */
  }
`;

// Componente funcional
const IconForSearch = ({ svg, title, onClick }) => {
  return (
    <IconContainer onClick={onClick}>
      <IconSVG>{svg}</IconSVG> {/* El SVG se pasa directamente como prop */}
      <IconLabel>{title}</IconLabel> {/* El título del ícono */}
    </IconContainer>
  );
};

export default IconForSearch;
