import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FaTimes, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import { useSwipeable } from "react-swipeable";

// Estilo del contenedor de la galería
const GalleryWrapper = styled.div`
  display: grid;
  gap: 0; /* Sin espacio entre imágenes */
  width: 100%;
  max-width: 900px; /* Limitar el ancho máximo a 3x3 */
  grid-template-columns: repeat(3, 1fr); /* Máximo 3 columnas */
  grid-auto-rows: 1fr; /* Filas automáticas para mantener proporción */
  overflow: hidden; /* Evita que las imágenes sobresalgan */
  place-self: anchor-center;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr); /* 2 columnas en tablets */
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr); /* 1 columna en móviles */
  }
`;

// Estilo para cada celda de imagen
const GalleryItem = styled.div`
  position: relative;
  overflow: hidden;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ajusta la imagen al contenedor */
    display: block; /* Evita espacios blancos */
  }
`;

// Estilo del contenedor para la imagen en grande
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Fondo opaco */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

// Estilo de la imagen grande con transición
const LargeImage = styled(motion.img)`
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
`;

// Estilo de los botones de navegación
const NavButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.7); /* Fondo blanco translúcido */
  border: none;
  color: #000;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center; /* Centra completamente el ícono */
  font-size: 20px;
  cursor: pointer;
  z-index: 1100;

  &:hover {
    background: rgba(255, 255, 255, 1);
  }

  &.left {
    left: 20px;
  }

  &.right {
    right: 20px;
  }
`;

// Estilo del botón de cierre
const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  z-index: 1200;

  &:hover {
    color: #ff6b6b; /* Rojo claro en hover */
  }
`;

// Componente principal
const Gallery = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(null);
  const [direction, setDirection] = useState(0); // 1 para derecha, -1 para izquierda
  const [animatedIndex, setAnimatedIndex] = useState(null); // Índice de la imagen animada

  useEffect(() => {
    // Configurar animación para una sola imagen
    const interval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * images.length);
      setAnimatedIndex(randomIndex);
    }, 2000); // Cambia de imagen cada 4 segundos

    return () => clearInterval(interval);
  }, [images.length]);

  const handleImageClick = (index) => {
    setCurrentIndex(index);
  };

  const handleNext = () => {
    setDirection(1);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length); // Siguiente imagen
  };

  const handlePrev = () => {
    setDirection(-1);
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    ); // Imagen anterior
  };

  const handleClose = () => {
    setCurrentIndex(null);
  };

  // Gestos táctiles
  const handlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
  });

  return (
    <>
      <GalleryWrapper>
        {images.slice(0, 9).map((image, index) => (
          <GalleryItem key={index} onClick={() => handleImageClick(index)}>
            <motion.img
              src={image}
              alt={`Gallery image ${index + 1}`}
              animate={{
                scale: animatedIndex === index ? 1.2 : 1, // Animar solo la imagen seleccionada
              }}
              transition={{
                duration: 0.5,
              }}
            />
          </GalleryItem>
        ))}
      </GalleryWrapper>

      {currentIndex !== null && (
        <Overlay {...handlers}>
          <CloseButton onClick={handleClose}>
            <FaTimes />
          </CloseButton>
          <NavButton className="left" onClick={handlePrev}>
            <FaChevronLeft />
          </NavButton>
          <AnimatePresence>
            <LargeImage
              key={images[currentIndex]}
              src={images[currentIndex]}
              alt="Selected"
              initial={{ x: direction === 1 ? 300 : -300, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: direction === 1 ? -300 : 300, opacity: 0 }}
              transition={{ duration: 0.1 }}
            />
          </AnimatePresence>
          <NavButton className="right" onClick={handleNext}>
            <FaChevronRight />
          </NavButton>
        </Overlay>
      )}
    </>
  );
};

export default Gallery;
