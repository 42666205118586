import { useEffect } from "react";
import { initializeGA, logPageView } from "./analytics";
import { Route, Routes, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import Header from "./components/Header";
import Home from "./pages/Home";
import ProductDetail from "./pages/ProductDetail";
import ProductSearch from "./pages/ProductSearch";
import About from "./pages/About";
import Blog from "./pages/Blog";
import Footer from "./components/Footer";
import BlogDetail from "./pages/BlogDetail";
import DisanSiembra from "./pages/DisanSiembra";
import Empaques from "./pages/EmpaquesSostenibles";
import PactoGlobal from "./pages/PactoGlobal";
import CertificacionOrganica from "./pages/CertificacionOrganica";
import ScrollToTop from "./utils/ScrollToTop"; // Importar el nuevo componente
import FloatingWhatsAppButton from "./components/WppButton";
import PlanNutricional from "./pages/PlanesNutricionales";
import PlanDetail from "./pages/PlanDetail";
import ContactUs from "./pages/ContactUs";
import NotFound from "./pages/NotFound";

function App() {
  const location = useLocation();
  useEffect(() => {
    initializeGA();
  }, []);

  useEffect(() => {
    logPageView(location.pathname);
  }, [location]);
  return (
    <>
      <Header />
      <ScrollToTop /> {/* Agregar aquí el componente para manejar el scroll */}
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route
            path="/"
            element={
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <Home />
              </motion.div>
            }
          />
          <Route
            path="/about-us"
            element={
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <About />
              </motion.div>
            }
          />
          <Route
            path="/soluciones-tecnologicas"
            element={
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <PlanNutricional />
              </motion.div>
            }
          />
          <Route
            path="/disan-siembra"
            element={
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <DisanSiembra />
              </motion.div>
            }
          />
          <Route
            path="/empaques-sostenibles"
            element={
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <Empaques />
              </motion.div>
            }
          />
          <Route
            path="/certificacion-organica"
            element={
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <CertificacionOrganica />
              </motion.div>
            }
          />
          <Route
            path="/pacto-global"
            element={
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <PactoGlobal />
              </motion.div>
            }
          />
          <Route
            path="/blog"
            element={
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <Blog />
              </motion.div>
            }
          />
          <Route
            path="/blog/:slug"
            element={
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <BlogDetail />
              </motion.div>
            }
          />
          <Route
            path="/product-search"
            element={
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <ProductSearch />
              </motion.div>
            }
          />
          <Route
            path="/product/:productName"
            element={
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <ProductDetail />
              </motion.div>
            }
          />

          <Route
            path="/plan/:planName"
            element={
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <PlanDetail />
              </motion.div>
            }
          />

          <Route
            path="/contacto"
            element={
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <ContactUs />
              </motion.div>
            }
          />
          <Route
            path="*"
            element={
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <NotFound />
              </motion.div>
            }
          />
        </Routes>
        <FloatingWhatsAppButton />
      </AnimatePresence>
      <Footer />
    </>
  );
}

export default App;
