import React from "react";
import styled from "styled-components";

// Contenedor principal para los aliados
const AlliesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5%;
  gap: 20px;
`;

// Título de la sección
const Title = styled.h2`
  color: ${(props) => props.theme.colors.primary};
  font-size: 2rem;
  margin-bottom: 20px;
`;

// Contenedor de los logos
const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap; /* Permite que los elementos se ajusten a nuevas líneas */
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  gap: 20px;
  width: 100%;
  max-width: 1200px;
`;

// Estilo para cada logo
const Logo = styled.img`
  width: auto;
  max-width: 150px;
  height: auto;
  max-height: 100px;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1); /* Efecto de zoom */
    opacity: 0.9; /* Suavidad */
  }

  @media (max-width: 768px) {
    max-width: 100px;
    max-height: 80px;
  }
`;

// Componente principal
const AlliesFlex = ({ images }) => {
  return (
    <AlliesContainer>
      <Title>Aliados</Title>
      <FlexContainer>
        {images.map((image, index) => (
          <Logo key={index} src={image} alt={`Aliado ${index + 1}`} />
        ))}
      </FlexContainer>
    </AlliesContainer>
  );
};

export default AlliesFlex;
