import React, { useState, useEffect, useMemo, useCallback } from "react";
import styled from "styled-components";
import normalizeString, {
  normalizeStringCapitalize,
} from "../utils/StringNormalization";
import Dropdown from "../components/dropDownMenu";
import BulletList from "../components/bulletList";
import ProductList from "../components/ProductList";
import Icon from "../components/IconsForSearch";
import searchIcon from "../assets/search-icon.png";
import ContactCard from "../components/ContactCard";
import { ReactComponent as Edaficos } from "../assets/new icons/edaficos.svg";
import { ReactComponent as Foliares } from "../assets/new icons/foliares.svg";
import { ReactComponent as Biofertilizantes } from "../assets/new icons/biofertilizantes.svg";
import { ReactComponent as Solubles } from "../assets/new icons/solubles.svg";
import { ReactComponent as Sustratos } from "../assets/new icons/sustratos.svg";
import { ReactComponent as Pqua } from "../assets/new icons/pqua icon.svg";

import { ReactComponent as ControlBiologico } from "../assets/new icons/control-biologico.svg";
import productsData from "../data/newProducts";
import { shuffle } from "../utils/ArrayUtils"; // Crea una función para mezclar los productos aleatoriamente
import ModalCountries from "../components/Modal";
import { motion, AnimatePresence } from "framer-motion";
import bannerProm from "../assets/Banner promocional.webp";
import DropdownCountries from "../components/DrownMenuCountries";
import { Seo } from "../utils/Seo";
import { logEvent } from "../analytics";

const ProductSearch = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCultivations, setSelectedCultivations] = useState([]);
  const [selectedHierarchy, setSelectedHierarchy] = useState("");
  const [selectedComponent, setSelectedComponent] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const recommendedProducts = shuffle(productsData).slice(0, 6); // Selecciona 6 productos aleatorios

  const handleChangeModal = useCallback((value) => {
    return setOpenModal(value);
  }, []);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      },
      (error) => {
        if (error.PERMISSION_DENIED) {
          handleChangeModal(true);
        }
      }
    );
  }, [handleChangeModal]);

  useEffect(() => {
    if (latitude && longitude) {
      const getCountry = async () => {
        try {
          const response = await fetch(
            `http://ip-api.com/json/?lat=${latitude}&lon=${longitude}`
          );
          const data = await response.json();
          if (data.country) {
            setSelectedCountry(data.country);
            const url = new URL(window.location);
            url.searchParams.set("country", data.country);
            window.history.pushState({}, "", url);
          }
        } catch (error) {
          console.error("Error obteniendo el país:", error);
          handleChangeModal(true);
        }
      };
      getCountry();
    }
  }, [handleChangeModal, latitude, longitude]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const query = urlParams.get("q"); // Obtener el valor de búsqueda desde la URL
    const hierarchy = urlParams.get("hierarchy");
    const component = urlParams.get("component");
    const country = urlParams.get("country");
    const cultivations = urlParams.getAll("cultivation");

    if (query) {
      setSearchTerm(query);
      logEvent(
        "portfolio",
        "buscar producto",
        `Termino busqueda: ${searchTerm}`
      ); // Enviar búsqueda a GA4
    }
    if (hierarchy) {
      setSelectedHierarchy(hierarchy);
    }
    if (component) {
      setSelectedComponent(component);
    }
    if (country) {
      setSelectedCountry(country);
    }
    if (cultivations.length > 0) {
      setSelectedCultivations(cultivations);
    }
  }, [searchTerm]);

  useEffect(() => {
    setSelectedComponent(""); // Reinicia la subcategoría al cambiar de categoría principal
  }, [selectedHierarchy]);

  const countries = [
    { value: "Colombia", label: "Colombia" },
    { value: "Peru", label: "Perú" },
    { value: "Bolivia", label: "Bolivia" },
    { value: "Venezuela", label: "Venezuela" },
    { value: "Panama", label: "Panamá" },
    { value: "Ecuador", label: "Ecuador" },
  ];

  // Definir variantes de animación
  const fadeVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0, transition: { duration: 0.4 } },
    exit: { opacity: 0, y: -20, transition: { duration: 0.3 } },
  };
  const cultivation = [
    "Aguacate",
    "Arroz",
    "Banano",
    "Berries",
    "Café",
    "Cannabis Medicinal",
    "Caña de azúcar",
    "Cacao",
    "Cereales",
    "Esparrago",
    "Flores",
    "Flores de Corte",
    "Frutales",
    "Gramineas",
    "Hortalizas",
    "Maíz",
    "Mango",
    "Musaceas",
    "Palma de Aceite",
    "Papa",
    "Perennes",
    "Soya",
    "Solanaceas",
    "Cultivos en desarrollo",
    "Cultivos en etapa de producción",
  ];

  const hierarchyLabels = {
    organicos: {
      title: "Orgánicos",
      description:
        "Productos naturales y sostenibles para mejorar la fertilidad del suelo y la salud de las plantas.",
    },
    edáfico: {
      title: "Edáficos",
      description:
        "Productos que mejoran la salud del suelo y su estructura para un mejor rendimiento agrícola.",
    },
    foliar: {
      title: "Foliares",
      description:
        "Fertilizantes aplicados directamente a las hojas para una absorción más rápida y eficiente.",
    },
    quelatos: {
      title: "Complejos y Quelatos",
      description:
        "Nutrientes quelatados para una mejor absorción y asimilación por parte de las plantas.",
    },
    pqua: {
      title: "PQUA",
      description:
        "Productos para la protección de cultivos y la salud de las plantas.",
    },
    coadyuvantes: {
      title: "Coadyuvantes",
      description:
        "Sustancias que mejoran la aplicación y efectividad de los productos agrícolas.",
    },
    sustratos: {
      title: "Sustratos",
      description:
        "Medios de cultivo diseñados para mejorar el desarrollo radicular y la retención de nutrientes.",
    },
    "control biologico": {
      title: "Control Biológico",
      description:
        "Productos y métodos para el control de plagas y enfermedades de forma natural y sostenible.",
    },
    biofertilizantes: {
      title: "Biofertilizantes",
      description:
        "Productos orgánicos que mejoran la fertilidad del suelo y la salud de las plantas.",
    },
    solubles: {
      title: "Solubles",
      description:
        "Fertilizantes en forma de polvo o cristales solubles en agua para una rápida absorción.",
    },
  };

  const categoryTitle = selectedHierarchy
    ? hierarchyLabels[selectedHierarchy]?.title || "Categoría no encontrada"
    : "Explora nuestras categorías";

  const categoryDescription = selectedHierarchy
    ? hierarchyLabels[selectedHierarchy]?.description ||
      "Selecciona una categoría para ver más detalles."
    : "Selecciona una categoría para filtrar productos y encontrar lo que necesitas.";

  const subcategoriesByHierarchy = {
    "control biologico": ["Fungicida", "Insecticida"],
    edáfico: [
      "Acondicionadores del suelo",
      "Nitrógeno",
      "Nitrógeno Orgánico",
      "Potasio",
      "Fósforo",
      "Magnesio",
      "Silicio",
      "Complejos NPK / Menores",
      "Mezclas Físicas NPK",
    ],
    biofertilizantes: ["Movilizadores fósforo", "Promotor crecimiento"],
    foliar: [
      "Bioestimulantes",
      "Nutrientes",
      "Quelatos y Complejos",
      "Protección Solar",
      "Inductores de Resistencia",
      "Reguladores de crecimiento",
      "Silicio",
    ],
    solubles: [
      "Acondicionadores de suelo",
      "Tecnologías eficientes",
      "Fósforo",
      "Potasio",
      "Magnesio",
      "Calcio",
      "Boro",
      "Compuestos NPK",
    ],
    pqua: [
      "Inseticida",
      "Fungicida",
      "Acaricida",
      "Tensoactivo - Humectante",
      "Correctores de Agua",
      "Orgánico",
    ],
    sustratos: [
      "Macetas biodegradables",
      "Pellets de turba",
      "Plugs de turba y coco",
      "Sustratos para germinación",
    ],
  };

  const filteredSubcategories = selectedHierarchy
    ? subcategoriesByHierarchy[selectedHierarchy] || []
    : [];

  const handleResetFilters = () => {
    setSearchTerm("");
    // setSelectedCountry(""); // Reinicia el filtro de país
    setSelectedCultivations([]); // Reinicia el filtro de cultivos (BulletList)
    setSelectedHierarchy(""); // Reinicia la jerarquía
    setSelectedComponent(""); // Reinicia el filtro de nutrientes

    // Reiniciar la URL eliminando los parámetros
    const url = new URL(window.location);
    url.searchParams.delete("hierarchy");
    url.searchParams.delete("component");
    // url.searchParams.delete("country");
    url.searchParams.delete("cultivation");
    window.history.pushState({}, "", url);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value.length >= 3) {
      console.log(e.target.value);
      logEvent(
        "portfolio",
        "buscar producto",
        `Termino busqueda: ${searchTerm}`
      ); // Enviar búsqueda a GA4
    }
  };

  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    logEvent("portfolio", "selección de país", `PaÍs: ${country}`); // Enviar país a GA4
    const url = new URL(window.location);
    url.searchParams.set("country", country);
    window.history.pushState({}, "", url);
    handleChangeModal(false);
  };

  const handleResetCountry = () => {
    setSelectedCountry("");

    // Reiniciar la URL
    const url = new URL(window.location);
    url.searchParams.delete("country");
    window.history.pushState({}, "", url);
    handleChangeModal(true);
  };

  const handleCultivationSelect = (items) => {
    setSelectedCultivations(items);
    logEvent("portfolio", "selección de cultivo", `Cultivo: ${items}`);
    const url = new URL(window.location);
    url.searchParams.delete("cultivation");
    items.forEach((cultivation) =>
      url.searchParams.append("cultivation", cultivation)
    );
    window.history.pushState({}, "", url);
  };

  const handleIconClick = (hierarchy) => {
    setSelectedHierarchy(hierarchy);
    logEvent("portfolio", "selección de categoría", `Categoría: ${hierarchy}`);
    const url = new URL(window.location);
    url.searchParams.set("hierarchy", hierarchy);
    window.history.pushState({}, "", url);
  };

  // Manejar la selección de nutrientes (componentes)
  const handleComponentSelect = (component) => {
    logEvent(
      "portfolio",
      "selección de subcategoría",
      `Subcategoría: ${component}`
    );
    setSelectedComponent(component);
    const url = new URL(window.location);
    url.searchParams.set("component", component);
    window.history.pushState({}, "", url);
  };

  // Filtrar productos usando useMemo para evitar recalculaciones innecesarias
  const filteredProducts = useMemo(() => {
    return productsData.filter((product) => {
      const normalizedSearchTerm = normalizeString(searchTerm);

      const matchesDescription = searchTerm
        ? normalizeString(product.description).includes(normalizedSearchTerm) ||
          Object.values(product.composition)
            .map(normalizeString)
            .join(" ")
            .includes(normalizedSearchTerm) ||
          normalizeString(product.name).includes(normalizedSearchTerm)
        : true;

      const matchesCountrySelected = selectedCountry
        ? product.countries
            .map(normalizeStringCapitalize)
            .includes(selectedCountry)
        : true;

      const matchesCultivation =
        selectedCultivations.length > 0
          ? selectedCultivations.some((cultivation) =>
              product.cultivation.includes(cultivation)
            )
          : true;

      const matchesHierarchy = selectedHierarchy
        ? normalizeString(selectedHierarchy)
            .split(" ") // Divide en palabras individuales
            .every(
              (word) => normalizeString(product.hierarchy).includes(word) // Verifica que cada palabra esté en hierarchy
            )
        : true;

      const matchesComponent = selectedComponent
        ? normalizeString(selectedComponent)
            .split(" ") // Divide en palabras
            .every(
              (word) => normalizeString(product.hierarchy).includes(word) // Busca cada palabra en la jerarquía
            )
        : true;

      return (
        matchesDescription &&
        matchesCountrySelected &&
        matchesCultivation &&
        matchesHierarchy &&
        matchesComponent
      );
    });
  }, [
    searchTerm,
    selectedCountry,
    selectedCultivations,
    selectedHierarchy,
    selectedComponent,
  ]);

  function corregirTildes(text) {
    const correcciones = {
      "\\bPeru\\b": "Perú",
      "\\bPanama\\b": "Panamá",
    };

    for (let letter in correcciones) {
      let regex = new RegExp(letter, "gi"); // 'gi' para búsqueda global e ignorar mayúsculas/minúsculas
      text = text.replace(regex, correcciones[letter]);
    }

    return text;
  }

  return (
    <>
      <Seo
        title="Portafolio"
        type="product.group"
        description="Explora el portafolio completo de DISAN Agro: fertilizantes, bioestimulantes y soluciones innovadoras para cada etapa del cultivo. Productos diseñados para potenciar tu productividad agrícola."
      />
      {openModal && (
        <ModalCountries
          isOpen={openModal}
          onClose={() => handleChangeModal(false)}
          children={
            <DropdownCountries
              items={countries}
              onSelect={handleCountrySelect}
              placeholder={"Todos los países"}
              selectedItem={selectedCountry || ""}
            />
          }
        />
      )}

      <Container>
        <TopContainer>
          <AnimatePresence mode="wait">
            {selectedHierarchy ? (
              <>
                <motion.h1
                  key={categoryTitle}
                  variants={fadeVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                >
                  {categoryTitle}
                </motion.h1>
                <motion.p>{categoryDescription}</motion.p>
              </>
            ) : (
              <motion.h1
                key="default-title"
                variants={fadeVariants}
                initial="initial"
                animate="animate"
                exit="exit"
              >
                Explora nuestras categorías
              </motion.h1>
            )}
          </AnimatePresence>
          <IconsContainer>
            <Icon
              svg={<Edaficos />}
              title="Edáficos"
              onClick={() => handleIconClick("edáfico")}
              isActive={selectedHierarchy === "edáfico"}
            />
            <Icon
              svg={<Solubles />}
              title="Solubles"
              onClick={() => handleIconClick("solubles")}
              isActive={selectedHierarchy === "solubles"}
            />
            <Icon
              svg={<Foliares />}
              title="Foliares"
              onClick={() => handleIconClick("foliar")}
              isActive={selectedHierarchy === "foliar"}
            />
            <Icon
              svg={<Sustratos />}
              title="Sustratos"
              onClick={() => handleIconClick("sustratos")}
              isActive={selectedHierarchy === "sustratos"}
            />
            <Icon
              svg={<ControlBiologico />}
              title="Control Biológico"
              onClick={() => handleIconClick("control biologico")}
              isActive={selectedHierarchy === "control biologico"}
            />
            <Icon
              svg={<Biofertilizantes />}
              title="Biofertilizantes"
              onClick={() => handleIconClick("biofertilizantes")}
              isActive={selectedHierarchy === "biofertilizantes"}
            />
            <Icon
              svg={<Pqua />}
              title="PQUA"
              onClick={() => handleIconClick("pqua")}
              isActive={selectedHierarchy === "PQUA"}
            />
          </IconsContainer>
          <Input
            type="text"
            placeholder="Buscar producto o nutriente"
            value={searchTerm}
            onChange={handleSearch}
          />
        </TopContainer>
        <PromoContainer>
          <img src={bannerProm} alt="Banner promocional" />
        </PromoContainer>
        <BottomContainer>
          <FilterContainer>
            {!selectedCountry ? (
              <BulletCountry>
                <span onClick={() => handleChangeModal(true)}>
                  Seleccionar país
                </span>
              </BulletCountry>
            ) : (
              <ContainerCountry>
                <h2>País: </h2>
                <HierarchyBullet>
                  {corregirTildes(selectedCountry)}
                  <span onClick={() => handleResetCountry()}>✖</span>
                </HierarchyBullet>
              </ContainerCountry>
            )}

            {selectedHierarchy && (
              <>
                <h2>Categoria Seleccionada</h2>
                <HierarchyBullet>
                  {hierarchyLabels[selectedHierarchy]?.title}
                  <span onClick={handleResetFilters}>✖</span>
                </HierarchyBullet>
              </>
            )}

            {/* Dropdown de SubCategoria */}
            {selectedHierarchy && (
              <Dropdown
                items={filteredSubcategories}
                title={"Subcategoría"}
                onSelect={handleComponentSelect}
                placeholder={"Seleccionar"}
                selectedItem={selectedComponent || ""}
              />
            )}

            <BulletList
              key={`cultivation-reset`} // Cambiar la clave para forzar el renderizado
              title={"Cultivos"}
              items={cultivation}
              onSelect={handleCultivationSelect}
              maxVisible={5}
              showMoreText={"Más cultivos..."}
              selectedItems={selectedCultivations} // Aquí se pasa el valor de selectedCultivations
            />

            <ResetButton onClick={handleResetFilters}>
              Reiniciar Filtros
            </ResetButton>
          </FilterContainer>

          <ProductList
            products={
              filteredProducts.length > 0
                ? filteredProducts
                : recommendedProducts
            }
            showRecommendation={filteredProducts.length === 0}
            country={selectedCountry}
          />
        </BottomContainer>
        <ContactCard title="¿Te interesa algún producto?" />
      </Container>
    </>
  );
};

const PromoContainer = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  img {
    width: 100%;
    height: auto;
  }
`;

const BulletCountry = styled.div`
  background-color: ${(props) => props.theme.colors.green};
  color: ${(props) => props.theme.colors.white};
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  gap: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.yellow};
  }
`;

const HierarchyBullet = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.green};
  color: ${(props) => props.theme.colors.white};
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  gap: 8px;

  span {
    cursor: pointer;
    font-weight: bold;

    &:hover {
      scale: 1.1;
      animation: 0.2s ease-in-out;
    }
  }
`;

const ResetButton = styled.button`
  background-color: ${(props) => props.theme.colors.red};
  color: ${(props) => props.theme.colors.primary};
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;

  &:hover {
    background-color: ${(props) => props.theme.colors.darkRed};
  }
`;

const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  color: ${(props) => props.theme.colors.primary};
  text-align: left;
  justify-content: center;
  align-items: flex-start;
  padding: 5% 2%;
  width: fit-content;

  h4 {
    margin-bottom: 10px;
  }

  @media (min-width: 768px) {
    flex-direction: column;
    max-width: 30vw;
    justify-content: flex-start;
  }
  * {
    font-size: 14px;
  }
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const IconsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  gap: 1rem;
  max-width: 70vw;
  @media (min-width: 768px) {
    justify-content: center;
    gap: 50px;
    width: 100%;
  }
`;

const TopContainer = styled.div`
  background-color: ${(props) => props.theme.colors.green};
  color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5% 2%;
  gap: 20px;
`;

const Input = styled.input`
  font-family: Titillium Web, sans-serif;
  width: 100%;
  max-width: 70vw;
  border: none;
  padding: 10px;
  margin: 0;
  background-image: url(${searchIcon});
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 20px;
  background-origin: content-box;
  z-index: 1;
  box-shadow: none;
  border-radius: 20px;
  color: ${(props) => props.theme.colors.green};
  font-weight: semibold;
  font-size: 1.1rem;
  text-align: inherit;
  appearance: none;

  &:checked,
  &:indeterminate {
    appearance: none;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ContainerCountry = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

export default ProductSearch;
