import ReactGA from "react-ga4";

export const initializeGA = () => {
  ReactGA.initialize("G-EHRVJ3CXKL"); //ID de medición GA4
};

export const logPageView = (path) => {
  ReactGA.send({ hitType: "pageview", page: path });
};

export const logEvent = (category, action, label = "") => {
  ReactGA.event({
    category,
    action,
    label,
  });
};
