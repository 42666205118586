import React from "react";
import { useParams } from "react-router-dom";
import nutritionPlan from "../data/nutritionPlans";
import styled from "styled-components";
import { BsDownload } from "react-icons/bs";
import Banner from "../components/Banner";
import bannerImg from "../assets/nutricional/banner-planes.webp";
import { Seo } from "../utils/Seo";
import { logEvent } from "../analytics";

const LeftSide = styled.div`
  width: 100%;
  max-width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden; /* Evita que el zoom sobresalga */

  .product-image {
    width: 100%;
    max-width: 300px; /* Tamaño máximo */
    height: 300px; /* Altura fija para uniformidad */
    object-fit: contain; /* Ajusta la imagen dentro sin recortarla */
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: scale(1.1); /* Suave efecto de zoom */
    }

    @media (max-width: 768px) {
      max-width: 100%; /* En pantallas pequeñas, la imagen ocupa todo el ancho */
      height: auto; /* La altura se ajusta automáticamente */
    }
  }
`;
const RightSide = styled.div`
  width: 100%;
  max-width: 50%;
  text-align: left;
`;

const BulletContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  gap: 5px;
  width: 100%;
  padding: 0 !important;
`;
const Bullet = styled.button`
  padding: 5px 10px !important;
  border: 1px solid #ccc;
  border-radius: 20px;
  cursor: pointer;
  background-color: white;
  color: ${(props) => props.theme.colors.primary};
  &:hover {
    color: white;
    border-color: ${(props) => props.theme.colors.yellow};
    transition: 0.2s;
    background-color: ${(props) => props.theme.colors.yellow};
  }
`;

const DownloadButton = styled.a`
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.green};
  width: 100%;
  cursor: pointer;
  border-radius: 20px;
  max-width: 150px;
  display: flex;
  justify-content: center;
  padding: 9px 9px !important;
  align-items: center;
  border: 2px solid ${(props) => props.theme.colors.green};
  gap: 5px;

  svg {
    fill: ${(props) => props.theme.colors.green};
    width: 24px;
    height: auto;
    @media (max-width: 768px) {
      width: 32px;
      height: 32px;
    }
  }
`;

const Top = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  .links {
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: space-between;
    .link {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  .techLinks {
    display: flex;
    align-items: flex-end;
    .link {
      width: 100%;
    }
  }
  img {
    width: 100%;
    max-width: 300px;
    height: auto;
  }
`;
const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  * {
    padding: 2% 2%;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: ${(props) => props.theme.colors.primary};
  * {
  }
  h2 {
    font-size: ${(props) => props.theme.fontSizes.xlarge};
    font-weight: 400;
  }
`;

const PlanDetail = () => {
  const { planName } = useParams();
  const plan = nutritionPlan.find((p) => p.name === planName);

  return (
    <>
      <Seo
        title={plan.name}
        type="product"
        description={`Conoce el plan de nutrición especializado para ${plan.name}. DISAN Agro ofrece soluciones adaptadas a cada etapa del crecimiento para obtener cosechas más productivas y sostenibles.`}
      />
      <Container>
        <Banner title="Plan de Nutrición" backgroundImage={bannerImg} />
        <DescriptionContainer>
          <Top>
            <LeftSide>
              <img src={"../" + plan.image} alt={plan.name} />
              <div className="techLinks">
                {plan.fenograma && plan.fenograma !== "" ? (
                  <div className="link">
                    <h4>Plan de nutrición</h4>
                    <DownloadButton
                      href={plan.fenograma}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() =>
                        logEvent(
                          "Descarga",
                          "Click en fenograma",
                          `Plan de Nutrición - ${plan.name}`
                        )
                      }
                    >
                      <BsDownload />
                      Descargar
                    </DownloadButton>
                  </div>
                ) : null}
              </div>
            </LeftSide>

            <RightSide>
              <div className="top">
                <div className="tile">
                  <h2>{plan.name}</h2>
                  <div className="product-desc">
                    <p style={{ whiteSpace: "pre-line" }}>{plan.description}</p>
                  </div>
                </div>
                <div>
                  <h2>Productos</h2>
                  <BulletContainer>
                    {plan.products.map((item) => (
                      // <Link
                      //   to={`/product/${item}`}
                      //   style={{ padding: "0", margin: "0" }}
                      // >
                      <Bullet key={item}>{item}</Bullet>
                      // </Link>
                    ))}
                  </BulletContainer>
                </div>
              </div>
            </RightSide>
          </Top>
        </DescriptionContainer>
      </Container>
    </>
  );
};

export default PlanDetail;
