import React from "react";
import styled from "styled-components";
import Banner from "../components/Banner";
import ColorTab from "../components/colorTab";
import SectionWrapper from "../components/SectionWrapper";
import bannerImg from "../assets/sostenibilidad/banner-mayacert.jpg";
import MayacertImg from "../assets/sostenibilidad/logo-mayacert.png";
import SelloOrganico from "../assets/sostenibilidad/Sello organico.png";
import LogoCAAE from "../assets/sostenibilidad/logo-caae.png";
import LogoKiwa from "../assets/sostenibilidad/kiwa-logo.png";
import { Seo } from "../utils/Seo";

// Contenedor principal
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  font-size: ${(props) => props.theme.fontSizes.normal};
`;

// Sección "Conoce más"
const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 2% 0;
  position: relative;
  // max-width: 70vw;

  h2 {
    color: ${(props) => props.theme.colors.primary};
    font-size: ${(props) => props.theme.fontSizes.large};
    font-weight: bold;
  }

  p {
    color: ${(props) => props.theme.colors.primary};
    font-size: ${(props) => props.theme.fontSizes.normal};
  }

  img {
    max-width: 300px;
  }
`;

// Contenedor para la descripción
const Description = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.$reverse ? "column-reverse" : "column")};
  align-items: center;
  gap: 20px;
  padding: 5% 2%;

  @media (min-width: 768px) {
    flex-direction: row;
    padding: 5% 5%;
    gap: 10%;
  }

  img {
    width: 100%;
    max-width: 300px;
    height: auto;
  }

  div {
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;

    @media (min-width: 768px) {
      text-align: left;
      max-width: 600px;
    }
  }
`;

const Mayacert = () => {
  return (
    <>
      <Seo
        title="Certificaciones"
        type="website"
        description="Conoce las certificaciones de DISAN Agro que respaldan nuestro compromiso con la sostenibilidad y la excelencia. Cumplimos con los más altos estándares internacionales para ofrecer productos de calidad que impulsan una agricultura responsable."
      />
      <Container>
        <Banner
          title="Productos con Certificación Orgánica"
          backgroundImage={bannerImg}
        />
        <SectionWrapper>
          <Section>
            <Description $reverse>
              <div>
                <h2>Certificaciones Orgánicas</h2>
                <p>
                  En DISAN AGRO, apostamos por la sostenibilidad agrícola
                  ofreciendo productos certificados para el uso en agricultura
                  orgánica que cumplen con estándares internacionales y
                  responden a la creciente demanda del mercado por insumos
                  sostenibles. Esto nos permite fortalecer la competitividad de
                  nuestros clientes, fomentar prácticas agrícolas responsables y
                  acceder a nuevos segmentos de mercado, posicionándonos como
                  líderes en innovación y sostenibilidad.​
                  <br />
                  <br />
                  En la actualidad DISAN AGRO cuenta con un portafolio de
                  productos con certificación de compatibilidad para el uso en
                  la Agricultura Orgánica avalados por organismos certificadores
                  como Mayacert, Kiwa y CAAE.
                  <br />
                  <br />
                </p>
              </div>
              <img src={SelloOrganico} alt="Logo Mayacert" />
            </Description>
          </Section>
        </SectionWrapper>

        <SectionWrapper>
          <h2>Conoce nuestras certificaciones</h2>
          <Section>
            <Description $reverse>
              <img src={MayacertImg} alt="Logo Mayacert" />
              <div>
                <h2>Certificación de excelencia de Latinoamérica y el mundo</h2>
                <p>
                  "Somos líderes en la multi-certificación de sistemas de
                  productos agropecuarios, proceso e industrialización para
                  pequeños, medianos y grandes productores, procesadores,
                  comercializadores y exportadores de alimentos."​
                </p>
                <a
                  href="https://www.mayacert.com/​"
                  target="_blank"
                  rel="noopener noreferrer"
                  re
                >
                  Conoce más
                </a>
              </div>
            </Description>
          </Section>
          <Section>
            <ColorTab />
            <Description>
              <div>
                <h2>Garantía global en certificación ecológica</h2>
                <p>
                  "CAAE es una entidad de ámbito internacional en certificación
                  con un enfoque global para un mundo más orgánico, que ofrece
                  servicios que cubren todas las necesidades del sector."​
                </p>
                <a
                  href="https://www.caae.es/acerca-del-caae​"
                  target="_blank"
                  rel="noopener noreferrer"
                  re
                >
                  Conoce más
                </a>
              </div>
              <img src={LogoCAAE} alt="Logo caae" />
            </Description>
          </Section>
          <Section>
            <ColorTab />
            <Description $reverse>
              <img src={LogoKiwa} alt="Logo KIWA" />
              <div>
                <h2>Certificación de Kiwa: equipados para el futuro</h2>
                <p>
                  "Como cualquier buena empresa, usted garantiza la calidad que
                  suministra. Después de todo, sus productos, servicios,
                  personal y procesos cumplen todas las normas y
                  reglamentaciones en materia de seguridad, eficiencia, control
                  y sostenibilidad."​
                </p>
                <a
                  href="https://www.kiwa.com/lat/es/tipo-de-servicio/certificacion/​"
                  target="_blank"
                  rel="noopener noreferrer"
                  re
                >
                  Conoce más
                </a>
              </div>
            </Description>
          </Section>
        </SectionWrapper>
      </Container>
    </>
  );
};

export default Mayacert;
