import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, EffectFade } from "swiper/modules";
import styled from "styled-components";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "swiper/css/pagination";

const SlideImage = styled.img`
  width: 100%;
  height: 250px; /* Establece un alto uniforme */
  max-width: 500px;
  display: block;
  object-fit: contain; /* Mantiene la relación de aspecto */
  background-color: transparent; /* Fondo transparente */
  margin: auto; /* Centra las imágenes */
`;

const SwiperContainer = styled(Swiper)`
  display: flex;
  justify-content: center;
  align-items: center;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent; /* Fondo transparente */
    opacity: 0; /* Oculta las diapositivas inactivas */
    transition: opacity 0.5s ease-in-out; /* Transición suave */
  }

  .swiper-slide-active {
    opacity: 1; /* La diapositiva activa es visible */
  }
`;

const ImageCarousel = () => {
  const slides = [
    { src: "/productsImg/optimized/patentkali.webp", alt: "Patentkali" },
    { src: "/productsImg/optimized/fylloton.webp", alt: "Fylloton" },
    {
      src: "/productsImg/optimized/Protamin Granum.webp",
      alt: "Protamin Granum",
    },
    { src: "/productsImg/optimized/folicist.webp", alt: "Folicist" },
    {
      src: "/productsImg/optimized/Sulfato de potasio granular.webp",
      alt: "Sulfato de potasio granular",
    },
  ];

  return (
    <SwiperContainer
      modules={[Navigation, Pagination, Autoplay, EffectFade]}
      effect="fade"
      spaceBetween={0}
      slidesPerView={1}
      autoplay={{ delay: 2000, disableOnInteraction: false }}
      fadeEffect={{ crossFade: true }}
      loop={true}
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={index}>
          <SlideImage src={slide.src} alt={slide.alt} />
        </SwiperSlide>
      ))}
    </SwiperContainer>
  );
};

export default ImageCarousel;
