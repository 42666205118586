import React from "react";
import styled from "styled-components";
import CountUp from "react-countup";

// Estilo del contenedor principal
const MetricsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 20px;
  padding: 0 10px;

  .section {
    margin-bottom: 30px;
    @media (min-width: 768px) {
      width: 100%;
    }
  }

  .divider {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 20px 0;

    span {
      flex: 1;
      height: 1px;
      background-color: ${(props) => props.theme.colors.secondary};
    }

    p {
      margin: 0 10px;
      font-size: ${(props) => props.theme.fontSizes.medium};
      font-weight: bold;
      color: ${(props) => props.theme.colors.primary};

      @media (min-width: 768px) {
        font-size: ${(props) => props.theme.fontSizes.large};
      }
    }
  }
`;

// Estilo para las métricas
const MetricItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 10px 0;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
    gap: 50px;
    flex-wrap: wrap;
  }

  .metric {
    display: flex;
    flex-direction: column;
    align-items: center;

    .value {
      font-size: ${(props) => props.theme.fontSizes.large};
      font-weight: bold;
      color: ${(props) => props.theme.colors.green};

      @media (min-width: 768px) {
        font-size: ${(props) => props.theme.fontSizes.xxlarge};
      }
    }

    .label {
      font-size: ${(props) => props.theme.fontSizes.medium};
      color: ${(props) => props.theme.colors.primary};

      @media (min-width: 768px) {
        font-size: ${(props) => props.theme.fontSizes.normal};
      }
    }
  }
`;

// Componente principal
const Metrics = ({ data }) => {
  // Dividimos las métricas en dos secciones
  const firstSection = data.slice(0, 2);
  const secondSection = data.slice(2, 4);

  return (
    <MetricsWrapper>
      {/* Primera sección: En proceso */}
      <div className="section">
        <div className="divider">
          <span />
          <p>En proceso</p>
          <span />
        </div>
        <MetricItem>
          {firstSection.map((metric, index) => (
            <div key={index} className="metric">
              <div className="value">
                {metric.plus ? "+" : ""}
                <CountUp
                  start={0}
                  end={metric.value}
                  duration={2}
                  separator=","
                  decimals={metric.showDecimals} // Lógica para los decimales
                  decimal="."
                />
                <span> {metric.unit}</span>
              </div>
              <div className="label">{metric.label}</div>
            </div>
          ))}
        </MetricItem>
      </div>

      {/* Segunda sección: Alcance */}
      <div className="section">
        <div className="divider">
          <span />
          <p>Alcance</p>
          <span />
        </div>
        <MetricItem>
          {secondSection.map((metric, index) => (
            <div key={index} className="metric">
              <div className="value">
                {metric.plus ? "+" : ""}
                <CountUp
                  start={0}
                  end={metric.value}
                  duration={2}
                  separator=","
                  decimals={metric.showDecimals} // Lógica para los decimales
                  decimal="."
                />
                <span> {metric.unit}</span>
              </div>
              <div className="label">{metric.label}</div>
            </div>
          ))}
        </MetricItem>
      </div>
    </MetricsWrapper>
  );
};

export default Metrics;
