import React from "react";
import styled from "styled-components";

// Contenedor principal para las imágenes
const ImagesWrapper = styled.div`
  display: flex;
  justify-content: center; /* Centra las imágenes horizontalmente */
  align-items: center; /* Centra verticalmente */
  flex-wrap: wrap; /* Permite ajustar imágenes en varias filas si es necesario */
  width: 100%; /* Ocupa todo el ancho del contenedor */
  @media (max-width: 768px) {
    width: 70w; /* Ajusta el ancho en tablets */
  }
`;

// Estilo individual para las imágenes
const ImageContainer = styled.div`
  flex: 1 1 calc(33.33% - 20px); /* Cada imagen ocupa 1/3 del espacio disponible con margen */
  max-width: calc(33.33% - 20px); /* Máximo ancho para 3 imágenes por fila */
  height: auto; /* Altura automática */
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */

  @media (max-width: 768px) {
    flex: 1 1 calc(50% - 20px); /* Ajusta a 2 imágenes por fila en tablets */
    max-width: calc(50% - 20px);
  }

  @media (max-width: 480px) {
    flex: 1 1 100%; /* Ajusta a 1 imagen por fila en móviles */
    max-width: 100%;
  }

  img {
    max-width: 100%; /* Asegura que las imágenes se ajusten al ancho del contenedor */
    max-height: 120px; /* Limita la altura máxima */
    object-fit: contain; /* Mantiene la relación de aspecto */
    display: block; /* Elimina espacios blancos por el navegador */
    @media (max-width: 768px) {
      max-height: 100px; /* Reduce la altura máxima en tablets */
    }
  }
`;

// Componente principal
const ImageGallery = ({ images }) => {
  return (
    <ImagesWrapper>
      {images.map((image, index) => (
        <ImageContainer key={index}>
          <img src={image} alt={`Imagen relacionada ${index + 1}`} />
        </ImageContainer>
      ))}
    </ImagesWrapper>
  );
};

export default ImageGallery;
