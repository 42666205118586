import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { motion } from "framer-motion";
import { FaExclamationTriangle } from "react-icons/fa";

const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #f4f4f4;
  padding: 20px;
`;

const Icon = styled(motion.div)`
  font-size: 80px;
  color: ${({ theme }) => theme.colors.yellow};
  margin-bottom: 20px;
`;

const Title = styled(motion.h1)`
  font-size: 3rem;
  color: #333;
  margin-bottom: 10px;
`;

const Subtitle = styled(motion.p)`
  font-size: 1.2rem;
  color: #777;
  margin-bottom: 20px;
`;

const Button = styled(motion.button)`
  background-color: #327541;
  color: white;
  font-size: 1rem;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background-color: #285a33;
  }
`;

const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Página no encontrada | DISAN Agro";

    // Agregar metaetiqueta para evitar indexación en Google
    const meta = document.createElement("meta");
    meta.name = "robots";
    meta.content = "noindex, nofollow";
    document.head.appendChild(meta);

    return () => {
      document.head.removeChild(meta);
    };
  }, []);

  return (
    <Container
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Icon
        initial={{ rotate: -15 }}
        animate={{ rotate: 0 }}
        transition={{ duration: 0.3 }}
      >
        <FaExclamationTriangle />
      </Icon>
      <Title>Página no encontrada</Title>
      <Subtitle>
        Lo sentimos, la página que buscas no existe o ha sido eliminada.
      </Subtitle>
      <Button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => navigate("/")}
      >
        Volver al inicio
      </Button>
    </Container>
  );
};

export default NotFound;
