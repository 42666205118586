import React from "react";
import styled from "styled-components";
import Banner from "../components/Banner";
import bannerImg from "../assets/disansiembrabanner.jpg";
import VideoContainer from "../components/VideoContainer";
import disanSiembraImg from "../assets/sostenibilidad/disan-siembra.png";
import ColorTab from "../components/colorTab";
import Metrics from "../components/Metrics";
import Gallery from "../components/Gallery";
import { importImages } from "../utils/ImportImages";
import SectionWrapper from "../components/SectionWrapper";
import ImageGallery from "../components/ImageGallery";
import { Seo } from "../utils/Seo";

const LandingPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  font-size: ${(props) => props.theme.fontSizes.normal};

  p {
    font-size: ${(props) => props.theme.fontSizes.normal};
    color: ${(props) => props.theme.colors.primary};
  }

  h2 {
    color: ${(props) => props.theme.colors.primary};
    font-weight: extra-bold;
    font-size: ${(props) => props.theme.fontSizes.large} !important;
  }
`;

const DescriptionContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column-reverse;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 5% 5%;

  @media (min-width: 768px) {
    flex-direction: row;
    padding: 2% 10%;
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  max-width: 90%;

  @media (min-width: 768px) {
    max-width: 40%;
  }

  img {
    width: 100%;
    height: auto;
    max-width: 250px;

    @media (min-width: 768px) {
      max-width: 200px;
    }
  }
`;

const MetricsContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

const ConoceMasSection = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 20px;
  padding: 5% 0;
`;

const AlliesContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
  padding: 5% 0;
  gap: 20px;
  @media (min-width: 768px) {
    padding: 5% 10%;
  }
`;

const DisanSiembra = () => {
  const images = importImages(
    require.context(
      "../assets/sostenibilidad/galeria",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  const alliesImages = importImages(
    require.context(
      "../assets/sostenibilidad/aliados",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  const suppliers = importImages(
    require.context(
      "../assets/sostenibilidad/proveedores",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );

  const metricsData = [
    {
      value: 18,
      unit: "mil",
      label: "árboles sembrados",
      plus: true,
    },
    {
      value: 4.1,
      unit: "hectáreas",
      label: "restauradas",
      showDecimals: true,
    },
    {
      value: 20,
      unit: "mil",
      label: "árboles por sembrar",
      plus: true,
    },
    {
      value: 4.5,
      unit: "hectáreas",
      label: "por restaurar",
      showDecimals: true,
    },
  ];

  return (
    <>
      <Seo
        title="DISAN Siembra"
        type="cause"
        description="Conoce DISAN Siembra: nuestra iniciativa de restauración ambiental en Colombia. Descubre cómo estamos sembrando vida con la reforestación de especies nativas y promoviendo la sostenibilidad."
      />
      <LandingPageContainer>
        <Banner
          title={"Bienvenido a DISAN Siembra"}
          backgroundImage={bannerImg}
        />

        <SectionWrapper>
          <DescriptionContainer>
            <VideoContainer videoId={"NokzFtr3YjY"} />
            <Description>
              <img src={disanSiembraImg} alt="Disan Siembra" />
              <p>
                DISAN Siembra tiene como propósito contribuir a la{" "}
                <b>restauración</b> de la <b>Amazonia colombiana</b> junto al
                apoyo de diferentes corporaciones y aliados estratégicos que se
                han vinculado.
                <br />
                Hemos sembrado más de <b>18.000 árboles</b> nativos en la zona
                bifronteriza de Corpoamazonia en La Hormiga, Putumayo
                (Colombia), con el objetivo de{" "}
                <b>promover la conservación y restauración </b>
                de los ecosistemas amazónicos por medio de la utilización de
                especies nativas y prácticas sostenibles de propagación.
              </p>
            </Description>
          </DescriptionContainer>
        </SectionWrapper>

        <SectionWrapper>
          <MetricsContainer>
            <ColorTab />
            <Metrics data={metricsData} />
          </MetricsContainer>
        </SectionWrapper>

        <SectionWrapper>
          <ConoceMasSection>
            <ColorTab />
            <h2>Conoce más sobre DISAN Siembra</h2>
            <VideoContainer videoId={"lxtBtF196To"} />
            <Gallery images={images} />
          </ConoceMasSection>
        </SectionWrapper>

        <AlliesContainer>
          <ColorTab />
          <h2>Aliados</h2>
          <ImageGallery images={alliesImages} />
        </AlliesContainer>
        <AlliesContainer>
          <ColorTab />
          <h2>Proveedores pioneros en sostenibilidad</h2>
          <ImageGallery images={suppliers} />
        </AlliesContainer>
      </LandingPageContainer>
    </>
  );
};

export default DisanSiembra;
