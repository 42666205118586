import React from "react";
import styled from "styled-components";

const Title = styled.h1`
  font-size: 2.5em;
  margin-bottom: 20px;
`;

const BannerStyled = styled.div`
  width: 100%;
  height: 450px;
  position: relative;
  background: #f9f9f9;
  margin-bottom: 20px;
  background-image: url("${(props) => props.$backgroundImage}");
  background-size: cover;
  background-position: ${(props) =>
    props.backgroundPosition ||
    "center"}; /* Usa la prop o por defecto center */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 24px;
  text-align: center;
  z-index: 1;
  min-height: 50%;

  h1 {
    @media (max-width: 768px) {
      font-size: ${(props) => props.theme.fontSizes.large};
    }
  }
  h3 {
    font-weight: 300;
    font-size: ${(props) => props.theme.fontSizes.medium};
    @media (max-width: 768px) {
      font-size: ${(props) => props.theme.fontSizes.normal};
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Capa semitransparente */
    z-index: -1; /* Ubica la capa detrás del contenido del banner */
  }
`;

const Banner = ({ backgroundImage, title, subtitle }) => {
  return (
    <BannerStyled $backgroundImage={backgroundImage}>
      <Title>{title}</Title>
      <h3>{subtitle}</h3>
    </BannerStyled>
  );
};

export default Banner;
