const numbersWhatsapp = [
  {
    país: "Colombia",
    número: "573108147982",
    área: "Servicio al Cliente Colombia",
  },
  {
    país: "Perú",
    número: "51965407186",
    área: "Servicio al Cliente Perú",
  },
  {
    país: "Ecuador",
    número: "593989563036",
    área: "Servicio al Cliente Ecuador",
  },
  {
    país: "Venezuela",
    número: "584244583420",
    área: "Representante Técnico Venezuela",
  },
  {
    país: "Panamá",
    número: "573176612228",
    área: "Gerente de Ventas LATAM",
  },
  {
    país: "Bolivia",
    número: "51997629111",
    área: "Gerente País",
  },
];

module.exports = numbersWhatsapp;
