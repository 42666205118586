import React from "react";
import styled from "styled-components";
import ImageCarousel from "./imageCarousel";
import ButtonLink from "./ButtonLink";
import mayacert from "../assets/mayacert-logo.png";

const ProductsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5%;
  box-sizing: border-box;
  padding: 5% 2%;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 50px;
  }
`;

const Hero = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.primary};

  img {
    width: 100px;
    height: auto;
  }

  h1 {
    color: ${(props) => props.theme.colors.green};
  }

  a {
    margin-top: 5%;
  }
`;

const Slides = styled.div`
  width: 100%;
  max-width: 50%;
  @media (min-width: 768px) {
    max-width: 300px;
  }
`;

const ProductsSection = () => {
  return (
    <ProductsContainer>
      <Hero>
        <img src={mayacert} alt="Logo Mayacert" />
        <h2>Descubre nuestro portafolio de</h2>
        <h1>AGRICULTURA ORGÁNICA</h1>
        <ButtonLink to="/product-search?hierarchy=organicos">
          Ver Aquí
        </ButtonLink>
      </Hero>
      <Slides>
        <ImageCarousel />
      </Slides>
    </ProductsContainer>
  );
};

export default ProductsSection;
