import React from "react";
import styled from "styled-components";

const ModalCountries = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  const handleConfirm = () => {
    onClose();
  };

  return (
    <ModalOverlay>
      <ModalContent>
        <h2>Elige una ubicación</h2>
        <p>Por favor, selecciona un país para ver el portafolio:</p>
        <div className="filter">{children}</div>
        <ButtonsContainer>
          <Button onClick={onClose}>Cancelar</Button>
          <Button $primary onClick={handleConfirm}>
            Confirmar
          </Button>
        </ButtonsContainer>
      </ModalContent>
    </ModalOverlay>
  );
};

export default ModalCountries;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
  width: 100%;

  .filter {
    margin-top: 10px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const Button = styled.button`
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background: ${(props) =>
    props.$primary ? props.theme.colors.green : "#ccc"};
  color: ${(props) => (props.$primary ? "white" : "black")};
  &:hover {
    opacity: 0.8;
  }
`;
