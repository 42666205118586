import React from "react";
import styled from "styled-components";
import Banner from "../components/Banner";
import ColorTab from "../components/colorTab";
import SectionWrapper from "../components/SectionWrapper";
import bannerImg from "../assets/sostenibilidad/banner-pacto-blobal.jpg";
import PactoImg from "../assets/sostenibilidad/pacto-global-removebg-preview.png";
import { Seo } from "../utils/Seo";

// Contenedor principal
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  font-size: ${(props) => props.theme.fontSizes.normal};
`;

// Sección "Conoce más"
const Section = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  gap: 20px;
  padding: 5% 0;
`;

// Contenedor para la descripción
const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 5% 2%;

  @media (min-width: 768px) {
    flex-direction: row;
    padding: 5% 5%;
  }

  img {
    max-width: 300px;
    height: auto;
  }

  div {
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;

    @media (min-width: 768px) {
      text-align: left;
      max-width: 500px;
    }

    h2 {
      color: ${(props) => props.theme.colors.primary};
      font-size: ${(props) => props.theme.fontSizes.large};
      font-weight: bold;
    }

    p {
      color: ${(props) => props.theme.colors.primary};
      font-size: ${(props) => props.theme.fontSizes.normal};
    }
  }
`;

const PactoGlobal = () => {
  return (
    <>
      <Seo
        title="Pacto Global"
        type="cause"
        description="DISAN Agro se une al Pacto Global de las Naciones Unidas, comprometidos con los principios de sostenibilidad, derechos humanos, medio ambiente y responsabilidad social. Descubre nuestras acciones para un futuro más justo y sostenible."
      />
      <Container>
        <Banner title="Pacto Global" backgroundImage={bannerImg} />
        <SectionWrapper>
          <Section>
            <ColorTab />
            <Description>
              <div>
                <h2>¿Qué es el pacto global?</h2>
                <p>
                  El Pacto Global de Naciones Unidas es una iniciativa que
                  promueve el compromiso del sector privado, sector público y
                  sociedad civil y alinea sus estrategias en cuatro áreas
                  temáticas: Derechos Humanos, Estándares Laborales, Medio
                  Ambiente y Lucha Contra la Corrupción, así como contribuir a
                  la consecución de los Objetivos de Desarrollo Sostenible
                  (ODS).
                  <br />
                  Las organizaciones que se adhieren al Pacto Global comparten
                  la convicción de que las prácticas empresariales basadas en
                  principios universales contribuyen a la generación de un
                  mercado global más estable, equitativo e incluyente, y que
                  fomenta sociedades más prósperas.
                </p>
              </div>
              <img src={PactoImg} alt="Pacto Global" />
            </Description>
          </Section>
        </SectionWrapper>
      </Container>
    </>
  );
};

export default PactoGlobal;
