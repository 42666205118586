import React from "react";
import styled from "styled-components";
import LogoLatam from "../assets/companies/white-DISAN-logo.png";
import LogoQuimicos from "../assets/companies/quimicos blanco.png";
import LogoMagentis from "../assets/companies/Magentislogoblanco.png";
import LogoMerquiand from "../assets/companies/logo-merquiand-blanco.png";
import LogoInferex from "../assets/companies/logo-inferex-blanca.png";
import LogoAgro from "../assets/companies/disanagro.png";

const FooterContainer = styled.footer`
  background-color: ${(props) =>
    props.theme.colors.primary || "#000000"}; /* Color de fondo */
  color: #ffffff; /* Color del texto */
  padding: 5% 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  font-size: ${(props) => props.theme.fontSizes.small || "1rem"};
  @media (max-width: 768px) {
    padding: 15px 5px;
  }
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  text-align: center;

  a {
    text-decoration: none;
    color: #ffffff;
    margin: 0 5px;
    transition: color 0.3s ease;

    &:hover {
      color: ${(props) => props.theme.colors.green || "#d1a54b"};
    }
  }

  .links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;

    @media (max-width: 768px) {
      gap: 10px;
    }
  }
`;

const Logo = styled.img`
  max-width: 150px;
  height: auto;
`;

const Divider = styled.div`
  width: 100%;
  max-width: 800px;
  height: 1px;
  background-color: #777777;
  margin: 10px 0;
`;

const BottomSection = styled.div`
  text-align: center;

  .logos-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;

    a {
      display: flex; /* Asegura que las imágenes sean clickeables */
    }

    img {
      max-width: 100px;
      height: auto;
      transition: transform 0.8s cubic-bezier(0.4, 0, 0.2, 1),
        filter 0.8s cubic-bezier(0.4, 0, 0.2, 1); /* Transiciones fluidas */

      &:hover {
        transform: scale(1.1); /* Suaviza el efecto de escala */
        filter: brightness(1.2); /* Agrega un ligero brillo para resaltar */
      }
    }

    @media (max-width: 768px) {
      gap: 15px;
    }
  }

  .footer-note {
    margin-top: 15px;
    font-size: 0.8rem;
    color: #cccccc;
  }
`;

const Footer = () => {
  const companyLogos = [
    {
      src: LogoLatam,
      alt: "DISAN Latam",
      link: "https://www.disanlatinoamerica.com",
    },
    {
      src: LogoQuimicos,
      alt: "DISAN Químicos",
      link: "https://www.disanquimicos.co",
    },
    {
      src: LogoMagentis,
      alt: "Magentis",
      link: "https://www.magentisfood.com",
    },
    {
      src: LogoMerquiand,
      alt: "Merquiand",
      link: "https://www.merquiand.com",
    },
    {
      src: LogoInferex,
      alt: "Inferex",
      link: "https://www.inferex.com.co",
    },
  ];

  return (
    <FooterContainer>
      <TopSection>
        <Logo src={LogoAgro} alt="DISAN Agro Logo" />
        <div className="links">
          <a
            href="https://disanlatinoamerica.com/trabaja-con-nosotros"
            target="_blank"
            rel="noopener noreferrer"
          >
            Trabaja con nosotros
          </a>
          <a href="/blog">Blog</a>
          <a href="/contacto" rel="noopener noreferrer">
            Contáctanos
          </a>
          <a
            href="https://disanlatinoamerica.com/wp-content/uploads/2024/04/GRUPO-DISAN-ISO-9001-2024.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Certificación multisitio ISO 9001
          </a>
          <a
            href="/files/Codigo-de-etica_V2025_DISANLatam.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Código de ética y Conducta
          </a>
        </div>
      </TopSection>
      <Divider />
      <BottomSection>
        <p>Familia de empresas DISAN:</p>
        <div className="logos-container">
          {companyLogos.map((logo, index) => (
            <a
              key={index}
              href={logo.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={logo.src} alt={logo.alt} />
            </a>
          ))}
        </div>
        <div className="footer-note">
          &copy; {new Date().getFullYear()} DISAN Agro. Todos los derechos
          reservados.
        </div>
      </BottomSection>
    </FooterContainer>
  );
};

export default Footer;
