import React from "react";
import styled from "styled-components";
import SocialLinks from "./SocialLinks";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.colors.secondary};
  padding: 10px 20px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const TopLinks = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;

  a {
    margin-right: 10px;
    text-decoration: none;
    color: ${(props) => props.theme.colors.white};
    transition: color 1s, transform 0.3s, font-weight 0.3s;

    &:hover {
      transform: scale(1.1);
      font-weight: bold;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const TopBar = () => {
  return (
    <Container>
      <TopLinks>
        <a
          href="https://disanlatinoamerica.com/?utm_source=topbar&utm_medium=link&utm_campaign=disan_navigation"
          target="_blank"
          rel="noopener noreferrer"
          title="Visita el sitio de DISAN Latinoamérica"
        >
          DISAN Latam
        </a>
        <a
          href="https://www.disanquimicos.co/?utm_source=topbar&utm_medium=link&utm_campaign=disan_navigation"
          target="_blank"
          rel="noopener noreferrer"
          title="Visita el sitio de DISAN Químicos"
        >
          DISAN Químicos
        </a>
        <a
          href="https://www.merquiand.com/?utm_source=topbar&utm_medium=link&utm_campaign=disan_navigation"
          target="_blank"
          rel="noopener noreferrer"
          title="Visita el sitio de Merquiand"
        >
          Merquiand
        </a>
        <a
          href="https://www.magentisfood.com/?utm_source=topbar&utm_medium=link&utm_campaign=disan_navigation"
          target="_blank"
          rel="noopener noreferrer"
          title="Visita el sitio de Magentis®"
        >
          Mangentis®
        </a>
        <a
          href="https://inferex.com.co/?utm_source=topbar&utm_medium=link&utm_campaign=disan_navigation"
          target="_blank"
          rel="noopener noreferrer"
          title="Visita el sitio de INFEREX"
        >
          INFEREX
        </a>
        <a
          href="https://recaudosdisan.epayco.me/recaudo/disanrecaudos?utm_source=topbar&utm_medium=link&utm_campaign=disan_navigation"
          target="_blank"
          rel="noopener noreferrer"
          title="Accede a la Zona de Pagos DISAN "
        >
          Zona de Pagos
        </a>
      </TopLinks>
      <SocialLinks />
    </Container>
  );
};

export default TopBar;
