import { Helmet } from "react-helmet-async";

export const Seo = ({ title, description, type, image }) => {
  return (
    <Helmet>
      <title>{`${title} | DISAN Agro`}</title>
      <meta property="description" content={description} />
      <meta property="og:title" content={`${title} | DISAN Agro`} />
      <meta property="og:description" content={description} />
      {!image ? null : (
        <meta
          property="og:image"
          content={`${window.location.href}/${image}`}
        />
      )}
      <meta property="og:url" content={window.location.href} />
      <meta property="og:type" content={type} />
    </Helmet>
  );
};
