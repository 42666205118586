const products = [
  {
    name: "Oxical Mg",
    description:
      "Fertilizante granulado acondicionador de suelo con alto contenido de Calcio y Magnesio en forma de óxido. OXICAL Mg además está complementado con Potasio.",
    benefits:
      "Fertilizante granulado acondicionador de fuente de Potasio, Calcio y Magnesio, estos dos últimos en forma de óxido que se liberan en forma de espiral por un periodo de hasta 60 días dependiendo de la humedad del suelo.\n\nAcondicionador que reestructura el orden nutricional del suelo, creando un ambiente ideal para que la raíz pueda absorber los nutrientes en la fertilización.\n\nPosee un poder relativo de neutralización (PRNT) de un 120%, lo que permite neutralizar de manera más rápida y eficiente los iones hidrógeno (H+) presentes en la solución del suelo, que causan acidez del medio.\n\nGranulometría uniforme con un tamaño de partículas en un 90% entre 2 a 4 mm y resistencia al rompimiento mayor a 2 kilogramos/gránulo, ideal para soportar operaciones de transporte, manejo y aplicación. Además, evita la generación de polvo hasta en un 98% gracias a su encapsulado, permitiendo aplicaciones limpias y seguras para la salud del aplicador.",
    composition: {
      "Potasio soluble en agua (K2O)": "4.0%",
      "Calcio total (CaO)": "24.0%",
      "Magnesio total (MgO)": "15.0%",
      "pH en solución al 10%": "10.23%",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros",
    ],
    hierarchy: "Fertilizantes Edáficos - Acondicionadores de suelo",
    supplier: "MANTTRA",
    countries: ["Colombia"],
    image: "productsImg/optimized/oxical col.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/Ecj3tse0q9lMnh6euSJUB94BMSaRq2Z1JAJBlXzfOZphdQ?e=LGRYdF",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EfB0N3JIM_pCs6Ad1LJzDQ0B-LFUXw2pFKv4BznveQQq0A?e=PRRsSR",
  },
  {
    name: "Oxical Mg",
    description:
      "Fertilizante granulado acondicionador de suelo con alto contenido de Calcio y Magnesio en forma de óxido. OXICAL Mg además está complementado con Potasio.",
    benefits:
      "Fertilizante granulado acondicionador de fuente de Potasio, Calcio y Magnesio, estos dos últimos en forma de óxido que se liberan en forma de espiral por un periodo de hasta 60 días dependiendo de la humedad del suelo.\n\nAcondicionador que reestructura el orden nutricional del suelo, creando un ambiente ideal para que la raíz pueda absorber los nutrientes en la fertilización.\n\nPosee un poder relativo de neutralización (PRNT) de un 120%, lo que permite neutralizar de manera más rápida y eficiente los iones hidrógeno (H+) presentes en la solución del suelo, que causan acidez del medio.\n\nGranulometría uniforme con un tamaño de partículas en un 90% entre 2 a 4 mm y resistencia al rompimiento mayor a 2 kilogramos/gránulo, ideal para soportar operaciones de transporte, manejo y aplicación. Además, evita la generación de polvo hasta en un 98% gracias a su encapsulado, permitiendo aplicaciones limpias y seguras para la salud del aplicador.",
    composition: {
      "Potasio soluble en agua (K2O)": "4.0%",
      "Calcio total (CaO)": "24.0%",
      "Magnesio total (MgO)": "15.0%",
      "pH en solución al 10%": "10.23%",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros",
    ],
    hierarchy: "Fertilizantes Edáficos - Acondicionadores de suelo",
    supplier: "MANTTRA",
    countries: ["Venezuela"],
    image: "productsImg/optimized/oxical-mg.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/Ecj3tse0q9lMnh6euSJUB94BMSaRq2Z1JAJBlXzfOZphdQ?e=LGRYdF",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EfB0N3JIM_pCs6Ad1LJzDQ0B-LFUXw2pFKv4BznveQQq0A?e=PRRsSR",
  },
  {
    name: "Equilibrio Pentax",
    description:
      "Acondicionador de suelo que mejora las propiedades químicas del suelo, con una relación ideal entre Ca y Mg y una rápida reacción que sube el pH del suelo.",
    benefits:
      "Tiene la relación ideal de bases Ca y Mg.\nMejora las propiedades químicas del suelo (desbloquea fósforo y elementos fijados, disminuye acidez).\nAumenta la capacidad de intercambio catiónico en los suelos.\nAumenta la retención de nutrientes, mejorando la eficiencia en las aplicaciones de fertilizantes en el suelo.\nMejora el pH de los suelos, ya que intercambia los H+ libres en el medio, permitiendo el desbloqueo de nutrientes.\nIdeal para suelos con relaciones Ca/Mg balanceadas.\nSu tamaño de partícula (Malla 100) permite una rápida reacción en el suelo.",
    composition: {
      "Fósforo total (P2O5)": "3.0%",
      "Calcio total (CaO)": "31.0%",
      "Magnesio total (MgO)": "15.0%",
      "Azufre total (S)": "3.0%",
      "Silicio total (SiO2)": "15.0%",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Café",
      "Palma",
      "Tomate",
      "Banano",
      "Plátano",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Cacao",
      "Vid/Uva",
      "Caña",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros",
    ],
    hierarchy: "Fertilizantes Edáficos - Acondicionadores de suelo",
    supplier: "CRENTO",
    countries: ["Ecuador"],
    image: "productsImg/optimized/equilibrio-pentax.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ETw3Cha2mCpMs1OwknUGzFUBGUsFjrr5pDUNuSf2eHvSzA?e=5k6hNF",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ESizCkbqI_RFp-B6n6w-eboBSXiUmVG5TeWXkM02xeanDQ?e=lgAstO",
  },
  {
    name: "Equilibrio Pentax",
    description:
      "Acondicionador de suelo que mejora las propiedades químicas del suelo, con una relación ideal entre Ca y Mg y una rápida reacción que sube el pH del suelo.",
    benefits:
      "Tiene la relación ideal de bases Ca y Mg.\nMejora las propiedades químicas del suelo (desbloquea fósforo y elementos fijados, disminuye acidez).\nAumenta la capacidad de intercambio catiónico en los suelos.\nAumenta la retención de nutrientes, mejorando la eficiencia en las aplicaciones de fertilizantes en el suelo.\nMejora el pH de los suelos, ya que intercambia los H+ libres en el medio, permitiendo el desbloqueo de nutrientes.\nIdeal para suelos con relaciones Ca/Mg balanceadas.\nSu tamaño de partícula (Malla 100) permite una rápida reacción en el suelo.",
    composition: {
      "Fósforo total (P2O5)": "3.0%",
      "Calcio total (CaO)": "31.0%",
      "Magnesio total (MgO)": "15.0%",
      "Azufre total (S)": "3.0%",
      "Silicio total (SiO2)": "15.0%",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Café",
      "Palma",
      "Tomate",
      "Banano",
      "Plátano",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Cacao",
      "Vid/Uva",
      "Caña",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros",
    ],
    hierarchy: "Fertilizantes Edáficos - Acondicionadores de suelo",
    supplier: "CRENTO",
    countries: ["Colombia"],
    image: "productsImg/optimized/equilibrio-pentax.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ETw3Cha2mCpMs1OwknUGzFUBGUsFjrr5pDUNuSf2eHvSzA?e=5k6hNF",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ESizCkbqI_RFp-B6n6w-eboBSXiUmVG5TeWXkM02xeanDQ?e=lgAstO",
  },
  {
    name: "Equilibrio Pentax",
    description:
      "Acondicionador de suelo que mejora las propiedades químicas del suelo, con una relación ideal entre Ca y Mg y una rápida reacción que sube el pH del suelo.",
    benefits:
      "Tiene la relación ideal de bases Ca y Mg.\nMejora las propiedades químicas del suelo (desbloquea fósforo y elementos fijados, disminuye acidez).\nAumenta la capacidad de intercambio catiónico en los suelos.\nAumenta la retención de nutrientes, mejorando la eficiencia en las aplicaciones de fertilizantes en el suelo.\nMejora el pH de los suelos, ya que intercambia los H+ libres en el medio, permitiendo el desbloqueo de nutrientes.\nIdeal para suelos con relaciones Ca/Mg balanceadas.\nSu tamaño de partícula (Malla 100) permite una rápida reacción en el suelo.",
    composition: {
      "Fósforo total (P2O5)": "3.0%",
      "Calcio total (CaO)": "31.0%",
      "Magnesio total (MgO)": "15.0%",
      "Azufre total (S)": "3.0%",
      "Silicio total (SiO2)": "15.0%",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Café",
      "Palma",
      "Tomate",
      "Banano",
      "Plátano",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Cacao",
      "Vid/Uva",
      "Caña",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros",
    ],
    hierarchy: "Fertilizantes Edáficos - Acondicionadores de suelo",
    supplier: "CRENTO",
    countries: ["Venezuela"],
    image: "productsImg/optimized/Equilibrio PENTAX VEN.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ETw3Cha2mCpMs1OwknUGzFUBGUsFjrr5pDUNuSf2eHvSzA?e=5k6hNF",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ESizCkbqI_RFp-B6n6w-eboBSXiUmVG5TeWXkM02xeanDQ?e=lgAstO",
  },
  {
    name: "Azufertil 2E",
    description:
      "Fertilizante acondicionador en gránulos dispersables, que funciona como fuente de azufre y calcio.",
    benefits:
      "Promueve la sostenibilidad y la eficiencia de los fertilizantes.\nFunciona como fuente de azufre y calcio.\nPromueve la recuperación de la microbiología del suelo.\nIdeal para mezcla física y facilita la aplicación al suelo de forma manual.\nTiene una resistencia a la ruptura mayor a 2 kilogramos/gránulo, ideal para soportar operaciones de transporte, manejo y aplicación.\nSe disuelve fácilmente en suelos húmedos, para hacerse rápidamente disponible.\nNo se recomienda utilizarlo en fertirriego.\nEs compatible con todo fertilizante sólido para aplicarse al suelo.",
    composition: {
      "Azufre (SO4)": "46.0%",
      "Calcio (CaO)": "29.0%",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros",
    ],
    hierarchy: "Fertilizantes Edáficos - Acondicionadores de suelo",
    supplier: "MANTTRA",
    countries: ["Colombia"],
    image: "productsImg/optimized/azufertil-2e.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EbX4G1fpJmJAsPLNIPcD1CcBadb9s30eHPUIR0jH2RQvtw?e=LeWhiS",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ESxnnablop1PotEyqRMkLoYB9eaNY3nVxof2_MV3IntTPg?e=QPEfBC",
  },
  {
    name: "Protamin Granum",
    description:
      "Fertilizante granulado de origen orgánico, que gracias a su activador proteico mejora el desarrollo radicular, la absorción de nutrientes y la fertilidad del suelo.",
    benefits:
      "Este fertilizante granulado de origen orgánico enriquecido con el complejo APR (Activador proteico de la rizosfera), impulsa el desarrollo radicular, la absorción de nutrientes y la fertilidad del suelo, promoviendo cosechas más saludables y abundantes.\nFertilizante orgánico con alto contenido de nitrógeno y carbono orgánico para aplicación al suelo.\nDe origen orgánico formulado con ingredientes naturales, Protamin GR respeta la agricultura orgánica y promueve un equilibrio sostenible en el ecosistema.\nEl Activador Proteico de la Rizosfera estimula un crecimiento radicular vigoroso aumentando la capacidad de las plantas para absorber nutrientes esenciales.\nLa aplicación de Protamin GR mejora la calidad del suelo creando un ambiente propicio para el crecimiento saludable de los cultivos.",
    composition: {
      "Nitrógeno total N": "12.0%",
      "Nitrógeno orgánico N": "12.0%",
      "Calcio total CaO": "0.55%",
      "Magnesio total MgO": "0.15%",
      "Azufre total S": "2.28%",
      "Hierro total Fe": "0.40%",
      "Silicio total SiO2": "0.50%",
      "Sodio total Na": "0.65%",
      "Carbono orgánico oxidable total": "34.56%",
      "pH en pasta de saturación": "4.29",
    },

    cultivation: [
      "Semestrales",
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros",
    ],
    hierarchy: "Fertilizantes Edáficos - Nitrógeno Orgánico - organicos",
    supplier: "BIOLCHIM",
    countries: ["Colombia"],
    image: "productsImg/optimized/protamin-granum.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EVa-eP_Hf1tHqaPTvPtnMboB-khL7HfXKf0FFi4DzxHOJw?e=rfWdqn",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/Ee9Wn-SDXaxPi8eH8alOMGEBQyI9P6Bn9W218mcDNL61WA?e=nnncoS",
  },
  {
    name: "Urea Nutrisphere",
    description:
      "Urea granulada recubierta con el polímero N-Charge que reduce las pérdidas de nitrógeno por lixiviación, volatilización y desnitrificación.",
    benefits:
      "La UREA NUTRISPHERE es un fertilizante ureico que aporta un alto porcentaje de nitrógeno recubierto con el polímero N-Charge G reduce las pérdidas de Nitrógeno por volatilización, lixiviación y desnitrificación.\nLa UREA NUTRISPHERE mantiene el nitrógeno en forma amoniacal que es más estable en el suelo, permitiendo una mejor nutrición en los cultivos.\n\nEl polímero N-Charge G captura tres iones implicados en la hidrolisis de la urea:\nEl ion Níquel es cofactor de la ureasa que es responsable de la trasformación de urea a amoniaco el cual se volatiliza.\nEl ion Cobre es cofactor de las nitrosomonas que son responsables de convertir el amonio en nitrito el cual puede perderse por lixiviación y/o desnitrificación.\nEl ion Hierro influye en la actividad de la Nitrobacter responsable de convertir el nitrito en nitrato cual puede perderse por lixiviación y/o desnitrificación.\n\nLa funcionalidad del polímero N-Charge G no es afectada por el tipo de suelo, pH o temperatura y es amigable con el ambiente ya que se degrada en la naturaleza.",
    composition: {
      "Nitrógeno total (N)": "46.0%",
      "Nitrógeno ureico (N-NH2)": "46.0%",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Café",
      "Palma",
      "Tomate",
      "Banano",
      "Plátano",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Cacao",
      "Vid/Uva",
      "Caña",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros",
    ],
    hierarchy: "Fertilizantes Edáficos - Nitrógeno",
    supplier: "VERDESIAN",
    countries: ["Colombia"],
    image: "productsImg/optimized/urea-nutrisphere.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EaZO-ff5f7pFm8cMM4MhSZQBQ-ha8fFsvxxMsJgJFbWqxg?e=UkYAo1",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EaZO-ff5f7pFm8cMM4MhSZQBQ-ha8fFsvxxMsJgJFbWqxg?e=UkYAo1",
  },
  {
    name: "Urea Nutrisphere",
    description:
      "Urea granulada recubierta con el polímero N-Charge que reduce las pérdidas de nitrógeno por lixiviación, volatilización y desnitrificación.",
    benefits:
      "La UREA NUTRISPHERE es un fertilizante ureico que aporta un alto porcentaje de nitrógeno recubierto con el polímero N-Charge G reduce las pérdidas de Nitrógeno por volatilización, lixiviación y desnitrificación.\nLa UREA NUTRISPHERE mantiene el nitrógeno en forma amoniacal que es más estable en el suelo, permitiendo una mejor nutrición en los cultivos.\n\nEl polímero N-Charge G captura tres iones implicados en la hidrolisis de la urea:\nEl ion Níquel es cofactor de la ureasa que es responsable de la trasformación de urea a amoniaco el cual se volatiliza.\nEl ion Cobre es cofactor de las nitrosomonas que son responsables de convertir el amonio en nitrito el cual puede perderse por lixiviación y/o desnitrificación.\nEl ion Hierro influye en la actividad de la Nitrobacter responsable de convertir el nitrito en nitrato cual puede perderse por lixiviación y/o desnitrificación.\n\nLa funcionalidad del polímero N-Charge G no es afectada por el tipo de suelo, pH o temperatura y es amigable con el ambiente ya que se degrada en la naturaleza.",
    composition: {
      "Nitrógeno total (N)": "46.0%",
      "Nitrógeno ureico (N-NH2)": "46.0%",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Café",
      "Palma",
      "Tomate",
      "Banano",
      "Plátano",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Cacao",
      "Vid/Uva",
      "Caña",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros",
    ],
    hierarchy: "Fertilizantes Edáficos - Nitrógeno",
    supplier: "VERDESIAN",
    countries: ["Perú"],
    image: "productsImg/optimized/Urea N. Perú.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EaZO-ff5f7pFm8cMM4MhSZQBQ-ha8fFsvxxMsJgJFbWqxg?e=UkYAo1",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EaZO-ff5f7pFm8cMM4MhSZQBQ-ha8fFsvxxMsJgJFbWqxg?e=UkYAo1",
  },
  {
    name: "Map Avail 10-50-0",
    description:
      "Fosfato Monoamónico recubierto con polímero AVAIL T5 que reduce la fijación del P y aumenta la disponibilidad del nutriente para la planta.",
    benefits:
      "La alta densidad de carga del polímero AVAIL T5 favorece la adsorción de los cationes polivalentes metálicos presentes en la solución del suelo, interrumpiendo y retrasando las reacciones normales de fijación de P, obteniéndose así una mayor disponibilidad de fósforo para la planta.\nEl recubrimiento con el polímero AVAIL reduce la conversión de fósforo soluble a formas menos solubles, reduciendo las pérdidas de fósforo en suelo.\nMás fósforo disponible puede conducir a un crecimiento rápido y temprano de las plantas, así como a cultivos más sanos y con mayor potencial de rendimiento.\nEl aporte de N y P favorece el crecimiento y desarrollo de los cultivos.",
    composition: {
      "Nitrógeno total (N)": "10.0%",
      "Nitrógeno amoniacal (N-NH4)": "10.0%",
      "Fósforo asimilable (P2O5)": "50.0%",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Café",
      "Palma",
      "Tomate",
      "Banano",
      "Plátano",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Cacao",
      "Vid/Uva",
      "Caña",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros",
    ],
    hierarchy: "Fertilizantes Edáficos - Fósforo",
    supplier: "VERDESIAN",
    countries: ["Colombia"],
    image: "productsImg/optimized/map-avail.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EXx9MlSpzyFPn32Iqms5xNcBY0kl9_Vlc1lw8CHahXFMEQ?e=gKjgSF",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EXx9MlSpzyFPn32Iqms5xNcBY0kl9_Vlc1lw8CHahXFMEQ?e=gKjgSF",
  },
  {
    name: "Map Avail 10-50-0",
    description:
      "Fosfato Monoamónico recubierto con polímero AVAIL T5 que reduce la fijación del P y aumenta la disponibilidad del nutriente para la planta.",
    benefits:
      "La alta densidad de carga del polímero AVAIL T5 favorece la adsorción de los cationes polivalentes metálicos presentes en la solución del suelo, interrumpiendo y retrasando las reacciones normales de fijación de P, obteniéndose así una mayor disponibilidad de fósforo para la planta.\nEl recubrimiento con el polímero AVAIL reduce la conversión de fósforo soluble a formas menos solubles, reduciendo las pérdidas de fósforo en suelo.\nMás fósforo disponible puede conducir a un crecimiento rápido y temprano de las plantas, así como a cultivos más sanos y con mayor potencial de rendimiento.\nEl aporte de N y P favorece el crecimiento y desarrollo de los cultivos.",
    composition: {
      "Nitrógeno total (N)": "10.0%",
      "Nitrógeno amoniacal (N-NH4)": "10.0%",
      "Fósforo asimilable (P2O5)": "50.0%",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Café",
      "Palma",
      "Tomate",
      "Banano",
      "Plátano",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Cacao",
      "Vid/Uva",
      "Caña",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros",
    ],
    hierarchy: "Fertilizantes Edáficos - Fósforo",
    supplier: "VERDESIAN",
    countries: ["Perú"],
    image: "productsImg/optimized/MAP AVAIL PERÚ.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EXx9MlSpzyFPn32Iqms5xNcBY0kl9_Vlc1lw8CHahXFMEQ?e=gKjgSF",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EXx9MlSpzyFPn32Iqms5xNcBY0kl9_Vlc1lw8CHahXFMEQ?e=gKjgSF",
  },
  {
    name: "Map Avail 10-50-0",
    description:
      "Fosfato Monoamónico recubierto con polímero AVAIL T5 que reduce la fijación del P y aumenta la disponibilidad del nutriente para la planta.",
    benefits:
      "La alta densidad de carga del polímero AVAIL T5 favorece la adsorción de los cationes polivalentes metálicos presentes en la solución del suelo, interrumpiendo y retrasando las reacciones normales de fijación de P, obteniéndose así una mayor disponibilidad de fósforo para la planta.\nEl recubrimiento con el polímero AVAIL reduce la conversión de fósforo soluble a formas menos solubles, reduciendo las pérdidas de fósforo en suelo.\nMás fósforo disponible puede conducir a un crecimiento rápido y temprano de las plantas, así como a cultivos más sanos y con mayor potencial de rendimiento.\nEl aporte de N y P favorece el crecimiento y desarrollo de los cultivos.",
    composition: {
      "Nitrógeno total (N)": "10.0%",
      "Nitrógeno amoniacal (N-NH4)": "10.0%",
      "Fósforo asimilable (P2O5)": "50.0%",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Café",
      "Palma",
      "Tomate",
      "Banano",
      "Plátano",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Cacao",
      "Vid/Uva",
      "Caña",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros",
    ],
    hierarchy: "Fertilizantes Edáficos - Fósforo",
    supplier: "VERDESIAN",
    countries: ["Venezuela"],
    image: "productsImg/optimized/map-avail.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EXx9MlSpzyFPn32Iqms5xNcBY0kl9_Vlc1lw8CHahXFMEQ?e=gKjgSF",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EXx9MlSpzyFPn32Iqms5xNcBY0kl9_Vlc1lw8CHahXFMEQ?e=gKjgSF",
  },
  {
    name: "Solufos 44 GR",
    description:
      "Fertilizante granulado con Nitrógeno de fuente ureica y Fósforo proveniente del ácido fosfórico, presenta una alta solubilidad y de reacción ácida en la solución del suelo.",
    benefits:
      "Fertilizante complejo compuesto por nitrógeno (N) y fósforo (P) para aplicación al suelo.\nPresenta un alto contenido de fósforo proveniente de la reacción entre ácido fosfórico.\nEs fuente ideal de fósforo por su formulación de escamas solubles, para aplicación al suelo en mezcla con algunos fertilizantes a base de potasio.\nPuede ser usado en todas las fases fenológicas del cultivo.\nPresenta una rápida disolución en el suelo y una asimilación inmediata por parte de la planta.\nEl fósforo juega un papel para la fotosíntesis y la respiración de la planta.\nFuente de alta eficiencia lo que permite aplicar bajas dosis del producto en el cultivo a menor costo.\nRespuesta rápida en el cultivo, ante una deficiencia de fósforo y nitrógeno.\nMaterias primas de excelente calidad y solubilidad.\nFertilizante hidrosoluble, es la mezcla química de urea con ácido fosfórico, formulado especialmente para nutrición por medio de sistemas de fertiirrigación.",
    composition: {
      "Nitrógeno total (N)": "17.0%",
      "Nitrógeno Ureico (N-NH2)": "17.0%",
      "Fósforo soluble en agua (P2O5)": "44.0%",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Café",
      "Palma",
      "Tomate",
      "Banano",
      "Plátano",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Cacao",
      "Vid/Uva",
      "Caña",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros",
    ],
    hierarchy: "Fertilizantes Edáficos - Fósforo",
    supplier: "EUROCHEM",
    countries: ["Colombia"],
    image: "productsImg/optimized/solufos-44-gr.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/Eco3qEvqknJKl74EMSeXeyIB3JMYKWhQiySy6Yqtrvl0mQ?e=fXpfiY",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EYOiSbUykBFAqshD258eFhIBkiSsZWR1FhMJdsgdPxSlGw?e=E6F0Eg",
  },
  {
    name: "Cloruro de potasio granular",
    description:
      "Fuente de Potasio en forma de cloruro en presentación granulada para ser incluida en todos los programas de nutrición de cultivos.",
    benefits:
      "El cloruro de potasio (KCl) es la fuente de fertilización de potasio (K) más usada en el mundo. Se ajusta a las necesidades de cultivos con de alta extracción de potasio.\nFertilizante con alta concentración de potasio, ideal para las correcciones de deficiencias de este nutriente.\nFertilizante recomendado para aplicación edáfica en diversos tipos de suelo y cultivo.\nEl KCl es un componente básico para la elaboración de fórmulas balanceadas de fertilización (mezclas físicas).\nEl efecto del potasio participa en la activación de enzimas, la regulación osmótica e hídrica, e incrementa la tolerancia a estreses bióticos y abióticos.",
    composition: {
      "Potasio soluble en agua K2O": "60.0%",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Café",
      "Palma",
      "Tomate",
      "Banano",
      "Plátano",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Cacao",
      "Vid/Uva",
      "Caña",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros",
    ],
    hierarchy: "Fertilizantes Edáficos - Potasio",
    supplier: "",
    countries: ["Colombia"],
    image: "productsImg/optimized/cloruro-de-potasio-granular.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EdvQFnMj_j1Jk_GedPTfuPcBua8dDIm-DYzlkupf67zUHQ?e=bDmZbB",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EdvQFnMj_j1Jk_GedPTfuPcBua8dDIm-DYzlkupf67zUHQ?e=bDmZbB",
  },
  {
    name: "Cloruro de potasio granular",
    description:
      "Fuente de Potasio en forma de cloruro en presentación granulada para ser incluida en todos los programas de nutrición de cultivos.",
    benefits:
      "El cloruro de potasio (KCl) es la fuente de fertilización de potasio (K) más usada en el mundo. Se ajusta a las necesidades de cultivos con de alta extracción de potasio.\nFertilizante con alta concentración de potasio, ideal para las correcciones de deficiencias de este nutriente.\nFertilizante recomendado para aplicación edáfica en diversos tipos de suelo y cultivo.\nEl KCl es un componente básico para la elaboración de fórmulas balanceadas de fertilización (mezclas físicas).\nEl efecto del potasio participa en la activación de enzimas, la regulación osmótica e hídrica, e incrementa la tolerancia a estreses bióticos y abióticos.",
    composition: {
      "Potasio soluble en agua K2O": "60.0%",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Café",
      "Palma",
      "Tomate",
      "Banano",
      "Plátano",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Cacao",
      "Vid/Uva",
      "Caña",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros",
    ],
    hierarchy: "Fertilizantes Edáficos - Potasio",
    supplier: "",
    countries: ["Perú"],
    image: "productsImg/optimized/cloruro-de-potasio-granular.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EdvQFnMj_j1Jk_GedPTfuPcBua8dDIm-DYzlkupf67zUHQ?e=bDmZbB",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EdvQFnMj_j1Jk_GedPTfuPcBua8dDIm-DYzlkupf67zUHQ?e=bDmZbB",
  },
  {
    name: "Cloruro de potasio granular",
    description:
      "Fuente de Potasio en forma de cloruro en presentación granulada para ser incluida en todos los programas de nutrición de cultivos.",
    benefits:
      "El cloruro de potasio (KCl) es la fuente de fertilización de potasio (K) más usada en el mundo. Se ajusta a las necesidades de cultivos con de alta extracción de potasio.\nFertilizante con alta concentración de potasio, ideal para las correcciones de deficiencias de este nutriente.\nFertilizante recomendado para aplicación edáfica en diversos tipos de suelo y cultivo.\nEl KCl es un componente básico para la elaboración de fórmulas balanceadas de fertilización (mezclas físicas).\nEl efecto del potasio participa en la activación de enzimas, la regulación osmótica e hídrica, e incrementa la tolerancia a estreses bióticos y abióticos.",
    composition: {
      "Potasio soluble en agua K2O": "60.0%",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Café",
      "Palma",
      "Tomate",
      "Banano",
      "Plátano",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Cacao",
      "Vid/Uva",
      "Caña",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros",
    ],
    hierarchy: "Fertilizantes Edáficos - Potasio",
    supplier: "",
    countries: ["Venezuela"],
    image: "productsImg/optimized/cloruro-de-potasio-granular.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EdvQFnMj_j1Jk_GedPTfuPcBua8dDIm-DYzlkupf67zUHQ?e=bDmZbB",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EdvQFnMj_j1Jk_GedPTfuPcBua8dDIm-DYzlkupf67zUHQ?e=bDmZbB",
  },
  {
    name: "Korn-Kali",
    description:
      "Fertilizante complejo de cloruro de potasio y sulfato de magnesio (Kieserita). Fuente de alta solubilidad para el suministro de potasio, magnesio y azufre.",
    benefits:
      "Ideal para el suministro básico de potasio, magnesio y azufre. Todos los nutrientes de Korn-Kali son totalmente solubles en agua y están inmediatamente disponibles para la planta.",
    composition: {
      "Cloruro de Potasio": "63.5%",
      "Cloruro de Sodio": "9.5%",
      "Sulfato de Magnesio": "4.0%",
    },
    cultivation: [
      "Papa",
      "Maíz",
      "Frutales",
      "Palma de aceite",
      "Musaceas",
      "Café",
      "Arroz",
      "Pastos",
      "Hortalizas",
      "Cultivos en desarrollo y producción",
    ],
    hierarchy: "Edáficos - Potasio",
    supplier: "K+S",
    countries: ["Colombia"],
    image: "productsImg/optimized/korn-kali.webp",
  },
  {
    name: "Korn-Kali",
    description:
      "Fertilizante complejo de cloruro de potasio y sulfato de magnesio (Kieserita). Fuente de alta solubilidad para el suministro de potasio, magnesio y azufre.",
    benefits:
      "Ideal para el suministro básico de potasio, magnesio y azufre. Todos los nutrientes de Korn-Kali son totalmente solubles en agua y están inmediatamente disponibles para la planta.",
    composition: {
      "Cloruro de Potasio": "63.5%",
      "Cloruro de Sodio": "9.5%",
      "Sulfato de Magnesio": "4.0%",
    },
    cultivation: [
      "Papa",
      "Maíz",
      "Frutales",
      "Palma de aceite",
      "Musaceas",
      "Café",
      "Arroz",
      "Pastos",
      "Hortalizas",
      "Cultivos en desarrollo y producción",
    ],
    hierarchy: "Edáficos - Potasio",
    supplier: "K+S",
    countries: ["Perú"],
    image: "productsImg/optimized/korn-kali.webp",
  },
  {
    name: "Korn-Kali",
    description:
      "Fertilizante complejo de cloruro de potasio y sulfato de magnesio (Kieserita). Fuente de alta solubilidad para el suministro de potasio, magnesio y azufre.",
    benefits:
      "Ideal para el suministro básico de potasio, magnesio y azufre. Todos los nutrientes de Korn-Kali son totalmente solubles en agua y están inmediatamente disponibles para la planta.",
    composition: {
      "Cloruro de Potasio": "63.5%",
      "Cloruro de Sodio": "9.5%",
      "Sulfato de Magnesio": "4.0%",
    },
    cultivation: [
      "Papa",
      "Maíz",
      "Frutales",
      "Palma de aceite",
      "Musaceas",
      "Café",
      "Arroz",
      "Pastos",
      "Hortalizas",
      "Cultivos en desarrollo y producción",
    ],
    hierarchy: "Edáficos - Potasio",
    supplier: "K+S",
    countries: ["Bolivia"],
    image: "productsImg/optimized/korn-kali.webp",
  },
  {
    name: "Korn-Kali +B",
    description:
      "Fertilizante complejo de cloruro de potasio, sulfato de magnesio (Kieserita) y boro. Fuente de alta solubilidad para el suministro de K, Mg, S y B.",
    benefits:
      "Ideal para el suministro de potasio, magnesio, azufre y boro.\nSu fuente de azufre es sulfato de origen natural (kieserita).\nBalance de potasio, azufre, magnesio y boro que mejora el rendimiento y la calidad de la cosecha.\nEl potasio mejora la tolerancia al estrés por sequía; el magnesio incrementa la fotosíntesis, y participa en procesos de defensa de la planta; el azufre es esencial para la síntesis de proteínas y mejora la eficiencia del nitrógeno y el boro es fundamental para la polinización, el desarrollo de frutos y semillas.\nTodos los nutrientes de Korn-Kali son totalmente solubles en agua y por esto están inmediatamente disponibles para la planta.",
    composition: {
      "Potasio K2O": "40.0%",
      "Magnesio MgO": "6.0%",
      "Azufre S": "4.0%",
      "Boro B": "0.25%",
    },

    cultivation: [
      "Semestrales",
      "Papa",
      "Café",
      "Palma",
      "Tomate",
      "Banano",
      "Plátano",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Cacao",
      "Vid/Uva",
      "Caña",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros",
    ],
    hierarchy: "Fertilizantes Edáficos - Potasio",
    supplier: "K+S",
    countries: ["Perú"],
    image: "productsImg/optimized/Korn-kali +B PERÚ.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EdO7kMnuhHhBouAX5N5x5F4Bek_FXmyXCU07kcFn6WQOAA?e=Rwhvxn",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ET7j7dtWf0xOsFyQTrx-LPIB4K3FFMuuzst3YzrShEXDtA?e=GGenwr",
  },
  {
    name: "Korn-Kali +B",
    description:
      "Fertilizante complejo de cloruro de potasio, sulfato de magnesio (Kieserita) y boro. Fuente de alta solubilidad para el suministro de K, Mg, S y B.",
    benefits:
      "Ideal para el suministro de potasio, magnesio, azufre y boro.\nSu fuente de azufre es sulfato de origen natural (kieserita).\nBalance de potasio, azufre, magnesio y boro que mejora el rendimiento y la calidad de la cosecha.\nEl potasio mejora la tolerancia al estrés por sequía; el magnesio incrementa la fotosíntesis, y participa en procesos de defensa de la planta; el azufre es esencial para la síntesis de proteínas y mejora la eficiencia del nitrógeno y el boro es fundamental para la polinización, el desarrollo de frutos y semillas.\nTodos los nutrientes de Korn-Kali son totalmente solubles en agua y por esto están inmediatamente disponibles para la planta.",
    composition: {
      "Potasio K2O": "40.0%",
      "Magnesio MgO": "6.0%",
      "Azufre S": "4.0%",
      "Boro B": "0.25%",
    },

    cultivation: [
      "Semestrales",
      "Papa",
      "Café",
      "Palma",
      "Tomate",
      "Banano",
      "Plátano",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Cacao",
      "Vid/Uva",
      "Caña",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros",
    ],
    hierarchy: "Fertilizantes Edáficos - Potasio",
    supplier: "K+S",
    countries: ["Colombia"],
    image: "productsImg/optimized/korn-kali-b.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EdO7kMnuhHhBouAX5N5x5F4Bek_FXmyXCU07kcFn6WQOAA?e=Rwhvxn",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ET7j7dtWf0xOsFyQTrx-LPIB4K3FFMuuzst3YzrShEXDtA?e=GGenwr",
  },
  {
    name: "Disacal B-Zinc",
    description:
      "Fertilizante potencializador en gránulos dispersables, que funciona como fuente de azufre, calcio, magnesio, zinc y boro",
    benefits:
      "Es ideal para mezcla física y facilita la aplicación al suelo de forma manual Se disuelve fácilmente en suelos húmedos, para hacerse rápidamente disponible. Por su encapsulado, evita la generación de polvo hasta en un 98% permitiendo aplicaciones limpias y seguras para la salud del aplicador. No se recomienda utilizarlo en fertirriego.",
    composition: {
      "Azufre (S)": "24,0 %",
      "Calcio (CaO)": "20,0 %",
      "Magnesio (MgO)": "4,0 %",
      "Zinc (ZnO)": "2,0 %",
      "Boro (B2O3)": "0,6 %",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Café",
      "Palma",
      "Tomate",
      "Banano",
      "Plátano",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      " Cacao",
      "Vid/Uva",
      "Caña",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      " Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Fertilizantes Edáficos - Acondicionadores del suelo ",
    supplier: "MANTTRA",
    countries: ["Perú"],
    image: "productsImg/optimized/disacal-b-zinc.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/Eepkez0QKAFDuvGZq8OZafEBtkdUB6DVSKkImQV6HGsymg?e=oUfefO",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EYEEVoWqwW1LtiLieFSZ8B4BqTdV11JItYDji4ZkMf4WZw?e=5HuW0N",
  },
  {
    name: "Disacal B-Zinc",
    description:
      "Fertilizante potencializador en gránulos dispersables, que funciona como fuente de azufre, calcio, magnesio, zinc y boro",
    benefits:
      "Es ideal para mezcla física y facilita la aplicación al suelo de forma manual Se disuelve fácilmente en suelos húmedos, para hacerse rápidamente disponible. Por su encapsulado, evita la generación de polvo hasta en un 98% permitiendo aplicaciones limpias y seguras para la salud del aplicador. No se recomienda utilizarlo en fertirriego.",
    composition: {
      "Azufre (S)": "24,0 %",
      "Calcio (CaO)": "20,0 %",
      "Magnesio (MgO)": "4,0 %",
      "Zinc (ZnO)": "2,0 %",
      "Boro (B2O3)": "0,6 %",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Café",
      "Palma",
      "Tomate",
      "Banano",
      "Plátano",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      " Cacao",
      "Vid/Uva",
      "Caña",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      " Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Fertilizantes Edáficos - Acondicionadores del suelo ",
    supplier: "MANTTRA",
    countries: ["Bolivia"],
    image: "productsImg/optimized/DISACAL B.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/Eepkez0QKAFDuvGZq8OZafEBtkdUB6DVSKkImQV6HGsymg?e=oUfefO",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EYEEVoWqwW1LtiLieFSZ8B4BqTdV11JItYDji4ZkMf4WZw?e=5HuW0N",
  },
  {
    name: "Nov@ GR",
    description:
      "Promotor del crecimiento granulado a base de extractos vegetales",
    benefits:
      "Aumenta el desarrollo radicular Mejora el enraizamiento de las plantas trasplantadas y envasadas Garantiza un desarrollo vegetativo equilibrado Contiene extractos vegetales ricos en ácidos fúlvicos, ácidos húmicos, aminoácidos y glicin-betaína Ácidos Fúlvicos Mejora el crecimiento y el desarrollo de la planta, remueve la asimilación de los microelementos ya presentes en el suelo o suministrados a través de la fertilización Ácidos Húmicos Favorecen la agregación de los coloides, mejoran el drenaje y el intercambio de aire en el suelo Betaínas Actúa como agente anti estresante, ahorrando energía para los mecanismos de defensa y creando condiciones favorables para el desarrollo.",
    composition: {
      "Potasio soluble en agua (K2O)": "5,1 %",
      "Carbono orgánico total oxidable (C)": "21,49 %",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Café",
      "Palma",
      "Tomate",
      "Banano",
      "Plátano",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      " Cacao",
      "Vid/Uva",
      "Caña",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      " Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Fertilizantes Edáficos - Acondicionadores del suelo ",
    supplier: "BIOLCHIM",
    countries: ["Colombia"],
    image: "productsImg/optimized/nov@-gr.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/Ed6TCnOohBdOg73rBR24g6QBicl0fuftgFYzyJxQfD0QUg?e=NUm8ab",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EYT88ut2D45CjINuClmvucsBDC_z5WI20yOCk9aRYX3BZA?e=2j4PV7",
  },
  {
    name: "Nov@ GR",
    description:
      "Promotor del crecimiento granulado a base de extractos vegetales",
    benefits:
      "Aumenta el desarrollo radicular Mejora el enraizamiento de las plantas trasplantadas y envasadas Garantiza un desarrollo vegetativo equilibrado Contiene extractos vegetales ricos en ácidos fúlvicos, ácidos húmicos, aminoácidos y glicin-betaína Ácidos Fúlvicos Mejora el crecimiento y el desarrollo de la planta, remueve la asimilación de los microelementos ya presentes en el suelo o suministrados a través de la fertilización Ácidos Húmicos Favorecen la agregación de los coloides, mejoran el drenaje y el intercambio de aire en el suelo Betaínas Actúa como agente anti estresante, ahorrando energía para los mecanismos de defensa y creando condiciones favorables para el desarrollo.",
    composition: {
      "Potasio soluble en agua (K2O)": "5,1 %",
      "Carbono orgánico total oxidable (C)": "21,49 %",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Café",
      "Palma",
      "Tomate",
      "Banano",
      "Plátano",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      " Cacao",
      "Vid/Uva",
      "Caña",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      " Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Fertilizantes Edáficos - Acondicionadores del suelo ",
    supplier: "BIOLCHIM",
    countries: ["Perú"],
    image: "productsImg/optimized/nov@.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/Ed6TCnOohBdOg73rBR24g6QBicl0fuftgFYzyJxQfD0QUg?e=NUm8ab",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EYT88ut2D45CjINuClmvucsBDC_z5WI20yOCk9aRYX3BZA?e=2j4PV7",
  },
  {
    name: "Silexmag GR",
    description:
      "Fertilizante simple con alto contenido de Magnesio para aplicación edáfica producido con materias primas de alta calidad.",
    benefits:
      "SILICATO DE Mg GR es sometido a tratamiento térmico, que mejora el desempeño del producto. Son gránulos de tamaño entre 2 y 4 mm cuya composición está garantizada en  cada uno de ellos según análisis químico. Es ideal para mezclar con los fertilizantes NPK, aportando Mg y Si. Aporta a la solución del suelo Si, el cual es tomado como ácido orto silícico depositándose en las paredes celulares fortaleciendo los tejidos vegetales El silicio presente contribuye con la liberación del P fijado en el suelo Regula el pH del suelo mitigando la toxicidad del Fe y Al Estimula los compuestos fenólicos o fitoalexinas que son responsables de la defensa bioquímica de la planta frente a plagas y enfermedades.",
    composition: {
      "Silicio (SiO2)": "41,0 %",
      "Magnesio (MgO)": "30,0 %",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Café",
      "Palma",
      "Tomate",
      "Banano",
      "Plátano",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      " Cacao",
      "Vid/Uva",
      "Caña",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      " Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Fertilizantes Edáficos - Acondicionadores del suelo ",
    supplier: "DISAN",
    countries: ["Perú"],
    image: "productsImg/optimized/silexmag-gr.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EUGfJ4DLYGRIoemSVgajON0BqOC52dff17RZxrxvkZleIw?e=AVpt6Y",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EZ6cPWmgp55Lp4TrKgxa32gB3Tfjux_e6O7sX4ancUXb4Q?e=NdT4B6",
  },
  {
    name: "Silexmag GR",
    description:
      "Fertilizante simple para aplicación edáfica producido con materias primas de alta calidad, ",
    benefits:
      "SILICATO DE Mg GR es sometido a tratamiento térmico, que mejora el desempeño del producto. Son gránulos de tamaño entre 2 y 4 mm cuya composición está garantizada en  cada uno de ellos según análisis químico. Es ideal para mezclar con los fertilizantes NPK, aportando Mg y Si. Aporta a la solución del suelo Si, el cual es tomado como ácido orto silícico depositándose en las paredes celulares fortaleciendo los tejidos vegetales El silicio presente contribuye con la liberación del P fijado en el suelo Regula el pH del suelo mitigando la toxicidad del Fe y Al Estimula los compuestos fenólicos o fitoalexinas que son responsables de la defensa bioquímica de la planta frente a plagas y enfermedades.",
    composition: {
      "Silicio (SiO2)": "41,0 %",
      "Magnesio (MgO)": "30,0 %",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Café",
      "Palma",
      "Tomate",
      "Banano",
      "Plátano",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      " Cacao",
      "Vid/Uva",
      "Caña",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      " Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Fertilizantes Edáficos - Acondicionadores del suelo ",
    supplier: "DISAN",
    countries: ["Bolivia"],
    image: "productsImg/optimized/Silexmag GR BOL.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EUGfJ4DLYGRIoemSVgajON0BqOC52dff17RZxrxvkZleIw?e=AVpt6Y",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EZ6cPWmgp55Lp4TrKgxa32gB3Tfjux_e6O7sX4ancUXb4Q?e=NdT4B6",
  },
  {
    name: "Disan 27-6-6-2(S)",
    description:
      "Fertilizante granulado NPK con proporciones iguales de nitrógeno amoniacal y nítrico para aplicación como fertilizante simple o en mezclas con otros granulados.  ",
    benefits:
      "El fósforo presente desempeña un papel clave en el desarrollo de raíces saludables, la floración y la fructificación. El fósforo es esencial para la transferencia d energía y la síntesis de ácidos nucleicos lo que favorece un adecuado desarrollo de las plantas El fertilizante proporciona un aporte necesario para el establecimiento de sistemas radiculares fuertes y una buena producción de flores y frutos.   El potasio es un nutriente esencial para el desarrollo de las plantas, dando equilibrio hídrico mejorando la resistencia al estrés y promueve la calidad de los frutos. Este fertilizante proporciona una fuente adecuada del nutriente para promover el crecimiento equilibrado y mejorar la productividad de los cultivos. ",
    composition: {
      "Nitrógeno total (N)": "27,0 %",
      "Nitrógeno amoniacal (N-NH4)": "14,0 %",
      "Nitrógeno nítrico (N-NO3)": "12,1 %",
      "Fósforo disponible (P2O5)": "5,5 %",
      "Fósforo soluble en agua (P2O5)": "4,0 %",
      "Potasio soluble en agua (K2O)": "5,5 %",
      "Azufre  (S-SO3)": "2,0 %",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Fertilizantes Edáficos - Nitrógeno",
    supplier: "DISAN",
    countries: ["Colombia"],
    image: "productsImg/optimized/disan-27-6-6-2(s).webp",
  },
  {
    name: "NitroS 30",
    description:
      "Fertilizante mezcla física con alto aporte de nitrógeno recubierto con el polímero N-Charge, que lo protege de pérdidas en el suelo. Ideal para cultivos en etapas de desarrollo previas a la producción.",
    benefits:
      "Fertilizante mezcla física con alto aporte de nitrógeno, ideal para cultivos en etapas de desarrollo previas a la producción. Aporta nitrógeno recubierto con el polímero N-Charge, que lo protege de pérdidas en el suelo. Suministra fósforo y potasio en menor proporción. El nitrógeno es un elemento esencial en el crecimiento de la planta ya que es componente de aminoácidos, proteínas, ácidos nucleicos y de la molécula de clorofila. Tiene aporte de fósforo en forma de MAP, fuente eficiente que se disuelve rápidamente en el suelo si se presenta la humedad adecuada. El fósforo juega un papel fundamental en la transferencia de energía, esencial para la fotosíntesis y la respiración. Aporta también azufre participando en la síntesis de proteínas, el cual juega un papel importante en los mecanismos de defensa de las plantas y mejora la eficiencia del nitrógeno.",
    composition: {
      "Nitrógeno total N": "30.0%",
      "Nitrógeno ureico N-NH2": "23.0%",
      "Nitrógeno amoniacal N-NH4": "7.0%",
      "Fósforo asimilable P2O5": "5.0%",
      "Potasio total K": "6.0%",
      "Azufre total S": "7.0%",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Café",
      "Palma",
      "Tomate",
      "Banano",
      "Plátano",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Cacao",
      "Vid/Uva",
      "Caña",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros",
    ],
    hierarchy: "Fertilizantes Edáficos - Nitrógeno",
    supplier: "DISAN",
    countries: ["Perú"],
    image: "productsImg/optimized/NitroS30.webp",
  },
  {
    name: "NitroS 40",
    description:
      "Combina la fuerza del nitrógeno y el azufre para dar a los cultivos una doble ventaja.",
    benefits:
      "Una nutrición enriquecida con azufre permite a los agricultores mejorar la eficiencia en el uso del nitrógeno Promueve el rendimiento y ganancias de calidad La presencia de nitrógeno en forma altamente efectiva de amonio y ureico asegura el suministro de N mientras el sulfato-azufre favorece el uso óptimo del nitrógeno en cada etapa del crecimiento La combinación de nutrientes en un gránulo proporciona una textura suave.  y aplicación constante de nutrientes vitales para las plantas sin trabajo de campo innecesario. ",
    composition: {
      "Nitrógeno total (N)": "40,0 %",
      "Nitrógeno amoniacal (N-NH4)": "5,0 %",
      "Nitrógeno Ureico (N-NH2)": "35,0 %",
      "Azufre total (S)": "5,0 %",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Café",
      "Palma",
      "Tomate",
      "Banano",
      "Plátano",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      " Cacao",
      "Vid/Uva",
      "Caña",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      " Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Fertilizantes Edáficos - Nitrógeno",
    supplier: "EUROCHEM",
    countries: ["Perú"],
    image: "productsImg/optimized/nitros-40.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EahWV8n2sANBkEmr-oMqfQIBbx_wz2BA4CDPvy6Npc5VBg?e=YogsJN",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EZu1CthGou9LnXxHleV_f04BGVELNuq-HlwubrwXuYA3XA?e=gJenXa",
  },
  {
    name: "Nitrosulfato Amónico NSA 26",
    description:
      "Fertilizante que aporta nitrato y sulfato como sal doble, con alta solubilidad y asimilación por las plantas",
    benefits:
      "Al tener solamente el 6,5% de nitrógeno nítrico se puede utilizar como abono de sementera (fondo) y en cobertera. El 19,5% de nitrógeno amoniacal va a alargar su aprovechamiento por la planta El azufre acidifica el suelo, movilizando microelementos en suelos alcalinos y retrasa la nitrificación, mejorando su aprovechamiento, por lo que puede considerarse de acción lenta.   Nitrógeno Nítrico, de inmediata asimilación para el ahijado.  Nitrógeno Amónico, de liberación progresiva hasta el final del ciclo. La alta solubilidad permite su uso en riegos extensivos, en especial en riegos con pivot.   Su dureza permite la distribución homogénea, facilita la manipulación y facilita correcta conservación del producto. El contenido en Fe, si bien no es declarable, se utiliza como aglomerante del producto, mejorando su dureza y su manejo y dando ese color rosa que identifica al producto. Además, contribuye a evitar la clorosis.",
    composition: {
      "Nitrógeno total (N)": "26,0 %",
      "Nitrógeno nítrico (N-NO3)": "6,5 %",
      "Nitrógeno amoniacal (N-NH4)": "19,5 %",
      "Azufre (S)": "15,0 %",
      "Azufre (SO3)": "37,0 %",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Café",
      "Palma",
      "Tomate",
      "Banano",
      "Plátano",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      " Cacao",
      "Vid/Uva",
      "Caña",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      " Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Fertilizantes Edáficos - Nitrógeno",
    supplier: "FERTIBERIA",
    countries: ["Colombia"],
    image: "productsImg/optimized/nitrosulfato-amónico-nsa-26.webp",
  },
  {
    name: "Nitrosulfato Amónico NSA 26",
    description:
      "Fertilizante que aporta nitrato y sulfato como sal doble, con alta solubilidad y asimilación por las plantas",
    benefits:
      "Al tener solamente el 6,5% de nitrógeno nítrico se puede utilizar como abono de sementera (fondo) y en cobertera. El 19,5% de nitrógeno amoniacal va a alargar su aprovechamiento por la planta El azufre acidifica el suelo, movilizando microelementos en suelos alcalinos y retrasa la nitrificación, mejorando su aprovechamiento, por lo que puede considerarse de acción lenta.   Nitrógeno Nítrico, de inmediata asimilación para el ahijado.  Nitrógeno Amónico, de liberación progresiva hasta el final del ciclo. La alta solubilidad permite su uso en riegos extensivos, en especial en riegos con pivot.   Su dureza permite la distribución homogénea, facilita la manipulación y facilita correcta conservación del producto. El contenido en Fe, si bien no es declarable, se utiliza como aglomerante del producto, mejorando su dureza y su manejo y dando ese color rosa que identifica al producto. Además, contribuye a evitar la clorosis.",
    composition: {
      "Nitrógeno total (N)": "26,0 %",
      "Nitrógeno nítrico (N-NO3)": "6,5 %",
      "Nitrógeno amoniacal (N-NH4)": "19,5 %",
      "Azufre (S)": "15,0 %",
      "Azufre (SO3)": "37,0 %",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Café",
      "Palma",
      "Tomate",
      "Banano",
      "Plátano",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      " Cacao",
      "Vid/Uva",
      "Caña",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      " Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Fertilizantes Edáficos - Nitrógeno",
    supplier: "FERTIBERIA",
    countries: ["Perú"],
    image: "productsImg/optimized/nitrosulfato-amónico-nsa-26.webp",
  },
  {
    name: "Nitra Organic",
    description:
      "NITRA ORGANIC es un fertilizante orgánico que aporta nitrogéno para aplicación al suelo.",
    benefits:
      "Este fertilizante granulado de origen orgánico enriquecido con el complejo APR (Activador proteico de la rizosfera), impulsa el desarrollo radicular, la absorción de nutrientes y la fertilidad del suelo, promoviendo cosechas más saludables y abundantes.Fertilizante orgánico con alto contenido de nitrógeno y carbono orgánico para aplicación al suelo. De origen orgánico formulado con ingredientes naturales, Protamin GR respeta la agricultura orgánica y promueve un equilibrio sostenible en el ecosistema. El Activador Proteico de la Rizosfera estimula un crecimiento radicular vigoroso aumentando la capacidad de las plantas para absorber nutrientes esenciales. La aplicación de Protamin GR mejora la calidad del suelo creando un ambiente propicio para el crecimiento saludable de los cultivos. ",
    composition: {
      "Nitrógeno total N": "12.5%",
      "Carbono total": "40.0%",
      "pH en solución al 10%": "<6",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Café",
      "Palma",
      "Tomate",
      "Banano",
      "Plátano",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      " Cacao",
      "Vid/Uva",
      "Caña",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      " Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Fertilizantes Edáficos - Nitrógeno Orgánico",
    supplier: "BIOLCHIM",
    countries: ["Perú"],
    image: "productsImg/optimized/nitra-organic.webp",
  },
  {
    name: "Qrop KS",
    description:
      "Qrop KS es una fuente natural, robusta y segura de potasio y nitrógeno nítrico cuya presentación lo hace ideal para mezclas con otros fertilizantes ",
    benefits:
      "Qrop™ KS es una fuente de potasio que también proporciona nitrógeno nítrico rápidamente absorbido, la fuente de nitrógeno preferida de las plantas. Como resultado, es la fuente más importante en términos de rendimiento de los cultivos. Qrop™ KS es una solución natural producida a partir de materias primas naturales extraídas en el norte de Chile, a través de un proceso amigable con el medio ambiente.  Qrop™ KS tiene una robustez superior. Los gránulos tienen menos pérdida de producto durante las pruebas de transporte y un mejor rendimiento durante las pruebas de abrasión e impacto.   Qrop™ KS está cambiando el juego en el mercado de la mezcla a granel, ofreciendo una fuente superior de potasio, libre de cloruro, y manipulación segura de nitrógeno nítrico.",
    composition: {
      "Nitrógeno total N": "12.0%",
      "Potasio K2O": "46.0%",
    },

    cultivation: [
      "Aguacate",
      "Hortalizas",
      "Papa",
      "Plátano",
      "Banano",
      "Cítricos",
      " Frutales",
    ],
    hierarchy: "Fertilizantes Edáficos - Potasio",
    supplier: "SQM",
    countries: ["Colombia"],
    image: "productsImg/optimized/qrop-ks.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EXJv65BQMllOm4Q8-MwOhU8BTkWGrN0ic8bUoQN2lnLKbA?e=uaV5Ko",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EeAG5X3BqbVBlEfEnO5d09cBBmZ0aip0se-u39xAWQrQiA?e=TCB03D",
  },
  {
    name: "Qrop KS",
    description:
      "Qrop KS es una fuente natural, robusta y segura de potasio y nitrógeno nítrico cuya presentación lo hace ideal para mezclas con otros fertilizantes ",
    benefits:
      "Qrop™ KS es una fuente de potasio que también proporciona nitrógeno nítrico rápidamente absorbido, la fuente de nitrógeno preferida de las plantas. Como resultado, es la fuente más importante en términos de rendimiento de los cultivos. Qrop™ KS es una solución natural producida a partir de materias primas naturales extraídas en el norte de Chile, a través de un proceso amigable con el medio ambiente.  Qrop™ KS tiene una robustez superior. Los gránulos tienen menos pérdida de producto durante las pruebas de transporte y un mejor rendimiento durante las pruebas de abrasión e impacto.   Qrop™ KS está cambiando el juego en el mercado de la mezcla a granel, ofreciendo una fuente superior de potasio, libre de cloruro, y manipulación segura de nitrógeno nítrico.",
    composition: {
      "Nitrógeno total N": "12.0%",
      "Potasio K2O": "46.0%",
    },

    cultivation: [
      "Aguacate",
      "Hortalizas",
      "Papa",
      "Plátano",
      "Banano",
      "Cítricos",
      " Frutales",
    ],
    hierarchy: "Fertilizantes Edáficos - Potasio",
    supplier: "SQM",
    countries: ["Ecuador"],
    image: "productsImg/optimized/qrop-ks.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EXJv65BQMllOm4Q8-MwOhU8BTkWGrN0ic8bUoQN2lnLKbA?e=uaV5Ko",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EeAG5X3BqbVBlEfEnO5d09cBBmZ0aip0se-u39xAWQrQiA?e=TCB03D",
  },
  {
    name: "Sulfato de potasio granular",
    description:
      "Fuente granulada de potasio en forma de sulfato para ser incluida en todos los programas de nutrición de cultivos orgánicos y tradicionales.",
    benefits:
      "Fertilizante con alta concentración de potasio, ideal para las correcciones de deficiencias de este nutriente. Fertilizante recomendado para aplicación edáfica en diversos tipos de suelo y cultivo. El sulfato de potasio es un componente usado en algunas fórmulas balanceadas de fertilización (mezclas físicas). El potasio es promueve la fotosíntesis, incrementa la tolerancia al estrés biótico y abiótico, regula varias enzimas y es determinante para la formación y carga de frutos y llenado de grano. El Azufre participa en la síntesis de proteínas y vitaminas, promueve la nodulación en leguminosas, importante en la producción de semillas y necesario en la formación de clorofila.",
    composition: {
      "Potasio soluble en agua K2O": "50.0%",
      "Azufre soluble en agua S": "18.0%",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Fertilizantes Edáficos - Potasio - organicos",
    supplier: "K+S",
    countries: ["Colombia"],
    image: "productsImg/optimized/sulfato-de-potasio-granular.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ET7ANlfz0tlLhCB9BQN6McEBxulXZIU4xHo7RgwRHrc2bQ?e=WcwsTq",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ETG018RDJ55CocXRH63_09IBk51dMSflvz4M8Qz_dvwEqw?e=ndbfga",
  },
  {
    name: "Disaphos Ca",
    description:
      "Es un fertilizante de aplicación directa al suelo que aporta fósforo, calcio y otros elementos.",
    benefits:
      "Ayuda en la mejora de la fertilidad de los suelos promoviendo el enrizamiento, floración y desarrollo de las plantas para todo tipo de cultivos de corto y largo periodo. Disaphos Ca tiene una función decisiva en la etapa crítica de la floración al promover enlaces de alta energía en los procesos de multiplicación celular, promoviendo un mayor número de flores.",
    composition: {
      "Fósforo P2O5": "23.0%",
      "Potasio K2O": "5.0%",
      "Calcio Ca": "35.0%",
      "Silicio SiO": "25.0%",
      "Magnesio MgO": "4.0%",
      "Azufre S": "6.0%",
      "Hierro Fe": "2.0%",
      "Cobre Cu": "2.0%",
      "Zinc Zn": "1.0%",
      "pH (1/100)": "6.0 - 9.0",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Café",
      "Palma",
      "Tomate",
      "Banano",
      "Plátano",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Cacao",
      "Vid/Uva",
      "Caña",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros",
    ],
    hierarchy: "Fertilizantes Edáficos - Fósforo",
    supplier: "DISAN",
    countries: ["Perú"],
    image: "productsImg/optimized/disaphosca.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EVDpNZVKJBBEub9GSIOsNhcB6uIDcH9fxFfM4ffuCx5UOg?e=mtfQTs",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EYc9fNY29rtJm-z4WSWYfloBCOwg6kMrAumgMP_Oelncjg?e=vlVVRL",
  },
  {
    name: "Disaphos Ca",
    description:
      "Es un fertilizante de aplicación directa al suelo que aporta fósforo, calcio y otros elementos.",
    benefits:
      "Ayuda en la mejora de la fertilidad de los suelos promoviendo el enrizamiento, floración y desarrollo de las plantas para todo tipo de cultivos de corto y largo periodo. Disaphos Ca tiene una función decisiva en la etapa crítica de la floración al promover enlaces de alta energía en los procesos de multiplicación celular, promoviendo un mayor número de flores.",
    composition: {
      "Fósforo P2O5": "23.0%",
      "Potasio K2O": "5.0%",
      "Calcio Ca": "35.0%",
      "Silicio SiO": "25.0%",
      "Magnesio MgO": "4.0%",
      "Azufre S": "6.0%",
      "Hierro Fe": "2.0%",
      "Cobre Cu": "2.0%",
      "Zinc Zn": "1.0%",
      "pH (1/100)": "6.0 - 9.0",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Café",
      "Palma",
      "Tomate",
      "Banano",
      "Plátano",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Cacao",
      "Vid/Uva",
      "Caña",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros",
    ],

    hierarchy: "Fertilizantes Edáficos - Fósforo",
    supplier: "DISAN",
    countries: ["Bolivia"],
    image: "productsImg/optimized/disaphos-ca.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EVDpNZVKJBBEub9GSIOsNhcB6uIDcH9fxFfM4ffuCx5UOg?e=mtfQTs",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EYc9fNY29rtJm-z4WSWYfloBCOwg6kMrAumgMP_Oelncjg?e=vlVVRL",
  },
  {
    name: "Esta Kieserita",
    description:
      "Fuente de magnesio en forma de sulfato, extraída directamente de la mina sin tratamiento químico, certificada para el uso en programas de nutrición en cultivos orgánicos y en agricultura convencional.",
    benefits:
      "ESTA Kieserita contiene magnesio y azufre en forma sulfatada los cuales están directamente disponibles para la planta.                                                                                                                                        Se puede emplear en todos los suelos independientemente del pH y en todos los cultivos por su rápida eficacia y su acción duradera.                                                                                                               Ayuda mejorando la fotosíntesis de la planta ya que el magnesio es un componente esencial de la clorofila, lo que se traduce como un crecimiento en las plantas y una gran eficiencia fotosintética. Un adecuado contenido de magnesio incrementa el rendimiento de los cultivos en términos de cantidad y calidad.                                                                                                                                       El magnesio como elemente facilita la absorción de otros elementos esenciales como el nitrógeno, fosforo y potasio.                                                                                                                  Con la aplicación y presencia del azufre mejora la calidad de los frutos                                                                                   El azufre es esencial para la fijación de nitrógeno por parte de las bacterias leguminosas lo que incrementa la disponibilidad de este en las plantas.                                                                                                                                                     La Kieserita aporta una serie de beneficios a los cultivos agrícolas, su adecuado uso contribuye a mejorar la fotosíntesis, la producción, calidad de frutos, resistencia a enfermedades y plagas, la eficiencia del uso de nitrógeno y la estructura del suelo.",
    composition: {
      "Sulfato de Magnesio (MgSO4)": "75 %",
      "Otros sulfatos (K2SO4 , CaSO4, Na2SO4)": "4 %",
      "Cloruros (KCl, NaCl)": "3 %",
      "Otros (Principalmente agua de cristalización)": "18 %",
    },
    cultivation: [
      "Café",
      "Maíz",
      "Banano",
      "Palma de Aceite",
      "Aguacate",
      "Cannabis Medicinal",
      "Mango",
      "Paturas",
    ],
    hierarchy: "Fertilizantes Edáficos - Magnesio",
    supplier: "K+S",
    countries: ["Colombia"],
    image: "productsImg/optimized/esta-kieserita.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EWlpnypRFklEt_AHrYc885sBIqchFWisyeSQs7Z_zc3yog?e=0epmmj",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EeuEnP4mVytFpCGAVS8DGywBSk6AEV8_8YHWwSf-PnyTqw?e=Rv3ymh",
  },
  {
    name: "Esta Kieserita",
    description:
      "Fuente de magnesio en forma de sulfato, extraída directamente de la mina sin tratamiento químico, certificada para el uso en programas de nutrición en cultivos orgánicos y en agricultura convencional.",
    benefits:
      "ESTA Kieserita contiene magnesio y azufre en forma sulfatada los cuales están directamente disponibles para la planta.                                                                                                                                        Se puede emplear en todos los suelos independientemente del pH y en todos los cultivos por su rápida eficacia y su acción duradera.                                                                                                               Ayuda mejorando la fotosíntesis de la planta ya que el magnesio es un componente esencial de la clorofila, lo que se traduce como un crecimiento en las plantas y una gran eficiencia fotosintética. Un adecuado contenido de magnesio incrementa el rendimiento de los cultivos en términos de cantidad y calidad.                                                                                                                                       El magnesio como elemente facilita la absorción de otros elementos esenciales como el nitrógeno, fosforo y potasio.                                                                                                                  Con la aplicación y presencia del azufre mejora la calidad de los frutos                                                                                   El azufre es esencial para la fijación de nitrógeno por parte de las bacterias leguminosas lo que incrementa la disponibilidad de este en las plantas.                                                                                                                                                     La Kieserita aporta una serie de beneficios a los cultivos agrícolas, su adecuado uso contribuye a mejorar la fotosíntesis, la producción, calidad de frutos, resistencia a enfermedades y plagas, la eficiencia del uso de nitrógeno y la estructura del suelo.",
    composition: {
      "Sulfato de Magnesio (MgSO4)": "75 %",
      "Otros sulfatos (K2SO4 , CaSO4, Na2SO4)": "4 %",
      "Cloruros (KCl, NaCl)": "3 %",
      "Otros (Principalmente agua de cristalización)": "18 %",
    },
    cultivation: [
      "Café",
      "Maíz",
      "Banano",
      "Palma de Aceite",
      "Aguacate",
      "Cannabis Medicinal",
      "Mango",
      "Paturas",
    ],
    hierarchy: "Fertilizantes Edáficos - Magnesio",
    supplier: "K+S",
    countries: ["Perú"],
    image: "productsImg/optimized/esta-kieserita.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EWlpnypRFklEt_AHrYc885sBIqchFWisyeSQs7Z_zc3yog?e=0epmmj",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EeuEnP4mVytFpCGAVS8DGywBSk6AEV8_8YHWwSf-PnyTqw?e=Rv3ymh",
  },
  {
    name: "Kieserita Potásica",
    description:
      "Kieserita Potásica contiene Magnesio y Azufre en forma sulfatada directamente disponibles para la planta y Potasio en forma de Cloruro de Potasio.",
    benefits:
      "El uso de Kieserita hace que el aporte de Mg y S sea eficaz ya que esta se emplea en todos los suelos independientemente del pH. Un suministro adecuado de K y Mg es de gran importancia, pese a que estos dos nutrientes son antagonistas en la absorción desde la solución del suelo, el K juega un papel fundamental en la translocación del Mg en la planta.  El Mg es constituyente de la clorofila, está involucrado en las reacciones de carboxilación de la fotosíntesis, es responsable de la regulación del pH y del ajuste de turgencia en las células de las plantas, es cofactor de la actividad enzimática del proceso de fosforilación e influye en la absorción de otros nutrientes como el Al, K y Ca. El S está involucrado en numerosos procesos metabólicos de la planta, tales como la fotosíntesis, la formación de azúcar, almidón, aminoácidos y proteínas, así como la síntesis de aceites y grasas.",
    composition: {
      "Potasio soluble en agua (K2O)": "3,0 %",
      "Magnesio total (MgO)": "23,5 %",
      "Azufre total (S)": "19,0 %",
    },
    cultivation: [
      "Café",
      "Maíz",
      "Banano",
      "Palma de Aceite",
      "Aguacate",
      "Cannabis Medicinal",
      "Mango",
      "Pasturas",
    ],
    hierarchy: "Fertilizantes Edáficos - Magnesio",
    supplier: "K+S",
    countries: ["Venezuela"],
    image: "productsImg/optimized/kieserita-potásica.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EaP8egL5IiNDuAwVk_QYplMB6f7l3A_u0PBnUoGzmnKlIA?e=ydKlFD",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EZBVdgQNz5dEqoOfQky3ZWoBNYbG39V0dLqB0EZctEnWDQ?e=4Ghcdv",
  },
  {
    name: "Firmeza Origen",
    description:
      "Fertilizante edáfico fuente de Silicio producido con materias primas de alta cálida, sometido a tratamiento térmico que mejora el desempeño del producto",
    benefits:
      "Aporta al suelo Silicio (Si), tomado como Acido Monosilícilico Se moviliza vía xilema y se deposita alrededor de la pared celular fortaleciendo los tejidos vegetales  El silicio presente promueve la liberación del fosforo (P) fijado en el suelo promoviendo el desarrollo radicular.  Perfecto para mezclar con fertilizantes NPK, aportando una alta concentración de Silicio (Si).",
    composition: {
      "Silicio (SiO2)": "70 %",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Fertilizantes Edáficos - Silicio",
    supplier: "CRENTO",
    countries: ["Colombia"],
    image: "productsImg/optimized/firmeza-origen.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EWoOUZD8p4JLmctC7ABEC1AB2dCHySX0-0jY3tcMV58bBg?e=ziuBcQ",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EXxJ7HSCSaZHrkbjJKgWsm0BbmEcbckEKIzlkWWnt1LiwA?e=cpPzNx",
  },
  {
    name: "Firmeza Origen",
    description:
      "Fertilizante edáfico fuente de Silicio producido con materias primas de alta cálida, sometido a tratamiento térmico que mejora el desempeño del producto",
    benefits:
      "Aporta al suelo Silicio (Si), tomado como Acido Monosilícilico Se moviliza vía xilema y se deposita alrededor de la pared celular fortaleciendo los tejidos vegetales  El silicio presente promueve la liberación del fosforo (P) fijado en el suelo promoviendo el desarrollo radicular.  Perfecto para mezclar con fertilizantes NPK, aportando una alta concentración de Silicio (Si).",
    composition: {
      "Silicio (SiO2)": "70 %",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Fertilizantes Edáficos - Silicio",
    supplier: "CRENTO",
    countries: ["Ecuador"],
    image: "productsImg/optimized/firmeza-origen.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EWoOUZD8p4JLmctC7ABEC1AB2dCHySX0-0jY3tcMV58bBg?e=ziuBcQ",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EXxJ7HSCSaZHrkbjJKgWsm0BbmEcbckEKIzlkWWnt1LiwA?e=cpPzNx",
  },
  {
    name: "Firmeza Origen",
    description:
      "Fertilizante edáfico fuente de Silicio producido con materias primas de alta cálida, sometido a tratamiento térmico que mejora el desempeño del producto",
    benefits:
      "Aporta al suelo Silicio (Si), tomado como Acido Monosilícilico Se moviliza vía xilema y se deposita alrededor de la pared celular fortaleciendo los tejidos vegetales  El silicio presente promueve la liberación del fosforo (P) fijado en el suelo promoviendo el desarrollo radicular.  Perfecto para mezclar con fertilizantes NPK, aportando una alta concentración de Silicio (Si).",
    composition: {
      "Silicio (SiO2)": "70 %",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Fertilizantes Edáficos - Silicio",
    supplier: "CRENTO",
    countries: ["Venezuela"],
    image: "productsImg/optimized/firmeza-origen.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EWoOUZD8p4JLmctC7ABEC1AB2dCHySX0-0jY3tcMV58bBg?e=ziuBcQ",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EXxJ7HSCSaZHrkbjJKgWsm0BbmEcbckEKIzlkWWnt1LiwA?e=cpPzNx",
  },
  {
    name: "Azufertil 5E",
    description:
      "Fertilizante en gránulos dispersables, que funciona como fuente de azufre, calcio, magnesio, zinc y boro rápidamente disponibles para la planta",
    benefits:
      "Promueve la sostenibilidad y la eficiencia de los fertilizantes Promueve la recuperación de la microbiología del suelo  Ideal para mezcla física, facilita la aplicación al suelo de forma natural tienen una resistencia a la ruptura mayor a 2 kilogramos/gránulo,  Ideal para soportar operaciones de transporte, manejo y aplicación. Se disuelve fácilmente en suelos húmedos, para hacerse rápidamente disponible. No se recomienda utilizarlo en fertirriego",
    composition: {
      "Azufre (SO4)": "24,0 %",
      "Calcio (CaO)": "22,0 %",
      "Magnesio (MgO)": "4,0 %",
      "Zinc (ZnO)": "2,0 %",
      "Boro (B2O3)": "0,60 %",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Fertilizantes Edáficos - Complejos NPK / Menores",
    supplier: "MANTTRA",
    countries: ["Colombia"],
    image: "productsImg/optimized/azufertil-5e.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EeyCOkvg6xhPivdG_zC-Xm0Bb1YtwCPdVwEDCz3xtv-bWQ?e=wzA7kP",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EcdrDFcruiNFl5-kG73IbtsBEA6OmZUE7E8-xPODGFmA5w?e=mzumbJ",
  },
  {
    name: "Disan 22-3-18-2(S)",
    description:
      "Mezcla especial balanceada para la fertilización del café con una relación ideal N/K de 1,2:1. Tiene un alto contenido de potasio en forma de nitrato, fuente altamente eficiente en la nutrición debido a su mayor velocidad de absorción por parte de la planta. ",
    benefits:
      "El nitrógeno como nutriente vital para el desarrollo de hojas y tallos, así como para síntesis de proteínas y enzimas en las plantas, el nitrógeno estimula el crecimiento vegetativo y mejora la producción de biomasa, lo que se traduce en plantas vigorosas y con una mayor capacidad de fotosíntesis. El fósforo presente es esencial para el desarrollo de raíces fuertes y saludables, así como para promover una adecuada floración y fructificación de los cultivos. El potasio es fundamental para múltiples procesos fisiológicos, incluyendo la regulación del equilibrio hídrico, la síntesis de carbohidratos y mejora la resistencia a condiciones de estrés y enfermedades. ",
    composition: {
      "Nitrógeno total (N)": "22.0 %",
      "Nitrógeno amoniacal  (NH4)": "11,0 %",
      "Nitrógeno nítrico (NO3)": "11,0 %",
      "Fósforo asimilable (P2O5)": "3,0 %",
      "Potasio soluble en agua (K2O)": "18,0 %",
      "Magnesio total (MgO)": "0,40 %",
      "Azufre total (S)": "1,55%",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Fertilizantes Edáficos - Complejos NPK / Menores",
    supplier: "EUROCHEM",
    countries: ["Colombia"],
    image: "productsImg/optimized/disan-22-3-18-2(s).webp",
  },
  {
    name: "Macromix 12-12-17",
    description:
      "Fertilizante mezcla química a base de NPK y elementos menores, ideal para aplicar en cualquier etapa fonológica de la planta",
    benefits:
      "Garantiza que cada granulo contiene el aporte nutricional declarado Alta asimilación y baja higroscopicidad. Ideal para aplicar en cualquier etapa fenológica de la planta  El contenido de nitrógeno nítrico y amoniacal hace que Macromix 12-12-17 promueve el máximo crecimiento y desarrollo de las plantas ",
    composition: {
      "Nitrógeno total N": "12.0%",
      "Nitrógeno nítrico N-NO3": "2.0%",
      "Nitrógeno amoniacal N-NH4": "10.0%",
      "Fósforo soluble en agua P2O5": "12.0%",
      "Potasio soluble en agua K2O": "17.0%",
      "Magnesio MgO": "2.0%",
      "Azufre S": "12.0%",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Café",
      "Palma",
      "Tomate",
      "Banano",
      "Plátano",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      " Cacao",
      "Vid/Uva",
      "Caña",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      " Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Fertilizantes Edáficos - Complejos NPK / Menores",
    supplier: "FERTIBERIA",
    countries: ["Perú"],
    image: "productsImg/optimized/MACROMIX.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EYI3XWF9FHdIj15JSkzFI1AB2-9L8m_IYZ4kJt0GM-FbIg?e=T3trK0",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ETQEruzi2EVKhorl5sMepfUBDpxPKiQYY4mC4BxvR2BI7A?e=zIQGkT",
  },
  {
    name: "Macromix 12-12-17",
    description:
      "Fertilizante mezcla química a base de NPK y elementos menores, ideal para aplicar en cualquier etapa fonológica de la planta",
    benefits:
      "Garantiza que cada granulo contiene el aporte nutricional declarado Alta asimilación y baja higroscopicidad. Ideal para aplicar en cualquier etapa fenológica de la planta  El contenido de nitrógeno nítrico y amoniacal hace que Macromix 12-12-17 promueve el máximo crecimiento y desarrollo de las plantas ",
    composition: {
      "Nitrógeno total N": "12.0%",
      "Nitrógeno nítrico N-NO3": "2.0%",
      "Nitrógeno amoniacal N-NH4": "10.0%",
      "Fósforo soluble en agua P2O5": "12.0%",
      "Potasio soluble en agua K2O": "17.0%",
      "Magnesio MgO": "2.0%",
      "Azufre S": "12.0%",
    },

    cultivation: [
      "Semestrales",
      "Papa",
      "Café",
      "Palma",
      "Tomate",
      "Banano",
      "Plátano",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      " Cacao",
      "Vid/Uva",
      "Caña",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      " Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Fertilizantes Edáficos - Complejos NPK / Menores",
    supplier: "FERTIBERIA",
    countries: ["Bolivia"],
    image: "productsImg/optimized/macromix-12-12-17.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EYI3XWF9FHdIj15JSkzFI1AB2-9L8m_IYZ4kJt0GM-FbIg?e=T3trK0",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ETQEruzi2EVKhorl5sMepfUBDpxPKiQYY4mC4BxvR2BI7A?e=zIQGkT",
  },
  {
    name: "Masterfos Zinc",
    description:
      "Fertilizante compuesto mezclado NPK 9-30-10 para aplicación al suelo.",
    benefits:
      "Fertilizante con alto contenido de Fósforo, ideal para etapas de siembra de cultivos. El Fósforo también juega un papel fundamental en la transferencia de energía, esencial para la fotosíntesis y la respiración. La fuente de fósforo es CROPLEX, una fuente compleja enriquecida con zinc, azufre y boro en un solo gránulo. Garantiza la aplicación homogénea de elementos menores, indispensables en el arranque del cultivo.",
    composition: {
      "Nitrógeno total N": "9.0%",
      "Nitrógeno amoniacal N-NH4": "5.8%",
      "Nitrógeno ureico N-NH2": "3.2%",
      "Fósforo asimilable P2O5": "30.0%",
      "Potasio total K": "10.0%",
      "Magnesio total MgO": "3.0%",
      "Azufre total S": "2.3%",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros",
    ],
    hierarchy: "Fertilizantes Edáficos - Complejos NPK / Menores",
    supplier: "OCP",
    countries: ["Perú"],
    image: "productsImg/optimized/masterfos-zinc.webp",
  },
  {
    name: "MF 9-30-10-3+2(S)",
    description: "Fertilizante compuesto mezclado NPK para aplicación al suelo",
    benefits:
      "Fertilizante con alto contenido de Fósforo, ideal para etapas de siembra de cultivos El Fósforo también juega un papel fundamental en la transferencia de energía, esencial para la fotosíntesis y la respiración. La fuente de fósforo es CROPLEX , una fuente compleja enriquecida con zinc, azufre y boro en un solo granulo Garantiza la aplicación homogénea de elementos menores, indispensables en el arranque del cultivo. ",
    composition: {
      "Nitrógeno total (N)": "9,0 %",
      "Nitrógeno amoniacal (N-NH4)": "5,8 %",
      "Nitrógeno ureico (N-NH2)": "3,2 %",
      "Fósforo asimilable (P2O5)": "30,0 %",
      "Potasio soluble en agua (K2O)": "10,0 %",
      "Azufre total (S)": "2,3 %",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Fertilizantes Edáficos - Complejos NPK / Menores",
    supplier: "DISAN",
    countries: ["Colombia"],
    image: "productsImg/optimized/mf-9-30-10-32(s).webp",
  },
  {
    name: "Disan Arábigo 23-4-21-2MG-2S ",
    description:
      "Mezcla física NPK con alto contenido de nitrógeno y potasio de fuentes de alta eficiencia (UREA NUTRISPHERE y PATENTKALI). Aporta magnesio y azufre. Una fuente ideal para mejorar la calidad y producción de café. ",
    benefits:
      "El Factor de éxito en sus cultivos de Café! Fertilizante con alto aporte de nitrógeno recubierto y en menor proporción de potasio, ideal para cultivos en etapas de desarrollo previas a la producción. Fertilizante NPK mezcla física con alto contenido de nitrógeno protegido con el polímero N-Charge G proveniente del producto UREA NUTRISPHERE que disminuye sus pérdidas en el suelo. La fuente de fósforo proviene del producto MAP AVAIL con tecnología AVAIL T5 y mejora la asimilación del nutriente en el cultivo. Contiene PATENTKALI una fuente en forma de sulfato de potasio y sulfato de magnesio, para tener un adecuado balance de estos tres nutrientes (K, S, Mg) y adquirir resultados diferenciadores en la calidad y producción en la cosecha de café.",
    composition: {
      "Nitrógeno total  (N)": "23,0 %",
      "Nitrógeno amoniacal (N-NH4)": "1,0 %",
      "Nitrógeno ureico (N-NH2)": "22,0 %",
      "Fósforo asimilable (P2O5)": "4,0 %",
      "Potasio soluble en agua (K2O)": "21,0 %",
      "Magnesio total (MgO)": "2,0 %",
      "Azufre total (S)": "2,0 %",
    },
    cultivation: ["Café "],
    hierarchy: "Fertilizantes Edáficos - Mezclas Físicas NPK",
    supplier: "DISAN",
    countries: ["Perú"],
    image: "productsImg/optimized/arabigo peru.webp",
    hojaS: "",
  },
  {
    name: "Disan Arábigo 23-4-21-2MG-2S ",
    description:
      "Mezcla física NPK con alto contenido de nitrógeno y potasio de fuentes de alta eficiencia (UREA NUTRISPHERE y PATENTKALI). Aporta magnesio y azufre. Una fuente ideal para mejorar la calidad y producción de café. ",
    benefits:
      "El Factor de éxito en sus cultivos de Café! Fertilizante con alto aporte de nitrógeno recubierto y en menor proporción de potasio, ideal para cultivos en etapas de desarrollo previas a la producción. Fertilizante NPK mezcla física con alto contenido de nitrógeno protegido con el polímero N-Charge G proveniente del producto UREA NUTRISPHERE que disminuye sus pérdidas en el suelo. La fuente de fósforo proviene del producto MAP AVAIL con tecnología AVAIL T5 y mejora la asimilación del nutriente en el cultivo. Contiene PATENTKALI una fuente en forma de sulfato de potasio y sulfato de magnesio, para tener un adecuado balance de estos tres nutrientes (K, S, Mg) y adquirir resultados diferenciadores en la calidad y producción en la cosecha de café.",
    composition: {
      "Nitrógeno total  (N)": "23,0 %",
      "Nitrógeno amoniacal (N-NH4)": "1,0 %",
      "Nitrógeno ureico (N-NH2)": "22,0 %",
      "Fósforo asimilable (P2O5)": "4,0 %",
      "Potasio soluble en agua (K2O)": "21,0 %",
      "Magnesio total (MgO)": "2,0 %",
      "Azufre total (S)": "2,0 %",
    },
    cultivation: ["Café "],
    hierarchy: "Fertilizantes Edáficos - Mezclas Físicas NPK",
    supplier: "DISAN",
    countries: ["Colombia"],
    image: "productsImg/optimized/disan-arábigo-23-4-21-2mg-2s-.webp",

    hojaS: " ",
  },
  {
    name: "Disan balance 18-18-18",
    description:
      "Mezcla física NPK que contiene los tres elementos más importantes de la nutrición de cultivos en forma balanceada. ",
    benefits:
      "El nitrógeno es un elemento esencial en el crecimiento de la planta ya que es componente de aminoácidos, proteínas, ácidos nucleicos y de la molécula de clorofila. El adecuado suministro de este nutriente se relaciona con la adecuada absorción de los demás elementos nutritivos. El fósforo es un elemento fundamental para el desarrollo y crecimiento de raíces. El P también juega un papel fundamental en la transferencia de energía, esencial para la fotosíntesis y la respiración. La fuente usada es MAP un fosfato monoamónico cuya ventaja radica en la mayor disponibilidad del elemento en el suelo, debido a la forma iónica cómo se comporta en el suelo. Disan Balance 18-18-18, potasio que es importante para el desarrollo vegetal debido a su papel en la activación enzimática, la síntesis de proteínas y la regulación del estado hídrico de la planta, este es aportado en forma de cloruro de potasio.",
    composition: {
      "Nitrógeno total  (N)": "18,0 %",
      "Nitrógeno amoniacal (N-NH4)": "3,0 %",
      "Nitrógeno ureico (N-NH2)": "15,0 %",
      "Fósforo asimilable (P2O5)": "18,0 %",
      "Potasio soluble en agua  (K2O)": "18,0 %",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Fertilizantes Edáficos - Mezclas Físicas NPK",
    supplier: "DISAN",
    countries: ["Colombia"],
    image: "productsImg/optimized/Disan Balance 18 18 18.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ET9wZ1dDmFRDlocTG9SsqKgBAN2BO6J-vyMlDMt3j9jHIA?e=LenGv7",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EVu2N-9FYldIqEYQDKQS8fUB9Cc5fewAMEUtm3tSPC7Mlw?e=Pg2LG0",
  },
  {
    name: "Disan balance 18-18-18",
    description:
      "Mezcla física NPK que contiene los tres elementos más importantes de la nutrición de cultivos en forma balanceada. ",
    benefits:
      "El nitrógeno es un elemento esencial en el crecimiento de la planta ya que es componente de aminoácidos, proteínas, ácidos nucleicos y de la molécula de clorofila. El adecuado suministro de este nutriente se relaciona con la adecuada absorción de los demás elementos nutritivos. El fósforo es un elemento fundamental para el desarrollo y crecimiento de raíces. El P también juega un papel fundamental en la transferencia de energía, esencial para la fotosíntesis y la respiración. La fuente usada es MAP un fosfato monoamónico cuya ventaja radica en la mayor disponibilidad del elemento en el suelo, debido a la forma iónica cómo se comporta en el suelo. Disan Balance 18-18-18, potasio que es importante para el desarrollo vegetal debido a su papel en la activación enzimática, la síntesis de proteínas y la regulación del estado hídrico de la planta, este es aportado en forma de cloruro de potasio.",
    composition: {
      "Nitrógeno total  (N)": "18,0 %",
      "Nitrógeno amoniacal (N-NH4)": "3,0 %",
      "Nitrógeno ureico (N-NH2)": "15,0 %",
      "Fósforo asimilable (P2O5)": "18,0 %",
      "Potasio soluble en agua  (K2O)": "18,0 %",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Fertilizantes Edáficos - Mezclas Físicas NPK",
    supplier: "DISAN",
    countries: ["Venezuela"],
    image: "productsImg/optimized/disan-balance-18-18-18.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ET9wZ1dDmFRDlocTG9SsqKgBAN2BO6J-vyMlDMt3j9jHIA?e=LenGv7",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EVu2N-9FYldIqEYQDKQS8fUB9Cc5fewAMEUtm3tSPC7Mlw?e=Pg2LG0",
  },
  {
    name: "Disan desarrollo 23-4-21",
    description:
      "Mezcla física NPK con alto contenido de nitrógeno y potasio en forma de sulfato. Su fuente de nitrógeno es de alta eficiencia.",
    benefits:
      "Fertilizante con alto aporte de nitrógeno recubierto y en menor proporción de potasio, ideal para cultivos en etapas de desarrollo previas a la producción. Su aporte del 4% de fósforo, en forma de MAP es fuente eficiente y completamente soluble en agua. El aporte de potasio de DISAN DESARROLLO promueve la síntesis de carbohidratos y proteínas y proteínas. Y mejora el estatus hídrico de la planta (osmo - regulación) además de favorecer la translocación de foto asimilados, lo que acelera el crecimiento y desarrollo de los frutos. Adecuado balance de N, P, K, S, Mg que permite resultados diferenciadores en crecimiento, producción y calidad de cosecha.",
    composition: {
      "Nitrógeno total  (N)": "23.0%",
      "Nitrógeno amoniacal (N-NH4)": "0.8 %",
      "Nitrógeno ureico (N-NH2)": "22.2%",
      "Fósforo asimilable (P2O5)": "4.0%",
      "Potasio soluble en agua  (K2O)": "21.0%",
      "Magnesio total (MgO)": "2.0%",
      "Azufre total  (S)": "2.4%",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Fertilizantes Edáficos - Mezclas Físicas NPK",
    supplier: "DISAN",
    countries: ["Colombia"],
    image: "productsImg/optimized/disan-desarrollo-23-4-21.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EXpKA_RQLfVJqYPse2cJLgQBXKIcE6Lf6QBN8R65_UtmVA?e=H7b1we",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EdC44rN4se9LlH0Mn5mxmNwBESVHTR-TsTvoljIxu-NGuw?e=h12swt",
  },
  {
    name: "Disan desarrollo 23-4-21",
    description:
      "Mezcla física NPK con alto contenido de nitrógeno y potasio en forma de sulfato. Su fuente de nitrógeno es de alta eficiencia.",
    benefits:
      "Fertilizante con alto aporte de nitrógeno recubierto y en menor proporción de potasio, ideal para cultivos en etapas de desarrollo previas a la producción. Su aporte del 4% de fósforo, en forma de MAP es fuente eficiente y completamente soluble en agua. El aporte de potasio de DISAN DESARROLLO promueve la síntesis de carbohidratos y proteínas y proteínas. Y mejora el estatus hídrico de la planta (osmo - regulación) además de favorecer la translocación de foto asimilados, lo que acelera el crecimiento y desarrollo de los frutos. Adecuado balance de N, P, K, S, Mg que permite resultados diferenciadores en crecimiento, producción y calidad de cosecha.",
    composition: {
      "Nitrógeno total  (N)": "23.0%",
      "Nitrógeno amoniacal (N-NH4)": "0.8 %",
      "Nitrógeno ureico (N-NH2)": "22.2%",
      "Fósforo asimilable (P2O5)": "4.0%",
      "Potasio soluble en agua  (K2O)": "21.0%",
      "Magnesio total (MgO)": "2.0%",
      "Azufre total  (S)": "2.4%",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Fertilizantes Edáficos - Mezclas Físicas NPK",
    supplier: "DISAN",
    countries: ["Venezuela"],
    image: "productsImg/optimized/DISAN DESARROLLO V.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EXpKA_RQLfVJqYPse2cJLgQBXKIcE6Lf6QBN8R65_UtmVA?e=H7b1we",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EdC44rN4se9LlH0Mn5mxmNwBESVHTR-TsTvoljIxu-NGuw?e=h12swt",
  },
  // {
  //   name: "Cu Sar",
  //   description: "Compuestos vegetales con acción biostática y biocida.",
  //   benefits:
  //     "Inductor de defensas SAR-SA, carga bioquímica para compensar metabolismo y costo de defensa. Manejo preventivo a curativo temprano: oomycetos, virus, bacterias y fases biotróficas de varios hongos, en cualquier tipo de cultivo.",
  //   composition: {
  //     "Extracto vegetal": "",
  //     "Sulfato cobre pentahidratado": "",
  //     "Aminoácidos libres": "",
  //     "Ácidos Carboxílicos": "",
  //     Biopolímero: "",
  //     Cobre: "0.5%",
  //   },
  //   cultivation: [],
  //   hierarchy: "Control biológico - Inductor de Defensas",
  //   supplier: "FIRST",
  //   countries: ["Colombia"],
  //   image: "productsImg/optimized/cu-sar.webp",
  // },
  {
    name: "Trun-Kagna",
    description:
      "TRUN-KAGNA, Herbicida orgánico, no selectivo, es una mezcla de 3 extractos vegetales.",
    benefits:
      "Formulado a base de extracto de tres plantas, (Cheilo costus), (Stephania elegans) y (Rubus ideaus) para el control ecológicamente amigable de las malezas. TRUN-KAGNA tiene un novedoso y complejo modo de acción, el producto penetra por las hojas y se mueve en las partes aéreas y subterráneas de las plantas, afecta la fotosíntesis y detiene el movimiento de nutrientes en las plantas, causando la muerte total de las malezas entre 10-30 días después del tratamiento. Brinda control de malezas por contacto y parcialmente sistémico. Actúa sobre la fotosíntesis y el transporte de nutrientes en las plantas aplicadas. Mínimo impacto al ambiente. Amplio espectro. Al no ser selectivo es necesario aplicar buscando que no entre en contacto con el cultivo.",
    composition: {
      "Extracto de Cheilo costus": "40 g/L",
      "Extracto de Stephania elegans": "50 g/L",
      "Extracto de Rubus ideaus": "80 g/L",
    },
    cultivation: ["Palma de aceite", "Café", "Limón", "Aguacate", "Cítricos"],
    hierarchy: "Control biológico - Herbicida",
    supplier: "FIRST",
    countries: ["Colombia"],
    image: "productsImg/optimized/trun-kagna.webp",
  },
  {
    name: "Patentkali",
    description:
      "Fertilizante complejo altamente concentrado de potasio, azufre y magnesio en forma sulfatada. Apto para la agricultura orgánica y convencional.",
    benefits:
      "Balance ideal de potasio, azufre y magnesio para lograr en la planta el mejor estado fitosanitario y calidad de cosecha. Contiene todos los nutrientes en una forma sulfatada de origen natural (kieserita) rápidamente soluble en agua y completamente disponible para las plantas. Extraído de depósitos naturales de sal cruda en Alemania. Aprobado para su uso en agricultura ecológica. El potasio mejora la tolerancia al estrés por sequía, el magnesio incrementa la fotosíntesis, y participa en procesos de defensa de la planta, y el azufre es esencial para la síntesis de proteínas. Prácticamente libre de cloruros (máx. 3% Cl) y actúa independientemente del valor de pH del suelo y no lo modifica.",
    composition: {
      "Potasio soluble en agua K2O": "30%",
      "Magnesio soluble en agua MgO": "10%",
      "Azufre soluble en agua S": "17%",
    },

    cultivation: [
      "Semestrales",
      "Papa",
      "Café",
      "Palma",
      "Tomate",
      "Banano",
      "Plátano",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Cacao",
      "Vid/Uva",
      "Caña",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros",
    ],
    hierarchy: "Fertilizantes Edáficos - Potasio - organicos",
    supplier: "K+S",
    countries: ["Bolivia"],
    image: "productsImg/optimized/patentkali-bo.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ESyEGx95L-pDup5i3-VR6swBgTVtQ9bC5pvHSLrVDasODg?e=3D2rmQ",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ESWfA396h6pKrz15PLf41MUBWpeFnOXNKqeKeClsW58sLg?e=lt5KPH",
  },
  {
    name: "Patentkali",
    description:
      "Fertilizante complejo altamente concentrado de potasio, azufre y magnesio en forma sulfatada. Apto para la agricultura orgánica y convencional.",
    benefits:
      "Balance ideal de potasio, azufre y magnesio para lograr en la planta el mejor estado fitosanitario y calidad de cosecha. Contiene todos los nutrientes en una forma sulfatada de origen natural (kieserita) rápidamente soluble en agua y completamente disponible para las plantas. Extraído de depósitos naturales de sal cruda en Alemania. Aprobado para su uso en agricultura ecológica. El potasio mejora la tolerancia al estrés por sequía, el magnesio incrementa la fotosíntesis, y participa en procesos de defensa de la planta, y el azufre es esencial para la síntesis de proteínas. Prácticamente libre de cloruros (máx. 3% Cl) y actúa independientemente del valor de pH del suelo y no lo modifica.",
    composition: {
      "Potasio soluble en agua K2O": "30%",
      "Magnesio soluble en agua MgO": "10%",
      "Azufre soluble en agua S": "17%",
    },

    cultivation: [
      "Semestrales",
      "Papa",
      "Café",
      "Palma",
      "Tomate",
      "Banano",
      "Plátano",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Cacao",
      "Vid/Uva",
      "Caña",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros",
    ],
    hierarchy: "Fertilizantes Edáficos - Potasio - organicos",
    supplier: "K+S",
    countries: ["Venezuela"],
    image: "productsImg/optimized/PATENTKALI VENEZUELA.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ESyEGx95L-pDup5i3-VR6swBgTVtQ9bC5pvHSLrVDasODg?e=3D2rmQ",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ESWfA396h6pKrz15PLf41MUBWpeFnOXNKqeKeClsW58sLg?e=lt5KPH",
  },
  {
    name: "Patentkali",
    description:
      "Fertilizante complejo altamente concentrado de potasio, azufre y magnesio en forma sulfatada. Apto para la agricultura orgánica y convencional.",
    benefits:
      "Balance ideal de potasio, azufre y magnesio para lograr en la planta el mejor estado fitosanitario y calidad de cosecha. Contiene todos los nutrientes en una forma sulfatada de origen natural (kieserita) rápidamente soluble en agua y completamente disponible para las plantas. Extraído de depósitos naturales de sal cruda en Alemania. Aprobado para su uso en agricultura ecológica. El potasio mejora la tolerancia al estrés por sequía, el magnesio incrementa la fotosíntesis, y participa en procesos de defensa de la planta, y el azufre es esencial para la síntesis de proteínas. Prácticamente libre de cloruros (máx. 3% Cl) y actúa independientemente del valor de pH del suelo y no lo modifica.",
    composition: {
      "Potasio soluble en agua K2O": "30%",
      "Magnesio soluble en agua MgO": "10%",
      "Azufre soluble en agua S": "17%",
    },

    cultivation: [
      "Semestrales",
      "Papa",
      "Café",
      "Palma",
      "Tomate",
      "Banano",
      "Plátano",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Cacao",
      "Vid/Uva",
      "Caña",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros",
    ],
    hierarchy: "Fertilizantes Edáficos - Potasio - organicos",
    supplier: "K+S",
    countries: ["Perú"],
    image: "productsImg/optimized/Patentkali PERÚ.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ESyEGx95L-pDup5i3-VR6swBgTVtQ9bC5pvHSLrVDasODg?e=3D2rmQ",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ESWfA396h6pKrz15PLf41MUBWpeFnOXNKqeKeClsW58sLg?e=lt5KPH",
  },
  {
    name: "Patentkali",
    description:
      "Fertilizante complejo altamente concentrado de potasio, azufre y magnesio en forma sulfatada. Apto para la agricultura orgánica y convencional.",
    benefits:
      "Balance ideal de potasio, azufre y magnesio para lograr en la planta el mejor estado fitosanitario y calidad de cosecha. Contiene todos los nutrientes en una forma sulfatada de origen natural (kieserita) rápidamente soluble en agua y completamente disponible para las plantas. Extraído de depósitos naturales de sal cruda en Alemania. Aprobado para su uso en agricultura ecológica. El potasio mejora la tolerancia al estrés por sequía, el magnesio incrementa la fotosíntesis, y participa en procesos de defensa de la planta, y el azufre es esencial para la síntesis de proteínas. Prácticamente libre de cloruros (máx. 3% Cl) y actúa independientemente del valor de pH del suelo y no lo modifica.",
    composition: {
      "Potasio soluble en agua K2O": "30%",
      "Magnesio soluble en agua MgO": "10%",
      "Azufre soluble en agua S": "17%",
    },

    cultivation: [
      "Semestrales",
      "Papa",
      "Café",
      "Palma",
      "Tomate",
      "Banano",
      "Plátano",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Cacao",
      "Vid/Uva",
      "Caña",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros",
    ],
    hierarchy: "Fertilizantes Edáficos - Potasio - organicos",
    supplier: "K+S",
    countries: ["Colombia"],
    image: "productsImg/optimized/patentkali.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ESyEGx95L-pDup5i3-VR6swBgTVtQ9bC5pvHSLrVDasODg?e=3D2rmQ",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ESWfA396h6pKrz15PLf41MUBWpeFnOXNKqeKeClsW58sLg?e=lt5KPH",
  },
  {
    name: "Disan Factor 23-4-21-2MG-2S",
    description:
      "Mezcla física NPK con alto contenido de nitrógeno y potasio de fuentes de alta eficiencia (UREA NUTRISPHERE y PATENTKALI). Aporta magnesio y azufre. Una fuente ideal para mejorar la calidad y producción de café. ",
    benefits:
      "El Factor de éxito en sus cultivos de Café! Fertilizante con alto aporte de nitrógeno recubierto y en menor proporción de potasio, ideal para cultivos en etapas de desarrollo previas a la producción. Fertilizante NPK mezcla física con alto contenido de nitrógeno protegido con el polímero N-Charge G proveniente del producto UREA NUTRISPHERE que disminuye sus pérdidas en el suelo. La fuente de fósforo proviene del producto MAP AVAIL con tecnología AVAIL T5 y mejora la asimilación del nutriente en el cultivo. Contiene PATENTKALI una fuente en forma de sulfato de potasio y sulfato de magnesio, para tener un adecuado balance de estos tres nutrientes (K, S, Mg) y adquirir resultados diferenciadores en la calidad y producción en la cosecha de café.",
    composition: {
      "Nitrógeno total  (N)": "23,0 %",
      "Nitrógeno amoniacal (N-NH4)": "1,0 %",
      "Nitrógeno ureico (N-NH2)": "22,0 %",
      "Fósforo asimilable (P2O5)": "4,5 %",
      "Potasio soluble en agua  (K2O)": "21,0 %",
      "Magnesio total (MgO)": "1,6 %",
      "Azufre total (S)": "2,0 %",
    },
    cultivation: ["Café"],
    hierarchy: "Fertilizantes Edáficos - Mezclas Físicas NPK",
    supplier: "DISAN",
    countries: ["Colombia"],
    image: "productsImg/optimized/disan-factor-23-4-21-2mg-2s.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EdJs5Iu6DmtAguW7FWvMCUEBG47hHGIasJpSk3_8m7CfWw?e=cOShfU",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/Ef_YXNCq8qhFkKubSr5DVtgBT5S-0T5VLPGsHgJtipBuiA?e=B5s84f",
  },
  {
    name: "Disan inicio 12-24-15-4(S)",
    description:
      "Fertilizante NPK mezcla física con alto contenido de fósforo a base de MAP recubierto que reduce la fijación del fósforo en el suelo.  Ideal etapas iniciales del cultivo",
    benefits:
      "Mezcla química NPK balanceada ideal para etapas iniciales de desarrollo del cultivo. Fuente de Nitrógeno amoniacal y fosforo proveniente del MAP recubierto, que hace de Disan Inicio una fuente de alta eficiencia. Ideal para el desarrollo de raíces y procesos de alta demanda energética. Mezcla química que permite un suministro uniforme de nitrógeno, fósforo, potasio y azufre.",
    composition: {
      "Nitrógeno amoniacal (NH4)": "12.0%",
      "Fósforo asimilable (P2O5)": "24.0%",
      "Potasio soluble en agua (K2O)": "12.0%",
      "Azufre total (S)": "4.0%",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Café",
      "Palma",
      "Tomate",
      "Banano",
      "Plátano",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      " Cacao",
      "Vid/Uva",
      "Caña",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      " Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Fertilizantes Edáficos - Mezclas Físicas NPK",
    supplier: "DISAN",
    countries: ["Perú"],
    image: "productsImg/optimized/disan-inicio-12-24-15-4(s).webp",
  },
  {
    name: "Disan inicio 12-27-13-2+1(S)",
    description:
      "Fertilizante NPK mezcla física con alto contenido de fósforo recubierto. Ideal para aplicación a la siembra, etapas iniciales del cultivo y/o desarrollo de raíces.",
    benefits:
      "Mezcla física NPK balanceada ideal para etapas iniciales de desarrollo de la planta, en cultivos que requieren un adecuado balance entre nitrógeno y potasio, con alta carga de fosforo, el cual esta recubierto.  Ideal para el desarrollo de raíces y procesos de alta demanda energética. Aporte de elementos secundarios como el azufre y magnesio necesarios para la fotosíntesis, síntesis de proteínas y procesos de defensa.",
    composition: {
      "Nitrógeno total (N)": "12,0 %",
      "Nitrógeno amoniacal (N-NH4)": "5,3 %",
      "Nitrógeno ureico (N-NH2)": "6,7 %",
      "Fósforo asimilable  (P2O5)": "27,0 %",
      "Potasio soluble en agua  (K2O)": "13,0 %",
      "Magnesio total  (MgO)": "2,0 %",
      "Azufre total (S)": "1,3 %",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Fertilizantes Edáficos - Mezclas Físicas NPK",
    supplier: "DISAN",
    countries: ["Colombia"],
    image: "productsImg/optimized/disan-inicio-12-27-13-21(s).webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EdiLiD86KN1MkGxoH3SeEwsB8-4YIVBuzG_Y2FTJAU5f-w?e=uifbZT",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EeFUv59IoTtNgYAB-9CHdbYBFDXIaoLygh8NMo9vA9ESRg?e=FPWXeg",
  },
  {
    name: "Disan inicio 12-27-13-2+1(S)",
    description:
      "Fertilizante NPK mezcla física con alto contenido de fósforo recubierto. Ideal para aplicación a la siembra, etapas iniciales del cultivo y/o desarrollo de raíces.",
    benefits:
      "Mezcla física NPK balanceada ideal para etapas iniciales de desarrollo de la planta, en cultivos que requieren un adecuado balance entre nitrógeno y potasio, con alta carga de fosforo, el cual esta recubierto.  Ideal para el desarrollo de raíces y procesos de alta demanda energética. Aporte de elementos secundarios como el azufre y magnesio necesarios para la fotosíntesis, síntesis de proteínas y procesos de defensa.",
    composition: {
      "Nitrógeno total (N)": "12,0 %",
      "Nitrógeno amoniacal (N-NH4)": "5,3 %",
      "Nitrógeno ureico (N-NH2)": "6,7 %",
      "Fósforo asimilable  (P2O5)": "27,0 %",
      "Potasio soluble en agua  (K2O)": "13,0 %",
      "Magnesio total  (MgO)": "2,0 %",
      "Azufre total (S)": "1,3 %",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Fertilizantes Edáficos - Mezclas Físicas NPK",
    supplier: "DISAN",
    countries: ["Bolivia"],
    image: "productsImg/optimized/DISAN INICIO 12 27 13.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EdiLiD86KN1MkGxoH3SeEwsB8-4YIVBuzG_Y2FTJAU5f-w?e=uifbZT",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EeFUv59IoTtNgYAB-9CHdbYBFDXIaoLygh8NMo9vA9ESRg?e=FPWXeg",
  },
  {
    name: "Disan llenado 13-5-30-2(MgO)",
    description:
      "Fertilizante NPK mezcla física con alto contenido de potasio proveniente del PATENTKALI, ideal para cultivos en etapas de llenado de frutos. Mejora calidad y cantidad de cosecha.",
    benefits:
      "Su fuente de potasio, PATENTKALI al ser un sulfato doble de potasio y magnesio permite una mejor calidad y cantidad de las cosechas. Su aporte balanceado de potasio, nitrógeno eficiente y azufre garantiza un suministro de nutrientes que mejora la producción, transporte y almacenamiento de foto asimilados y proteínas. Acelera el crecimiento y desarrollo de los frutos, tubérculos, bulbos y otros órganos cosechables.",
    composition: {
      "Nitrógeno total  (N)": "13.0%",
      "Nitrógeno amoniacal (N-NH4)": "1.5%",
      "Nitrógeno ureico (N-NH2)": "11.5%",
      "Fósforo asimilable (P2O5)": "5.0%",
      "Potasio soluble en agua  (K2O)": "30.0%",
      "Magnesio total (MgO)": "2.0%",
      "Azufre total (S)": "3.0%",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Café",
      "Palma",
      "Tomate",
      "Banano",
      "Plátano",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      " Cacao",
      "Vid/Uva",
      "Caña",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      " Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Fertilizantes Edáficos - Mezclas Físicas NPK",
    supplier: "DISAN",
    countries: ["Colombia"],
    image: "productsImg/optimized/disan-llenado-13-5-30-2(mgo).webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EQe9IAFUE_9NkBcb_XoQdHEBbQwLpXr0nSTnp0nj6L3t6g?e=j6t9ln",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ETG3NzbeeB5OgWRY06GfVfUB-ORxljO96oc8ar74Vd8aWg?e=JyDwaQ",
  },
  {
    name: "Disan llenado 13-5-30-2(MgO)",
    description:
      "Fertilizante NPK mezcla física con alto contenido de potasio proveniente del PATENTKALI, ideal para cultivos en etapas de llenado de frutos. Mejora calidad y cantidad de cosecha.",
    benefits:
      "Su fuente de potasio, PATENTKALI al ser un sulfato doble de potasio y magnesio permite una mejor calidad y cantidad de las cosechas. Su aporte balanceado de potasio, nitrógeno eficiente y azufre garantiza un suministro de nutrientes que mejora la producción, transporte y almacenamiento de foto asimilados y proteínas. Acelera el crecimiento y desarrollo de los frutos, tubérculos, bulbos y otros órganos cosechables.",
    composition: {
      "Nitrógeno total  (N)": "13.0%",
      "Nitrógeno amoniacal (N-NH4)": "1.5%",
      "Nitrógeno ureico (N-NH2)": "11.5%",
      "Fósforo asimilable (P2O5)": "5.0%",
      "Potasio soluble en agua  (K2O)": "30.0%",
      "Magnesio total (MgO)": "2.0%",
      "Azufre total (S)": "3.0%",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Café",
      "Palma",
      "Tomate",
      "Banano",
      "Plátano",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      " Cacao",
      "Vid/Uva",
      "Caña",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      " Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Fertilizantes Edáficos - Mezclas Físicas NPK",
    supplier: "DISAN",
    countries: ["Venezuela"],
    image: "productsImg/optimized/Disan llenado-13-5-30-2(MgO)-ven.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EQe9IAFUE_9NkBcb_XoQdHEBbQwLpXr0nSTnp0nj6L3t6g?e=j6t9ln",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ETG3NzbeeB5OgWRY06GfVfUB-ORxljO96oc8ar74Vd8aWg?e=JyDwaQ",
  },
  {
    name: "Disan llenado 13-5-30-2(MgO)",
    description:
      "Fertilizante NPK mezcla física con alto contenido de potasio proveniente del PATENTKALI, ideal para cultivos en etapas de llenado de frutos. Mejora calidad y cantidad de cosecha.",
    benefits:
      "Su fuente de potasio, PATENTKALI al ser un sulfato doble de potasio y magnesio permite una mejor calidad y cantidad de las cosechas. Su aporte balanceado de potasio, nitrógeno eficiente y azufre garantiza un suministro de nutrientes que mejora la producción, transporte y almacenamiento de foto asimilados y proteínas. Acelera el crecimiento y desarrollo de los frutos, tubérculos, bulbos y otros órganos cosechables.",
    composition: {
      "Nitrógeno total  (N)": "13.0%",
      "Nitrógeno amoniacal (N-NH4)": "1.5%",
      "Nitrógeno ureico (N-NH2)": "11.5%",
      "Fósforo asimilable (P2O5)": "5.0%",
      "Potasio soluble en agua  (K2O)": "30.0%",
      "Magnesio total (MgO)": "2.0%",
      "Azufre total (S)": "3.0%",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Café",
      "Palma",
      "Tomate",
      "Banano",
      "Plátano",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      " Cacao",
      "Vid/Uva",
      "Caña",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      " Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Fertilizantes Edáficos - Mezclas Físicas NPK",
    supplier: "DISAN",
    countries: ["Perú"],
    image: "productsImg/optimized/DISAN MF LLENADO.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EQe9IAFUE_9NkBcb_XoQdHEBbQwLpXr0nSTnp0nj6L3t6g?e=j6t9ln",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ETG3NzbeeB5OgWRY06GfVfUB-ORxljO96oc8ar74Vd8aWg?e=JyDwaQ",
  },
  {
    name: "Disan NPK Carga 20-4-20-1(MgO)+1(S)",
    description:
      "Mezcla especial balanceada para la fertilización de café, con una relación ideal N/K de 1,2: 1. ",
    benefits:
      "Mezcla especial balanceada para la fertilización de café, con una relación ideal N/K de 1: 1. Tiene un alto contenido de potasio en forma de nitrato (de los 20 puntos, 16 provienen del Nitrato de Potasio). Fuente altamente eficiente en la nutrición debido a su mayor velocidad de absorción por parte de la planta. Formulado con mezcla de diferentes fuentes de potasio a partir de complejos químicos solubles Aporte elementos secundarios como calcio, magnesio y azufre para una nutrición balanceada en su cultivo de café.",
    composition: {
      "Nitrógeno total (N)": "19.65%",
      "Nitrógeno amoniacal (N-NH4)": "10.50%",
      "Nitrógeno nítrico (N-NO3)": "9.15%",
      "Fósforo asimilable (P2O5)": "4.0%",
      "Potasio soluble en agua  (K2O)": "19.63%",
      "Calcio total (CaO)": "1.30%",
      "Magnesio total (MgO)": "0.8%",
      "Azufre total (S)": "1.5%",
    },
    cultivation: ["Café "],
    hierarchy: "Fertilizantes Edáficos - Mezclas Físicas NPK",
    supplier: "EUROCHEM",
    countries: ["Colombia"],
    image: "productsImg/optimized/disan-npk-carga-20-4-20.webp",
  },
  {
    name: "Disan Producción 20-0-29",
    description:
      "Fertilizante NPK mezcla física con alto contenido de potasio; usando como fuente PATENTKALI ideal para cultivos en etapas de producción. Mejora calidad y cantidad de cosecha.",
    benefits:
      "Una de sus fuentes es PATENTKALI, un sulfato doble de potasio y magnesio que permite una mejor calidad y cantidad de las cosechas. Su aporte balanceado de potasio, nitrógeno, y azufre garantiza un suministro de nutrientes que mejora la producción, transporte y almacenamiento de foto asimilados y proteínas. Su fuente de nitrógeno recubierto hace más eficiente del uso de este elemento en la planta.  Acelera el crecimiento y desarrollo de los frutos, tubérculos, bulbos y otros órganos cosechables.",
    composition: {
      "Nitrógeno total (N)": "20.0%",
      "Nitrógeno ureico (N-NH2)": "20.0%",
      "Potasio soluble en agua  (K2O)": "29.0%",
      "Magnesio total (MgO)": "1.6%",
      "Azufre total (S)": "2.8%",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Fertilizantes Edáficos - Mezclas Físicas NPK",
    supplier: "DISAN",
    countries: ["Colombia"],
    image: "productsImg/optimized/disan-producción-20-0-29.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EZBrJnGkXxNFvITgqbaUljUBZQ5fqZVu5LfYDk2SoeZnhQ?e=t3knHE",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EX-jFJcGOG9Ih5n8Q2Xlp50BudtcQk9lSQy_nVszIVf-Zg?e=5EeHdE",
  },
  {
    name: "Disan siembra 7-35-12-2(MgO)+2(S)",
    description:
      "Fertilizante NPK mezcla física con alto contenido de fósforo a base de MAP recubierto con el polímero AVAIL T5 que reduce la fijación del fósforo en el suelo.  Ideal para aplicaciones a la siembra.",
    benefits:
      "Mezcla física NPK balanceada ideal para etapas iniciales de desarrollo de la planta. Evita la fijación de fósforo e incrementa su disponibilidad para la planta. Ideal para el desarrollo de raíces y procesos de alta demanda energética. Alta eficiencia en la fertilización con fósforo gracias a su recubrimiento con el polímero AVAIL T5. Aporte de elementos secundarios como el azufre y magnesio necesarios para la fotosíntesis, síntesis de proteínas y procesos de defensa.",
    composition: {
      "Nitrógeno total  (N)": "7.0%",
      "Nitrógeno amoniacal (N-NH4)": "7.0%",
      "Fósforo asimilable  (P2O5)": "35.0%",
      "Potasio soluble en agua  (K2O)": "12.0%",
      "Magnesio total (MgO)": "2.0%",
      "Azufre total (S)": "2.0%",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Fertilizantes Edáficos - Mezclas Físicas NPK",
    supplier: "DISAN",
    countries: ["Colombia"],
    image: "productsImg/optimized/disan-siembra-7-35-12.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EczhJroYNmJCvhMIyKr_co0BaAzT_7ldqvLzRRSajKO8WA?e=Ma472R",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EV8cXWwJ34tDhsJPWew7xnoBuHjvXy1DCx8kk2N0vlcaLQ?e=BogT8H",
  },
  {
    name: "Disan siembra 7-35-12",
    description:
      "Fertilizante NPK mezcla física con alto contenido de fósforo a base de MAP recubierto con el polímero AVAIL T5 que reduce la fijación del fósforo en el suelo.  Ideal para aplicaciones a la siembra.",
    benefits:
      "Mezcla física NPK balanceada ideal para etapas iniciales de desarrollo de la planta. Evita la fijación de fósforo e incrementa su disponibilidad para la planta. Ideal para el desarrollo de raíces y procesos de alta demanda energética. Alta eficiencia en la fertilización con fósforo gracias a su recubrimiento con el polímero AVAIL T5. Aporte de elementos secundarios como el azufre y magnesio necesarios para la fotosíntesis, síntesis de proteínas y procesos de defensa.",
    composition: {
      "Nitrógeno total  (N)": "7.0%",
      "Nitrógeno amoniacal (N-NH4)": "7.0%",
      "Fósforo asimilable  (P2O5)": "35.0%",
      "Potasio soluble en agua  (K2O)": "12.0%",
      "Magnesio total (MgO)": "2.0%",
      "Azufre total (S)": "2.0%",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Fertilizantes Edáficos - Mezclas Físicas NPK",
    supplier: "DISAN",
    countries: ["Venezuela"],
    image: "productsImg/optimized/DISAN-SIEMBRA-V.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EczhJroYNmJCvhMIyKr_co0BaAzT_7ldqvLzRRSajKO8WA?e=Ma472R",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EV8cXWwJ34tDhsJPWew7xnoBuHjvXy1DCx8kk2N0vlcaLQ?e=BogT8H",
  },
  {
    name: "Vigortem S",
    description:
      "Producto especialmente diseñado para favorecer el enraizamiento y asegurar un adecuado establecimiento del cultivo.",
    benefits:
      "Proporciona el ambiente y los elementos necesarios para la raíz. Incrementa el vigor y resistencia del cultivo. Combina un alto contenido en fósforo, ácidos húmicos que mejoran las características físico-químicas del suelo. Aumenta la disponibilidad de nutrientes, algas y aminoácidos que estimulan los procesos fisiológicos que tienen lugar en las raíces. Permite el refuerzo de la zona radicular necesario para la recuperación y reactivación de los cultivos.",
    composition: {
      "Nitrógeno total N": "6.0%",
      "Fósforo soluble en agua P2O5": "17.0%",
      "Potasio soluble en agua K2O": "5.0%",
      "Extracto húmico total": "43.0%",
      Aminoácidos: "5.0%",
      Manitol: "0.9%",
      "Materia orgánica": "62.0%",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Flores de corte",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Viveros",
    ],
    hierarchy: "Tecnologías Solubles - Acondicionadores de suelo",
    supplier: "MERISTEM",
    countries: ["Ecuador"],
    image: "productsImg/optimized/vigortem-s.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EU3BgmzIyPVHt3RjDNn7Hh8BW2dORb5obkeHTYdKhFRB5A?e=TrnvEj",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EenVSukGxZVMveRko4lbI_oBeBpezJrbz09mkZpJ7nCLQg?e=98U38h",
  },
  {
    name: "Fertileaf Humic",
    description: "FERTILEAF HUMIC es un líquido soluble con ácidos húmicos ",
    benefits:
      "Mejora la estructura fisicoquímica del suelo Estimula la microflora del suelo.  Aumenta la asimilación de los nutrientes. El producto puede mezclarse con todas las formulaciones, con excepción de soluciones muy ácidas, productos a base de Cobre, aceites minerales y emulsiones ",
    composition: {
      "Nitrógeno Orgánico (N)": "0,4 %",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Viveros.",
    ],
    hierarchy: "Tecnologías Solubles - Acondicionadores del suelo ",
    supplier: "DISAN",
    countries: ["Perú"],
    image: "productsImg/optimized/fertileaf-humic.webp",
  },
  {
    name: "Fertileaf Humic",
    description: "FERTILEAF HUMIC es un líquido soluble con ácidos húmicos ",
    benefits:
      "Mejora la estructura fisicoquímica del suelo Estimula la microflora del suelo.  Aumenta la asimilación de los nutrientes. El producto puede mezclarse con todas las formulaciones, con excepción de soluciones muy ácidas, productos a base de Cobre, aceites minerales y emulsiones ",
    composition: {
      "Nitrógeno Orgánico (N)": "0,4 %",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Viveros.",
    ],
    hierarchy: "Tecnologías Solubles - Acondicionadores del suelo ",
    supplier: "DISAN",
    countries: ["Bolivia"],
    image: "productsImg/optimized/fertileaf-humic.webp",
  },
  {
    name: "Evolution",
    description:
      "Es un fertilizante líquido con tecnología del carbono de alta concentración derivada de la leonardita y potasio.",
    benefits:
      "Mejora las características físicas químicas y biológicas de los suelos por el agregado de carbono orgánico al suelo Estimula el desenvolvimiento temprano de la raíz, el crecimiento de la planta y desarrollo del fruto.  Es una fuente de potasio altamente disponible asociada con materia orgánica que tienen la finalidad de cubrir los requerimientos de potasio. Es un fertilizante ideal para los productores interesados en mayor absorción de nutrientes y mejora la actividad microbiana del suelo. Todas aplicaciones foliares deben estar diluido Se puede aplicar directamente a los suelos o inyectar a través de sistemas de riego ",
    composition: {
      "Potasio soluble en agua (K2O)": "4,0 %",
      "Materia orgánica (MO)": "13,9 %",
    },
    cultivation: [
      "Flores de corte",
      "Fresa",
      "Papa",
      "Arándanos",
      "Banano",
      "Plátano",
      "Mango",
      "Cítricos",
      "Aguacate",
      "Uva/Vid",
      "Tomate",
      "Piña",
      "Cacao",
      "Frutales",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego o uso de Drench.",
    ],
    hierarchy: "Tecnologías Solubles - Tecnologías eficientes",
    supplier: "ACTAGRO",
    countries: ["Perú"],
    image: "productsImg/optimized/evolution.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EQyQl2C0GHpBqq7r5uiS2LIBcn9u_0CrVtWqk5UmAoW7zQ?e=gM37Bx",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EbrDpO7uTWpKozDtp3pz5FMBY9hdYGleHjXZZwxPRfWtwQ?e=G5k1PC",
  },
  {
    name: "Katalyst",
    description:
      "Es un fertilizante que contiene en su composición potasio altamente disponible para la planta, protegido con tecnología del carbono avanzada.",
    benefits:
      "Especialmente diseñado para para ser absorbido rápidamente por la raíz en las aplicaciones al suelo Es una fuente de potasio altamente disponible asociada con materia orgánica  que tienen la finalidad de cubrir los requerimientos de potasio. Es un Aporte de potasio, nutriente responsable del proceso de translocación de carbohidratos de la planta y de mejorar la eficiencia del uso del agua para un cultivo significativamente más saludable, equilibrado y productivo. Katalyst FS ® es una forma de potasio líquido que facilita la incorporación en  cualquier programa nutricional Katalyst FS®. se puede aplicar a la mayoría de los cultivos de vegetales y  cucurbitáceas, cultivos en campo y en hilera, frutos caducifolios y árboles de  nueces, uvas, bayas, aceitunas, aguacates y cítricos. Todas aplicaciones foliares deben estar diluido. También se puede aplicar  directamente a los suelos o inyectar a través de sistemas de riego donde la  calidad del agua es satisfactoria para mantener la solubilidad Aplicaciones repetidas pueden ser de beneficio",
    composition: {
      "Potasio soluble en agua (K2O)": "30,0 %",
      "Materia orgánica (MO)": "27,0 %",
    },
    cultivation: [
      "Flores de corte",
      "Fresa",
      "Papa",
      "Arándanos",
      "Banano",
      "Plátano",
      "Mango",
      "Cítricos",
      "Palto/Aguacate",
      "Uva/Vid",
      "Tomate",
      "Piña",
      "Cacao",
      "Frutales",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego o uso de Drench.",
    ],
    hierarchy: "Tecnologías Solubles - Tecnologías eficientes",
    supplier: "ACTAGRO",
    countries: ["Perú"],
    image: "productsImg/optimized/katalyst.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/Ea88CRnYMfZLoQ9Q7xWSr5MBbI4R5hj7On6Kk7BHF0QoPw?e=432dcZ",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EXWTSyuvskNCqR1SuwHVnucBpk9ZdM4lMkR8qZ97ZKZfKQ?e=MWCyyy",
  },
  {
    name: "Lyra",
    description:
      "LYRA™ es una solución de manejo de nitrógeno para fertiirrigación. Combina tecnologías de Verdesian que mejoran la disponibilidad de nutrientes como el Nitrógeno.",
    benefits:
      "LYRA™, que incluye las tecnologías de copolímero, Take-Off y Plus de Verdesian, que han producido repetidamente resultados confiables en muchos productos de Verdesian, está formulado para aplicaciones de fertiirrigación en cultivos especiales y en hileras. Aplicado junto con nitrógeno fertirrigado, LYRA ha producido importantes beneficios en el rendimiento en ensayos de productos en muchos cultivos y geografías.  BENEFICIOS DEL PRODUCTO Mejora la disponibilidad y absorción de nutrientes. Optimiza el cuajado y la calidad de la fruta. Mejora la tolerancia al estrés abiótico. Mejora la disponibilidad de nutrientes al mitigar la lixiviación de nitratos.",
    composition: {
      "Potasio soluble en agua (K2O)": "7 %",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Café",
      "Palma",
      "Tomate",
      "Banano",
      "Plátano",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Cacao",
      "Vid/Uva",
      "Caña",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Tecnologías Solubles - Tecnologías eficientes",
    supplier: "VERDESIAN",
    countries: ["Perú"],
    image: "productsImg/optimized/lyra.webp",
  },
  {
    name: "Phytosoap",
    description:
      "Sal potásica de ácidos que ayuda a proteger a la planta frente a la acción de insectos de cutícula blanda",
    benefits:
      "Sal potásica de ácidos grasos vegetales, especialmente indicado para ayudar a proteger a la planta frente a la acción de insectos de cutícula blanda, como áfidos, mosca blanca, cochinillas, que provoca la disolución de la quitina del esqueleto de estos y su muerte por deshidratación.",
    composition: {
      "Potasio soluble en agua (K2O)": "60.0 g/L",
      "pH en solución al 10%": "11.2",
    },
    cultivation: [],
    hierarchy: "Control biológico - Insecticida",
    supplier: "MANVERT",
    countries: ["Colombia"],
    image: "productsImg/optimized/phytosoap.webp",
  },
  {
    name: "Disan Equilibrio pH",
    description:
      "Acondicionador de suelo que mejora las propiedades químicas del suelo, con una relación óptima entre Ca y Mg y una rápida reacción que sube el pH del suelo.",
    benefits:
      "Corrector de pH, con dosis variables en función de las condiciones de suelo.Producto para aplicación edáfica manual o mecanizada.",
    composition: {
      "Calcio total (CaO)": "26.0%",
      "Magnesio total (MgO)": "7.0%",
      "Azufre total (S)": "3.0%",
      "Silicio total (SiO2)": "8.0%",
      "pH en solución al 10%": "12.4",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros",
    ],
    hierarchy: "Fertilizantes Edáficos - Acondicionadores del suelo",
    supplier: "CRENTO",
    countries: ["Colombia"],
    image: "productsImg/optimized/disan-equilibrio-ph.webp",
  },
  {
    name: "Structure",
    description: "Fertilizante líquido que combina Nitrógeno, Fósforo y Zinc",
    benefits:
      " Diseñado para estimular el desenvolvimiento temprano de la raíz, el crecimiento de la planta y desarrollo del fruto. Incrementa la eficiencia del uso del Fósforo  Structure® es un nutriente foliar eficaz cuando los niveles de fosfato caen por debajo de los valores óptimos.",
    composition: {
      "Nitrógeno total N": "7.0%",
      "Nitrógeno Amoniacal N-NH4": "6.0%",
      "Nitrógeno Nítrico N-NO3": "0.3%",
      "Nitrógeno Ureico N-NH2": "0.6%",
      "Fósforo total soluble en agua P2O5": "21.0%",
      "Zinc soluble en agua Zn": "0.22%",
      "Materia orgánica MO": "23.0%",
      pH: "6.3",
    },

    cultivation: [
      "Flores de corte",
      "Fresa",
      "Papa",
      "Arándanos",
      "Banano",
      "Plátano",
      "Mango",
      "Cítricos",
      "Palto/Aguacate",
      "Uva/Vid",
      "Tomate",
      "Piña",
      "Cacao",
      "Frutales",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego o uso de Drench.",
    ],
    hierarchy: "Tecnologías Solubles - Tecnologías eficientes",
    supplier: "ACTAGRO",
    countries: ["Perú"],
    image: "productsImg/optimized/structure.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EY3_IwoM9yZMnM6Rr61hKScBXOmnB1Hdm6VbTBSsLGNs2A?e=5f5vnS",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ERAVmUFxbUlOvcVLQMwUlHEBRMc_VibbdSBmnTjaI8kgyw?e=0eqwq3",
  },
  {
    name: "Fosfato Monopotásico (MKP)",
    description:
      "Fertilizante con una concentración óptima de Fósforo y Potasio, proporciona los nutrientes esenciales necesarios para un crecimiento saludable y una producción de calidad. ",
    benefits:
      "Este producto es una valiosa fuente de fósforo y potasio que se disuelve fácilmente en agua, garantizando una nutrición eficaz y precisa para los cultivos. Con una concentración óptima de fósforo y potasio, este fertilizante proporciona los nutrientes esenciales necesarios para un crecimiento saludable y una producción de calidad. Su capacidad para disolverse completamente en agua facilita su aplicación en sistemas de fertirriego, asegurando una distribución uniforme de nutrientes en los cultivos.",
    composition: {
      "Potasio K2O": "34.0%",
      "Fósforo P2O5": "52.0%",
      "Valor pH": "4.72",
    },
    cultivation: [
      "Flores de corte",
      "Fresa",
      "Papa",
      "Arándanos",
      "Banano",
      "Plátano",
      "Mango",
      "Cítricos",
      "Aguacate",
      "Uva/Vid",
      "Tomate",
      "Piña",
      "Cacao",
      "Frutales",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego o uso de Drench.",
    ],
    hierarchy: "Tecnologías Solubles - Fósforo",
    supplier: "Shifang Anda Chemicals ",
    countries: ["Colombia"],
    image: "productsImg/optimized/fosfato-monopotásico-(mkp).webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EeJYlgQeRmFIilYB9jzFHQMBSY1Fdc2gjV1qZu4T8Wjk-g?e=4FuzQd",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EQ1-Yb7QywpAuT8Eo3qJeVEBvYnpmmMZ75w9UODmM7vAWA?e=MDZXaU",
  },
  {
    name: "PeKacid",
    description:
      "Fertilizante sólido y seco, posee las ventajas y eficiencia del ácido fosfórico, tiene gran facilidad y seguridad de aplicación tal como un fertilizante cristalino.",
    benefits:
      "Fuente rica en Fósforo y Potasio Fácil de disolver, ideal para fertiirrigación en campo abierto o cultivos sin tierra. Este producto es una forma segura y cristalina está enriquecido con Potasio para aumentar la absorción de tu cultivo.  Tiene un efecto acidificante que asegura que tus líneas no se van a tapar u obstruir. Puedes usarlo incluso para limpiar tus líneas de goteo.  Nova PeKacid 0-60-20 es perfecto para condiciones con agua dura o suelos calcáreos. También lo puedes usar en una mezcla de tanque con Calcio y Magnesio, gracias a su nivel de acidez, su bajo nivel de Sodio, a que no tiene cloruro y a su alto nivel de solubilidad a 670 g/l de agua (a 20 °C).",
    composition: {
      "Fósforo (P2O5)": "60 %",
      "Potasio (K2O)": "20 %",
      "Cloro (Cl)": "<90 ppm",
      "Cadmio (Cd)": "<1 ppm",
      "Arsénico (As)": "<1 ppm",
    },
    cultivation: [
      "Flores de corte",
      "Fresa",
      "Papa",
      "Arándanos",
      "Banano",
      "Plátano",
      "Mango",
      "Cítricos",
      "Aguacate",
      "Uva/Vid",
      "Tomate",
      "Piña",
      "Cacao",
      "Frutales",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego o uso de Drench.",
    ],
    hierarchy: "Tecnologías Solubles - Fósforo",
    supplier: "ICL",
    countries: ["Colombia"],
    image: "productsImg/optimized/pekacid.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ESxnzgtmQ_BOrr19XlgTBuwBapD1u0RYZm4uslM4JPmOUA?e=72eKNo",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ESB3CEutycFHtey7IqOEhBYBARkMIjZa5pF6CrrETT_pTw?e=1Jmum7",
  },
  {
    name: "Solufos 44",
    description:
      "Fertilizante hidrosoluble, es la mezcla química de urea con ácido fosfórico, formulado especialmente para nutrición por medio de sistemas de fertiirrigación. ",
    benefits:
      "Fertilizante complejo compuesto por nitrógeno (N) y Fosforo (P) para aplicación al suelo.                                                                                                                              Presenta un alto contenido de fosforo proveniente de la reacción entre ácido fosfórico y urea, por su formulación de escamas solubles es una fuente ideal de fosforo para aplicación al suelo en mezcla con algunos fertilizantes a base de potasio.                                                                                       Puede ser usado en todas las fases fenológicas del cultivo                                                                                         Presenta una rápida disolución en el suelo y una asimilación inmediata por parte de la planta.                                                                                                                                  El Fosforo juega un papel fundamental en la transferencia de energía, fundamental para la fotosíntesis y la respiración de la planta.                                                                                                                                                                              Solufos 44 presenta una fuente alta de eficiencia lo que permite aplicar bajas dosis del producto.                                                                                                                                                                                                         Un cultivo con deficiencia en nitrógeno y fosforo presenta una rápida respuesta",
    composition: {
      "Nitrógeno (N)": "17,5 %",
      "Pentóxido de Fósforo (P2O5)": "44,0 %",
    },
    cultivation: [
      "Flores de corte",
      "Fresa",
      "Papa",
      "Arándanos",
      "Banano",
      "Plátano",
      "Mango",
      "Cítricos",
      "Aguacate",
      "Uva/Vid",
      "Tomate",
      "Piña",
      "Cacao",
      "Frutales",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego o uso de Drench.",
    ],
    hierarchy: "Tecnologías Solubles - Fósforo",
    supplier: "EUROCHEM",
    countries: ["Colombia"],
    image: "productsImg/optimized/solufos-44.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/Eco3qEvqknJKl74EMSeXeyIB3JMYKWhQiySy6Yqtrvl0mQ?e=jUT4T6",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EYOiSbUykBFAqshD258eFhIBkiSsZWR1FhMJdsgdPxSlGw?e=pFPJFq",
  },
  {
    name: "Agrigrow K",
    description:
      "Fertilizante cristalizado soluble con 60% K₂O, ideal para corregir deficiencias de potasio, pero no recomendado en cultivos sensibles al cloro debido a su alta concentración de este elemento.",
    benefits:
      "Es un fertilizante con un NPK (0-0-60), cristalizado soluble con la más alta concentración de potasio del mercado (60% K2O). Esta característica lo convierte en una buena fuente de potasio para corregir deficiencias y/o aportar el potasio extraído por los cultivos. Sin embargo, se debe considerar que su alta concentración de cloro no lo hace indicado para ser usado en cultivos sensibles a este elemento.",
    composition: {
      "Nitrógeno (N)": "13,5 %",
      "Potasio (K2O)": "45,5 %",
    },
    cultivation: ["Cultivos de fertirriego no sensibles al  cloruro"],
    hierarchy: "Tecnologías Solubles - Potasio",
    supplier: "SQM",
    countries: ["Ecuador"],
    image: "productsImg/optimized/agrigrow-k.webp",
  },
  {
    name: "Amilsol KP",
    description:
      "Fertilizante de alta solubilidad con nutrientes de alta eficiencia, con potasio en forma de nitrato, lo que asegura un mayor aprovechamiento por parte de la planta y una mejor condición nutricional de los cultivos. ",
    benefits:
      "Amilsol® KP es ideal para ser utilizado en programas de fertilización vía riego por goteo, microjet, microaspersión y fertilización foliar en flores, frutales, hortalizas y cultivos tradicionales. El Nitrato de Potasio es también un fuerte antagonista de los Cloruros, evitando que sean absorbidos por las plantas cultivadas en suelos/aguas de alta salinidad. En condiciones de suelo ácido, el Nitrato de Potasio, juega un rol importante evitando/neutralizando las acidificación generada por la fuente de Nitrógeno Amoniacal-Ureico. A solo segundos de su aplicación, Amilsol® KP 13-3-44 ya está trabajando para aumentar los rendimientos y la calidad de sus productos. Amilsol® KP asegura calidad y condiciones en las cosechas debido al efecto sobre el tamaño final y acumulación de azúcares en los frutos.",
    composition: {
      "Nitrógeno total (N)": "13.0 %",
      "Nitrógeno amoniacal (N-NH4)": "0.8 %",
      "Nitrógeno nítrico (N-NO3)": "12.2 %",
      "Fósforo (P2O5)": "3.0 %",
      "Potasio  (K2O)": "44.0 %",
    },
    cultivation: [
      "Flores de corte",
      "Fresa",
      "Papa",
      "Arándanos",
      "Banano",
      "Plátano",
      "Mango",
      "Cítricos",
      "Aguacate",
      "Uva/Vid",
      "Tomate",
      "Piña",
      "Cacao",
      "Frutales",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego o uso de Drench.",
    ],
    hierarchy: "Tecnologías Solubles - Potasio",
    supplier: "SQM",
    countries: ["Colombia"],
    image: "productsImg/optimized/amilsol-kp.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EYM1jJ9t1s5FupWXJC0gMScBu8ZTiTO99ig_FdDKZ7TCHw?e=sHE80R",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EXF8Ytv2tHxNt9Zh_193tRYBJrMVe_oNIlOjX3FthpZg2g?e=uCSw1b",
  },
  {
    name: "Amilsol KP",
    description:
      "Fertilizante de alta solubilidad con nutrientes de alta eficiencia, con potasio en forma de nitrato, lo que asegura un mayor aprovechamiento por parte de la planta y una mejor condición nutricional de los cultivos. ",
    benefits:
      "Amilsol® KP es ideal para ser utilizado en programas de fertilización vía riego por goteo, microjet, microaspersión y fertilización foliar en flores, frutales, hortalizas y cultivos tradicionales. El Nitrato de Potasio es también un fuerte antagonista de los Cloruros, evitando que sean absorbidos por las plantas cultivadas en suelos/aguas de alta salinidad. En condiciones de suelo ácido, el Nitrato de Potasio, juega un rol importante evitando/neutralizando las acidificación generada por la fuente de Nitrógeno Amoniacal-Ureico. A solo segundos de su aplicación, Amilsol® KP 13-3-44 ya está trabajando para aumentar los rendimientos y la calidad de sus productos. Amilsol® KP asegura calidad y condiciones en las cosechas debido al efecto sobre el tamaño final y acumulación de azúcares en los frutos.",
    composition: {
      "Nitrógeno total (N)": "13.0 %",
      "Nitrógeno amoniacal (N-NH4)": "0.8 %",
      "Nitrógeno nítrico (N-NO3)": "12.2 %",
      "Fósforo (P2O5)": "3.0 %",
      "Potasio  (K2O)": "44.0 %",
    },
    cultivation: [
      "Flores de corte",
      "Fresa",
      "Papa",
      "Arándanos",
      "Banano",
      "Plátano",
      "Mango",
      "Cítricos",
      "Aguacate",
      "Uva/Vid",
      "Tomate",
      "Piña",
      "Cacao",
      "Frutales",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego o uso de Drench.",
    ],
    hierarchy: "Tecnologías Solubles - Potasio",
    supplier: "SQM",
    countries: ["Ecuador"],
    image: "productsImg/optimized/amilsol-kp.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EYM1jJ9t1s5FupWXJC0gMScBu8ZTiTO99ig_FdDKZ7TCHw?e=sHE80R",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EXF8Ytv2tHxNt9Zh_193tRYBJrMVe_oNIlOjX3FthpZg2g?e=uCSw1b",
  },
  {
    name: "Amilsol NKS",
    description:
      "Fertilizante de alta solubilidad con nutrientes de alta eficiencia, con potasio en forma de nitrato, lo que asegura un mayor aprovechamiento por parte de la planta y una mejor condición nutricional de los cultivos. ",
    benefits:
      "Amilsol® NKS, derivado de Nitrato de Potasio, es completamente soluble, especialmente diseñado para aplicaciones vía riego tecnificado:  - Aporte de nitrógeno 100% nítrico, fuente eficiente para la nutrición vegetal dado que genera sinergismo con la absorción de Potasio, Calcio y Magnesio.  - El nitrato de potasio es también un fuerte antagonista de los Cloruros, evitando que sean absorbidos por las plantas cultivadas en suelos/aguas de alta salinidad.  - En condiciones de suelo ácido, el nitrato de potasio, juega un rol importante evitando/neutralizando la acidificación generada por la fuente de nitrógeno amonical-ureíco.  Además, gracias a su fórmula Amilsol® NKS, es ideal para el complemento nutricional de los cultivos mediante aplicaciones por aspersión foliar y totalmente compatible para mezclas con otras fuentes como bioestimulantes y fungicidas.",
    composition: {
      "Nitrógeno nítrico (N-NO3)": "12.0 %",
      "Potasio (K2O)": "46.0 %",
      "Azufre (S)": "1.8 %",
    },
    cultivation: [
      "Flores de corte",
      "Fresa",
      "Papa",
      "Arándanos",
      "Banano",
      "Plátano",
      "Mango",
      "Cítricos",
      "Aguacate",
      "Uva/Vid",
      "Tomate",
      "Piña",
      "Cacao",
      "Frutales",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego o uso de Drench.",
    ],
    hierarchy: "Tecnologías Solubles - Potasio",
    supplier: "SQM",
    countries: ["Colombia"],
    image: "productsImg/optimized/amilsol-nks.webp",
  },
  {
    name: "Amilsol NKS",
    description:
      "Fertilizante de alta solubilidad con nutrientes de alta eficiencia, con potasio en forma de nitrato, lo que asegura un mayor aprovechamiento por parte de la planta y una mejor condición nutricional de los cultivos. ",
    benefits:
      "Amilsol® NKS, derivado de Nitrato de Potasio, es completamente soluble, especialmente diseñado para aplicaciones vía riego tecnificado:  - Aporte de nitrógeno 100% nítrico, fuente eficiente para la nutrición vegetal dado que genera sinergismo con la absorción de Potasio, Calcio y Magnesio.  - El nitrato de potasio es también un fuerte antagonista de los Cloruros, evitando que sean absorbidos por las plantas cultivadas en suelos/aguas de alta salinidad.  - En condiciones de suelo ácido, el nitrato de potasio, juega un rol importante evitando/neutralizando la acidificación generada por la fuente de nitrógeno amonical-ureíco.  Además, gracias a su fórmula Amilsol® NKS, es ideal para el complemento nutricional de los cultivos mediante aplicaciones por aspersión foliar y totalmente compatible para mezclas con otras fuentes como bioestimulantes y fungicidas.",
    composition: {
      "Nitrógeno nítrico (N-NO3)": "12.0 %",
      "Potasio (K2O)": "46.0 %",
      "Azufre (S)": "1.8 %",
    },
    cultivation: [
      "Flores de corte",
      "Fresa",
      "Papa",
      "Arándanos",
      "Banano",
      "Plátano",
      "Mango",
      "Cítricos",
      "Aguacate",
      "Uva/Vid",
      "Tomate",
      "Piña",
      "Cacao",
      "Frutales",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego o uso de Drench.",
    ],
    hierarchy: "Tecnologías Solubles - Potasio",
    supplier: "SQM",
    countries: ["Ecuador"],
    image: "productsImg/optimized/amilsol-nks.webp",
  },
  {
    name: "Amilsoline KP",
    description:
      "Amilsoline KP es un componente indispensable en programas de nutrición bien balanceados para la fertiirrigación de cultivos, sembrados en suelo o en sistemas hidropónicos. Fuente de Potasio en forma de Nitrato.\n",
    benefits:
      "Además de todas las ventajas de un Nitrato de Potasio, Amilsoline KP  ofrece un aporte nutricional complementario para que sus cultivos tengan: Mejor desarrollo del sistema de raíces. Mayores rendimientos y mejor calidad en la cosecha. Mayor tolerancia al estrés. Promueve la asimilación de calcio dando mayor firmeza a los tejidos. Amilsol®ine KP asegura un suministro adecuado de yodo en la solución nutritiva.",
    composition: {
      "Nitrógeno total (N)": "13,0 %",
      "Nitrógeno nítrico (N-NO3)": "12,2 %",
      "Nitrógeno amoniacal (NH2)": "0,8 %",
      "Fósforo  (P2O5)": "3,0 %",
      "Potasio (K2O)": "44,0 %",
    },
    cultivation: [
      "Flores de corte",
      "Fresa",
      "Papa",
      "Arándanos",
      "Banano",
      "Plátano",
      "Mango",
      "Cítricos",
      "Aguacate",
      "Uva/Vid",
      "Tomate",
      "Piña",
      "Cacao",
      "Frutales",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego o uso de Drench.",
    ],
    hierarchy: "Tecnologías Solubles - Potasio",
    supplier: "SQM",
    countries: ["Colombia"],
    image: "productsImg/optimized/amilsoline-kp.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EbNxxYtehRdKjdT2kr2Y85YBtiWnfcG81vUVuB4_FXytIA?e=tmXOwq",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ERdp-L3njT5DkWbD0xiKPIgBceJ4d-D5lO1y6W83R31vAg?e=VSYweN",
  },
  {
    name: "Entec Solub 21",
    description:
      "Entec Solub 21 es apto para hidroponía, fertiirrigación y aplicación foliar",
    benefits:
      "Este producto está compuesto exclusivamente por nutrientes y no contiene ningún elemento nocivo para las plantas. El polímero ENTEC reduce las pérdidas del nitrógeno en el suelo Recomendado para fertilización en sistemas de fertirriego Impulsa el crecimiento y la calidad de los cultivos Maximiza la absorción de nutrientes por parte de las plantas",
    composition: {
      "Nitrógeno total (N)": "21,0 %",
      "Nitrógeno amoniacal (N-NH4)": "21,0 %",
      "Azufre soluble en agua (S)": "24,0 %",
    },
    cultivation: [
      "Flores de corte",
      "Fresa",
      "Papa",
      "Arándanos",
      "Banano",
      "Plátano",
      "Mango",
      "Cítricos",
      "Aguacate",
      "Uva/Vid",
      "Tomate",
      "Piña",
      "Cacao",
      "Frutales",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego o uso de Drench.",
    ],
    hierarchy: "Tecnologías Solubles - Potasio",
    supplier: "EUROCHEM",
    countries: ["Colombia"],
    image: "productsImg/optimized/entec-solub-21.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EcHgFfokqZJDov16yM_oOhABO6K0wiYmfuNm3__ruVBaBg?e=F6AUiM",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EfkiTjgz3a5AjqjcSu9kjzkBvrmSjAjSiuPD4aOczK4kVQ?e=bq41e8",
  },
  {
    name: "SoluK 60",
    description:
      "Sulfato de potasio hidrosoluble de alta eficiencia para la fertilización en sistemas de fertirriego",
    benefits:
      "Con un alto contenido de potasio fortalece la salud de las plantas promoviendo la producción de frutos y flores de calidad Hidrosolubilidad avanzada, su rápida disolución en agua facilita la aplicación en sistemas de fertirriego, asegurando una distribución uniforma y una absorción optima de nutrientes La alta eficiencia de este sulfato de potasio lo convierte en una elección confiable para maximizar el rendimiento de los cultivos. ",
    composition: {
      "Potasio (K2O)": "51,5 %",
      "Cloro (Cl)": "0,6 %",
      "Azufre (SO4)": "56,0 %",
    },
    cultivation: [
      "Flores de corte",
      "Fresa",
      "Papa",
      "Arándanos",
      "Banano",
      "Plátano",
      "Mango",
      "Cítricos",
      "Palto/Aguacate",
      "Uva/Vid",
      "Tomate",
      "Piña",
      "Cacao",
      "Frutales",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego o uso de Drench.",
    ],
    hierarchy: "Tecnologías Solubles - Potasio",
    supplier: "DISAN",
    countries: ["Perú"],
    image: "productsImg/optimized/soluk-60.webp",
  },
  {
    name: "SoluK Organic",
    description:
      "Fuente de Potasio en forma de sulfato extraído directamente de mina en presentación de cristales para ser incluido en los programas de nutrición de cultivos. ",
    benefits:
      "Por ser extraído directamente de mina y no tener tratamiento químico, posee certificación para ser usado en agricultura orgánica.",
    composition: {
      "Sulfato de Potasio (K2SO4)": "93,0 %",
      "Otros sulfatos (MgSO4 - CaSO4)": "4,0 %",
      "Cloruros (KCl - NaCl)": "2,0 %",
      "Cloro (Cl)": "1,2 %",
    },
    cultivation: [
      "Flores de corte",
      "Fresa",
      "Papa",
      "Arándanos",
      "Banano",
      "Plátano",
      "Mango",
      "Cítricos",
      "Palto/Aguacate",
      "Uva/Vid",
      "Tomate",
      "Piña",
      "Cacao",
      "Frutales",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego o uso de Drench.",
    ],
    hierarchy: "Tecnologías Solubles - Potasio",
    supplier: "K+S",
    countries: ["Perú"],
    image: "productsImg/optimized/soluk-organic.webp",
  },
  {
    name: "SoluPotasse",
    description:
      "Sulfato de potasio hidrosoluble de alta eficiencia para la fertilización en sistemas de fertirriego",
    benefits:
      "Con un alto contenido de potasio fortalece la salud de las plantas promoviendo la producción de frutos y flores de calidad Hidrosolubilidad avanzada, su rápida disolución en agua facilita la aplicación en sistemas de fertirriego, asegurando una distribución uniforma y una absorción optima de nutrientes La alta eficiencia de este sulfato de potasio lo convierte en una elección confiable para maximizar el rendimiento de los cultivos. ",
    composition: {
      "Potasio (K2O)": "51,5 %",
      "Cloro (Cl)": "0,6 %",
      "Azufre (SO4)": "56,0 %",
    },
    cultivation: [
      "Flores de corte",
      "Fresa",
      "Papa",
      "Arándanos",
      "Banano",
      "Plátano",
      "Mango",
      "Cítricos",
      "Aguacate",
      "Uva/Vid",
      "Tomate",
      "Piña",
      "Cacao",
      "Frutales",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego o uso de Drench.",
    ],
    hierarchy: "Tecnologías Solubles - Potasio",
    supplier: "TESSENDERLO",
    countries: ["Colombia"],
    image: "productsImg/optimized/solupotasse.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EXiXkjjN3sNGpf3ixBljM3gBU7z_wT0ozQN3BBk-TliVCg?e=exhe4C",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EQbZdIVjxuBAvxKVMWL6Je0BeDYf2apO-XH2oVJ1N3Msrw?e=8DODKV",
  },
  {
    name: "SoluPotasse",
    description:
      "Sulfato de potasio hidrosoluble de alta eficiencia para la fertilización en sistemas de fertirriego",
    benefits:
      "Con un alto contenido de potasio fortalece la salud de las plantas promoviendo la producción de frutos y flores de calidad Hidrosolubilidad avanzada, su rápida disolución en agua facilita la aplicación en sistemas de fertirriego, asegurando una distribución uniforma y una absorción optima de nutrientes La alta eficiencia de este sulfato de potasio lo convierte en una elección confiable para maximizar el rendimiento de los cultivos. ",
    composition: {
      "Potasio (K2O)": "51,5 %",
      "Cloro (Cl)": "0,6 %",
      "Azufre (SO4)": "56,0 %",
    },
    cultivation: [
      "Flores de corte",
      "Fresa",
      "Papa",
      "Arándanos",
      "Banano",
      "Plátano",
      "Mango",
      "Cítricos",
      "Aguacate",
      "Uva/Vid",
      "Tomate",
      "Piña",
      "Cacao",
      "Frutales",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego o uso de Drench.",
    ],
    hierarchy: "Tecnologías Solubles - Potasio",
    supplier: "TESSENDERLO",
    countries: ["Ecuador"],
    image: "productsImg/optimized/solupotasse.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EXiXkjjN3sNGpf3ixBljM3gBU7z_wT0ozQN3BBk-TliVCg?e=exhe4C",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EQbZdIVjxuBAvxKVMWL6Je0BeDYf2apO-XH2oVJ1N3Msrw?e=8DODKV",
  },
  {
    name: "Sulfato de potasio estándar",
    description:
      "Fuente de Potasio en forma de sulfato extraído directamente de mina en presentación de cristales para ser incluido en los programas de nutrición de cultivos. ",
    benefits:
      "Por ser extraído directamente de mina y no tener tratamiento químico, posee certificación para ser usado en agricultura orgánica.",
    composition: {
      "Sulfato de Potasio (K2SO4)": "93,0 %",
      "Otros sulfatos (MgSO4 - CaSO4)": "4,0 %",
      "Cloruros (KCl - NaCl)": "2,0 %",
      "Cloro (Cl)": "1,2 %",
    },
    cultivation: [
      "Flores de corte",
      "Fresa",
      "Papa",
      "Arándanos",
      "Banano",
      "Plátano",
      "Mango",
      "Cítricos",
      "Aguacate",
      "Uva/Vid",
      "Tomate",
      "Piña",
      "Cacao",
      "Frutales",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego o uso de Drench.",
    ],
    hierarchy: "Tecnologías Solubles - Potasio",
    supplier: "K+S",
    countries: ["Colombia"],
    image: "productsImg/optimized/sulfato-de-potasio-estándar.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EajKTG9PTqBBu1yPvjj1PVUBSLynwoBgLSk-oQmIGSwQKA?e=z19ayI",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ERpwRnLlJupFjFX7btnb6S8BevUVE7xhMzPbc5hPDbzsEw?e=bL1SIu",
  },
  {
    name: "EpsoTOP",
    description:
      "Fertilizante soluble sulfato de magnesio heptahidratado, extraído directamente de mina, lo que permite ser utilizado en programas de fertilización de agricultura orgánica.",
    benefits:
      "EPSO Top es un fertilizante con magnesio y azufre que tiene un efecto inmediato al ser aplicado vía foliar. Los nutrientes son totalmente solubles en agua y están presentes en forma de sulfato Se disuelve rápidamente sin dejar residuos en el agua y por esto es ideal para ser aplicado como fertilizante foliar en conjunto con fitosanitarios, así como para ser usado en sistemas de fertirriego. Puede usarse en todos los cultivos como complemento de la fertilización al suelo y sobre todo si se observa deficiencia de magnesio.",
    composition: {
      "Sulfato de Magnesio heptahidratado calculado como  (MgSO4)": "49 %",
    },
    cultivation: [
      "Flores de corte",
      "Fresa",
      "Papa",
      "Arándanos",
      "Banano",
      "Plátano",
      "Mango",
      "Cítricos",
      "Palto/Aguacate",
      "Uva/Vid",
      "Tomate",
      "Piña",
      "Cacao",
      "Frutales",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego o uso de Drench.",
    ],
    hierarchy: "Tecnologías Solubles - Magnesio",
    supplier: "K+S",
    countries: ["Colombia"],
    image: "productsImg/optimized/epsotop.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ESTNnnbS32hLseOcW2EFCnwBkCud3F2MhugA4iFaz-r2HA?e=SkSMhf",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ERH64A1uk8xJvPOfwamX_9IBYN10LIn69K9mSsSj9sdnsQ?e=bsisZ7",
  },
  {
    name: "EpsoTOP",
    description:
      "Fertilizante soluble sulfato de magnesio heptahidratado, extraído directamente de mina, lo que permite ser utilizado en programas de fertilización de agricultura orgánica.",
    benefits:
      "EPSO Top es un fertilizante con magnesio y azufre que tiene un efecto inmediato al ser aplicado vía foliar. Los nutrientes son totalmente solubles en agua y están presentes en forma de sulfato Se disuelve rápidamente sin dejar residuos en el agua y por esto es ideal para ser aplicado como fertilizante foliar en conjunto con fitosanitarios, así como para ser usado en sistemas de fertirriego. Puede usarse en todos los cultivos como complemento de la fertilización al suelo y sobre todo si se observa deficiencia de magnesio.",
    composition: {
      "Sulfato de Magnesio heptahidratado calculado como  (MgSO4)": "49 %",
    },
    cultivation: [
      "Flores de corte",
      "Fresa",
      "Papa",
      "Arándanos",
      "Banano",
      "Plátano",
      "Mango",
      "Cítricos",
      "Palto/Aguacate",
      "Uva/Vid",
      "Tomate",
      "Piña",
      "Cacao",
      "Frutales",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego o uso de Drench.",
    ],
    hierarchy: "Tecnologías Solubles - Magnesio",
    supplier: "K+S",
    countries: ["Perú"],
    image: "productsImg/optimized/EpsoTOP Perú.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ESTNnnbS32hLseOcW2EFCnwBkCud3F2MhugA4iFaz-r2HA?e=SkSMhf",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ERH64A1uk8xJvPOfwamX_9IBYN10LIn69K9mSsSj9sdnsQ?e=bsisZ7",
  },
  {
    name: "Nitrato de Magnesio",
    description: "Fuente de Magnesio y Nitrógeno para aplicación fertirriego.",
    benefits:
      "Mejora la calidad de la clorofila aumentando el color verde de las hojas No contiene Cloro, Sodio u otros elementos perjudiciales  Es un producto que se disuelve rápidamente en agua y fluye fácilmente Puede ser usado para satisfacer las necesidades de magnesio de la mayoría de los cultivos sin aportar cloruro ",
    composition: {},
    cultivation: [
      "Flores de corte",
      "Fresa",
      "Papa",
      "Arándanos",
      "Banano",
      "Plátano",
      "Mango",
      "Cítricos",
      "Palto/Aguacate",
      "Uva/Vid",
      "Tomate",
      "Piña",
      "Cacao",
      "Frutales",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego o uso de Drench.",
    ],
    hierarchy: "Tecnologías Solubles - Magnesio",
    supplier: "DISAN",
    countries: ["Colombia"],
    image: "productsImg/optimized/nitrato-de-magnesio.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/Ed7ZGgwEkIJDmzcVbEQ8NE8Bxokc9Gg0n6KRhri2ipWepw?e=dBhfsG",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EVZNz1ICKDVEm1dvmxGkhRMBEqlwqzw5Job9BUgbewogtQ?e=UEDwJk",
  },
  {
    name: "Nitrato de Magnesio",
    description: "Fuente de Magnesio y Nitrógeno para aplicación fertirriego.",
    benefits:
      "Mejora la calidad de la clorofila aumentando el color verde de las hojas No contiene Cloro, Sodio u otros elementos perjudiciales  Es un producto que se disuelve rápidamente en agua y fluye fácilmente Puede ser usado para satisfacer las necesidades de magnesio de la mayoría de los cultivos sin aportar cloruro ",
    composition: {},
    cultivation: [
      "Flores de corte",
      "Fresa",
      "Papa",
      "Arándanos",
      "Banano",
      "Plátano",
      "Mango",
      "Cítricos",
      "Palto/Aguacate",
      "Uva/Vid",
      "Tomate",
      "Piña",
      "Cacao",
      "Frutales",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego o uso de Drench.",
    ],
    hierarchy: "Tecnologías Solubles - Magnesio",
    supplier: "DISAN",
    countries: ["Perú"],
    image: "productsImg/optimized/nitrato-de-magnesio.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/Ed7ZGgwEkIJDmzcVbEQ8NE8Bxokc9Gg0n6KRhri2ipWepw?e=dBhfsG",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EVZNz1ICKDVEm1dvmxGkhRMBEqlwqzw5Job9BUgbewogtQ?e=UEDwJk",
  },
  {
    name: "Sulfato de Magnesio",
    description:
      "Sulfato de Magnesio heptahidratado soluble en agua recomendado para las prácticas de fertilización en sistemas de fertirriego.",
    benefits:
      "Fuente de Magnesio y Azufre para fertirriego, en formas solubles en agua y de rápida disponibilidad para el cultivo. Previene y corrige rápidamente las deficiencias de magnesio y azufre en la planta. El magnesio es fundamental en la producción de clorofila, una molécula involucrada en la fotosíntesis contribuye a la activación enzimática y la síntesis de proteínas. El Azufre participa en la síntesis de proteínas y vitaminas, promueve la nodulación en leguminosas, importante en la producción de semillas y necesario en la formación de clorofila.",
    composition: {
      "Magnesio (Mg)": "9,7 %",
      "Óxido de Magnesio  (MgO)": "16,0 %",
      "Azufre (S)": "12,7 %",
    },
    cultivation: [
      "Flores de corte",
      "Fresa",
      "Papa",
      "Arándanos",
      "Banano",
      "Plátano",
      "Mango",
      "Cítricos",
      "Palto/Aguacate",
      "Uva/Vid",
      "Tomate",
      "Piña",
      "Cacao",
      "Frutales",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego o uso de Drench.",
    ],
    hierarchy: "Tecnologías Solubles - Magnesio",
    supplier: "TIMAB",
    countries: ["Colombia"],
    image: "productsImg/optimized/Sulfato-de-magnesio.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EYmVAumlGGJBs4-tL3Xs5P8BjgtRx8yYxDYJQLkTblFG5Q?e=rgGNgN",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EX4pkHvEeoFAj39iCIxZQ5UBI-osUrSdA0W3sBYmicr6hw?e=VE7vcJ",
  },
  {
    name: "Sulfato de Magnesio",
    description:
      "Sulfato de Magnesio heptahidratado soluble en agua recomendado para las prácticas de fertilización en sistemas de fertirriego.",
    benefits:
      "Fuente de Magnesio y Azufre para fertirriego, en formas solubles en agua y de rápida disponibilidad para el cultivo. Previene y corrige rápidamente las deficiencias de magnesio y azufre en la planta. El magnesio es fundamental en la producción de clorofila, una molécula involucrada en la fotosíntesis contribuye a la activación enzimática y la síntesis de proteínas. El Azufre participa en la síntesis de proteínas y vitaminas, promueve la nodulación en leguminosas, importante en la producción de semillas y necesario en la formación de clorofila.",
    composition: {
      "Magnesio (Mg)": "9,7 %",
      "Óxido de Magnesio  (MgO)": "16,0 %",
      "Azufre (S)": "12,7 %",
    },
    cultivation: [
      "Flores de corte",
      "Fresa",
      "Papa",
      "Arándanos",
      "Banano",
      "Plátano",
      "Mango",
      "Cítricos",
      "Palto/Aguacate",
      "Uva/Vid",
      "Tomate",
      "Piña",
      "Cacao",
      "Frutales",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego o uso de Drench.",
    ],
    hierarchy: "Tecnologías Solubles - Magnesio",
    supplier: "TIMAB",
    countries: ["Venezuela"],
    image: "productsImg/optimized/sulfato-de-magnesio.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EYmVAumlGGJBs4-tL3Xs5P8BjgtRx8yYxDYJQLkTblFG5Q?e=rgGNgN",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EX4pkHvEeoFAj39iCIxZQ5UBI-osUrSdA0W3sBYmicr6hw?e=VE7vcJ",
  },
  {
    name: "Sulfato de Magnesio",
    description:
      "Sulfato de Magnesio heptahidratado soluble en agua recomendado para las prácticas de fertilización en sistemas de fertirriego.",
    benefits:
      "Fuente de Magnesio y Azufre para fertirriego, en formas solubles en agua y de rápida disponibilidad para el cultivo. Previene y corrige rápidamente las deficiencias de magnesio y azufre en la planta. El magnesio es fundamental en la producción de clorofila, una molécula involucrada en la fotosíntesis contribuye a la activación enzimática y la síntesis de proteínas. El Azufre participa en la síntesis de proteínas y vitaminas, promueve la nodulación en leguminosas, importante en la producción de semillas y necesario en la formación de clorofila.",
    composition: {
      "Magnesio (Mg)": "9,7 %",
      "Óxido de Magnesio  (MgO)": "16,0 %",
      "Azufre (S)": "12,7 %",
    },
    cultivation: [
      "Flores de corte",
      "Fresa",
      "Papa",
      "Arándanos",
      "Banano",
      "Plátano",
      "Mango",
      "Cítricos",
      "Palto/Aguacate",
      "Uva/Vid",
      "Tomate",
      "Piña",
      "Cacao",
      "Frutales",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego o uso de Drench.",
    ],
    hierarchy: "Tecnologías Solubles - Magnesio",
    supplier: "TIMAB",
    countries: ["Perú"],
    image: "productsImg/optimized/Sulfato-de-magnesio.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EYmVAumlGGJBs4-tL3Xs5P8BjgtRx8yYxDYJQLkTblFG5Q?e=rgGNgN",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EX4pkHvEeoFAj39iCIxZQ5UBI-osUrSdA0W3sBYmicr6hw?e=VE7vcJ",
  },
  {
    name: "Sulfato de Magnesio",
    description:
      "Sulfato de Magnesio heptahidratado soluble en agua recomendado para las prácticas de fertilización en sistemas de fertirriego.",
    benefits:
      "Fuente de Magnesio y Azufre para fertirriego, en formas solubles en agua y de rápida disponibilidad para el cultivo. Previene y corrige rápidamente las deficiencias de magnesio y azufre en la planta. El magnesio es fundamental en la producción de clorofila, una molécula involucrada en la fotosíntesis contribuye a la activación enzimática y la síntesis de proteínas. El Azufre participa en la síntesis de proteínas y vitaminas, promueve la nodulación en leguminosas, importante en la producción de semillas y necesario en la formación de clorofila.",
    composition: {
      "Magnesio (Mg)": "9,7 %",
      "Óxido de Magnesio  (MgO)": "16,0 %",
      "Azufre (S)": "12,7 %",
    },
    cultivation: [
      "Flores de corte",
      "Fresa",
      "Papa",
      "Arándanos",
      "Banano",
      "Plátano",
      "Mango",
      "Cítricos",
      "Palto/Aguacate",
      "Uva/Vid",
      "Tomate",
      "Piña",
      "Cacao",
      "Frutales",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego o uso de Drench.",
    ],
    hierarchy: "Tecnologías Solubles - Magnesio",
    supplier: "TIMAB",
    countries: ["Ecuador"],
    image: "productsImg/optimized/SULFATO DE MAGNESIO ECU.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EYmVAumlGGJBs4-tL3Xs5P8BjgtRx8yYxDYJQLkTblFG5Q?e=rgGNgN",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EX4pkHvEeoFAj39iCIxZQ5UBI-osUrSdA0W3sBYmicr6hw?e=VE7vcJ",
  },
  {
    name: "Nitra Cal 26",
    description:
      "Fertilizante altamente concentrado, 100% soluble en agua, libre de Cloro y Sodio",
    benefits:
      "Nitrato de Calcio soluble  Fuente de Calcio y Nitrógeno para aplicación a fertirriego El uso de nitrato de calcio ayuda a aumentar la resistencia de las plantas a los factores del estrés del medio ambiente mejora la calidad de los frutos y prolonga el período de vida post cosecha. Contiene una mayor proporción de nitrógeno nítrico frente a nitrógeno amoniacal, lo que incrementa la sinergia de absorción de calcio, potasio y magnesio.   ",
    composition: {},
    cultivation: [
      "Flores de corte",
      "Fresa",
      "Papa",
      "Arándanos",
      "Banano",
      "Plátano",
      "Mango",
      "Cítricos",
      "Palto/Aguacate",
      "Uva/Vid",
      "Tomate",
      "Piña",
      "Cacao",
      "Frutales",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego o uso de Drench.",
    ],
    hierarchy: "Tecnologías Solubles - Calcio",
    supplier: "FERTIBERIA",
    countries: ["Perú"],
    image: "productsImg/optimized/nitra-cal-26.webp",
  },
  {
    name: "Ácido Bórico",
    description:
      "El ácido bórico se puede usar solo o en combinación con otros fertilizantes en suelos o sustratos con bajo contenido de boro.",
    benefits:
      "El Ácido Bórico es un fertilizante que suministra Boro en forma soluble, ideal para aplicaciones mediante fertirriego. Este micronutriente esencial desempeña un papel crucial en las plantas, favoreciendo la elongación del tubo polínico. Además, está estrechamente relacionado con la lignificación de tejidos jóvenes y la translocación de azúcares, en sinergia con el potasio.",
    composition: {
      "Boro soluble en agua (B)":
        "17,47 – 17,67 %                                           17,47 – 17,67 %",
    },
    cultivation: [
      "Palma",
      "Flores de corte",
      "Fresa",
      "Arándanos",
      "Banano",
      "Mango",
      "Cítricos",
      "Esparrago",
      "Aguacate/Palto",
      "Uva/Vid",
      "Tomate",
      "Piña",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego. ",
    ],
    hierarchy: "Tecnologías Solubles - Boro",
    supplier: "ETIMADEN",
    countries: ["Colombia"],
    image: "productsImg/optimized/ácido-bórico.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EcvEptM7f3FPuTuv1GSoarcBAmEdpA8Gvtya_nlN8_ze0Q?e=KRjejJ",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ERHPRnXHIrhAhSbj-z60QmgBdiNriGkFPBVQuFSXvOP-_Q?e=JYHT28",
  },
  {
    name: "Ácido Bórico",
    description:
      "El ácido bórico se puede usar solo o en combinación con otros fertilizantes en suelos o sustratos con bajo contenido de boro.",
    benefits:
      "El Ácido Bórico es un fertilizante que suministra Boro en forma soluble, ideal para aplicaciones mediante fertirriego. Este micronutriente esencial desempeña un papel crucial en las plantas, favoreciendo la elongación del tubo polínico. Además, está estrechamente relacionado con la lignificación de tejidos jóvenes y la translocación de azúcares, en sinergia con el potasio.",
    composition: {
      "Boro soluble en agua (B)":
        "17,47 – 17,67 %                                           17,47 – 17,67 %",
    },
    cultivation: [
      "Palma",
      "Flores de corte",
      "Fresa",
      "Arándanos",
      "Banano",
      "Mango",
      "Cítricos",
      "Esparrago",
      "Aguacate/Palto",
      "Uva/Vid",
      "Tomate",
      "Piña",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego. ",
    ],
    hierarchy: "Tecnologías Solubles - Boro",
    supplier: "ETIMADEN",
    countries: ["Venezuela"],
    image: "productsImg/optimized/ácido-bórico.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EcvEptM7f3FPuTuv1GSoarcBAmEdpA8Gvtya_nlN8_ze0Q?e=KRjejJ",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ERHPRnXHIrhAhSbj-z60QmgBdiNriGkFPBVQuFSXvOP-_Q?e=JYHT28",
  },
  {
    name: "Boráx Pentahidratado",
    description:
      "Fertilizante simple soluble formulado a base de boro y complementado cosn potasio teniendo como fuente cloruro de potasio estándar y bórax, indicado para fertilización por sistemas de fertirriego. ",
    benefits:
      "Proporciona a las plantas una nutrición balanceada y completa. Al aplicar este fertilizante se favorece el desarrollo vegetativo, formación de frutos de calidad y se estimula la resistencia de las plantas frente a diferentes situaciones. Con el fertilizante MF BÓRAX + K los agricultores pueden mejorar significativamente el rendimiento de los cultivos, obteniendo plantas más saludables, frutos de mayor calidad y cosechas más abundantes. El boro es un elemento que debe estar presente en el suelo para el crecimiento y desarrollo de las plantas, se utiliza para aumentar la producción agrícola y obtener productos de mayor calidad. ",
    composition: {
      "Boro (B2O3)": "48.00%",
      "Boro (B)": "14.91%",
      "Boro soluble en agua (B)": "14.91%",
      "Óxido de Sodio (Na2O)": "21.37%",
      "Azufre (SO4)": "135ppm",
      "Cloro (Cl)": "70ppm",
      "Hierro (Fe)": "3ppm",
    },
    cultivation: [
      "Palma",
      "Flores de corte",
      "Fresa",
      "Arándanos",
      "Banano",
      "Mango",
      "Cítricos",
      "Esparrago",
      "Aguacate/Palto",
      "Uva/Vid",
      "Tomate",
      "Piña",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego.",
    ],
    hierarchy: "Tecnologías Solubles - Boro",
    supplier: "ETIMADEN",
    countries: ["Colombia"],
    image: "productsImg/optimized/borax-pentahidratado.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EcvEptM7f3FPuTuv1GSoarcBAmEdpA8Gvtya_nlN8_ze0Q?e=yfFkSk",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ERHPRnXHIrhAhSbj-z60QmgBdiNriGkFPBVQuFSXvOP-_Q?e=YZfs4p",
  },
  {
    name: "Boráx Pentahidratado",
    description:
      "Fertilizante simple soluble formulado a base de boro y complementado cosn potasio teniendo como fuente cloruro de potasio estándar y bórax, indicado para fertilización por sistemas de fertirriego. ",
    benefits:
      "Proporciona a las plantas una nutrición balanceada y completa. Al aplicar este fertilizante se favorece el desarrollo vegetativo, formación de frutos de calidad y se estimula la resistencia de las plantas frente a diferentes situaciones. Con el fertilizante MF BÓRAX + K los agricultores pueden mejorar significativamente el rendimiento de los cultivos, obteniendo plantas más saludables, frutos de mayor calidad y cosechas más abundantes. El boro es un elemento que debe estar presente en el suelo para el crecimiento y desarrollo de las plantas, se utiliza para aumentar la producción agrícola y obtener productos de mayor calidad. ",
    composition: {
      "Boro (B2O3)": "48.00%",
      "Boro (B)": "14.91%",
      "Boro soluble en agua (B)": "14.91%",
      "Óxido de Sodio (Na2O)": "21.37%",
      "Azufre (SO4)": "135ppm",
      "Cloro (Cl)": "70ppm",
      "Hierro (Fe)": "3ppm",
    },
    cultivation: [
      "Palma",
      "Flores de corte",
      "Fresa",
      "Arándanos",
      "Banano",
      "Mango",
      "Cítricos",
      "Esparrago",
      "Aguacate/Palto",
      "Uva/Vid",
      "Tomate",
      "Piña",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego.",
    ],
    hierarchy: "Tecnologías Solubles - Boro",
    supplier: "ETIMADEN",
    countries: ["Venezuela"],
    image: "productsImg/optimized/borax-pentahidratado.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EcvEptM7f3FPuTuv1GSoarcBAmEdpA8Gvtya_nlN8_ze0Q?e=yfFkSk",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ERHPRnXHIrhAhSbj-z60QmgBdiNriGkFPBVQuFSXvOP-_Q?e=YZfs4p",
  },
  {
    name: "Amilsol crecimiento",
    description:
      "Formulada diseñada para etapa fenológicas con alto requerimiento de nitrógeno, especialmente durante la expansión de la hoja y crecimiento vegetativo. ",
    benefits:
      "Fertilizante soluble formulado a base de Nitrógeno, Fosforo, Potasio y microelementos para una nutrición completa de los cultivos, asegurando un mayor aprovechamiento de la planta.  Producto altamente efectivo diseñado para proporcionar un suministro equilibrado de nutrientes. Su alta concentración de nitrógeno permite un rápido desarrollo de las hojas y tallos, favoreciendo una mayor fotosíntesis y un crecimiento vigoroso de las plantas, el nitrógeno mejora la capacidad de las plantas para resistir enfermedades estrés, fortaleciendo su sistema inmunológico y su resistencia a condiciones adversas. El fosforo presente en este fertilizante es esencial para el desarrollo de raíces fuertes y saludables, así como para la producción de flores y frutos, el fosforo desempeña un papel crucial en la transferencia de energía, la síntesis de ácidos nucleicos y la activación de enzimas vitales para el metabolismo de las plantas. Promueve un establecimiento solido de las raíces y un desarrollo temprano de estructuras reproductivas, mejorando la calidad y cantidad de la producción de los cultivos. ",
    composition: {
      "Nitrógeno total (N)": "25.0%",
      "Nitrógeno amoniacal (N-H4)": "12.5%",
      "Nitrógeno nítrico (N-NO3)": "12.5%",
      "Fosforo (P2O5)": "10.0%",
      "Potasio (K2O)": "10.0%",
      "Azufre (S)": "1.8%",
      "Magnesio (MgO)": "0.5%",
      "Hierro (Fe) (EDTA)": "0.04%",
      "Manganeso (Mn) (EDTA)": "0.02%",
      "Zinc (Zn) (EDTA)": "0.02%",
      "Cobre (Cu) (EDTA)": "0.01%",
      "Molibdeno (Mo)": "0.01%",
      "pH en solución (al)": "10%",
    },
    cultivation: [
      "Flores de corte",
      "Fresa",
      "Papa",
      "Arándanos",
      "Banano",
      "Plátano",
      "Mango",
      "Cítricos",
      "Aguacate",
      "Uva/Vid",
      "Tomate",
      "Piña",
      "Cacao",
      "Frutales",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego o uso de Drench.",
    ],
    hierarchy: "Tecnologías Solubles - Compuestos NPK ",
    supplier: "SQM",
    countries: ["Colombia"],
    image: "productsImg/optimized/amilsol-crecimiento.webp",
  },
  {
    name: "Amilsol inicial",
    description:
      "Fertilizante cristalizado, hidrosoluble con alto contenido de fosforo, diseñado para estimular la brotación y desarrollo de raíces tallos y hojas. ",
    benefits:
      "Fertilizante soluble formulado para mejorar la absorción de nutrientes, contribuyendo a la fertilidad del suelo y a la productividad de los cultivos. Fertilizante de alta solubilidad diseñado para proporcionar un impulso nutricional inicial a los cultivos durante las etapas de establecimiento y crecimiento. El fosforo desempeña un papel clave en el desarrollo del sistema radicular robusta, floración y fructificación saludable. Ayuda en la transferencia de energía y en la síntesis de ADN y ARN, lo que resulta en un crecimiento celular adecuado y un desarrollo temprano de las plántulas. ",
    composition: {
      "Nitrógeno total (N)": "15.0%",
      "Nitrógeno amoniacal (N-NH4)": "8.0%",
      "Nitrógeno nítrico (N)": "-%",
      "Fósforo (P2O5)": "30.0%",
      "Potasio (K2O)": "15.0%",
      "Azufre (S)": "1.2%",
      "Magnesio (MgO)": "0.6%",
      "Zinc (Zn) (EDTA)": "0.1%",
      "Boro (B)": "0.1%",
      "pH en solución (al)": "10%",
    },
    cultivation: [
      "Flores de corte",
      "Fresa",
      "Papa",
      "Arándanos",
      "Banano",
      "Plátano",
      "Mango",
      "Cítricos",
      "Aguacate",
      "Uva/Vid",
      "Tomate",
      "Piña",
      "Cacao",
      "Frutales",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego o uso de Drench.",
    ],
    hierarchy: "Tecnologías Solubles - Compuestos NPK ",
    supplier: "SQM",
    countries: ["Colombia"],
    image: "productsImg/optimized/amilsol-inicial.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EdrWjou5GmRDnYiUS8GYicgBuGRcyZr7e9cuzT9Yjh2QgQ?e=zhpLzS",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/Edy_9X_LDv9BkHObFQ4rSo4BmbqtWQYGLZLBm-fLo98BTA?e=JZer7v",
  },
  {
    name: "Amilsol Multiproposito 18-18-18+EM",
    description:
      "Formula diseñada para ser usada en cualquier etapa de crecimiento y desarrollo ",
    benefits:
      "Fertilizante de alta solubilidad con nutrientes de alta eficiencia, con potasio en forma de nitrato lo que asegura un mayor aprovechamiento por parte de la planta y una mejor condición nutricional de los cultivos. Producto avanzado y eficiente, diseñado para proporcionar una nutrición equilibrada y de alta calidad de los cultivos, esta formulación especial combina un contenido balanceado de Nitrógeno, Fosforo y potasio en una proporción 18-18-18. Amilsol 18-18-18 puede corregir deficiencias nutricionales cuando es difícil de identificar la causa de esta deficiencia. Contiene un completo rango de macro y micronutrientes El nitrógeno presente es esencial para el crecimiento vegetativo y la síntesis de proteínas, contribuye al desarrollo de hojas verdes y saludables, promoviendo una mayor fotosíntesis y una producción de cultivos optima, además ayuda a fortalecer la resistencia de las plantas a enfermedades y estrés ambiental. ",
    composition: {
      "Nitrógeno total (N)": "18.0%",
      "Nitrógeno amoniacal (N-NH4)": "8.0%",
      "Nitrógeno nítrico (N-NO3)": "10.0%",
      "Fosforo (P2O5)": "18.0%",
      "Potasio (K2O)": "18.0%",
      "Azufre (S)": "1.5%",
      "Magnesio (MgO)": "0.6%",
      "Hierro (Fe) (EDTA)": "0.04%",
      "Manganeso (Mn) (EDTA)": "0.02%",
      "Zinc (Zn) (EDTA)": "0.02%",
      "Cobre (Cu) (EDTA)": "0.01%",
      "Molibdeno (Mo)": "0.01%",
      "pH en solución (al)": "10%",
    },
    cultivation: [
      "Flores de corte",
      "Fresa",
      "Papa",
      "Arándanos",
      "Banano",
      "Plátano",
      "Mango",
      "Cítricos",
      "Aguacate",
      "Uva/Vid",
      "Tomate",
      "Piña",
      "Cacao",
      "Frutales",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego o uso de Drench.",
    ],
    hierarchy: "Tecnologías Solubles - Compuestos NPK ",
    supplier: "SQM",
    countries: ["Colombia"],
    image: "productsImg/optimized/amilsol-multiproposito-18-18-18+em.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ETrC5DS6RABKr5hR80cpq2YBKM3FVwoIQ647fIAzF2iQBw?e=RD2BLI",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EWSJHrtrfPZNhkVGfNPy3boBnqnGAW5zN4pArXPfWnai2w?e=gWOrP1",
  },
  {
    name: "Amilsol producción",
    description:
      "Fertilizante de alta solubilidad con nutrientes de alta eficiencia, con Potasio en forma de Nitrato, lo que asegura un mayor aprovechamiento por parte de la planta y una mejor condición nutricional de los cultivos.",
    benefits:
      "Fórmula diseñada para el proceso más intensivo en el ciclo del cultivo: desde la floración hasta la formación del fruto, donde el potasio es responsable del transporte de carbohidratos desde las hojas a los órganos reproductivos (fruta, semilla, tubérculo) para obtener mayor calibre, mejor calidad y peso. Promueve en su mayoría la maduración, se intensifica el color de la fruta y mejora su color y apariencia.                                       ",
    composition: {
      "Nitrógeno total (N)": "13.0%",
      "Nitrógeno amoniacal (N-NH4)": "2.0%",
      "Nitrógeno nítrico (N-NO3)": "11.0%",
      "Fósforo (P2O5)": "6.0%",
      "Potasio (K2O)": "40.0%",
      "pH en solución (al)": "10%",
    },
    cultivation: [
      "Flores de corte",
      "Fresa",
      "Papa",
      "Arándanos",
      "Banano",
      "Plátano",
      "Mango",
      "Cítricos",
      "Aguacate",
      "Uva/Vid",
      "Tomate",
      "Piña",
      "Cacao",
      "Frutales",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego o uso de Drench.",
    ],
    hierarchy: "Tecnologías Solubles - Compuestos NPK ",
    supplier: "SQM",
    countries: ["Colombia"],
    image: "productsImg/optimized/amilsol-producción.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EQc3ORHPAFVDiK1QSUtrHCgBTp8vhKOfIauwsA2EKl8KPw?e=N4ZB14",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EZL-Ih6GUHVMnb3gb8knQJcBgrCw1-iplqS8eOfjJBaSIw?e=vCIkaC",
  },
  {
    name: "Algaton",
    description:
      "Promotor del crecimiento de la planta a base de algas marinas (Ascophyllum nodosum) y complejo de aminoácidos. Facilita la recuperación de la planta en condiciones de estrés.",
    benefits:
      "Estimula el metabolismo y el desarrollo del cultivo Aumenta la cantidad de energía disponible para el crecimiento Ayuda a la planta a sobrellevar situaciones de estrés Asegura una respuesta nutricional efectiva promoviendo un desarrollo vegetativo vigoroso, una mayor producción de flores y frutos Estimula el crecimiento vegetativo Ayuda a superar las detenciones del crecimiento debido a condiciones de estrés Favorece la absorción de los principios activos asociados Algaton es compatible con la mayoría de los productos fertilizantes, coadyuvantes y plaguicidas usuales, con excepción de funguicidas a base de cobre, aceites minerales y concentrados emulsionables.",
    composition: {
      "Nitrógeno total (N)": "73,0g",
      "Nitrógeno orgánico (N)": "73,0g",
      "Carbono orgánico oxidable (total)": "224,0g",
      "pH en solución (al)": "10%",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Bioestimulantes",
    supplier: "BIOLCHIM",
    countries: ["Colombia"],
    image: "productsImg/optimized/algaton.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EXBUVelWjgtFoPes_1ofiOEBepEJA9aVjNOqRi3cQD4RcA?e=cTXIFb",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/Eao3T2YLN19CpeO5RRoSnigBwoH5c0fDnBUvMO7ZvBME4w?e=ky6xkK",
  },
  {
    name: "Algaton",
    description:
      "Promotor del crecimiento de la planta a base de algas marinas (Ascophyllum nodosum) y complejo de aminoácidos. Facilita la recuperación de la planta en condiciones de estrés.",
    benefits:
      "Estimula el metabolismo y el desarrollo del cultivo Aumenta la cantidad de energía disponible para el crecimiento Ayuda a la planta a sobrellevar situaciones de estrés Asegura una respuesta nutricional efectiva promoviendo un desarrollo vegetativo vigoroso, una mayor producción de flores y frutos Estimula el crecimiento vegetativo Ayuda a superar las detenciones del crecimiento debido a condiciones de estrés Favorece la absorción de los principios activos asociados Algaton es compatible con la mayoría de los productos fertilizantes, coadyuvantes y plaguicidas usuales, con excepción de funguicidas a base de cobre, aceites minerales y concentrados emulsionables.",
    composition: {
      "Nitrógeno total (N)": "73,0g",
      "Nitrógeno orgánico (N)": "73,0g",
      "Carbono orgánico oxidable (total)": "224,0g",
      "pH en solución (al)": "10%",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Bioestimulantes",
    supplier: "BIOLCHIM",
    countries: ["Perú"],
    image: "productsImg/optimized/algaton.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EXBUVelWjgtFoPes_1ofiOEBepEJA9aVjNOqRi3cQD4RcA?e=cTXIFb",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/Eao3T2YLN19CpeO5RRoSnigBwoH5c0fDnBUvMO7ZvBME4w?e=ky6xkK",
  },
  {
    name: "Aminomax",
    description:
      "AMINOMAX N es la solución para aportar aminoácidos de origen vegetal a la planta por vía foliar.",
    benefits:
      "Alto contenido en aminoácidos libres Genera una respuesta inmediata en la reactivación de los cultivos El resultado es especialmente visible en condiciones desfavorables como en el caso de bajas temperaturas, vientos fríos, suelos empobrecidos, etc. La formulación se complementa con nitrógeno mineral que potencia el buen desarrollo de la planta.",
    composition: {
      "Aminoácidos (libres)": "16,0%",
      "Nitrógeno total (N)": "9,0%",
      "Nitrógeno orgánico (N)": "3,3%",
      "Nitrógeno ureico (N-NH2)": "5,4%",
      "p (H)": "3,9%",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Bioestimulantes",
    supplier: "MERISTEM",
    countries: ["Ecuador"],
    image: "productsImg/optimized/aminomax.webp",
  },
  {
    name: "Bulitem CC",
    description:
      "Fertilizante foliar con extractos de algas marinas y efecto bioestimulante; contiene macro y microelementos quelatados en EDTA. Su formulación líquida fácilmente asimilable por el cultivo que estimula el engorde del fruto.",
    benefits:
      "Formulación liquida fácilmente asimilable por el cultivo que estimula el engorde del fruto. Suministra extracto de algas Ascophyllium nodosum y aminoácidos libres Bulitem proporciona un aporte equilibrado de nitrógeno, fosforo y potasio, hierro, manganeso y zinc para la fase de engorde y llenado de frutos. Para asegurar un óptimo desarrollo del fruto, el producto suministra extracto de algas Ascophyllum nodosum y  aminoácidos libres, para asegurar que el fruto disponga de toda la energía necesaria que requiere la fase de engorde.",
    composition: {
      "Extracto de algas Ascophyllium (nodosum)": "12,0%",
      "Aminoácidos (libres)": "6,5%",
      "M (anitol)": "0,6%",
      "Nitrógeno (total)": "7,0%",
      "Nitrógeno orgá (nico)": "2,2%",
      "Nitrógeno (amoniacal)": "1,1%",
      "Nitrógeno (ureico)": "3,7%",
      "Pentóxido de fósforo soluble en agua (P2O5)": "5,0%",
      "Óxido de potasio soluble en agua (K2O)": "5,0%",
      "Hierro soluble en (agua)": "0,5%",
      "Hierro quelatado por (DTPA)": "0,5%",
      "Manganeso soluble en (agua)": "0,5%",
      "Manganeso quelatado por (EDTA)": "0,5%",
      "Zinc soluble en (agua)": "0,5%",
      "Zinc quelatado por (EDTA)": "0,5%",
      "p (H)": "5,2%",
      "Intervalo de pH en el que se garantiza una buena estabilidad de la fracción quelada: pH (entre)":
        "3.5y",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Bioestimulantes",
    supplier: "MERISTEM",
    countries: ["Colombia"],
    image: "productsImg/optimized/bulitem-cc.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EaZURqfXhNtEgTnDgBSYnNABz6MCToC4Izz1Gom3XXwLzA?e=hdgPkO",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EWW4R6gJ3S5MkORItePmpvIBPNFA3mNf1vS3BR30NiZxpA?e=eeyUaa",
  },
  {
    name: "Concorde",
    description:
      "Concorde es un Fertilizante Foliar a base de aminoácidos para diversos cultivos especialmente desarrollado para favorecer la rápida recuperación de las plantas tras situaciones de estrés.",
    benefits:
      "Al aplicar Concorde, la planta retorna rápidamente a sus actividades fisiológicas, manteniendo el crecimiento y permitiendo una mejor productividad. Aumenta el metabolismo de las plantas. Aumenta la velocidad de absorción y translocación de nutrientes dentro de la planta. Rápida recuperación tras las vacaciones de verano. Reduce la fitotoxicidad de los pesticidas agrícolas. Incrementa el cuajado de flores y frutos.",
    composition: {
      "Nitrógeno soluble en agua (N)": "9,0%",
      "Fósforo soluble en agua (P2O5)": "2,0%",
      "Potasio soluble en agua (K2O)": "1,0%",
      "Boro soluble en agua (B)": "0,04%",
      "Cobre soluble en agua (Cu)": "0,1%",
      "Manganeso soluble en agua (Mn)": "0,1%",
      "Molibdeno soluble en agua (Mo)": "0,1%",
      "Zinc soluble en agua (Zn)": "0,1%",
      "Carbono orgánico (total)": "6,0%",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Bioestimulantes",
    supplier: "ICL",
    countries: ["Perú"],
    image: "productsImg/optimized/concorde.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ES9KWUn_-X9CnoM77AlC82sB7BEC1KwqZk-wr4JclZuziQ?e=nRXaK8",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EXjgjoo1ZFVFkbALw7EodlgBVSrECxw9ee_ZHs-Hq92e1w?e=Exz1RK",
  },
  {
    name: "Concorde",
    description:
      "Concorde es un Fertilizante Foliar a base de aminoácidos para diversos cultivos especialmente desarrollado para favorecer la rápida recuperación de las plantas tras situaciones de estrés.",
    benefits:
      "Al aplicar Concorde, la planta retorna rápidamente a sus actividades fisiológicas, manteniendo el crecimiento y permitiendo una mejor productividad. Aumenta el metabolismo de las plantas. Aumenta la velocidad de absorción y translocación de nutrientes dentro de la planta. Rápida recuperación tras las vacaciones de verano. Reduce la fitotoxicidad de los pesticidas agrícolas. Incrementa el cuajado de flores y frutos.",
    composition: {
      "Nitrógeno soluble en agua (N)": "9,0%",
      "Fósforo soluble en agua (P2O5)": "2,0%",
      "Potasio soluble en agua (K2O)": "1,0%",
      "Boro soluble en agua (B)": "0,04%",
      "Cobre soluble en agua (Cu)": "0,1%",
      "Manganeso soluble en agua (Mn)": "0,1%",
      "Molibdeno soluble en agua (Mo)": "0,1%",
      "Zinc soluble en agua (Zn)": "0,1%",
      "Carbono orgánico (total)": "6,0%",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Bioestimulantes",
    supplier: "ICL",
    countries: ["Ecuador"],
    image: "productsImg/optimized/concorde.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ES9KWUn_-X9CnoM77AlC82sB7BEC1KwqZk-wr4JclZuziQ?e=nRXaK8",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EXjgjoo1ZFVFkbALw7EodlgBVSrECxw9ee_ZHs-Hq92e1w?e=Exz1RK",
  },
  {
    name: "Folicist",
    description:
      "Bioestimulante potenciador metabólico de la floración, cuajado, formación de semillas y calidad de frutos. Con efecto antiestrés. ",
    benefits:
      "Bioestimulante potencializador del metabolismo de las plantas recomendado para agricultura convencional y orgánica.  Optimiza y uniformiza la floración, promueve la germinación del polen y el cuajado de frutos, y estimula el metabolismo de la planta durante las fases reproductivas. Por su formulación tiene un alto efecto antiestrés, contribuye al crecimiento vegetativo y productivo de la planta, y a la buena calidad de las cosechas. Sus diferentes componentes aportan varios beneficios a la planta:  •\tÁcido fólico: promueve la síntesis de proteínas y ácidos nucleicos para la división celular. •\tGlicin-betaína: asegura la recuperación frente al estrés metabólico de floración y cuajado. •\tExtractos vegetales: suministran aminoácidos, vitaminas y reguladores del crecimiento. •\tExtractos de algas marinas: suministran reguladores del crecimiento.",
    composition: {
      "Nitrógeno total (N)": "26,7%",
      "Nitrógeno orgánico (N)": "26,7Potasio",
      "Sodio (Na)": "6,3%",
      "Carbono orgánico oxidable (total)": "118%",
      "p (H)": "7,68%",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Bioestimulantes - organicos",
    supplier: "BIOLCHIM",
    countries: ["Colombia"],
    image: "productsImg/optimized/folicist.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EfMG8LxJTRpCqsZEbl2jwG0BqmRZhh1nZz18y9cWyma2ag?e=teFAgA",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ER1H738pkAhCqkRhcRzhRm8B-3rGC8CGUFEbGv3CaTiCKw?e=UPf1d8",
  },
  {
    name: "Folicist",
    description:
      "Bioestimulante potenciador metabólico de la floración, cuajado, formación de semillas y calidad de frutos. Con efecto antiestrés. ",
    benefits:
      "Bioestimulante potencializador del metabolismo de las plantas recomendado para agricultura convencional y orgánica.  Optimiza y uniformiza la floración, promueve la germinación del polen y el cuajado de frutos, y estimula el metabolismo de la planta durante las fases reproductivas. Por su formulación tiene un alto efecto antiestrés, contribuye al crecimiento vegetativo y productivo de la planta, y a la buena calidad de las cosechas. Sus diferentes componentes aportan varios beneficios a la planta:  •\tÁcido fólico: promueve la síntesis de proteínas y ácidos nucleicos para la división celular. •\tGlicin-betaína: asegura la recuperación frente al estrés metabólico de floración y cuajado. •\tExtractos vegetales: suministran aminoácidos, vitaminas y reguladores del crecimiento. •\tExtractos de algas marinas: suministran reguladores del crecimiento.",
    composition: {
      "Nitrógeno total (N)": "26,7%",
      "Nitrógeno orgánico (N)": "26,7Potasio",
      "Sodio (Na)": "6,3%",
      "Carbono orgánico oxidable (total)": "118%",
      "p (H)": "7,68%",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Bioestimulantes - organicos",
    supplier: "BIOLCHIM",
    countries: ["Perú"],
    image: "productsImg/optimized/folicist.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EfMG8LxJTRpCqsZEbl2jwG0BqmRZhh1nZz18y9cWyma2ag?e=teFAgA",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ER1H738pkAhCqkRhcRzhRm8B-3rGC8CGUFEbGv3CaTiCKw?e=UPf1d8",
  },
  {
    name: "Fylloton",
    description:
      "Promotor del crecimiento de la planta a base de aminoácidos de origen vegetal y algas marinas.  Facilita la recuperación de la planta en condiciones de estrés.",
    benefits:
      "Promueve el desarrollo vegetativo y la recuperación de la planta en situaciones de estrés. Fuente de aminoácidos vegetales obtenidos por hidrólisis enzimática, un proceso que mantiene inalterada su estructura y su funcionalidad. Complementado con algas marinas ricas en promotores naturales del crecimiento. Contiene triptófano, precursor natural de las auxinas, además estimula la síntesis de proteínas y promotores del crecimiento vegetal. Favorece el ahorro energético y la actividad metabólica haciendo que las plantas sigan creciendo aún en condiciones de estrés. Potencia la eficacia de ingredientes activos de herbicidas, fitorreguladores, bioestimulante facilitando la penetración en los tejidos y reduciendo los efectos colaterales.",
    composition: {
      "Nitrógeno total (N)": "83g",
      "Nitrógeno orgánico (N)": "83g",
      "Carbono orgánico oxidable (total)": "251g",
      "p (H)": "6,73%",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Bioestimulantes - organicos",
    supplier: "BIOLCHIM",
    countries: ["Perú"],
    image: "productsImg/optimized/fylloton.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EfZoAmkytIRHvivW9Bu17p8B3nCjZa9HE1HcH_Q2rMepxA?e=wpz6OK",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EasTCPUVvmJAo4f34ZGS93cBYtU3GgROQxvVf2FvyFQtSg?e=sY2YLL",
  },
  {
    name: "Fylloton",
    description:
      "Promotor del crecimiento de la planta a base de aminoácidos de origen vegetal y algas marinas.  Facilita la recuperación de la planta en condiciones de estrés.",
    benefits:
      "Promueve el desarrollo vegetativo y la recuperación de la planta en situaciones de estrés. Fuente de aminoácidos vegetales obtenidos por hidrólisis enzimática, un proceso que mantiene inalterada su estructura y su funcionalidad. Complementado con algas marinas ricas en promotores naturales del crecimiento. Contiene triptófano, precursor natural de las auxinas, además estimula la síntesis de proteínas y promotores del crecimiento vegetal. Favorece el ahorro energético y la actividad metabólica haciendo que las plantas sigan creciendo aún en condiciones de estrés. Potencia la eficacia de ingredientes activos de herbicidas, fitorreguladores, bioestimulante facilitando la penetración en los tejidos y reduciendo los efectos colaterales.",
    composition: {
      "Nitrógeno total (N)": "83g",
      "Nitrógeno orgánico (N)": "83g",
      "Carbono orgánico oxidable (total)": "251g",
      "p (H)": "6,73%",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Bioestimulantes - organicos",
    supplier: "BIOLCHIM",
    countries: ["Colombia"],
    image: "productsImg/optimized/fylloton.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EfZoAmkytIRHvivW9Bu17p8B3nCjZa9HE1HcH_Q2rMepxA?e=wpz6OK",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EasTCPUVvmJAo4f34ZGS93cBYtU3GgROQxvVf2FvyFQtSg?e=sY2YLL",
  },
  {
    name: "Manvert Alcafol",
    description:
      " Bioestimulante orgánico completo a base de aminoácidos, azúcares reductores, extracto de algas Ascophyllum nodosum y bioactivadores de origen natural. Proporciona al ciclo del inicio vegetativo una mejor brotación, también mejora la floración y el cuajado y, como consecuencia, mejora el peso y el color del fruto, así como incrementa el contenido en azúcares",
    benefits:
      "El efecto bioestimulante del metabolismo vegetal que induce manera alcafol se debe a la acción combinada de sus componentes: aminoácidos libres altamente asimilables, potenciados por los polisacáridos y bioactivadores naturales.  Manvert alcafol promueve un mayor ritmo de división celular y por ello un crecimiento vegetativo mayor y más regular, un mayor desarrollo y fertilidad de las flores, un calibre de los frutos mayor y más regular, una estimulación de los procesos de engorde y precocidad de los frutos y una mejor respuesta de la planta ante situaciones de estrés.    Manvert alcafol aumenta la síntesis de proteínas, hidratos de carbono y hormonas vegetales, así como la producción de glutamina, factor de resistencia del organismo vegetal a condiciones adversas.     Manvert alcafol ayuda a la recuperación de los cultivos después de periodos de estrés tanto internos como ambientales (sequías, heladas, fitotoxicidades y bloqueo de nutrientes).   Manvert alcafol mejora la asimilación de elementos nutricionales tanto macro como microelementos y estimula las funciones fisiológicas de los cultivos en las épocas de brotación, floración, cuajado y desarrollo de flores, frutos y tubérculos. ",
    composition: {
      "Nitrógeno total (N)": "12%",
      "Nitrógeno orgánico (N)": "12%",
      "Carbono orgánico oxidable (total)": "165%",
      "Aminoácidos libres* (4)": "8%",
      "*Contiene los siguientes aminoácidos: (Alanina)": ",Arginina",
      "pH en solución (al)": "10%",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Bioestimulantes",
    supplier: "MANVERT",
    countries: ["Colombia"],
    image: "productsImg/optimized/manvert-alcafol.webp",
  },
  {
    name: "Manvert Amin 24",
    description:
      "Bioestimulante orgánico con alto contenido en aminoácidos libres (24 % p/p) y péptidos de bajo peso molecular. Diseñado para cultivos que atraviesan épocas criticas como toxicidad y estrés biótico. ",
    benefits:
      "Su aplicación tiene un fuerte efecto de choque, absorbiéndose muy rápida e independientemente de que haya función clorofílica. Se recomienda en todos aquellos cultivos que atraviesen periodos críticos, así como de estrés, sequías, heladas, fitotoxicidades o decaimiento del cultivo, y como estimulante de la vegetación. Mejora la asimilación de elementos nutricionales tanto macro como microelementos.  Favorece el desarrollo radicular cuando se aplica vía riego Mejora el vigor de las plantas y facilita el ingreso de nutrientes.  ",
    composition: {
      "Aminoácidos libres* (26)": "6%",
      "Nitrógeno total (N)": "107%",
      "Nitrógeno amoniacal (N-NH4)": "7,09%",
      "Nitrógeno orgánico (N)": "61,2%",
      "Nitrógeno amínico (N)": "33,1%",
      "Nitrógeno proteico (N)": "28,1%",
      "Nitrógeno ureico (N-NH2)": "38,8%",
      "*Contiene los siguientes aminoácidos: (Aspartato)": ",Glutamato",
      "pH en solución (al)": "10%",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Bioestimulantes",
    supplier: "MANVERT",
    countries: ["Colombia"],
    image: "productsImg/optimized/manvert-amin-24.webp",
  },
  {
    name: "Manvert Amin 24",
    description:
      "Bioestimulante orgánico con alto contenido en aminoácidos libres (24 % p/p) y péptidos de bajo peso molecular. Diseñado para cultivos que atraviesan épocas criticas como toxicidad y estrés biótico. ",
    benefits:
      "Su aplicación tiene un fuerte efecto de choque, absorbiéndose muy rápida e independientemente de que haya función clorofílica. Se recomienda en todos aquellos cultivos que atraviesen periodos críticos, así como de estrés, sequías, heladas, fitotoxicidades o decaimiento del cultivo, y como estimulante de la vegetación. Mejora la asimilación de elementos nutricionales tanto macro como microelementos.  Favorece el desarrollo radicular cuando se aplica vía riego Mejora el vigor de las plantas y facilita el ingreso de nutrientes.  ",
    composition: {
      "Aminoácidos libres* (26)": "6%",
      "Nitrógeno total (N)": "107%",
      "Nitrógeno amoniacal (N-NH4)": "7,09%",
      "Nitrógeno orgánico (N)": "61,2%",
      "Nitrógeno amínico (N)": "33,1%",
      "Nitrógeno proteico (N)": "28,1%",
      "Nitrógeno ureico (N-NH2)": "38,8%",
      "*Contiene los siguientes aminoácidos: (Aspartato)": ",Glutamato",
      "pH en solución (al)": "10%",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Bioestimulantes",
    supplier: "MANVERT",
    countries: ["Panamá"],
    image: "productsImg/optimized/manvert-amin-24.webp",
  },
  {
    name: "Manvert Miconic",
    description:
      "Producto estimulante activador de los sistemas metabólicos de las plantas",
    benefits:
      "Da vigor a los cultivos, mejorando su respuesta al estrés abiótico Mejora la cicatrización del corte/herida de la hoja del cultivo Aumenta la producción Cicatrizante y antioxidante",
    composition: {
      "Nitrógeno total (N)": "5,90%",
      "Ácidos orgá (nicos)": "45,0%",
      "Aminoácidos (libres)": "6,10%",
      "p (H)": "7%",
    },
    cultivation: [
      "Banano",
      "Berries",
      "Café",
      "Cítricos",
      "Extensivos",
      "Flores",
      "Frutas",
      "Frutos secos",
      "Hortalizas",
      "Olivo",
      "Patata",
      "Uva",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Bioestimulantes",
    supplier: "MANVERT",
    countries: ["Colombia"],
    image: "productsImg/optimized/manvert-miconic.webp",
  },
  {
    name: "Manvert Miconic",
    description:
      "Producto estimulante activador de los sistemas metabólicos de las plantas",
    benefits:
      "Da vigor a los cultivos, mejorando su respuesta al estrés abiótico Mejora la cicatrización del corte/herida de la hoja del cultivo Aumenta la producción Cicatrizante y antioxidante",
    composition: {
      "Nitrógeno total (N)": "5,90%",
      "Ácidos orgá (nicos)": "45,0%",
      "Aminoácidos (libres)": "6,10%",
      "p (H)": "7%",
    },
    cultivation: [
      "Banano",
      "Berries",
      "Café",
      "Cítricos",
      "Extensivos",
      "Flores",
      "Frutas",
      "Frutos secos",
      "Hortalizas",
      "Olivo",
      "Patata",
      "Uva",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Bioestimulantes",
    supplier: "MANVERT",
    countries: ["Panamá"],
    image: "productsImg/optimized/manvert-miconic.webp",
  },
  {
    name: "Manvert Movili-Ca",
    description:
      "Manvert movili-Ca es una mezcla sólida que contiene calcio complejado, aminoácidos libres y PACaR (Promotor de Absorción de Calcio Radicular)",
    benefits:
      "PACaR es un movilizador del calcio desde la raíz al resto de la planta Metabolizador y sinergizador de las funciones del Ca en las células de la planta Con manera movili-Ca se consigue mayor contenido de calcio activo Disminución de frutos con cracking Disminución de alteración de daños en piel Mejora la calidad de los frutos, aumentando su dureza Reducción de fisiopatías de calcio Mayor vida post-cosecha ",
    composition: {
      "Óxido de calcio soluble en agua y complejado (CaO)": "18,0%",
      "Aminoácidos (libres)": "2,0%",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Bioestimulantes",
    supplier: "MANVERT",
    countries: ["Colombia"],
    image: "productsImg/optimized/manvert-movili-ca.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EZfUpzcGUxpGidAmDrbHnOMBEltbuu9GL2rj5Bd7o-KF7w?e=0m6glN",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/Ea-7HbJHiCJNkd3fNBcjoMUBV_QIP8sgojRDBORjoJxgRA?e=GpSrQw",
  },
  {
    name: "Manvert Ocean",
    description:
      "Manvert Ocean es un bioestimulante orgánico basado en algas Ascophyllum nodosum, rico en micronutrientes que promueven el enraizamiento y el desarrollo del fruto. ",
    benefits:
      "Manvert ocean es un bioestimulante a base de algas (Ascophyllum nodosum) rico en micronutrientes que favorecen el enraizamiento, así como el crecimiento y tamaño del fruto. Gracias a la tecnología seavert tech obtenemos una mayor concentración de los principios activos de la alga Ascophyllum nodosum, potenciando así el poder bioestimulante de dicho extracto de algas. Certificado Orgánico por la CAAE, según la normativa de insumos UE.",
    composition: {
      "Extracto de algas (Ascophyllum (nodosum)": "14%",
      "Manganeso (Mn)**** (1)": ",2%",
      "Zinc (Zn)**** (1)": ",2%",
      "Materia orgá (nica)": "10,5%",
      "Ácido algí (nico)": "5,1%",
      "M (anitol)": "1,1%",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Bioestimulantes",
    supplier: "MANVERT",
    countries: ["Bolivia"],
    image: "productsImg/optimized/manvert-ocean.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EYZR1gxZ0ElArEC_3IWzpWkBRbkPI870GC7QNU9aWvWylw?e=oVEREG",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ERdAeT3Vk3ZLhGd5lpCnUVoBxQOvtih4sziuWWpgg5yO-w?e=XaTFwT",
  },
  // {
  //   name: "Manvert Stimulante Plus",
  //   description:
  //     "Es un bioestimulante orgánico con alto contenido de aminoácidos libres y péptidos de bajo peso molecular. ",
  //   benefits:
  //     "Manvert estimulante plus es un bioestimulante orgánico con alto contenido en aminoácidos libres y péptidos de bajo peso molecular. Su aplicación tiene un fuerte efecto de choque. Se recomienda en todos aquellos cultivos que atraviesen periodos críticos, así como de estrés, sequías, heladas, fitotoxicidades o decaimiento del cultivo, y como estimulante de la vegetación.",
  //   composition: {
  //     "Aminoácidos (libres)": "29,2%",
  //     "Aminoácidos (Totales)": "48,8%",
  //     "Nitrógeno total (N)": "8,1%",
  //   },
  //   cultivation: [
  //     "Papa",
  //     "Tomate",
  //     "Banano",
  //     "Plátano",
  //     "Café",
  //     "Palma",
  //     "Flores de corte",
  //     "Caña",
  //     "Piña",
  //     "Aguacate",
  //     "Mango",
  //     "Arándanos",
  //     "Cítricos",
  //     "Vid/Uva",
  //     "Espárragos",
  //     "Cacao",
  //     "Hortalizas",
  //     "Frutales",
  //     "Plantas aromáticas",
  //     "Semestrales",
  //     "Pasturas",
  //     "Perennes",
  //     "Viveros.",
  //   ],
  //   hierarchy: "Nutrición / Estimulación Foliar - Bioestimulantes",
  //   supplier: "MANVERT",
  //   countries: ["Colombia"],
  //   image: "productsImg/optimized/manvert-stimulante-plus.webp",
  // },
  {
    name: "Manvert Stimulante Plus",
    description:
      "Es un bioestimulante orgánico con alto contenido de aminoácidos libres y péptidos de bajo peso molecular. ",
    benefits:
      "Manvert estimulante plus es un bioestimulante orgánico con alto contenido en aminoácidos libres y péptidos de bajo peso molecular. Su aplicación tiene un fuerte efecto de choque. Se recomienda en todos aquellos cultivos que atraviesen periodos críticos, así como de estrés, sequías, heladas, fitotoxicidades o decaimiento del cultivo, y como estimulante de la vegetación.",
    composition: {
      "Aminoácidos (libres)": "29,2%",
      "Aminoácidos (Totales)": "48,8%",
      "Nitrógeno total (N)": "8,1%",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Bioestimulantes",
    supplier: "MANVERT",
    countries: ["Bolivia"],
    image: "productsImg/optimized/manvert-stimulante-plus.webp",
  },
  {
    name: "Meristem Raíz",
    description:
      "Fertilizante orgánico mineral con algas marinas y una formula balaceada de micronutrientes quelatados por DTPA o EDTA.  ",
    benefits:
      "Bioestimulante con extracto de algas marinas y adición de macronutrientes y micronutrientes quelatados en EDTA, para mejorar la nutrición de las plantas y favorecer los procesos de enraizamiento. Se enfoca en la nutrición y estimulo de las raíces, que son fundamentales para el crecimiento vigoroso de la planta, sus ingredientes están diseñados para promover la proliferación de raíces, mejorar la absorción de nutrientes y fortalecer el sistema radicular. Se caracteriza por inducir un fuerte desarrollo de todo el sistema radicular de la planta, lo que favorecerá un aumento del vigor del sistema aéreo produciendo un incremento de la producción y calidad de la cosecha.  Ayuda en la recuperación de aquellos cultivos que hayan estado sometidos a circunstancia adversas tales como heladas, sequias, tratamientos fitosanitarios.  Actúa como vigorizante de la fase de enraizamiento.                             ",
    composition: {
      "Nitrógeno total (N)": "15.0g",
      "Hierro soluble en agua (Fe) (DTPA)": "11.6g",
      "Manganeso soluble en agua (Mn)": "6.0g",
      "Zinc soluble en agua (Zn) (EDTA)": "6.0g",
      "Carbono orgánico oxidable (total)": "82.0g",
      "pH en solución (al)": "10%",
      "Intervalo de pH en el que se garantiza una buena estabilidad de la fracción quelatada: pH (entre)":
        "3.5y",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Flores de corte",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Bioestimulantes",
    supplier: "MERISTEM",
    countries: ["Colombia"],
    image: "productsImg/optimized/meristem-raíz.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ESCfqz19GRVPnxP6Qo6f_j8BpyvGkweKDg4wTUsmiF26iw?e=RKCZav",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/Ea6jnSqhFTpJj2dQ03bFADQB6k2axSZVLGEsGXLZpxSrhg?e=emF08c",
  },
  {
    name: "Nov@",
    description:
      "Fertilizante orgánico nitrogenado extracto fluido de levadura contiene algas marrones",
    benefits:
      "Favorece el desarrollo y la mejor funcionalidad radicular. Promueve el crecimiento vegetativo equilibrado de las plantas. Mejora el tamaño y la uniformidad de los frutos.",
    composition: {
      "Nitrógeno orgá (nico)": "1,0%",
      "Carbono orgánico de origen bioló (gico)": "10,0%",
      "pH del (producto)": "5,7%",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Flores de corte",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Bioestimulantes",
    supplier: "BIOLCHIM",
    countries: ["Perú"],
    image: "productsImg/optimized/nov@.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EUBZlBy8_6xDl2d1zvCgCyQBxusDeMrRkStJFLW0NX96-A?e=8aEF6p",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EV9uwrowg6lKiO8uXmLZHnkBvFmsxHSZ-pLkFfyeveskbw?e=BdZMPv",
  },
  {
    name: "Sprintalga",
    description:
      "Fertilizante de efecto bioestimulante formulado a base de la mezcla de extracto de algas Macrosystis, Ascophyllium y Sargassum más un complejo de aminoácidos que actúan como biopromotores del desarrollo y crecimiento de la raíz. ",
    benefits:
      "Estimula el desarrollo de la raíz en particular el transporte Mejora la germinación de la semilla Puede mezclarse con todas las formulaciones comunes excepto con productos a base de Cobre. Contiene extractos altamente concentrados de diversas especies de algas y enriquecidos con aminoácidos Suministra grandes cantidades de compuestos con alto valor biológico y Nitrógeno rápidamente disponible de manera que sea posible estimular el metabolismo primario y equilibrar el balance hormonal en el sistema radicular Acelera la formación y la elongación de las nuevas raíces ",
    composition: {
      "Nitrógeno total (N)": "144.0g",
      "Nitrógeno orgánico (N)": "40.8g",
      "Nitrógeno ureico (N-NH2)": "103.2g",
      "Sodio (Na)": "4.3g",
      "Carbono orgánico oxidable (total)": "112.0g",
      "pH en solución (al)": "10%",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Flores de corte",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Bioestimulantes",
    supplier: "BIOLCHIM",
    countries: ["Colombia"],
    image: "productsImg/optimized/sprintalga.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EW9grxBwwDxIuwaEYjeblPMBkjAUEvkM2UCmSmiNU2JM4w?e=gslhqa",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EWmTamf5-htFm_tV-sHKn2QBPxORXrWWwfoPT9gEQM-png?e=1IlbAe",
  },
  {
    name: "Sprintalga",
    description:
      "Fertilizante de efecto bioestimulante formulado a base de la mezcla de extracto de algas Macrosystis, Ascophyllium y Sargassum más un complejo de aminoácidos que actúan como biopromotores del desarrollo y crecimiento de la raíz. ",
    benefits:
      "Estimula el desarrollo de la raíz en particular el transporte Mejora la germinación de la semilla Puede mezclarse con todas las formulaciones comunes excepto con productos a base de Cobre. Contiene extractos altamente concentrados de diversas especies de algas y enriquecidos con aminoácidos Suministra grandes cantidades de compuestos con alto valor biológico y Nitrógeno rápidamente disponible de manera que sea posible estimular el metabolismo primario y equilibrar el balance hormonal en el sistema radicular Acelera la formación y la elongación de las nuevas raíces ",
    composition: {
      "Nitrógeno total (N)": "144.0g",
      "Nitrógeno orgánico (N)": "40.8g",
      "Nitrógeno ureico (N-NH2)": "103.2g",
      "Sodio (Na)": "4.3g",
      "Carbono orgánico oxidable (total)": "112.0g",
      "pH en solución (al)": "10%",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Flores de corte",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Bioestimulantes",
    supplier: "BIOLCHIM",
    countries: ["Perú"],
    image: "productsImg/optimized/sprintalga.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EW9grxBwwDxIuwaEYjeblPMBkjAUEvkM2UCmSmiNU2JM4w?e=gslhqa",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EWmTamf5-htFm_tV-sHKn2QBPxORXrWWwfoPT9gEQM-png?e=1IlbAe",
  },
  {
    name: "Fertileaf Algafos",
    description:
      "Fertilizante orgánico-mineral con algas marinas para aplicación al suelo mediante sistemas de fertiirrigación, es un fertilizante formulado como suspensión concentrada de nutrientes, con base de elementos primarios, secundarios y micronutrientes. ",
    benefits:
      "Fertilizante formulado como suspensión concentrada de nutrientes Alivia los efectos negativos y mejora la recuperación de la planta ante condiciones de estrés Promueve el desarrollo vegetativo  Su aporte de micronutrientes ayuda a la planta en procesos enzimáticos, hormonales y de síntesis de biocompuestos necesarios para un óptimo crecimiento y desarrollo Su componente orgánico favorece el ingreso de nutrientes a la planta y los procesos metabólicos ",
    composition: {
      "Nitrógeno total (N)": "87,50g",
      "Nitrógeno amoniacal (N)": "4,0g",
      "Nitrógeno orgánico (N)": "17,1g",
      "Nitrógeno ureico (N)": "66,4g",
      "Fósforo soluble en agua (P2O5)": "301,00g",
      "Azufre soluble en agua (S)": "2,47g",
      "Manganeso soluble en agua (Mn)": "1,45g",
      "Molibdeno soluble en agua (Mo)": "0,07g",
      "Sodio soluble en agua (Na)": "3,81g",
      "Carbono orgánico oxidable (total)": "30,40g",
      "pH en solución (al)": "10%",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Nutrientes",
    supplier: "DISAN",
    countries: ["Colombia"],
    image: "productsImg/optimized/fertileaf-algafos.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ETPGRmwQg4tItRVPM_ay-0QBkV51qMbGdX-_e5U44K7_TQ?e=wsHktG",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EZ1c_fLjsQdJmFmYlzxAJ-wBrEWKcfrwXRtsVPVtRqhvKQ?e=uL0bHj",
  },
  {
    name: "Fertileaf Algas PK",
    description:
      "Fertilizante rico en fósforo y potasio complementado con compuestos orgánicos para etapas de floración, cuajado y crecimiento de frutos.",
    benefits:
      "El balance nutricional que favorece la floración, el cuajado y crecimiento frutos.  Su combinación de nutrientes y compuestos orgánicos fomenta un desarrollo vigoroso de la planta, mayor producción y cosechas de mejor calidad. Producto diseñado para etapas de máximo requerimiento de energía como generación de brotes, crecimiento de frutos sin alto aporte de nitrógeno.  Favorece procesos de diferenciación como la  floración y cuajado de frutos. Favorece la formación de frutos, la división celular y el trasporte de asimilados para cosechas más abundantes.  Su componente orgánico reduce los efectos adversos de condiciones de estrés y  la mejora producción de azucares. ",
    composition: {
      "Nitrógeno total (N)": "46,40%",
      "Fósforo soluble en agua (P2O5)": "110,00%",
      "Potasio soluble en agua (K2O)": "70,90%",
      "Azufre soluble en agua (S)": "5,77%",
      "Cobre soluble en agua (Cu)": "1,00%",
      "Hierro soluble en agua (Fe)": "0,55%",
      "Manganeso soluble en agua (Mn)": "1,00%",
      "Molibdeno soluble en agua (Mo)": "0,07%",
      "Zinc soluble en agua (Zn)": "7,00%",
      "pH en solución (al)": "10%",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Nutrientes",
    supplier: "DISAN",
    countries: ["Colombia"],
    image: "productsImg/optimized/fertileaf-algas-pk.webp",
  },
  {
    name: "Fertileaf Algastress N",
    description:
      "Fertilizante orgánico-mineral para aplicación al suelo mediante sistemas de fertiirrigación, es un fertilizante formulado como concentrado soluble, con base de elementos mayores, secundarios y micronutrientes.",
    benefits:
      "Contiene la tecnología Fertileaf NutriQuel que favorece la toma de nutrientes en la planta Incrementa la dispersión y humectación en la hoja Cultivos más verdes, mejor desarrollados y con más vigor Recuperador de crecimiento en condiciones de estrés  Mayor producción de tallos, macollos y estolones Acelera el crecimiento en etapas vegetativas Favorece la síntesis de clorofila y fotosíntesis  Ideal para acelerar el crecimiento luego de poda o cosecha",
    composition: {
      "Nitrógeno total (N)": "278,6g",
      "Nitrógeno nítrico (N)": "44,0g",
      "Nitrógeno ureico (N)": "234,6g",
      "Magnesio soluble en agua (MgO)": "60,00g",
      "Azufre soluble en agua (S)": "7,66g",
      "Cobre soluble en agua (Cu)": "0,21g",
      "Sodio soluble en agua (Na)": "5,10g",
      "Zinc soluble en agua (Zn)": "15,00g",
      "pH en solución (al)": "10%",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Nutrientes",
    supplier: "DISAN",
    countries: ["Colombia"],
    image: "productsImg/optimized/fertileaf-algastress-n.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EflMgwHhDN9PvI5DHAk0ryoBcaXSQbaIaqKkLeDwKLGlsg?e=HHAcnD",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EQqptqmiJLVFtgPaxpZA3R4Be6QKZwlbTgSFyw0C2DSvKQ?e=SejNyT",
  },
  {
    name: "Fertileaf Calcio BoZinc",
    description:
      "Abono foliar con alta concentración de Calcio, Boro y Zinc para mejorar el amarre, cuajado, calidad y firmeza de flores y frutos ",
    benefits:
      "Fertilizante foliar líquido, combinado con calcio, Boro, Zinc Induce el crecimiento radicular, floración y amarre de flor y fruto  Aumenta el peso y calibre del fruto Mayor vida post cosecha Evita la caída prematura de flores y frutos  Otorga mayor resistencia a la planta en condiciones de estrés  Mayor productividad  Aporta firmeza al fruto evitando el rajado Prevenir la mezcla con agentes fuertemente ácidos y oxidantes. No mezclar con productos que contengan Azufre y/o Fósforo por el riesgo de precipitación de sales insolubles de Calcio",
    composition: {
      "Calcio (Ca)": "12,0%",
      "Boro (B)": "1,0%",
      "Zinc (Zn)": "1,0%",
    },
    cultivation: [
      "Esparrago",
      "Arveja",
      "Frijol",
      "Sandia",
      "Tomate",
      "Pallar",
      "Pepino",
      "Pimiento",
      "Paprika",
      "Papa",
      "Cebolla",
      "Coliflor",
      "Ajo",
      "Alcachofa",
      "Camote",
      "Brócoli",
      "Zanahoria",
      "Col",
      "Mandarina",
      "Naranja",
      "Durazno",
      "Pera",
      "Mango",
      "Manzana",
      "Vid",
      "Limonera.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Nutrientes",
    supplier: "DISAN",
    countries: ["Colombia"],
    image: "productsImg/optimized/fertileaf-calcio-bozinc.webp",
  },
  {
    name: "Fertileaf Calcio-Boro-Zinc",
    description:
      "Abono Foliar de uso agrícola con alta concentración de Calcio Boro, Zinc",
    benefits:
      "La combinación de calcio, boro y zinc induce el crecimiento radicular, la floración y el amarre de flor y fruto de la planta. Aumenta el peso, calibre y vida post cosecha de los cultivos. Evita la caída prematura de flores y frutas y otorga mayor resistencia a la planta ante condiciones fitopatógenas adversas Logra producciones de alto valor comercial y mayor vida post cosecha.",
    composition: {
      "Calcio (CaO)": "12,0%",
      "Boro (B)": "1,0%",
      "Zinc (Zn)": "1,0%",
      "pH                                                            < (4)":
        ",0%",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Nutrientes",
    supplier: "DISAN",
    countries: ["Perú"],
    image: "productsImg/optimized/FERTILEAF CALCIO BORO ZINC c p.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ERBJ7kvPeZFBvh9FMOdthYMBpqBRiZQAmgYdlLLMuB9ojg?e=K7PWzf",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EcF77csm1hFKhetuP8VnxzkB7OdExwgxY7uyRAh1FM1Apg?e=YjI2O5",
  },
  {
    name: "Fertileaf Calcio-Boro-Zinc",
    description:
      "Abono Foliar de uso agrícola con alta concentración de Calcio Boro, Zinc",
    benefits:
      "La combinación de calcio, boro y zinc induce el crecimiento radicular, la floración y el amarre de flor y fruto de la planta. Aumenta el peso, calibre y vida post cosecha de los cultivos. Evita la caída prematura de flores y frutas y otorga mayor resistencia a la planta ante condiciones fitopatógenas adversas Logra producciones de alto valor comercial y mayor vida post cosecha.",
    composition: {
      "Calcio (CaO)": "12,0%",
      "Boro (B)": "1,0%",
      "Zinc (Zn)": "1,0%",
      "pH                                                            < (4)":
        ",0%",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Nutrientes",
    supplier: "DISAN",
    countries: ["Bolivia"],
    image: "productsImg/optimized/Fertileaf Calcio-Boro-Zinc BOL.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ERBJ7kvPeZFBvh9FMOdthYMBpqBRiZQAmgYdlLLMuB9ojg?e=K7PWzf",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EcF77csm1hFKhetuP8VnxzkB7OdExwgxY7uyRAh1FM1Apg?e=YjI2O5",
  },
  {
    name: "Fertileaf Fósforo",
    description:
      "FERTILEAF FÓSFORO 5-40-5 es un fertilizante liquido con alto contenido de fósforo, 100% asimilable por las plantas.",
    benefits:
      "Aporta aminoácidos libres Contiene elementos mayores y menores, los que se encuentran quelatados orgánicamente, permitiendo una rápida y total asimilación de los mismos Puede ser aplicado foliarmente o vía sistema de riego tecnificado. El porcentaje de magnesio en su fórmula, favorece la absorción y utilización del fósforo FERTILEAF FÓSFORO 5-40-5 es de vital importancia para las plantas ya que participa en todos los procesos de enraizamiento, crecimiento y fructificación. Es fundamental en la fotosíntesis, respiración y división celular, por lo que se recomienda aplicarlo vía foliar en los momentos críticos de las plantas Aporta una adecuada nutrición de acuerdo al desarrollo del cultivo.",
    composition: {
      "Nitrógeno total (N)": "5,0%",
      "Fósforo asimilable (P2O5)": "40.0%",
      "Potasio soluble en agua (K2O)": "5,0%",
      "Magnesio (Mg)": "0,5%",
      "Hierro (Fe)": "0,5%",
      "Zinc (Zn)": "0,05%",
      "Cobre (Cu)": "0,02%",
      "Aminoácidos (totales)": "1,00%",
      "pH                                                                                  < (2)":
        ",5%",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Nutrientes",
    supplier: "DISAN",
    countries: ["Perú"],
    image: "productsImg/optimized/fertileaf-fósforo.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EZtGVLbd5SJBvvgfNgLB9AcBu73lS_DwsRCaJy8AQuuhHQ?e=SHqrm9",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EcEfxsMNMuhKls-O7FW28q8Bwd5rSDZ8HCdQedESmZDduQ?e=bnCWdw",
  },
  {
    name: "Fertileaf Fósforo",
    description:
      "FERTILEAF FÓSFORO 5-40-5 es un fertilizante liquido con alto contenido de fósforo, 100% asimilable por las plantas.",
    benefits:
      "Aporta aminoácidos libres Contiene elementos mayores y menores, los que se encuentran quelatados orgánicamente, permitiendo una rápida y total asimilación de los mismos Puede ser aplicado foliarmente o vía sistema de riego tecnificado. El porcentaje de magnesio en su fórmula, favorece la absorción y utilización del fósforo FERTILEAF FÓSFORO 5-40-5 es de vital importancia para las plantas ya que participa en todos los procesos de enraizamiento, crecimiento y fructificación. Es fundamental en la fotosíntesis, respiración y división celular, por lo que se recomienda aplicarlo vía foliar en los momentos críticos de las plantas Aporta una adecuada nutrición de acuerdo al desarrollo del cultivo.",
    composition: {
      "Nitrógeno total (N)": "5,0%",
      "Fósforo asimilable (P2O5)": "40.0%",
      "Potasio soluble en agua (K2O)": "5,0%",
      "Magnesio (Mg)": "0,5%",
      "Hierro (Fe)": "0,5%",
      "Zinc (Zn)": "0,05%",
      "Cobre (Cu)": "0,02%",
      "Aminoácidos (totales)": "1,00%",
      "pH                                                                                  < (2)":
        ",5%",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Nutrientes",
    supplier: "DISAN",
    countries: ["Bolivia"],
    image: "productsImg/optimized/fertileaf-fósforo.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EZtGVLbd5SJBvvgfNgLB9AcBu73lS_DwsRCaJy8AQuuhHQ?e=SHqrm9",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EcEfxsMNMuhKls-O7FW28q8Bwd5rSDZ8HCdQedESmZDduQ?e=bnCWdw",
  },
  {
    name: "Fertileaf Potasio",
    description:
      "FERTILEAF POTASIO con alto contenido de potasio en mezcla de aminoácidos libres, lo que permite una rápida y fácil absorción de nutrientes.",
    benefits:
      "Debido al contenido de citrato en su formulación es una fuente de energía para las plantas. Permite el ahorro de energía para ser aprovechada en la formación de flores, frutos, vainas, tubérculos, etc. Incrementa el rendimiento. Ayuda a las plantas a compensar condiciones climáticas desfavorables ( heladas, sequías). Incrementa la resistencia a plagas y enfermedades Estimula el desarrollo de frutos, logrando mayor tamaño y peso. Incrementa el contenido de azúcares en frutas y productos cosechados. No causa ni retrasa la maduración de frutos, ya que no contiene nitrógenos sulfatos, cloruro y/o sodio.",
    composition: {
      "Fósforo asimilable (P2O5)": "0,5%",
      "Potasio soluble en agua (K2O)": "38,0%",
      "Boro (B)": "0,02%",
      "Extractos hú (micos)": "0,05%",
      "Aminoácidos (totales)": "0,50%",
      "pH                                                                                                    < (1)":
        "2%",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Nutrientes",
    supplier: "DISAN",
    countries: ["Perú"],
    image: "productsImg/optimized/fertileaf-potasio.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EROWfzfYz4NKrztbccTobcsB_LH4j-z3ZA0GO77F4wswQg?e=Az23sc",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EXuPCm_rlFhLkJghMpHS-RYBy5JkOmGg1xTYHO_IvMK1oA?e=WfOeex",
  },
  {
    name: "Fertileaf Potasio",
    description:
      "FERTILEAF POTASIO con alto contenido de potasio en mezcla de aminoácidos libres, lo que permite una rápida y fácil absorción de nutrientes.",
    benefits:
      "Debido al contenido de citrato en su formulación es una fuente de energía para las plantas. Permite el ahorro de energía para ser aprovechada en la formación de flores, frutos, vainas, tubérculos, etc. Incrementa el rendimiento. Ayuda a las plantas a compensar condiciones climáticas desfavorables ( heladas, sequías). Incrementa la resistencia a plagas y enfermedades Estimula el desarrollo de frutos, logrando mayor tamaño y peso. Incrementa el contenido de azúcares en frutas y productos cosechados. No causa ni retrasa la maduración de frutos, ya que no contiene nitrógenos sulfatos, cloruro y/o sodio.",
    composition: {
      "Fósforo asimilable (P2O5)": "0,5%",
      "Potasio soluble en agua (K2O)": "38,0%",
      "Boro (B)": "0,02%",
      "Extractos hú (micos)": "0,05%",
      "Aminoácidos (totales)": "0,50%",
      "pH                                                                                                    < (1)":
        "2%",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Nutrientes",
    supplier: "DISAN",
    countries: ["Bolivia"],
    image: "productsImg/optimized/fertileaf-potasio.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EROWfzfYz4NKrztbccTobcsB_LH4j-z3ZA0GO77F4wswQg?e=Az23sc",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EXuPCm_rlFhLkJghMpHS-RYBy5JkOmGg1xTYHO_IvMK1oA?e=WfOeex",
  },
  {
    name: "K-Bomber 56",
    description:
      "Fuente de Potasio altamente asimilable y en alta concentración",
    benefits:
      "Mejora la calidad de las frutas Regula el crecimiento vegetativo Incrementa el transporte de fotoasimilados a los órganos de reserva",
    composition: {
      "Anhídrido fosfórico (P2O)": "5%",
      "Óxido de potasio soluble en agua (K2O)": "56,0%",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Nutrientes",
    supplier: "BIOLCHIM",
    countries: ["Perú"],
    image: "productsImg/optimized/k-bomber-56.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EYsbRNrEepJKrsgkzWZqlpEBUe2he4Ad3qFu077saNd9aw?e=3DaqCl",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EZqxM8k4hiFDk2kGFP3w4RYBa1B73yeX0a7-MU8s48JJeA?e=DxSgDs",
  },
  {
    name: "Manvert potasio",
    description:
      "Solución liquida con elevado contenido en potasio acondicionado con ácidos orgánicos para optimizar su asimilación. ",
    benefits:
      "Especialmente formulada para mejorar color, peso, tamaño y contenido de azucares de los frutos. Manvert Potasio es imprescindible durante el periodo de desarrollo del fruto en todos los cultivos, ya que por su formulación de alta concentración el potasio acodiciado para su total asimilación actúa directamente sobre los factores de calidad del fruto sin provocar estimulación vegetativa. También aumenta la resistencia de las plantas a la sequía.  Puede aplicarse a toda clase de cultivos tanto vía foliar como a través de todos los sistemas de fertiirrigación, es compatible con la mayoría de los productos pesticidas utilizados habitualmente, excepto los de reacción alcalina o aceites minerales. ",
    composition: {
      "Potasio soluble en agua (K)": "2O",
      "pH en solución (al)": "10%",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Nutrientes",
    supplier: "MANVERT",
    countries: ["Colombia"],
    image: "productsImg/optimized/manvert-potasio.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EYeKFpoxrnRGkNIcGMoBdk0B9DUU2iWZG8PxW8KeiXxTMQ?e=x9EREZ",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EbFSuV1OcgpGlRJQNuWu1ysBRyKgZQvUeveC38nWTyibYQ?e=HHDphg",
  },
  {
    name: "Molibor",
    description:
      "Fertilizante Foliar con alto contenido de Molibdeno y Boro, ideal para el periodo de polinización y en máxima absorción de Nitrógeno.",
    benefits:
      "Debido a su composición estimula la aparición precoz de polen y mejora su calidad, obteniendo así una mayor cantidad de flores de mayor calidad y con mayor viabilidad. Las flores resultan más atractivas para los polinizadores favoreciendo el establecimiento de la fauna auxiliar y su permanencia a lo largo del cultivo. La aplicación de MOLIBOR reduce la abscisión y mejora por consiguiente el proceso de cuajado. Combina el Molibdeno y el Boro, por lo que su aplicación permite corregir las carencias de estos elementos. La forma en la que se presenta el Boro es de lenta liberación, lo que significa una disminución del peligro de fitotoxicidad y un aporte escalonado a lo largo del cultivo. El molibdeno participa en el metabolismo y balance del Nitrógeno mediante la activación de la enzima nitrato reductasa. MOLIBOR es apto para su aplicación vía fertirriego o vía foliar. ",
    composition: {
      "Boro soluble en agua (B)": "8.0%",
      "Molibdeno soluble en agua (Mo)": "10.0%",
      "p (H)": "7,8%",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Nutrientes",
    supplier: "MERISTEM",
    countries: ["Colombia"],
    image: "productsImg/optimized/molibor.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EX43rAYicb5Akcz1XEokkK0Bu_5wmR3KvR3SdMMq2WJU2w?e=zmjibn",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EWAbQ4W2CNxHqu1_nzvGxDwBI6o-S6EcdMJ_Oreh8VV8pw?e=VPnZqv",
  },
  {
    name: "Tonus",
    description:
      "Tonus es un fertilizante que contiene macro y micronutrientes solubles en agua, destinado a aplicación foliar.",
    benefits:
      "Estimula y mantiene el eficiente proceso de Fijación Biológica de Nitrógeno (BNF) hasta el final del ciclo del cultivo. Mejorar la fijación y el metabolismo del Nitrógeno en la planta Proporciona la formación continua de nódulos jóvenes y fuertes, mediante la modulación del equilibrio hormonal. Suplementación nutricional de macro y micronutrientes Hace que la fijación de nitrógeno sea mucho más eficiente, proporcionando más energía para que la planta crezca. Rápida transformación del nitrógeno aportado por los nódulos en proteínas y granos más pesados. Mantiene el suministro de energía a los nodos. Aporta elementos esenciales para mantener fisiológicamente activos los nódulos. Reduce la formación de etileno en la planta, previniendo la muerte prematura de los nódulos.",
    composition: {
      "Fósforo soluble en agua (P2O5)": "6,0%",
      "Potasio soluble en agua (K2O)": "4,0%",
      "Magnesio soluble en agua (Mg)": "1,0%",
      "Azufre soluble en agua (S)": "1,3%",
      "Cobalto soluble en agua (Co)": "0,5%",
      "Molibdeno soluble en agua (Mo)": "10,0%",
      "Níquel soluble en agua (Ni)": "1,5%",
      "Zinc soluble en agua (Zn)": "6,0%",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Nutrientes",
    supplier: "ICL",
    countries: ["Perú"],
    image: "productsImg/optimized/tonus.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ERUxdgIsIfhLlQde8PHrE3UBMk6V7_mSiA4XigalGCRZPg?e=PosTYb",
    hojaS: "Hoja de seguridad Tonus - ICL",
  },
  {
    name: "Translok",
    description:
      "Translok, es un fertilizante foliar con tecnología exclusiva para ayudar a la planta en el proceso de llenado de frutos y granos, proceso decisivo en la productividad del cultivo.",
    benefits:
      "Translok contiene una combinación equilibrada de macro y micronutrientes. Ayuda a promover el crecimiento saludable de las plantas y mejorar la resistencia a las enfermedades y el estrés ambiental. Aporta un equilibrio de nutrientes específicos para la fase de llenado, favoreciendo un mayor peso de granos y frutas. Reduce el estrés, asegurando mucha más energía para la planta. Mantiene la planta con una mayor tasa fotosintética, generando granos y frutos mejor formados.",
    composition: {
      "Nitrógeno soluble en agua (N)": "5,0%",
      "Fósforo soluble en agua (P2O5)": "10,0%",
      "Potasio soluble en agua (K2O)": "20,0%",
      "Magnesio soluble en agua (Mg)": "9,0%",
      "Azufre soluble en agua (S)": "11,7%",
      "Boro soluble en agua (B)": "0,5%",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Flores de corte",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Semestrales",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales Pasturas",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Nutrientes",
    supplier: "ICL",
    countries: ["Perú"],
    image: "productsImg/optimized/translok.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EWtt8Kpd-YlHkaPhdBc1734BxD7two4ymhKo3wuwdVCKXA?e=z7Ed9m",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ERn37y0wPldFi_pOR2_gvMUBIU460Lr6g8gPF2tcRq5e6g?e=ggiXF7",
  },
  {
    name: "Translok",
    description:
      "Translok, es un fertilizante foliar con tecnología exclusiva para ayudar a la planta en el proceso de llenado de frutos y granos, proceso decisivo en la productividad del cultivo.",
    benefits:
      "Translok contiene una combinación equilibrada de macro y micronutrientes. Ayuda a promover el crecimiento saludable de las plantas y mejorar la resistencia a las enfermedades y el estrés ambiental. Aporta un equilibrio de nutrientes específicos para la fase de llenado, favoreciendo un mayor peso de granos y frutas. Reduce el estrés, asegurando mucha más energía para la planta. Mantiene la planta con una mayor tasa fotosintética, generando granos y frutos mejor formados.",
    composition: {
      "Nitrógeno soluble en agua (N)": "5,0%",
      "Fósforo soluble en agua (P2O5)": "10,0%",
      "Potasio soluble en agua (K2O)": "20,0%",
      "Magnesio soluble en agua (Mg)": "9,0%",
      "Azufre soluble en agua (S)": "11,7%",
      "Boro soluble en agua (B)": "0,5%",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Flores de corte",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Semestrales",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales Pasturas",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Nutrientes",
    supplier: "ICL",
    countries: ["Ecuador"],
    image: "productsImg/optimized/translok.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EWtt8Kpd-YlHkaPhdBc1734BxD7two4ymhKo3wuwdVCKXA?e=z7Ed9m",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ERn37y0wPldFi_pOR2_gvMUBIU460Lr6g8gPF2tcRq5e6g?e=ggiXF7",
  },
  {
    name: "Microfol mix",
    description:
      "Fertilizante inorgánico con Magnesio, Boro y otros micronutrientes quelatados en EDTA para aplicación al suelo mediante sistemas de fertiirrigación. ",
    benefits:
      "Fertilizante formulado con una mezcla de micronutrientes quelatados con EDTA y adición de Magnesio mejorando el estado nutricional de los cultivos. Formulado con alto contenido de Zinc y específico para la cura y prevención de los trastornos fisiológicos en cultivos que exigen altos suministros de este elemento y en condiciones ambientales que limitan la disponibilidad y absorción de este. Indicado al principio del ciclo del cultivo ya que favorece la brotación y el crecimiento vegetativo",
    composition: {
      "Magnesio soluble en agua": "MgO 8.0 %",
      "Boro soluble en agua": "B 0.5 %",
      "Cobre soluble en agua": "Cu EDTA 1.5 %",
      "Hierro soluble en agua": "Fe EDTA 1.0 %",
      "Manganeso soluble en agua": "Mn EDTA 2.0 %",
      "Molibdeno soluble en agua": "Mo 0.1 %",
      "Zinc soluble en agua": "Zn EDTA 5.0 %",
      "pH en solución al 10%": "4,42",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Foliares - Quelatos y Complejos",
    supplier: "BIOLCHIM",
    countries: ["Colombia"],
    image: "productsImg/optimized/microfol-mix.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EZt6Y1TI7mlCsuqDnh3AKlEBsKfb6Nc0FIAfhZMYdtLFzQ?e=Ug9RbX",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ETmXZAfsUfNOn9612A45IOEBCoLDPhPTi7XXb9vjW3IQRA?e=UsrrYg",
  },
  {
    name: "Quelato de Cu DISAN",
    description:
      "Fuente de Cobre 100% quelatado con EDTA altamente eficiente para aplicación foliar y fertirriego. ",
    benefits:
      "Tiene muy alta solubilidad (12 kg/10 litros de agua), con excelente estabilidad y compatibilidad en solución. Cobre 100% quelatado con EDTA. Permiten el aporte equilibrado de Cobre, para prevenir y corregir deficiencias de este elemento en las plantas. Máxima actividad fisiológica y nutricional de las plantas para explorar su potencial productivo. ",
    composition: {
      "Cobre soluble en agua Cu": "14.5%",
      "pH en solución al 5%": "7,7",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Foliares - Quelatos y Complejos",
    supplier: "ICL",
    countries: ["Colombia"],
    image: "productsImg/optimized/quelato-de-cu-disan.webp",
  },
  {
    name: "Quelato de Cu DISAN",
    description:
      "Fuente de Cobre 100% quelatado con EDTA altamente eficiente para aplicación foliar y fertirriego. ",
    benefits:
      "Tiene muy alta solubilidad (12 kg/10 litros de agua), con excelente estabilidad y compatibilidad en solución. Cobre 100% quelatado con EDTA. Permiten el aporte equilibrado de Cobre, para prevenir y corregir deficiencias de este elemento en las plantas. Máxima actividad fisiológica y nutricional de las plantas para explorar su potencial productivo. ",
    composition: {
      "Cobre soluble en agua Cu": "14.5%",
      "pH en solución al 5%": "7,7",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Foliares - Quelatos y Complejos",
    supplier: "ICL",
    countries: ["Perú"],
    image: "productsImg/optimized/quelato-de-cu-disan.webp",
  },
  {
    name: "Quelato de Fe DISAN",
    description:
      "Fuente de Hierro 100% quelatado con EDTA altamente eficiente para aplicación foliar y fertirriego. ",
    benefits:
      "Tiene muy alta solubilidad, con excelente estabilidad y compatibilidad en solución. Hierro 100% quelatado con EDTA, permitiendo una mayor translocación en la planta, llegando a sitios de acción ubicados en todas las partes de la planta. El Hierro es necesario para la clorofila, fotosíntesis, respiración, etc. La falta de este elemento provoca desequilibrios muy graves en la planta, llegando en algunos casos a la pérdida del cultivo. ",
    composition: {
      "Hierro soluble en agua Fe": "13.0%",
      "pH en solución al 10%": "4,33",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Foliares - Quelatos y Complejos",
    supplier: "ICL",
    countries: ["Colombia"],
    image: "productsImg/optimized/quelato-de-fe-disan.webp",
  },
  {
    name: "Quelato de Fe DISAN",
    description:
      "Fuente de Hierro 100% quelatado con EDTA altamente eficiente para aplicación foliar y fertirriego. ",
    benefits:
      "Tiene muy alta solubilidad, con excelente estabilidad y compatibilidad en solución. Hierro 100% quelatado con EDTA, permitiendo una mayor translocación en la planta, llegando a sitios de acción ubicados en todas las partes de la planta. El Hierro es necesario para la clorofila, fotosíntesis, respiración, etc. La falta de este elemento provoca desequilibrios muy graves en la planta, llegando en algunos casos a la pérdida del cultivo. ",
    composition: {
      "Hierro soluble en agua Fe": "13.0%",
      "pH en solución al 10%": "4,33",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Foliares - Quelatos y Complejos",
    supplier: "ICL",
    countries: ["Perú"],
    image: "productsImg/optimized/quelato-de-fe-disan.webp",
  },
  {
    name: "Quelato de Mn DISAN",
    description:
      "Fuente de Manganeso 100% quelatado con EDTA altamente eficiente para aplicación foliar y fertirriego. ",
    benefits:
      "Tiene muy alta solubilidad, con excelente estabilidad y compatibilidad en solución. Manganeso 100% quelatado con EDTA, permitiendo una mayor translocación en la planta, llegando a sitios de acción ubicados en todas las partes de la planta. El Manganeso participa en la síntesis de clorofila, asimilación de nitratos, síntesis de vitaminas, síntesis de aminoácidos, síntesis de ATP, síntesis de lignina, activación hormonal y división celular. ",
    composition: {
      "Manganeso soluble en agua Mn": "13.0%",
      "pH en solución al 10%": "7,5",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Foliares - Quelatos y Complejos",
    supplier: "ICL",
    countries: ["Colombia"],
    image: "productsImg/optimized/quelato-de-mn-disan.webp",
  },
  {
    name: "Quelato de Mn DISAN",
    description:
      "Fuente de Manganeso 100% quelatado con EDTA altamente eficiente para aplicación foliar y fertirriego. ",
    benefits:
      "Tiene muy alta solubilidad, con excelente estabilidad y compatibilidad en solución. Manganeso 100% quelatado con EDTA, permitiendo una mayor translocación en la planta, llegando a sitios de acción ubicados en todas las partes de la planta. El Manganeso participa en la síntesis de clorofila, asimilación de nitratos, síntesis de vitaminas, síntesis de aminoácidos, síntesis de ATP, síntesis de lignina, activación hormonal y división celular. ",
    composition: {
      "Manganeso soluble en agua Mn": "13.0%",
      "pH en solución al 10%": "7,5",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Foliares - Quelatos y Complejos",
    supplier: "ICL",
    countries: ["Perú"],
    image: "productsImg/optimized/quelato-de-mn-disan.webp",
  },
  {
    name: "Quelato de Zn DISAN",
    description:
      "Fuente de Zinc 100% quelatado con EDTA altamente eficiente para aplicación foliar y fertirriego.",
    benefits:
      "Tiene muy alta solubilidad, con excelente estabilidad y compatibilidad en solución.  Zinc 100% quelatado con EDTA, permitiendo una mayor translocación en la planta, llegando a sitios de acción ubicados en todas las partes de la planta. El Zinc participa en la síntesis de carbohidratos, la transformación de los azúcares en almidón, el metabolismo de hormonas al regular el nivel de auxinas y actúa en procesos de polinización, maduración y producción de semillas, así como en elongación de los brotes y la expansión de las hojas. ",
    composition: {
      "Zinc total": "Zn 15,0 %",
      "Zinc soluble en agua": "Zn 15,0 %",
      "pH en solución al 5%": "5,9",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Foliares - Quelatos y Complejos",
    supplier: "ICL",
    countries: ["Colombia"],
    image: "productsImg/optimized/quelato-de-zn-disan.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EdJvO_rTDJ1EllbN7UCZSgYBD5i095Rz-ZiGzT4mn8Gfww?e=hPzVhp",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EciBnt3jG6VHjGPOK_Y530YBUlTLe8smCjCWhkAeJcEvYQ?e=XvdT5G",
  },
  {
    name: "Quelato de Zn DISAN",
    description:
      "Fuente de Zinc 100% quelatado con EDTA altamente eficiente para aplicación foliar y fertirriego.",
    benefits:
      "Tiene muy alta solubilidad, con excelente estabilidad y compatibilidad en solución.  Zinc 100% quelatado con EDTA, permitiendo una mayor translocación en la planta, llegando a sitios de acción ubicados en todas las partes de la planta. El Zinc participa en la síntesis de carbohidratos, la transformación de los azúcares en almidón, el metabolismo de hormonas al regular el nivel de auxinas y actúa en procesos de polinización, maduración y producción de semillas, así como en elongación de los brotes y la expansión de las hojas. ",
    composition: {
      "Zinc total": "Zn 15,0 %",
      "Zinc soluble en agua": "Zn 15,0 %",
      "pH en solución al 5%": "5,9",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Foliares - Quelatos y Complejos",
    supplier: "ICL",
    countries: ["Perú"],
    image: "productsImg/optimized/quelato-de-zn-disan.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EdJvO_rTDJ1EllbN7UCZSgYBD5i095Rz-ZiGzT4mn8Gfww?e=hPzVhp",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EciBnt3jG6VHjGPOK_Y530YBUlTLe8smCjCWhkAeJcEvYQ?e=XvdT5G",
  },
  {
    name: "Protección Solar",
    description:
      "Protección Solar es un producto de origen natural que protege a las plantas frente a la excesiva radiación solar.",
    benefits:
      "Producto protectante a base de silicio, con una combinación única de tamaño de partícula y microestructura que contribuye a un alto grado de opacidad y cubrimiento. Es un producto de origen natural que protege a las plantas frente a la excesiva radiación solar. Está constituido por partículas de caolín que al aplicarse sobre las hojas crea una barrera que reduce el impacto negativo de los rayos UV, disminuye la temperatura foliar y optimiza el metabolismo vegetal, particularmente cuando las plantas están sometidas a estrés térmico. Protección Solar proporciona una cobertura que ayuda a aumentar el albedo foliar y un mayor reflejo de la radiación, permitiendo plantas más frescas y funcionalmente más activas.",
    composition: {
      "Silicio total SiO2": "70.0%",
      "Potasio soluble en agua": "5.0%",
      "pH al 20%": "5.5",
    },
    cultivation: [
      "Banano",
      "Plátano",
      "Café",
      "Piña",
      "Aguacate",
      "Mango",
      "Cítricos",
    ],
    hierarchy: "Nutrición y bioestimulación Foliar - Protección Solar",
    supplier: "CRENTO",
    countries: ["Colombia"],
    image: "productsImg/optimized/solar.webp",
  },
  {
    name: "Fosika",
    description:
      "Fosfito de potasio (0-30-20), que combina la acción potenciadora del sistema natural de defensas de la planta contra enfermedades fúngicas del ion fosfito, con la acción correctora de deficiencias de potasio, potenciando su asimilación y transporte",
    benefits:
      'Manvert Fosika induce la síntesis de metabolitos secundarios de tipo terpénico, fitoalexinas y enzimas implicadas en los  mecanismos internos de resistencia a enfermedades.  manera fosita es un corrector sistémico de carencias de fósforo, de fácil asimilación para la corrección de fisiopatías  relacionadas con la carencia de dicho elemento. manera fosita proporciona una respuesta inducida por la propia planta frente a distintas enfermedades causada por  hongos. Protege al cultivo contra enfermedades fúngicas, tales como: Phytophtora; podredumbre o "mal de cuello" en  hortalizas, flores y árboles frutales; chancros de cuello en frutales, cítricos, piña, kiwi, aguacate; podredumbre de raíz en  frutales, cítricos, piña, kiwi, aguacate; gomosis fúngica en cítricos, mildiu (viña y hortalizas); escoriosis (viña y frutales);  aguado de los cítricos (podredumbre de la piel del fruto) y monilias en olivos y frutales.',
    composition: {},
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Palma",
      "Flores de corte",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Foliares - Inductores de Resistencia",
    supplier: "MANVERT",
    countries: ["Colombia"],
    image: "productsImg/optimized/fosika.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/Ee3jCMQgAtlNnJ7UATf8pycBs8sgXk4kRoo0YtxDYFZL6A?e=4jhOwZ",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EXtpDBlgzgdIjbsis2ukKy0BngiEZmgDBzNq_1mpY5DBQQ?e=oyLd1b",
  },
  {
    name: "Fosika Calcio",
    description:
      "Fosfito de calcio que incrementa el vigor del cultivo y permite a la planta resistir mejor el estrés tanto biótico como abiótico.",
    benefits:
      'Fosika calcio induce la síntesis de metabolitos secundarios de tipo terpenico, fitoalexinas y enzimas implicadas en los mecanismos internos de resistencia a enfermedades. Fosika calcio es un corrector sistémico de carencias de calcio, de fácil asimilación para la corrección de fisiopatías proporciona una respuesta inducida por la propia planta frente a distintas enfermedades causada por hongos. Protege al cultivo contra enfermedades fúngicas, tales como: Phytophtora; podredumbre o "mal de cuello" Puede aplicarse en todo tipo de cultivos y tanto por vía foliar como por vía radicular Debe aplicarse preventivamente para fortalecer la planta, y en casos en que se quiera provocar una respuesta vegetativa. Es compatible con la mayoría de pesticidas utilizados habitualmente. No mezclar con aceites minera les ni con productos de reacción alcalina, ni con azufres, ni con dimetoato, ni con productos que contengan cobre.',
    composition: {
      "Fósforo asimilable": "P2O5 186 g/L",
      Calcio: "CaO 58 g/L",
      "pH en solución al 10%": "1,64",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Palma",
      "Flores de corte",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Foliares - Inductores de Resistencia",
    supplier: "MANVERT",
    countries: ["Colombia"],
    image: "productsImg/optimized/fosika-calcio.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EXJJpSaTCO9KmEPMiyUR8e8B5sHEAkRGCZRdKbQeMAGqXQ?e=ErTYcs",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EezOvQIg1U9NhoUcFEqn-W8BY2qTE8m3hLAIgfe3n_CgIA?e=nVDr79",
  },
  {
    name: "Fosika Cobre",
    description:
      "Fosfito de cobre que incrementa el vigor del cultivo y permite a la planta resistir mejor el estrés biótico y abiótico.",
    benefits:
      "Fosika Cobre induce la síntesis de metabolitos secundarios de tipo terpenico implicados en los mecanismos internos de resistencia a enfermedades Es un corrector sistémico de carencias de cobre, de fácil asimilación para la corrección de fisiopatías relacionados con la carencia de Cobre Proporciona una respuesta inducida por la propia planta frente a distintas enfermedades causadas por hongos. Protege el cultivo contra enfermedades fúngicas. No se recomienda su mezcla con aceites minerales ni con productos de reacción alcalina, ni con azufres y ni dimetoato. ",
    composition: {
      "Nitrógeno total": "N 90.0 g/L",
      "Nitrógeno amoniacal": "N 90.0 g/L",
      "Fósforo asimilable": "P2O5 210.0 g/L",
      Cobre: "Cu 47.0 g/L",
      "pH en solución al 10%": "8.5",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Palma",
      "Flores de corte",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Foliares - Inductores de Resistencia",
    supplier: "MANVERT",
    countries: ["Colombia"],
    image: "productsImg/optimized/fosika-cobre.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ETI-XHLRR5pMnaD4VFYr0XUBcl75OsQJSgfW6agFJs7Msg?e=YBeUOo",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ES-j920Zn_xOha4002f2cHgBHjXO3XbEIJKRTG8P3-IcFQ?e=ARe0cx",
  },
  {
    name: "Fosika Zinc",
    description:
      "Fosfito de zinc que incrementa el vigor del cultivo y permite a la planta resistir mejor los efectos del estrés biótico y abiótico",
    benefits:
      'Induce la síntesis de metabolitos secundarios de tipo terpenico, fitoalexinas y enzimas implicadas en los mecanismos internos de resistencia a los efectos de enfermedades. es un corrector sistémico de carencias de zinc, de fácil asimilación para la corrección de fisiopatías El zinc es cofactor de muchos procesos enzimáticos clave, que favorece la síntesis de proteínas y es fundamental en la síntesis de auxinas por parte de la planta. proporciona una respuesta inducida por la propia planta frente a las consecuencias de enfermedades causadas por hongos, como Phytophthora, podredumbre o "mal de cuello". Fosika zinc puede aplicarse en todo tipo de cultivos y tanto por vía foliar como por vía radicular Fosika zinc debe aplicarse preventivamente para activar el sistema de defensas de la planta, y en casos en que se quiera provocar una respuesta vegetativa.',
    composition: {
      "Anhídrido fosfórico soluble en agua P2O5 soluble en agua": "32,2",
      "Zinc soluble en agua Zn": "9,1",
      "Nitrógeno total N": "2,0",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Palma",
      "Flores de corte",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Foliares - Inductores de Resistencia",
    supplier: "MANVERT",
    countries: ["Colombia"],
    image: "productsImg/optimized/fosika-zinc.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ETLsfGLCq8hJgI3BAzWlHngBku4WAyrqaPJDDfEBJOPsDw?e=QENiLA",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EcqmmUa0wq9HhzK3aZkyGRoB0D0jXOli-Z_i89hG9BvyTg?e=bCVxI1",
  },
  {
    name: "Kafom",
    description:
      "Fertilizante foliar fosfito de potasio para uso en programas fitosanitarios de control de enfermedades como activador de defensas en las plantas. ",
    benefits:
      "KAFOM es un producto sistémico a base de fosfito de potásico. La acción sinérgica entre el ion fosfito y el potasio estimula las autodefensas de la planta. KAFOM incrementa la resistencia contra oomicetos como Phytophtora spp. o Pythium spp., al provocar disfunciones en su metabolismo. Y evita síntomas como podredumbres o ahogamientos que dificultan el adecuado desarrollo del cultivo y perjudican su rendimiento. Promueve la síntesis de fitoalexinas, con efecto antimicrobiano, y activa los sistemas de respuesta al estrés de los cultivos. Su contenido de Potasio favorece procesos de lignificación manteniendo dura y resistente la piel de los frutos. Además, aumenta la calidad y el tamaño de los frutos, así como su color y su sabor, facilitando su transporte y conservación.",
    composition: {
      "Pentóxido de Fósforo soluble en agua": "P2O5 30.0 %",
      "Óxido de potasio soluble en agua K2O": "20.0%",
      "pH 4.6 -": "5.6",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Palma",
      "Flores de corte",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Foliares - Inductores de Resistencia",
    supplier: "MERISTEM",
    countries: ["Colombia"],
    image: "productsImg/optimized/kafom.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/Ee8-C_dI2mtNjVYRrja4V4IBqII0qIhMVths05hyd0MAAQ?e=NPt1f1",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EQ9ioUzSDGFPqbe4EXXJiQEBaMjIZzqmdyIjuddaiyu-Dg?e=M5bwhy",
  },
  {
    name: "Loker",
    description:
      "Fertilizante foliar, bioestimulante formulado a partir de extractos vegetales y enriquecido con macroelementos para mejorar el estado nutricional de las plantas y sus defensas. ",
    benefits:
      "Garantiza una rápida absorción y asimilación de nutrientes, resulta en una respuesta nutricional rápida y efectiva, mejorando el desarrollo vegetativo, enraizamiento y producción de flores y frutos. Potencia los mecanismos de defensa natural de la planta Mejora el estado nutricional de la planta Favorece la formación de plantas más verdes y compactas ",
    composition: {
      "Nitrógeno total": "N 27,4 g/L",
      "Nitrógeno orgánico": "N 4,1 g/L",
      "Nitrógeno Ureico": "N 23,3 g/L",
      "Fósforo soluble en agua": "P2O5 109,6 g/L",
      "Potasio soluble en agua": "K2O 82,2 g/L",
      "Magnesio soluble en agua": "MgO 45,2 g/L",
      Sodio: "Na 1,3 g/L",
      "pH en solución al 10%": "2,0",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Foliares - Inductores de Resistencia",
    supplier: "BIOLCHIM",
    countries: ["Colombia"],
    image: "productsImg/optimized/loker.webp",
  },
  {
    name: "Manvert Defense Cu",
    description:
      "Fosfito de cobre que incrementa el vigor del cultivo y permite a la planta resistir mejor el estrés biótico y abiótico.",
    benefits:
      "Fosika Cobre induce la síntesis de metabolitos secundarios de tipo terpenico implicados en los mecanismos internos de resistencia a enfermedades Es un corrector sistémico de carencias de cobre, de fácil asimilación para la corrección de fisiopatías relacionados con la carencia de Cobre Proporciona una respuesta inducida por la propia planta frente a distintas enfermedades causadas por hongos. Protege el cultivo contra enfermedades fúngicas. No se recomienda su mezcla con aceites minerales ni con productos de reacción alcalina, ni con azufres y ni dimetoato. ",
    composition: {
      "Nitrógeno total": "N 88.0 g/L",
      "Nitrógeno amoniacal": "N 98.0 g/L",
      Fósforo: "P2O5 210.0 g/L",
      Cobre: "Cu 47.0 g/L",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Palma",
      "Flores de corte",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Foliares - Inductores de Resistencia",
    supplier: "MANVERT",
    countries: ["Colombia"],
    image: "productsImg/optimized/manvert-defense-cu.webp",
  },
  {
    name: "Manvert Defense Cu",
    description:
      "Fosfito de cobre que incrementa el vigor del cultivo y permite a la planta resistir mejor el estrés biótico y abiótico.",
    benefits:
      "Fosika Cobre induce la síntesis de metabolitos secundarios de tipo terpenico implicados en los mecanismos internos de resistencia a enfermedades Es un corrector sistémico de carencias de cobre, de fácil asimilación para la corrección de fisiopatías relacionados con la carencia de Cobre Proporciona una respuesta inducida por la propia planta frente a distintas enfermedades causadas por hongos. Protege el cultivo contra enfermedades fúngicas. No se recomienda su mezcla con aceites minerales ni con productos de reacción alcalina, ni con azufres y ni dimetoato. ",
    composition: {
      "Nitrógeno total": "N 88.0 g/L",
      "Nitrógeno amoniacal": "N 98.0 g/L",
      Fósforo: "P2O5 210.0 g/L",
      Cobre: "Cu 47.0 g/L",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Palma",
      "Flores de corte",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Foliares - Inductores de Resistencia",
    supplier: "MANVERT",
    countries: ["Panamá"],
    image: "productsImg/optimized/manvert-defense-cu.webp",
  },
  {
    name: "Manvert Letbio",
    description:
      "Microelementos complejados con doble función: bioestimulante y nutricional. Incorpora compuestos ADV (Activador del Desbloqueo Vascular) que activan el cultivo. ",
    benefits:
      "Manvert Letbio proporciona a la planta mejor respuesta frente a factores de todo tipo de estrés que causan disminución del vigor y del rendimiento. Manvert Letbio ayuda a incrementar la brotación mejorando la masa vegetativa y la capacidad fotosintética; obteniendo mejor producción en toda clase de cultivos. Manvert Letbio aplicado junto con el tratamiento fitosanitario convencional, revitaliza el cultivo mejorando la efectividad de este y dando como resultado una mejora del estado general del cultivo.",
    composition: {
      "Cobre Cu*": "20,65",
      "Manganeso Mn*": "8,85",
      "Zinc Zn*": "5,90",
      "*Agente complejante": "Ácido Lignosulfónico",
      "pH en solución al 10%": "2,75",
    },
    cultivation: [],
    hierarchy: "Foliares - Inductores de Resistencia",
    supplier: "MANVERT",
    countries: ["Colombia"],
    image: "productsImg/optimized/manvert-letbio.webp",
  },
  {
    name: "Manvert Micout",
    description:
      " Es un bioactivador natural del sistema inmunológico formulado con aminoácidos y ácidos orgánicos",
    benefits:
      "Manvert Micout tiene un alto poder estimulante de elicitores naturales (aumenta la PAL y los compuestos fenólicos) con un mecanismo de acción diferenciado, que desencadena una respuesta sistémica y de amplio espectro frente a enfermedades fúngicas provocadas por hongos oomicetos, enfermedades de tipo mildiu y botrytis. Manvert Micout indicado para reforzar los mecanismos de defensa propios de la planta, activando los elicitores internos que son capaces de inducir respuestas estructurales y/o bioquímicas asociadas a la resistencia del cultivo frente al ataque de hongos. ",
    composition: {
      "Nitrógeno total N": "66,0",
      "Nitrógeno amoniacal N": "60,0",
      "Nitrógeno orgánico N": "6,0",
      "Potasio soluble en agua K2O": "45,0",
      "Carbono orgánico oxidable total": "157",
      "Aminoácidos libre [1]*": "94,0",
      "*Contiene los siguiente aminoácidos libres": "Ácido glutámico",
      "pH en solución al 10%": "6,84",
    },
    cultivation: [
      "Banano",
      "Plátano",
      "Berries",
      "Café",
      "Cítricos",
      "Extensivos",
      "Flores de Corte",
      "Frutales",
      "Hortalizas",
      "Olivo",
      "Patata",
      "Uva",
    ],
    hierarchy: "Foliares - Inductores de Resistencia",
    supplier: "MANVERT",
    countries: ["Colombia"],
    image: "productsImg/optimized/manvert-micout.webp",
  },
  {
    name: "Solavit Mn",
    description:
      "Fosfito altamente rico en manganeso, constituyente o activador de múltiples enzimas involucradas en la fotosíntesis y la biosíntesis de carbohidratos. ",
    benefits:
      "Actúa en la planta como fosfito de manganeso ayudando a corregir las deficiencias de nutrientes y fortaleciendo el sistema de defensas de la planta. Producto formulado específicamente para mejorar el rendimiento y la calidad del cultivo Rico en Manganeso, catalizador o activador de muchas vías enzimáticas envueltas en la fotosíntesis y en la biosíntesis de carbohidratos. Los macronutrientes y micronutrientes optimizan el estado nutricional de la planta, la presencia de Fosforo altamente biodisponible mejora la absorción y translocación de los nutrientes. Estimula la formación y crecimiento de los tubérculos y mejora la acumulación de almidón llevando a tener una producción de mayor tamaño y calidad ",
    composition: {
      "Nitrógeno total": "N 39.0 g/L",
      "Nitrógeno ureico": "N 39.0 g/L",
      "Fósforo asimilable": "P2O5 401.0 g/L",
      "Cobre soluble en agua": "Cu EDTA 0.25 g/L",
      "Hierro soluble en agua": "Fe EDTA 0.25 g/L",
      "Manganeso soluble en agua": "Mn 53,5 g/L",
      "Molibdeno soluble en agua": "Mo 0.01 g/L",
      "Zinc soluble en agua": "Zn EDTA 0.13 g/L",
      "pH en solución al 10 %": "1.32",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Palma",
      "Flores de corte",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Foliares - Inductores de Resistencia",
    supplier: "BIOLCHIM",
    countries: ["Colombia"],
    image: "productsImg/optimized/solavit-mn.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EWcz8uNg0ddKv-NEn7HEH0gBgKWAuUAiPX75ByuDWgCmgA?e=LQ3ngK",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/EUAz-ziG0GZPv6ZEgp9NtrIBLnDijH4EvlIupJnGAD0rSQ?e=JbR2eZ",
  },
  {
    name: "Manvert Ortosil",
    description:
      "Bioestimulante líquido formulado a base de Silicio que reduce la pérdida de agua dentro de la planta manteniéndola hidratada y nutrida. ",
    benefits:
      "Bioestimulante líquido formulado a base de Silicio que reduce la pérdida de agua dentro de la planta manteniéndola hidratada y nutrida. Su especial formulación consigue una mayor concentración de Silicio en solución y un mayor cubrimiento y penetración en las hojas, con lo que se logra una máxima efectividad en la aplicación del producto. El silicio tiene numerosos efectos beneficiosos para los cultivos como:  Estimulación del crecimiento y el rendimiento, especialmente en condiciones de estrés hídrico. Reducción de la pérdida de agua por transpiración y la consecuente mejora de la vida útil de los cultivos. Mejora de la resistencia y rigidez de los tejidos, lo que provoca que los tallos, las hojas y los frutos estén más firmes. Reduce alteraciones de la piel como el cracking, el pitting o el colapso de corteza. Alarga la vida post cosecha.",
    composition: {
      "Silicio soluble en agua": "SiO2 360 g/L",
      "pH en solución al 10%": "10,4",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "foliares - silicio",
    supplier: "MANVERT",
    countries: ["Colombia"],
    image: "productsImg/optimized/manvert-ortosil.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ESXRETWGmWxJruUpFxaNSs4BxzvUiAxOzVRTdIshOiHtTA?e=RTmq2a",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/Eb_JdfQfWhpNrXoH1NwPIx8B5A-Nf9eYnmS9fYQFrSIc0Q?e=Yl5imA",
  },
  {
    name: "Manvert Ortosil",
    description:
      "Bioestimulante líquido formulado a base de Silicio que reduce la pérdida de agua dentro de la planta manteniéndola hidratada y nutrida. ",
    benefits:
      "Bioestimulante líquido formulado a base de Silicio que reduce la pérdida de agua dentro de la planta manteniéndola hidratada y nutrida. Su especial formulación consigue una mayor concentración de Silicio en solución y un mayor cubrimiento y penetración en las hojas, con lo que se logra una máxima efectividad en la aplicación del producto. El silicio tiene numerosos efectos beneficiosos para los cultivos como:  Estimulación del crecimiento y el rendimiento, especialmente en condiciones de estrés hídrico. Reducción de la pérdida de agua por transpiración y la consecuente mejora de la vida útil de los cultivos. Mejora de la resistencia y rigidez de los tejidos, lo que provoca que los tallos, las hojas y los frutos estén más firmes. Reduce alteraciones de la piel como el cracking, el pitting o el colapso de corteza. Alarga la vida post cosecha.",
    composition: {
      "Silicio soluble en agua": "SiO2 360 g/L",
      "pH en solución al 10%": "10,4",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "foliares - silicio",
    supplier: "MANVERT",
    countries: ["Panamá"],
    image: "productsImg/optimized/manvert-ortosil.webp",
    fichaT:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/ESXRETWGmWxJruUpFxaNSs4BxzvUiAxOzVRTdIshOiHtTA?e=RTmq2a",
    hojaS:
      "https://cidisan-my.sharepoint.com/:b:/g/personal/natalia_sierra_disan_com_co/Eb_JdfQfWhpNrXoH1NwPIx8B5A-Nf9eYnmS9fYQFrSIc0Q?e=Yl5imA",
  },
  {
    name: "Jiffy Pot 6,2×5,7 cms Round",
    supplier: "Jiffy",
    description:
      "Maceta biodegradable de 6,2 cm de diámetro y 5,7 cm de altura, ideal para la siembra y trasplante de diversas plantas.",
    benefits:
      "Facilita el trasplante sin dañar las raíces; biodegradable y ecológico; mejora el enraizamiento.",
    composition: {
      "Fibra de madera": "",
      "Turba prensada": "",
    },
    cultivation: ["Hortalizas", "Flores", "Plantas ornamentales."],
    hierarchy: "Sustratos - Macetas biodegradables",
    countries: ["Colombia"],
    image: "productsImg/optimized/s-l1200.webp",
  },
  {
    name: "Jiffy Pot 6,2×5,7 cms Round",
    supplier: "Jiffy",
    description:
      "Maceta biodegradable de 6,2 cm de diámetro y 5,7 cm de altura, ideal para la siembra y trasplante de diversas plantas.",
    benefits:
      "Facilita el trasplante sin dañar las raíces; biodegradable y ecológico; mejora el enraizamiento.",
    composition: {
      "Fibra de madera": "",
      "Turba prensada": "",
    },
    cultivation: ["Hortalizas", "Flores", "Plantas ornamentales."],
    hierarchy: "Sustratos - Macetas biodegradables",
    countries: ["Perú"],
    image: "productsImg/optimized/s-l1200.webp",
  },
  {
    name: "Jiffy Pot 6,2×5,7 cms Round",
    supplier: "Jiffy",
    description:
      "Maceta biodegradable de 6,2 cm de diámetro y 5,7 cm de altura, ideal para la siembra y trasplante de diversas plantas.",
    benefits:
      "Facilita el trasplante sin dañar las raíces; biodegradable y ecológico; mejora el enraizamiento.",
    composition: {
      "Fibra de madera": "",
      "Turba prensada": "",
    },
    cultivation: ["Hortalizas", "Flores", "Plantas ornamentales."],
    hierarchy: "Sustratos - Macetas biodegradables",
    countries: ["Ecuador"],
    image: "productsImg/optimized/s-l1200.webp",
  },
  {
    name: "Jiffy Pot 6,2×5,7 cms Round",
    supplier: "Jiffy",
    description:
      "Maceta biodegradable de 6,2 cm de diámetro y 5,7 cm de altura, ideal para la siembra y trasplante de diversas plantas.",
    benefits:
      "Facilita el trasplante sin dañar las raíces; biodegradable y ecológico; mejora el enraizamiento.",
    composition: {
      "Fibra de madera": "",
      "Turba prensada": "",
    },
    cultivation: ["Hortalizas", "Flores", "Plantas ornamentales."],
    hierarchy: "Sustratos - Macetas biodegradables",
    countries: ["Panamá"],
    image: "productsImg/optimized/s-l1200.webp",
  },
  {
    name: "Jiffy Pot 6,2×5,7 cms Round",
    supplier: "Jiffy",
    description:
      "Maceta biodegradable de 6,2 cm de diámetro y 5,7 cm de altura, ideal para la siembra y trasplante de diversas plantas.",
    benefits:
      "Facilita el trasplante sin dañar las raíces; biodegradable y ecológico; mejora el enraizamiento.",
    composition: {
      "Fibra de madera": "",
      "Turba prensada": "",
    },
    cultivation: ["Hortalizas", "Flores", "Plantas ornamentales."],
    hierarchy: "Sustratos - Macetas biodegradables",
    countries: ["Venezuela"],
    image: "productsImg/optimized/s-l1200.webp",
  },
  {
    name: "Jiffy Pot 6,2×5,7 cms Round",
    supplier: "Jiffy",
    description:
      "Maceta biodegradable de 6,2 cm de diámetro y 5,7 cm de altura, ideal para la siembra y trasplante de diversas plantas.",
    benefits:
      "Facilita el trasplante sin dañar las raíces; biodegradable y ecológico; mejora el enraizamiento.",
    composition: {
      "Fibra de madera": "",
      "Turba prensada": "",
    },
    cultivation: ["Hortalizas", "Flores", "Plantas ornamentales."],
    hierarchy: "Sustratos - Macetas biodegradables",
    countries: ["Bolivia"],
    image: "productsImg/optimized/s-l1200.webp",
  },
  {
    name: "Jiffy-7 Pellet de Turba Hortícola con NPK SRF Osmocote, 50x150mm 20mm ",
    supplier: "Jiffy",
    description:
      "Pellet de turba hortícola enriquecido con fertilizante de liberación controlada, ideal para la propagación de plantas.",
    benefits:
      "Proporciona nutrientes esenciales; facilita la germinación y el enraizamiento; malla biodegradable que elimina residuos plásticos.",
    composition: {
      "Turba canadiense Sphagnum": "100%",
      "fertilizante NPK 12-7-18 (Osmocot bloom)": "",
      cal: "calcárea.",
    },
    cultivation: [
      "Cacao",
      "Café",
      "Palma africana",
      "Jabillo",
      "Estacas de uva",
      "Plantas intermedias",
      "Especies nativas.",
    ],
    hierarchy: "Sustratos - Pellets de turba",
    countries: ["Colombia"],
    image: "productsImg/optimized/JF_1.webp",
  },
  {
    name: "Jiffy-7 Pellet de Turba Hortícola con NPK SRF Osmocote, 50x150mm 20mm ",
    supplier: "Jiffy",
    description:
      "Pellet de turba hortícola enriquecido con fertilizante de liberación controlada, ideal para la propagación de plantas.",
    benefits:
      "Proporciona nutrientes esenciales; facilita la germinación y el enraizamiento; malla biodegradable que elimina residuos plásticos.",
    composition: {
      "Turba canadiense Sphagnum": "100%",
      "fertilizante NPK 12-7-18 (Osmocot bloom)": "",
      cal: "calcárea.",
    },
    cultivation: [
      "Cacao",
      "Café",
      "Palma africana",
      "Jabillo",
      "Estacas de uva",
      "Plantas intermedias",
      "Especies nativas.",
    ],
    hierarchy: "Sustratos - Pellets de turba",
    countries: ["Perú"],
    image: "productsImg/optimized/JF_1.webp",
  },
  {
    name: "Jiffy-7 Pellet de Turba Hortícola con NPK SRF Osmocote, 50x150mm 20mm ",
    supplier: "Jiffy",
    description:
      "Pellet de turba hortícola enriquecido con fertilizante de liberación controlada, ideal para la propagación de plantas.",
    benefits:
      "Proporciona nutrientes esenciales; facilita la germinación y el enraizamiento; malla biodegradable que elimina residuos plásticos.",
    composition: {
      "Turba canadiense Sphagnum": "100%",
      "fertilizante NPK 12-7-18 (Osmocot bloom)": "",
      cal: "calcárea.",
    },
    cultivation: [
      "Cacao",
      "Café",
      "Palma africana",
      "Jabillo",
      "Estacas de uva",
      "Plantas intermedias",
      "Especies nativas.",
    ],
    hierarchy: "Sustratos - Pellets de turba",
    countries: ["Ecuador"],
    image: "productsImg/optimized/JF_1.webp",
  },
  {
    name: "Jiffy-7 Pellet de Turba Hortícola con NPK SRF Osmocote, 50x150mm 20mm ",
    supplier: "Jiffy",
    description:
      "Pellet de turba hortícola enriquecido con fertilizante de liberación controlada, ideal para la propagación de plantas.",
    benefits:
      "Proporciona nutrientes esenciales; facilita la germinación y el enraizamiento; malla biodegradable que elimina residuos plásticos.",
    composition: {
      "Turba canadiense Sphagnum": "100%",
      "fertilizante NPK 12-7-18 (Osmocot bloom)": "",
      cal: "calcárea.",
    },
    cultivation: [
      "Cacao",
      "Café",
      "Palma africana",
      "Jabillo",
      "Estacas de uva",
      "Plantas intermedias",
      "Especies nativas.",
    ],
    hierarchy: "Sustratos - Pellets de turba",
    countries: ["Panamá"],
    image: "productsImg/optimized/JF_1.webp",
  },
  {
    name: "Jiffy-7 Pellet de Turba Hortícola con NPK SRF Osmocote, 50x150mm 20mm ",
    supplier: "Jiffy",
    description:
      "Pellet de turba hortícola enriquecido con fertilizante de liberación controlada, ideal para la propagación de plantas.",
    benefits:
      "Proporciona nutrientes esenciales; facilita la germinación y el enraizamiento; malla biodegradable que elimina residuos plásticos.",
    composition: {
      "Turba canadiense Sphagnum": "100%",
      "fertilizante NPK 12-7-18 (Osmocot bloom)": "",
      cal: "calcárea.",
    },
    cultivation: [
      "Cacao",
      "Café",
      "Palma africana",
      "Jabillo",
      "Estacas de uva",
      "Plantas intermedias",
      "Especies nativas.",
    ],
    hierarchy: "Sustratos - Pellets de turba",
    countries: ["Venezuela"],
    image: "productsImg/optimized/JF_1.webp",
  },
  {
    name: "Jiffy-7 Pellet de Turba Hortícola con NPK SRF Osmocote, 50x150mm 20mm ",
    supplier: "Jiffy",
    description:
      "Pellet de turba hortícola enriquecido con fertilizante de liberación controlada, ideal para la propagación de plantas.",
    benefits:
      "Proporciona nutrientes esenciales; facilita la germinación y el enraizamiento; malla biodegradable que elimina residuos plásticos.",
    composition: {
      "Turba canadiense Sphagnum": "100%",
      "fertilizante NPK 12-7-18 (Osmocot bloom)": "",
      cal: "calcárea.",
    },
    cultivation: [
      "Cacao",
      "Café",
      "Palma africana",
      "Jabillo",
      "Estacas de uva",
      "Plantas intermedias",
      "Especies nativas.",
    ],
    hierarchy: "Sustratos - Pellets de turba",
    countries: ["Bolivia"],
    image: "productsImg/optimized/JF_1.webp",
  },
  {
    name: "PELLET HORTICOLA 42X75 - CJ440",
    supplier: "Jiffy",
    description: "Pellet de turba para esquejes y germinación.",
    benefits: "Favorece enraizamiento y nutrición inicial.",
    composition: {
      "Turba Sphagnum": "100%",
      "fertilizante NPK": "10-15-15",
    },
    cultivation: ["Rosas", "Frutales", "Aromáticas."],
    hierarchy: "Sustratos - Pellets de turba",
    countries: ["Colombia"],
    image: "productsImg/optimized/JF_2.webp",
  },
  {
    name: "PELLET HORTICOLA 42X75 - CJ440",
    supplier: "Jiffy",
    description: "Pellet de turba para esquejes y germinación.",
    benefits: "Favorece enraizamiento y nutrición inicial.",
    composition: {
      "Turba Sphagnum": "100%",
      "fertilizante NPK": "10-15-15",
    },
    cultivation: ["Rosas", "Frutales", "Aromáticas."],
    hierarchy: "Sustratos - Pellets de turba",
    countries: ["Perú"],
    image: "productsImg/optimized/JF_2.webp",
  },
  {
    name: "PELLET HORTICOLA 42X75 - CJ440",
    supplier: "Jiffy",
    description: "Pellet de turba para esquejes y germinación.",
    benefits: "Favorece enraizamiento y nutrición inicial.",
    composition: {
      "Turba Sphagnum": "100%",
      "fertilizante NPK": "10-15-15",
    },
    cultivation: ["Rosas", "Frutales", "Aromáticas."],
    hierarchy: "Sustratos - Pellets de turba",
    countries: ["Ecuador"],
    image: "productsImg/optimized/JF_2.webp",
  },
  {
    name: "PELLET HORTICOLA 42X75 - CJ440",
    supplier: "Jiffy",
    description: "Pellet de turba para esquejes y germinación.",
    benefits: "Favorece enraizamiento y nutrición inicial.",
    composition: {
      "Turba Sphagnum": "100%",
      "fertilizante NPK": "10-15-15",
    },
    cultivation: ["Rosas", "Frutales", "Aromáticas."],
    hierarchy: "Sustratos - Pellets de turba",
    countries: ["Panamá"],
    image: "productsImg/optimized/JF_2.webp",
  },
  {
    name: "PELLET HORTICOLA 42X75 - CJ440",
    supplier: "Jiffy",
    description: "Pellet de turba para esquejes y germinación.",
    benefits: "Favorece enraizamiento y nutrición inicial.",
    composition: {
      "Turba Sphagnum": "100%",
      "fertilizante NPK": "10-15-15",
    },
    cultivation: ["Rosas", "Frutales", "Aromáticas."],
    hierarchy: "Sustratos - Pellets de turba",
    countries: ["Venezuela"],
    image: "productsImg/optimized/JF_2.webp",
  },
  {
    name: "PELLET HORTICOLA 42X75 - CJ440",
    supplier: "Jiffy",
    description: "Pellet de turba para esquejes y germinación.",
    benefits: "Favorece enraizamiento y nutrición inicial.",
    composition: {
      "Turba Sphagnum": "100%",
      "fertilizante NPK": "10-15-15",
    },
    cultivation: ["Rosas", "Frutales", "Aromáticas."],
    hierarchy: "Sustratos - Pellets de turba",
    countries: ["Bolivia"],
    image: "productsImg/optimized/JF_2.webp",
  },
  {
    name: "PELLET HORTICOLA 36X100 GNL - CJ640",
    supplier: "Jiffy",
    description: "Pellet de turba para enraizamiento de esquejes.",
    benefits: "Mantiene humedad óptima, ideal para viveros.",
    composition: {
      "Turba Sphagnum": "",
      "fertilizante NPK": "",
    },
    cultivation: ["Esquejes forestales", "Frutales pequeños."],
    hierarchy: "Sustratos - Pellets de turba",
    countries: ["Colombia"],
    image: "productsImg/optimized/JIFFYS PELLET 36x100.webp",
  },
  {
    name: "PELLET HORTICOLA 36X100 GNL - CJ640",
    supplier: "Jiffy",
    description: "Pellet de turba para enraizamiento de esquejes.",
    benefits: "Mantiene humedad óptima, ideal para viveros.",
    composition: {
      "Turba Sphagnum": "",
      "fertilizante NPK": "",
    },
    cultivation: ["Esquejes forestales", "Frutales pequeños."],
    hierarchy: "Sustratos - Pellets de turba",
    countries: ["Perú"],
    image: "productsImg/optimized/JIFFYS PELLET 36x100.webp",
  },
  {
    name: "PELLET HORTICOLA 36X100 GNL - CJ640",
    supplier: "Jiffy",
    description: "Pellet de turba para enraizamiento de esquejes.",
    benefits: "Mantiene humedad óptima, ideal para viveros.",
    composition: {
      "Turba Sphagnum": "",
      "fertilizante NPK": "",
    },
    cultivation: ["Esquejes forestales", "Frutales pequeños."],
    hierarchy: "Sustratos - Pellets de turba",
    countries: ["Ecuador"],
    image: "productsImg/optimized/JIFFYS PELLET 36x100.webp",
  },
  {
    name: "PELLET HORTICOLA 36X100 GNL - CJ640",
    supplier: "Jiffy",
    description: "Pellet de turba para enraizamiento de esquejes.",
    benefits: "Mantiene humedad óptima, ideal para viveros.",
    composition: {
      "Turba Sphagnum": "",
      "fertilizante NPK": "",
    },
    cultivation: ["Esquejes forestales", "Frutales pequeños."],
    hierarchy: "Sustratos - Pellets de turba",
    countries: ["Panamá"],
    image: "productsImg/optimized/JIFFYS PELLET 36x100.webp",
  },
  {
    name: "PELLET HORTICOLA 36X100 GNL - CJ640",
    supplier: "Jiffy",
    description: "Pellet de turba para enraizamiento de esquejes.",
    benefits: "Mantiene humedad óptima, ideal para viveros.",
    composition: {
      "Turba Sphagnum": "",
      "fertilizante NPK": "",
    },
    cultivation: ["Esquejes forestales", "Frutales pequeños."],
    hierarchy: "Sustratos - Pellets de turba",
    countries: ["Venezuela"],
    image: "productsImg/optimized/JIFFYS PELLET 36x100.webp",
  },
  {
    name: "PELLET HORTICOLA 36X100 GNL - CJ640",
    supplier: "Jiffy",
    description: "Pellet de turba para enraizamiento de esquejes.",
    benefits: "Mantiene humedad óptima, ideal para viveros.",
    composition: {
      "Turba Sphagnum": "",
      "fertilizante NPK": "",
    },
    cultivation: ["Esquejes forestales", "Frutales pequeños."],
    hierarchy: "Sustratos - Pellets de turba",
    countries: ["Bolivia"],
    image: "productsImg/optimized/JIFFYS PELLET 36x100.webp",
  },
  {
    name: "PELLET HORTICOLA 50X150-48 SRF BIO - CJ144",
    supplier: "Jiffy",
    description: "Pellet de turba para propagación de cultivos.",
    benefits: "Mantiene estructura y facilita el trasplante.",
    composition: {
      NPK: "12-6-6",
      "turba Sphagnum": "",
    },
    cultivation: ["Cacao", "Café", "Palma", "Uva."],
    hierarchy: "Sustratos - Pellets de turba",
    countries: ["Colombia"],
    image: "productsImg/optimized/JF_1.webp",
  },
  {
    name: "PELLET HORTICOLA 50X150-48 SRF BIO - CJ144",
    supplier: "Jiffy",
    description: "Pellet de turba para propagación de cultivos.",
    benefits: "Mantiene estructura y facilita el trasplante.",
    composition: {
      NPK: "12-6-6",
      "turba Sphagnum": "",
    },
    cultivation: ["Cacao", "Café", "Palma", "Uva."],
    hierarchy: "Sustratos - Pellets de turba",
    countries: ["Perú"],
    image: "productsImg/optimized/JF_1.webp",
  },
  {
    name: "PELLET HORTICOLA 50X150-48 SRF BIO - CJ144",
    supplier: "Jiffy",
    description: "Pellet de turba para propagación de cultivos.",
    benefits: "Mantiene estructura y facilita el trasplante.",
    composition: {
      NPK: "12-6-6",
      "turba Sphagnum": "",
    },
    cultivation: ["Cacao", "Café", "Palma", "Uva."],
    hierarchy: "Sustratos - Pellets de turba",
    countries: ["Ecuador"],
    image: "productsImg/optimized/JF_1.webp",
  },
  {
    name: "PELLET HORTICOLA 50X150-48 SRF BIO - CJ144",
    supplier: "Jiffy",
    description: "Pellet de turba para propagación de cultivos.",
    benefits: "Mantiene estructura y facilita el trasplante.",
    composition: {
      NPK: "12-6-6",
      "turba Sphagnum": "",
    },
    cultivation: ["Cacao", "Café", "Palma", "Uva."],
    hierarchy: "Sustratos - Pellets de turba",
    countries: ["Panamá"],
    image: "productsImg/optimized/JF_1.webp",
  },
  {
    name: "PELLET HORTICOLA 50X150-48 SRF BIO - CJ144",
    supplier: "Jiffy",
    description: "Pellet de turba para propagación de cultivos.",
    benefits: "Mantiene estructura y facilita el trasplante.",
    composition: {
      NPK: "12-6-6",
      "turba Sphagnum": "",
    },
    cultivation: ["Cacao", "Café", "Palma", "Uva."],
    hierarchy: "Sustratos - Pellets de turba",
    countries: ["Venezuela"],
    image: "productsImg/optimized/JF_1.webp",
  },
  {
    name: "PELLET HORTICOLA 50X150-48 SRF BIO - CJ144",
    supplier: "Jiffy",
    description: "Pellet de turba para propagación de cultivos.",
    benefits: "Mantiene estructura y facilita el trasplante.",
    composition: {
      NPK: "12-6-6",
      "turba Sphagnum": "",
    },
    cultivation: ["Cacao", "Café", "Palma", "Uva."],
    hierarchy: "Sustratos - Pellets de turba",
    countries: ["Bolivia"],
    image: "productsImg/optimized/JF_1.webp",
  },
  {
    name: "PELLET HORTICOLA 70X100-18I PLA - CJ120",
    supplier: "Jiffy",
    description:
      "Pellet de turba para propagación y fortalecimiento de raíces.",
    benefits: "Excelente retención de agua y aireación.",
    composition: {
      "Turba Sphagnum": "",
      calcio: "",
      microelementos: "",
    },
    cultivation: ["Banano in vitro", "Aguacate", "Café."],
    hierarchy: "Sustratos - Pellets de turba",
    countries: ["Colombia"],
    image: "productsImg/optimized/70X100.webp",
  },
  {
    name: "PELLET HORTICOLA 70X100-18I PLA - CJ120",
    supplier: "Jiffy",
    description:
      "Pellet de turba para propagación y fortalecimiento de raíces.",
    benefits: "Excelente retención de agua y aireación.",
    composition: {
      "Turba Sphagnum": "",
      calcio: "",
      microelementos: "",
    },
    cultivation: ["Banano in vitro", "Aguacate", "Café."],
    hierarchy: "Sustratos - Pellets de turba",
    countries: ["Perú"],
    image: "productsImg/optimized/70X100.webp",
  },
  {
    name: "PELLET HORTICOLA 70X100-18I PLA - CJ120",
    supplier: "Jiffy",
    description:
      "Pellet de turba para propagación y fortalecimiento de raíces.",
    benefits: "Excelente retención de agua y aireación.",
    composition: {
      "Turba Sphagnum": "",
      calcio: "",
      microelementos: "",
    },
    cultivation: ["Banano in vitro", "Aguacate", "Café."],
    hierarchy: "Sustratos - Pellets de turba",
    countries: ["Ecuador"],
    image: "productsImg/optimized/70X100.webp",
  },
  {
    name: "PELLET HORTICOLA 70X100-18I PLA - CJ120",
    supplier: "Jiffy",
    description:
      "Pellet de turba para propagación y fortalecimiento de raíces.",
    benefits: "Excelente retención de agua y aireación.",
    composition: {
      "Turba Sphagnum": "",
      calcio: "",
      microelementos: "",
    },
    cultivation: ["Banano in vitro", "Aguacate", "Café."],
    hierarchy: "Sustratos - Pellets de turba",
    countries: ["Panamá"],
    image: "productsImg/optimized/70X100.webp",
  },
  {
    name: "PELLET HORTICOLA 70X100-18I PLA - CJ120",
    supplier: "Jiffy",
    description:
      "Pellet de turba para propagación y fortalecimiento de raíces.",
    benefits: "Excelente retención de agua y aireación.",
    composition: {
      "Turba Sphagnum": "",
      calcio: "",
      microelementos: "",
    },
    cultivation: ["Banano in vitro", "Aguacate", "Café."],
    hierarchy: "Sustratos - Pellets de turba",
    countries: ["Venezuela"],
    image: "productsImg/optimized/70X100.webp",
  },
  {
    name: "PELLET HORTICOLA 70X100-18I PLA - CJ120",
    supplier: "Jiffy",
    description:
      "Pellet de turba para propagación y fortalecimiento de raíces.",
    benefits: "Excelente retención de agua y aireación.",
    composition: {
      "Turba Sphagnum": "",
      calcio: "",
      microelementos: "",
    },
    cultivation: ["Banano in vitro", "Aguacate", "Café."],
    hierarchy: "Sustratos - Pellets de turba",
    countries: ["Bolivia"],
    image: "productsImg/optimized/70X100.webp",
  },
  {
    name: "PREFORMA BOX PLUG 20/40 VECO - CJ2160",
    supplier: "Jiffy",
    description: "Plug preformado para germinación y esquejes.",
    benefits: "Mantiene humedad y evita compactación.",
    composition: {
      "Coco peat": "70%",
      turba: "30%",
    },
    cultivation: ["Ornamentales", "Plantas in vitro."],
    hierarchy: "Sustratos - Plugs de turba y coco",
    countries: ["Colombia"],
    image: "productsImg/optimized/PLUG-20-40.webp",
  },
  {
    name: "PREFORMA BOX PLUG 20/40 VECO - CJ2160",
    supplier: "Jiffy",
    description: "Plug preformado para germinación y esquejes.",
    benefits: "Mantiene humedad y evita compactación.",
    composition: {
      "Coco peat": "70%",
      turba: "30%",
    },
    cultivation: ["Ornamentales", "Plantas in vitro."],
    hierarchy: "Sustratos - Plugs de turba y coco",
    countries: ["Perú"],
    image: "productsImg/optimized/PLUG-20-40.webp",
  },
  {
    name: "PREFORMA BOX PLUG 20/40 VECO - CJ2160",
    supplier: "Jiffy",
    description: "Plug preformado para germinación y esquejes.",
    benefits: "Mantiene humedad y evita compactación.",
    composition: {
      "Coco peat": "70%",
      turba: "30%",
    },
    cultivation: ["Ornamentales", "Plantas in vitro."],
    hierarchy: "Sustratos - Plugs de turba y coco",
    countries: ["Ecuador"],
    image: "productsImg/optimized/PLUG-20-40.webp",
  },
  {
    name: "PREFORMA BOX PLUG 20/40 VECO - CJ2160",
    supplier: "Jiffy",
    description: "Plug preformado para germinación y esquejes.",
    benefits: "Mantiene humedad y evita compactación.",
    composition: {
      "Coco peat": "70%",
      turba: "30%",
    },
    cultivation: ["Ornamentales", "Plantas in vitro."],
    hierarchy: "Sustratos - Plugs de turba y coco",
    countries: ["Panamá"],
    image: "productsImg/optimized/PLUG-20-40.webp",
  },
  {
    name: "PREFORMA BOX PLUG 20/40 VECO - CJ2160",
    supplier: "Jiffy",
    description: "Plug preformado para germinación y esquejes.",
    benefits: "Mantiene humedad y evita compactación.",
    composition: {
      "Coco peat": "70%",
      turba: "30%",
    },
    cultivation: ["Ornamentales", "Plantas in vitro."],
    hierarchy: "Sustratos - Plugs de turba y coco",
    countries: ["Venezuela"],
    image: "productsImg/optimized/PLUG-20-40.webp",
  },
  {
    name: "PREFORMA BOX PLUG 20/40 VECO - CJ2160",
    supplier: "Jiffy",
    description: "Plug preformado para germinación y esquejes.",
    benefits: "Mantiene humedad y evita compactación.",
    composition: {
      "Coco peat": "70%",
      turba: "30%",
    },
    cultivation: ["Ornamentales", "Plantas in vitro."],
    hierarchy: "Sustratos - Plugs de turba y coco",
    countries: ["Bolivia"],
    image: "productsImg/optimized/PLUG-20-40.webp",
  },
  {
    name: "TURBA PRENSADA PERLITA FINA 300 lt",
    supplier: "Jiffy",
    description: "Sustrato a base de turba rubia con perlita.",
    benefits: "Alta aireación y retención de agua.",
    composition: {
      "Materia orgánica": "75%",
      humedad: "57%",
      pH: "5.8",
    },
    cultivation: ["Semillas", "Esquejes en bandejas pequeñas."],
    hierarchy: "Sustratos - Sustratos para germinación",
    countries: ["Colombia"],
    image:
      "productsImg/optimized/TURBA-JIFFI-FINA-CON-PERLITA-X-300-LITROS.webp",
  },
  {
    name: "TURBA PRENSADA PERLITA FINA 300 lt",
    supplier: "Jiffy",
    description: "Sustrato a base de turba rubia con perlita.",
    benefits: "Alta aireación y retención de agua.",
    composition: {
      "Materia orgánica": "75%",
      humedad: "57%",
      pH: "5.8",
    },
    cultivation: ["Semillas", "Esquejes en bandejas pequeñas."],
    hierarchy: "Sustratos - Sustratos para germinación",
    countries: ["Perú"],
    image:
      "productsImg/optimized/TURBA-JIFFI-FINA-CON-PERLITA-X-300-LITROS.webp",
  },
  {
    name: "TURBA PRENSADA PERLITA FINA 300 lt",
    supplier: "Jiffy",
    description: "Sustrato a base de turba rubia con perlita.",
    benefits: "Alta aireación y retención de agua.",
    composition: {
      "Materia orgánica": "75%",
      humedad: "57%",
      pH: "5.8",
    },
    cultivation: ["Semillas", "Esquejes en bandejas pequeñas."],
    hierarchy: "Sustratos - Sustratos para germinación",
    countries: ["Ecuador"],
    image:
      "productsImg/optimized/TURBA-JIFFI-FINA-CON-PERLITA-X-300-LITROS.webp",
  },
  {
    name: "TURBA PRENSADA PERLITA FINA 300 lt",
    supplier: "Jiffy",
    description: "Sustrato a base de turba rubia con perlita.",
    benefits: "Alta aireación y retención de agua.",
    composition: {
      "Materia orgánica": "75%",
      humedad: "57%",
      pH: "5.8",
    },
    cultivation: ["Semillas", "Esquejes en bandejas pequeñas."],
    hierarchy: "Sustratos - Sustratos para germinación",
    countries: ["Panamá"],
    image:
      "productsImg/optimized/TURBA-JIFFI-FINA-CON-PERLITA-X-300-LITROS.webp",
  },
  {
    name: "TURBA PRENSADA PERLITA FINA 300 lt",
    supplier: "Jiffy",
    description: "Sustrato a base de turba rubia con perlita.",
    benefits: "Alta aireación y retención de agua.",
    composition: {
      "Materia orgánica": "75%",
      humedad: "57%",
      pH: "5.8",
    },
    cultivation: ["Semillas", "Esquejes en bandejas pequeñas."],
    hierarchy: "Sustratos - Sustratos para germinación",
    countries: ["Venezuela"],
    image:
      "productsImg/optimized/TURBA-JIFFI-FINA-CON-PERLITA-X-300-LITROS.webp",
  },
  {
    name: "TURBA PRENSADA PERLITA FINA 300 lt",
    supplier: "Jiffy",
    description: "Sustrato a base de turba rubia con perlita.",
    benefits: "Alta aireación y retención de agua.",
    composition: {
      "Materia orgánica": "75%",
      humedad: "57%",
      pH: "5.8",
    },
    cultivation: ["Semillas", "Esquejes en bandejas pequeñas."],
    hierarchy: "Sustratos - Sustratos para germinación",
    countries: ["Bolivia"],
    image:
      "productsImg/optimized/TURBA-JIFFI-FINA-CON-PERLITA-X-300-LITROS.webp",
  },
  {
    name: "Fosfotal",
    description:
      "Fosfotal es un biofertilizante y promotor del crecimiento vegetal, cuyo principio activo es la cepa nativa de Rhizobium pusense (B02),",
    benefits:
      "FOSFOTAL es un biofertilizante cuyo principio activo es la cepa nativa de Rhizobium pusense (B02), con una concentración mínima de 1x108 UFC/ml de producto.Sirve como complemento a la fertilización fosfórica química de síntesis, ya que ayuda a optimizar el aprovechamiento de fertilizantes fosfóricos en los cultivos de arroz y maíz siguiendo un esquema de fertilización convencional para estos sistemas productivos, es decir, manteniendo la aplicación de fertilizantes para suplir otros elementos.Es compatible con los principales principios activos de fertilizantes y plaguicidas usados en los cultivos de maíz y arroz, lo que permite su incorporación en programas de manejo integrado.Reduce los potenciales impactos ambientales negativos causados por la aplicación de fertilizante químico en exceso, obteniéndose alimentos más saludables e inocuos.",
    composition: {
      "Rhizobium pusense (B02)": "1x108UFC/mL 0,14",
      "Excipientes c.s.p.": "100 %",
    },
    cultivation: ["Cereales"],
    hierarchy: "Biofertilizantes - Promotor crecimiento",
    supplier: "FORBIO",
    countries: ["Colombia"],
    image: "productsImg/optimized/fosfotal.webp",
  },
  {
    name: "Phosbio",
    description:
      "PHOSBIO es un inoculante en suspensión elaborado a partir de bacterias del género Pseudomonas fluorescens",
    benefits:
      "Pseudomonas fluorescens es un microorganismo que favorece la promoción de crecimiento vegetal.Aporta a la mejor disponibilidad del fósforo, mejorando el desarrollo vegetativo de las plantas",
    composition: {
      "Pseudomonas fluorescens": "1,63 x 108 UFC/ml",
      "Ingredientes activos c.s.p": "100%",
    },
    cultivation: ["Semestrales"],
    hierarchy: "Biofertilizantes - Promotor crecimiento",
    supplier: "FORBIO",
    countries: ["Colombia"],
    image: "productsImg/optimized/Phosbio.webp",
  },
  {
    name: "Forcontrol PLUS",
    description:
      "ForControl PLUS se compone de microorganismos activos formulado con cepas aisladas e identificadas bioquímicamente y molecularmente. Promotor de crecimiento y fungicida biológico.",
    benefits:
      "ForControl PLUS se compone de un consorcio de microorganismos activos formulado con cepas aisladas e identificadas bioquímicamente y molecularmente. Las bacterias forman endosporas que, aplicadas a las semillas, se expanden por las raíces de las plántulas y compiten con hongos patógenos del suelo constituyendo su función defensiva o también pueden ser aplicadas vía foliar. Entre hongos que controla se destacan fusariosis (Fusarium sp.), mancha o tizón de la hoja (Alternaria sp.), pudrición ácida (Aspergillus spp.), etc. Simultáneamente a la competencia con hongos patógenos del suelo, favorecen el crecimiento y desarrollo radicular de la planta hospedante.",
    composition: {
      "Bacillus subtillis": "",
      "Bacillus amyloliquefaciens": "",
      "Bacillus pumillus": "1,50 x 108UFC/ml",
      "Ingredientes activos c.s.p": "100 %",
    },
    cultivation: [],
    hierarchy: "Control biológico - Fungicida",
    supplier: "FORBIO",
    countries: ["Colombia"],
    image: "productsImg/optimized/forcontrol-plus.webp",
  },
  {
    name: "Tricogenia",
    description:
      "TRICOGENIA es un inoculante en suspensión elaborado a partir un hongo del género Trichoderma harzianum, promotor del crecimiento vegetal de la planta",
    benefits:
      "Tricogenia es un fungicida biológico elaborado con hongos del género Trichoderma harzianum. Actúa como hiperparásito competitivo, que produce metabolitos antifúngicos y enzimas hidrolíticas a los que se les atribuyen los cambios estructurales a nivel celular en los hongos con los que interactúa, sin afectar en modo alguno a los cultivos. Además, compite con los patógenos por espacio y nutrientes. Tricogenia presenta además acción solubilizadora de nutrientes y minerales del suelo, lo que conlleva a un aumento en el crecimiento de las plantas. Las cepas de alta efectividad de Tricogenia resultan muy eficaces en el control de enfermedades del suelo, causadas por hongos de los géneros: Phytophthora, Rhizoctonia, Sclerotium, Pythium y Fusarium.",
    composition: {
      "Trichoderma harzianum": "1,17 x 108 conidios/ml",
      "Ingredientes activos c.s.p": "100%",
    },
    cultivation: [],
    hierarchy: "Control biológico - Fungicida",
    supplier: "FORBIO",
    countries: ["Colombia"],
    image: "productsImg/optimized/tricogenia.webp",
  },
  {
    name: "Advance IN",
    description:
      "Inoculante que controla el desarrollo del ciclo de vida de invertebrados plaga mediante la producción de toxinas que limitan sus mecanismos de defensa natural.",
    benefits:
      "Advance In es un inoculante biológico líquido compuesto por Purpureocillium lilacinum metaboitos de  Beauveria bassiana, que actúan como controladores biológicos de insectos, ácaros y nematodos. Estos hongos secretan enzimas que afectan al hospedador y producen toxinas que limitan los mecanismos de defensa de las plagas. Además, mejoran la fertilidad y salud del suelo, promoviendo el crecimiento y rendimiento de las plantas de manera sostenible. Su uso reduce la dependencia de fertilizantes sintéticos y pesticidas, contribuyendo a prácticas agrícolas más respetuosas con el medio ambiente.",
    composition: {
      "Purpureocillium lilacinum": " 1 x 108 conidios/mL",
      "Ingredientes activos c.s.p": "100%",
    },
    cultivation: [],
    hierarchy: "Control biológico - Insecticida",
    supplier: "FORBIO",
    countries: ["Colombia"],
    image: "productsImg/optimized/Advance In2.webp",
  },
  {
    name: "Forcontrol BTS",
    description:
      "FORCONTROL BTS es un inoculante elaborado a partir de la unión de bacterias Bacillus subtilis y Bacillus thuringensis, actúan como agentes de control biológico de plagas.",
    benefits:
      "FORCONTROL BTS puede actuar como controlador biológico de diversos hongos fitopatógenos y favorecer el crecimiento y desarrollo radicular de las plantas a nivel edáfico y foliar. Es un inoculante acuoso elaborado a partir de la unión de bacterias Bacillus subtilis y Bacillus thuringensis. Estos actúan como agentes de control biológico de plagas, mediante la producción de enzimas quitinoliticas y celuloliticas. B. subtilis genera la degradación del tejido epitelial causando deformaciones y facilitando el ingreso de cristales de origen proteico producido por B. thuringensis. Este ocasiona la ruptura de las células intestinales provocando efectos letales y subletales sobre las plagas. Su fórmula se compone de un microorganismo activo formulado con una cepa aislada e identificada bioquímicamente y molecularmente. La bacteria forma endosporas que aplicadas a las semillas, se expanden por las raíces de las plántulas y compiten con hongos fitopatógenos del suelo constituyendo su función defensiva.",
    composition: {
      "Bacillus subtilis": "6,97 x 108 UFC/mL",
      "Bacillus thuringiensis": "6,97 x 108 UFC/mL",
      "Ingredientes activos c.s.p": "100%",
    },
    cultivation: [],
    hierarchy: "Control biológico - Insecticida",
    supplier: "FORBIO",
    countries: ["Colombia"],
    image: "productsImg/optimized/forcontrol bts.webp",
  },
  {
    name: "Neem First",
    description:
      "Extracto vegetal de uso Agrícola: Insecticida orgánico Neemfirst, puede ser aplicado tanto como una prevención como un tratamiento para la infestación de insectos dañinos.",
    benefits:
      "El Neemfirst® es un bioinsumo formulado a base de extracto de semilla de Neem (Azadirachta indica), extracto del árbol de Karanjan (Pongamia pinnata) y aceite de Canela (Cinnamomum verum) que combina las propiedades repelentes de la canela y los efectos de disruptor del crecimiento reconocidos del extracto de Neem y Karanja. El Neemfirst® tiene efecto de contacto e ingestión, inhibiendo la edición o muda de la glándula que se produce en el cerebro de los insectos a controlar. Es un potente repelente, anti alimentario y regulador de crecimiento de plagas como la mosca Blanca y de los ácaros.",
    composition: {
      "Extracto de Neem (Azadirachta indica)": "350 g/L",
      "Extracto de Karanja (Pongamia pinnata)": "400 g/L",
      "Aceite de Canela (Cinnamomum verum)": "200 g/L",
      "Ingredientes Aditivos c.s.p": "1 Litro",
    },
    cultivation: [],
    hierarchy: "Control biológico - Insecticida",
    supplier: "FIRST",
    countries: ["Colombia"],
    image: "productsImg/optimized/Neem First.webp",
  },
  {
    name: "Azoduo",
    description:
      "AZODUO es un inoculante biológico en suspensión concentrada. Fijador de nitrógeno simbiótico y de vida libre, enraizador y bioestimulante",
    benefits:
      "Elaborado a partir de un consorcio microbiano entre Azotobacter chroococcum y Azospirillum brasilense, que fortalece el crecimiento de las plantas.Favorece el crecimiento y fijación de nitrógeno mejorando el área rizosférica de la planta.Por su carácter biológico AZODUO no genera impacto nocivo sobre el medio ambiente.",
    composition: {
      "Azospirillum brasilense": "1,20 x 10^8 UFC/ml",
      "Azotobacter chroococcum": "100%",
      "pH en inoculantes biológicos": "7,7 a 8,5",
      "Densidad en inoculantes": "1,0 a 1,1 (g/ml)",
      "Pureza microbiológica": "100 %",
    },
    cultivation: ["Cereales"],
    hierarchy: "Biofertilizantes - Promotor crecimiento",
    supplier: "FORBIO",
    countries: ["Colombia"],
    image: "productsImg/optimized/azoduo.webp",
  },
  {
    name: "Calibor",
    description:
      "Fertilizante foliar corrector de carencias de Calcio y Boro que asegura un adecuado balance de los nutrientes en los cultivos.",
    benefits:
      "Fertilizante foliar formulado para asegurar un adecuado balance de nutrientes para la fertilización de los cultivos. Contiene una combinación equilibrada de nutrientes esenciales con un enfoque en Boro, micronutriente vital para el crecimiento y desarrollo adecuado de las plantas. Este fertilizante se encuentra formulado a base de heotagluconato de Calcio y Boro acomplejado en monoetanilamina para asegurar un adecuado balance de los dos nutrientes, asegurando una máxima absorción por las plantas tanto por vía foliar como radicular. La aplicación de ambos elementos al mismo tiempo permite aprovechar la sinergia en su absorción consiguiendo una asimilación optima de ambos nutrientes.",
    composition: {
      "Óxido de calcio soluble en agua (CaO)": "7 %",
      "Óxido de calcio complejado por glucoheptonato": "7 %",
      "Boro soluble en agua (B)": "1 %",
      pH: "4,6 – 5,8",
      "Intervalo de pH en el que se garantiza una buena estabilidad de la fracción complejada":
        " pH entre 3 y 9",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Quelatos y Complejos",
    supplier: "MERISTEM",
    countries: ["Colombia"],
    image: "productsImg/optimized/Calibor.webp",
  },
  {
    name: "Calibor",
    description:
      "Fertilizante foliar corrector de carencias de Calcio y Boro que asegura un adecuado balance de los nutrientes en los cultivos.",
    benefits:
      "Fertilizante foliar formulado para asegurar un adecuado balance de nutrientes para la fertilización de los cultivos. Contiene una combinación equilibrada de nutrientes esenciales con un enfoque en Boro, micronutriente vital para el crecimiento y desarrollo adecuado de las plantas. Este fertilizante se encuentra formulado a base de heotagluconato de Calcio y Boro acomplejado en monoetanilamina para asegurar un adecuado balance de los dos nutrientes, asegurando una máxima absorción por las plantas tanto por vía foliar como radicular. La aplicación de ambos elementos al mismo tiempo permite aprovechar la sinergia en su absorción consiguiendo una asimilación optima de ambos nutrientes.",
    composition: {
      "Óxido de calcio soluble en agua (CaO)": "7 %",
      "Óxido de calcio complejado por glucoheptonato": "7 %",
      "Boro soluble en agua (B)": "1 %",
      pH: "4,6 – 5,8",
      "Intervalo de pH en el que se garantiza una buena estabilidad de la fracción complejada":
        " pH entre 3 y 9",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Quelatos y Complejos",
    supplier: "MERISTEM",
    countries: ["Ecuador"],
    image: "productsImg/optimized/Calibor.webp",
  },
  {
    name: "Etaboro",
    description:
      "Solución liquida para corregir la carencia de Boro en las plantas por vía foliar, el Boro es aportado en forma de Boroetanolamina.",
    benefits:
      "El Boro al ser aportado en forma de Boroetanolamina lo que favorece su estabilidad y absorción a través de las hojas. El Boro es esencial para la división y elongación de las células vegetales y reforzar la pared celular para la polinización, floración, producción de semillas y transporte de azúcar, así como para el buen funcionamiento del sistema hormonal de la planta. La carencia de Boro provoca la malformación en yemas florales, secado de brotes, acortamiento de entrenudos, deformaciones, baja viabilidad del polen y desarrollo inhibido de semillas.",
    composition: {
      "Boro soluble en agua (B)": "11 %",
      "Boro en forma de boroetalonamina": "",
      pH: " 7,3 - 8,3",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Quelatos y Complejos",
    supplier: "MERISTEM",
    countries: ["Colombia"],
    image: "productsImg/optimized/Etaboro.webp",
  },
  {
    name: "Etaboro",
    description:
      "Solución liquida para corregir la carencia de Boro en las plantas por vía foliar, el Boro es aportado en forma de Boroetanolamina.",
    benefits:
      "El Boro al ser aportado en forma de Boroetanolamina lo que favorece su estabilidad y absorción a través de las hojas. El Boro es esencial para la división y elongación de las células vegetales y reforzar la pared celular para la polinización, floración, producción de semillas y transporte de azúcar, así como para el buen funcionamiento del sistema hormonal de la planta. La carencia de Boro provoca la malformación en yemas florales, secado de brotes, acortamiento de entrenudos, deformaciones, baja viabilidad del polen y desarrollo inhibido de semillas.",
    composition: {
      "Boro soluble en agua (B)": "11 %",
      "Boro en forma de boroetalonamina": "",
      pH: " 7,3 - 8,3",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Quelatos y Complejos",
    supplier: "MERISTEM",
    countries: ["Ecuador"],
    image: "productsImg/optimized/etaboro-ec.webp",
  },
  {
    name: "Glibor Ca",
    description:
      "Glibor Calcium es un producto que contiene boroetanolamina y calcio activado con glicinabetaína en altas concentraciones y equilibrada proporción.",
    benefits:
      "Contiene una combinación equilibrada de nutrientes esenciales con enfoque en Calcio (Ca) elemento vital para el desarrollo saludable de las plantas. Gracias a su fórmula el calcio y el boro permanecen solubles y pueden ser fácilmente absorbidos por la planta, la glicin-betaína potencia el efecto del fertilizante. Proporciona una forma altamente biodisponible de Calcio que es fácilmente absorbido por las hojas de las plantas. El Calcio desempeña un papel crucial en la estructura celular, el crecimiento de los tejidos y la resistencia a enfermedades y estrés abiótico. Gracias a su fórmula exclusiva, calcio y boro permanecen solubles y puede ser fácilmente absorbidos por la planta que los emplea para mejorar la firmeza de los tejidos y la fertilidad del polen. La glicin-betaína potencia el efecto del fertilizante.",
    composition: {
      "Calcio soluble en agua (CaO)": "118,1 g/L",
      "Boro soluble en agua (B)": "30,29 g/L",
      "pH en solución al 10% ": "8,35",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Quelatos y Complejos",
    supplier: "BIOLCHIM",
    countries: ["Perú"],
    image: "productsImg/optimized/Glibor Ca.webp",
  },
  {
    name: "Manvert Calcio",
    description:
      "Fertilizante foliar complejado con ácido lignosulfónico que actúa como corrector de las deficiencias de calcio, o como aporte extra de este elemento mejorando la calidad y consistencia de los frutos.",
    benefits:
      "Manvert Calcio puede utilizarse como corrector de las deficiencias de calcio, o para realizar aportaciones extras de este elemento y mejorar así la calidad y consistencia de los frutos ya que el calcio es un componente fundamental de la pared y membrana celular. Permite obtener frutos de mejor calidad, más firmes y resistentes a la manipulación, previene o detiene la caída de flores y frutos y prolonga la vida post cosecha de frutas y hortalizas. Manvert Calcio está especialmente indicado para evitarlas fisiopatías debidas a la carencia de este elemento, como son: el blossom end rot del tomate, fresón y sandía, el bitter pit de la manzana, el corazón negro de la remolacha, nabo, apio, espárragos, leguminosas y el agrietado del fruto “cracking”.",
    composition: {
      "Nitrógeno total (N)": "97 g/L",
      "Nitrógeno nítrico (N)": "97 g/L",
      "Calcio total (CaO)*": "195 g/L",
      "*Agente complejante": "Ácido Lignosulfónico",
      "pH en solución al 10 %": "3,5",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Quelatos y Complejos",
    supplier: "MANVERT",
    countries: ["Colombia"],
    image: "productsImg/optimized/Manvert-Calcio.webp",
  },
  {
    name: "Manvert Complex Arroz",
    description:
      "Mezcla líquida de microelementos complejados diseñado para satisfacer las necesidades de estos elementos en diferentes etapas.",
    benefits:
      "Es un corrector complejado que actúa como un agente preventivo de las carencias de zinc. cobre, hierro y manganeso. Puede aplicarse tanto por vía foliar, como en fertiirrigación o aspersión y en cultivo hidropónico. Puede aplicarse también en pulverización aérea. Tiene una formulación balanceada específica para el tratamiento de carencias nutricionales en cultivos de cereal, aunque puede emplearse en toda clase de cultivos, sobre todo en los más sensibles a la carencia de estos elementos.",
    composition: {
      "Cobre (Cu)*": "12 g/L",
      "Hierro (Fe)*": "12 g/L",
      "Manganeso (Mn)*": "12 g/L",
      "Zinc (Zn)*": "49 g/L",
      "*Agente complejante": "Ácido Lignosulfónico",
      "pH en solución al 10 %": "3,5 g/L",
    },
    cultivation: ["Semestrales"],
    hierarchy: "Nutrición / Estimulación Foliar - Quelatos y Complejos",
    supplier: "MANVERT",
    countries: ["Colombia"],
    image: "productsImg/optimized/Manvert complex arroz_5L_alta.webp",
  },
  {
    name: "Manvert Ironestrene",
    description:
      "Quelato de Hierro EDDHSA sólido, corrector de la clorosis férrica. Alta efectividad en suelos calcáreos y alcalinos aplicándolo por fertiirrigación.",
    benefits:
      "Manvert Ironestene está especialmente formulado para corregir la clorosis férrica. La carencia de hierro se produce en la mayoría de los suelos alcalinos y calcáreos ya que el pH elevado no permite la solubilidad del hierro en el suelo. El intervalo de pH en el que se garantiza la estabilidad en la aplicación al suelo de la fracción quelatada es de 4-12. Manvert Ironestene favorece la síntesis de clorofila, mejora el proceso respiratorio de la planta, incrementa el metabolismo de las proteínas, interviene en la fijación del nitrógeno y reducción de los nitratos, favorece la activación de enzimas y potencia las reacciones fundamentales de óxido reducción. Manvert Ironestene tiene alta efectividad en suelos calcáreos y alcalinos aplicándolo por fertiirrigación. Su efectividad aumenta mediante una aplicación fraccionada a lo largo del ciclo en lugar de realizar una sola aplicación.",
    composition: {
      "Hierro soluble en agua (Fe)": "6,0 g/L",
      "Fracción quelatada orto-orto": "4,0 mínimo",
      "Agente quelatante": "EDDHSA",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Café",
      "Flores de corte",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Quelatos y Complejos",
    supplier: "MANVERT",
    countries: ["Colombia"],
    image: "productsImg/optimized/Manvert Ironestrene.webp",
  },
  {
    name: "Manvert Magnesio",
    description:
      "Fertilizante foliar complejado con ácido lignosulfónico que actúa como corrector de las deficiencias de Magnesio, o como aporte extra de este elemento.",
    benefits:
      "Manvert Magnesio está indicado para corregir las deficiencias de magnesio y mejorar los niveles de este elemento en las plantas y favorecer los procesos en los que interviene, como son: · La fotosíntesis, el magnesio es uno de los constituyentes de la clorofila, jugando un papel prioritario en la fotosíntesis. · Formación de proteínas y vitaminas. · Formación y acumulación de hidratos de carbono, proteínas, vitaminas. · Fijación de nitrógeno atmosférico en las leguminosas. · Actuar como complemento en todas las enzimas que activan el proceso de fosforilación. · Aumentar la resistencia de la planta en medios adversos: frío, sequía, enfermedades, etc.",
    composition: {
      "Nitrógeno total (N)": "80 g/L",
      "Nitrógeno nítrico (N)": "80 g/L",
      "Magnesio total (MgO)*": "104 g/L",
      "Agente complejante": "Ácido Lignosulfónico",
      "pH en solución al 10 %": "3,01",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Quelatos y Complejos",
    supplier: "MANVERT",
    countries: ["Colombia"],
    image: "productsImg/optimized/Manvert magnesio 5L alta.webp",
  },
  {
    name: "Manvert Zinc",
    description:
      "Fertilizante foliar complejado con ácido lignosulfónico que corrige deficiencias de Zinc. Potenciador del crecimiento celular y desarrollo de la planta gracias a que favorece la síntesis de auxinas.",
    benefits:
      "Fertilizante rico en Zinc complejado con ácido lignosulfónico que mejora el ingreso de este elemento a la planta. Manvert Zinc está indicado para prevenir y corregir las deficiencias de Zinc en todos los cultivos y favorecer los procesos en los que interviene este elemento, como son: · Activar enzimas y hormonas promotoras de crecimiento. · Formación, mantenimiento y metabolismo de las auxinas (Síntesis del triptófano). · Síntesis de ácidos nucleicos y proteínas participando como cofactor enzimático. · Interviene en la fotosíntesis · Evitar entrenudos cortos y el arrosetado de hojas pequeñas. · Aumentar la formación y brotamiento de yemas.",
    composition: {
      "Nitrógeno total (N)": "42 g/L",
      "Nitrógeno nítrico (N)": "42 g/L",
      "Zinc total (Zn)*": "96 g/L",
      "Agente complejante": "Ácido Lignosulfónico",
      "pH en solución al 10 %": "3,78",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Quelatos y Complejos",
    supplier: "MANVERT",
    countries: ["Colombia"],
    image: "productsImg/optimized/Manvert  zinc_5L_alta 1.webp",
  },
  {
    name: "Meristem FE-6",
    description:
      "Formulación solida de quelato de hierro EDDHA, indicado para prevenir y corregir las carencias de hierro de cualquier tipo de cultivo por vía radicular.",
    benefits:
      "El Hiero es un elemento fundamental para el metabolismo de la planta, cuya deficiencia causa graves problemas de rendimiento en los cultivos, el hierro es un microelemento indispensable para la formación de clorofila y para el transporte de oxígeno en la planta. Meristem Fe-6 posee un elevado porcentaje de EDDHA en forma de isómero orto-orto, siendo la forma más estable del quelato, evitando de forma eficiente la clorosis férrica en los cultivos. Dado su amplio intervalo de estabilidad de pH puede ser usado en cualquier tipo de suelo, incluido los suelos calcáreos de pH básico. El agente quelatante EDDHA garantiza la disponibilidad del hierro incluido los suelos alcalinos, siendo diferenciador de otros agentes quelatantes.",
    composition: {
      "Hiero soluble en agua (Fe)": "6.0 %",
      "Hierro quelatado por EDDHA (Fe)": "6.0 %",
      "Hierro quelatado por EDDHA en posición orto-orto (min) (Fe)": "4.0 %",
      "pH en solución al 1 %": "8.5 +/- 0.5",
      "Intervalo de pH en el que se garantiza una buena estabilidad de la fracción quelatada":
        " pH entre 3.5 y 11",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Café",
      "Flores de corte",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Quelatos y Complejos",
    supplier: "MERISTEM",
    countries: ["Colombia"],
    image: "productsImg/optimized/meristemfe6.webp",
  },
  {
    name: "Meristem FE-6",
    description:
      "Formulación solida de quelato de hierro EDDHA, indicado para prevenir y corregir las carencias de hierro de cualquier tipo de cultivo por vía radicular.",
    benefits:
      "El Hiero es un elemento fundamental para el metabolismo de la planta, cuya deficiencia causa graves problemas de rendimiento en los cultivos, el hierro es un microelemento indispensable para la formación de clorofila y para el transporte de oxígeno en la planta. Meristem Fe-6 posee un elevado porcentaje de EDDHA en forma de isómero orto-orto, siendo la forma más estable del quelato, evitando de forma eficiente la clorosis férrica en los cultivos. Dado su amplio intervalo de estabilidad de pH puede ser usado en cualquier tipo de suelo, incluido los suelos calcáreos de pH básico. El agente quelatante EDDHA garantiza la disponibilidad del hierro incluido los suelos alcalinos, siendo diferenciador de otros agentes quelatantes.",
    composition: {
      "Hiero soluble en agua (Fe)": "6.0 %",
      "Hierro quelatado por EDDHA (Fe)": "6.0 %",
      "Hierro quelatado por EDDHA en posición orto-orto (min) (Fe)": "4.0 %",
      "pH en solución al 1 %": "8.5 +/- 0.5",
      "Intervalo de pH en el que se garantiza una buena estabilidad de la fracción quelatada":
        " pH entre 3.5 y 11",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Café",
      "Flores de corte",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "Nutrición / Estimulación Foliar - Quelatos y Complejos",
    supplier: "MERISTEM",
    countries: ["Ecuador"],
    image: "productsImg/optimized/meristemfe6.webp",
  },
  {
    name: "POLYAMINE CALCIUM ORGANIC",
    description:
      "Este complejo de aminoácidos está fabricado y diseñado para la aplicación foliar y del suelo para prevenir o corregir deficiencias",
    benefits:
      "Fácilmente disponible y es absorbido rápidamente por la planta Soluble en agua y no tóxico para las plantas Proporciona un amplio rango de facilidad para mezclarlo con otros fertilizantes o productos químicos",
    composition: {
      "Calcio (Ca)": "5,0 %",
      "Complejo de Calcio derivado de complejo de aminoácidos de Calcio (Ca)":
        "5,0 %",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Flores de corte",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Semestrales",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales Pasturas",
      "Viveros.",
    ],
    hierarchy: "VERDESIAN - Quelatos y Complejos",
    supplier: "VERDESIAN",
    countries: ["Perú"],
    image: "productsImg/optimized/POLYAMINE CALCIUM ORGANIC.webp",
  },
  {
    name: "POLYAMINE COPPER ORGANIC",
    description:
      "Este complejo de aminoácidos está fabricado y diseñado para aplicación foliar y al suelo para prevenir o corregir deficiencias",
    benefits:
      "Este producto está fácilmente disponible y es rápidamente absorbido por la planta. Está diseñado para aplicación foliar, en hileras o al voleo. Es soluble en agua y no tóxico para las plantas Proporciona una amplia gama de facilidad de mezcla con otros fertilizantes o productos químicos.",
    composition: {
      "Cobre (Cu)": "2,0 %",
      "Complejo de Cobre derivado de complejo de aminoácidos de Cobre (Cu)":
        "2,0 %",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "VERDESIAN - Quelatos y Complejos",
    supplier: "VERDESIAN",
    countries: ["Perú"],
    image: "productsImg/optimized/POLYAMINE COPPER ORGANIC.webp",
  },
  {
    name: "POLYAMINE IRON ORGANIC",
    description:
      "Este complejo de aminoácidos está fabricado y diseñado para aplicación foliar y al suelo para prevenir o corregir deficiencias",
    benefits:
      "Este producto está fácilmente disponible y es rápidamente absorbido por la planta. Está diseñado para aplicación foliar, en hileras o al voleo. Es soluble en agua y no tóxico para las plantas Proporciona una amplia gama de facilidad de mezcla con otros fertilizantes o productos químicos.",
    composition: {
      "Hierro (Fe)": "5,5 %",
      "Complejo de Hierro derivado de complejo de aminoácidos de Hierro (Fe)":
        "5,5 %",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "VERDESIAN - Quelatos y Complejos",
    supplier: "VERDESIAN",
    countries: ["Perú"],
    image: "productsImg/optimized/POLYAMINE IRON ORGANIC.webp",
  },
  {
    name: "POLYAMINE MANGANESE ORGANIC",
    description:
      "Este complejo de aminoácidos está fabricado y diseñado para aplicación foliar y al suelo para prevenir o corregir deficiencias",
    benefits:
      "Este producto está fácilmente disponible y es rápidamente absorbido por la planta. Está diseñado para aplicación foliar, en hileras o al voleo. Es soluble en agua y no tóxico para las plantas Proporciona una amplia gama de facilidad de mezcla con otros fertilizantes o productos químicos.",
    composition: {
      "Manganeso (Mn)": "5,6 %",
      "Complejo de Manganeso derivado de complejo de aminoácidos de Manganeso (Mn)":
        "5,6 %",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros.",
    ],
    hierarchy: "VERDESIAN - Quelatos y Complejos",
    supplier: "VERDESIAN",
    countries: ["Perú"],
    image: "productsImg/optimized/POLYAMINE MANGANESE ORGANIC.webp",
  },
  {
    name: "Li Plus",
    description:
      "LI PLUS® es un surfactante penetrante no iónico de baja formación de espuma",
    benefits:
      "Reduce la deriva del rociado fuera del objetivo Reduce el pH del agua rociada Proporciona una cobertura más uniforme de las soluciones Puede utilizar para acidificar (reducir el pH) de soluciones de pulverización Mejora la deposición y retarda la deriva al producir un patrón de pulverización más uniforme",
    composition: {
      "Fosfatidilcolina, ácido metilacético Alquil Polioxietileno Eter":
        "80,0 %",
    },
    cultivation: [],
    hierarchy: "NUTRIEN - Tensoactivo - Humectante",
    supplier: "NUTRIEN",
    countries: ["Perú"],
    image: "productsImg/optimized/Li Plus.webp",
  },
  {
    name: "Choice",
    description:
      "Es un agente acondicionador de agua que permite mejorar el desempeño de la mezcla de soluciones para aplicación en spray",
    benefits:
      "Los componentes de CHOICE® actúan como secuestrantes y agentes quelantes de los iones de las aguas duras y ayudan en la asimilación de algunos herbicidas en ciertas especies de plantas. Está formulado para ayudar al rendimiento y la mezcla de soluciones de pulverización Los componentes de CHOICE® secuestran y quelatan iones de agua dura. Ayudará a que ciertas especies de plantas absorban algunos herbicidas.",
    composition: {
      "Propionato de Amonio, Ácido cítrico, Dietilenglicol alcohol, Etoxilado fosfato":
        "100 %",
      pH: "4,3 - 4,6",
    },
    cultivation: [],
    hierarchy: "NUTRIEN - Correctores de Agua",
    supplier: "NUTRIEN",
    countries: ["Perú"],
    image: "productsImg/optimized/Choice.webp",
  },
  {
    name: "Manvert H2O Plus",
    description:
      "Manvert H2O Plus es un producto soluble en agua con manganeso que mejora la calidad del agua en pulverizaciones agrícolas: - Regula el pH con indicador de color - Corrige la dureza del agua y actúa como tensioactivo.",
    benefits:
      "Manvert H2O plus es un fertilizante totalmente soluble en agua que contiene manganeso. Es un producto desarrollado para mejorar la calidad de las aguas utilizadas en la pulverización de agroquímicos y productos nutricionales con 4 funciones principales: regular el pH del agua con indicador de color, corrector de la dureza del agua y efecto tensioactivo.",
    composition: {
      Manganeso: "6,5 %",
      "Ingredientes Inertes": "1,5 %",
    },
    cultivation: [],
    hierarchy: "Foliares - Correctores de Agua",
    supplier: "MANVERT",
    countries: ["Panamá"],
    image: "productsImg/optimized/Manvert H2O Plus.webp",
  },
  {
    name: "Disan inicio 12-27-15",
    description:
      "Fertilizante NPK mezcla física con alto contenido de fósforo a base de MAP recubierto que reduce la fijación del fósforo en el suelo. Ideal para etapas iniciales del cultivo.",
    benefits:
      "Mezcla química NPK balanceada ideal para etapas iniciales de desarrollo del cultivo. Fuente de Nitrógeno amoniacal y fósforo proveniente del MAP recubierto, que hace de Disan Inicio una fuente de alta eficiencia. Ideal para el desarrollo de raíces y procesos de alta demanda energética. Mezcla química que permite un suministro uniforme de nitrógeno, fósforo, potasio y azufre.",
    composition: {
      "Nitrógeno total N": "12.0%",
      "Nitrógeno ureico N-NH2": "6.3%",
      "Nitrógeno amoniacal N-NH4": "5.7%",
      "Fósforo soluble en agua P2O5": "27.0%",
      "Potasio soluble en agua K2O": "15.0%",
    },
    cultivation: [
      "Semestrales",
      "Papa",
      "Café",
      "Palma",
      "Tomate",
      "Banano",
      "Plátano",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Espárragos",
      "Cacao",
      "Vid/Uva",
      "Caña",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Perennes",
      "Viveros",
    ],
    hierarchy: "Fertilizantes Edáficos - Mezclas Físicas NPK",
    supplier: "DISAN",
    countries: ["Perú"],
    image: "productsImg/optimized/disan-inicio-12-27-15.webp",
  },
  {
    name: "CTA-Humus NK",
    description:
      "Acondicionador orgánico húmico para aplicación al suelo, indicado para mejorar las condiciones de intercambio catiónico y optimizar la absorción de nutrientes.",
    benefits:
      "Acondicionador de suelos líquido rico en extracto húmico. Aporta al suelo tanto ácidos húmicos como ácidos fúlvicos por lo que mejora la estructura del suelo tanto de manera inmediata como a largo plazo. La aplicación de CTA HUMUS mejora la estructura del suelo, aumentando la permeabilidad del suelo en terrenos compactos y la capacidad de retención de agua en terrenos arenosos. Incrementa la capacidad de intercambio catiónico, aumentando la disponibilidad de nutrientes para la planta y con ello obteniendo una mayor productividad.",
    composition: {
      "Nitrógeno total N": "36.0 g/L",
      "Nitrógeno ureico N-NH2": "36.0 g/L",
      "Potasio soluble en agua K2O": "60.0 g/L",
      "Carbono orgánico oxidable total": "93.0 g/L",
      "Carbono del extracto húmico total CEHT": "85.3 g/L",
      "Carbono de ácidos húmicos CAH": "83.3 g/L",
      "Carbono de ácidos fúlvicos CAF": "2.0 g/L",
      "pH en solución al 10%": "12.07",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Flores de corte",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Pasturas",
      "Viveros",
    ],
    hierarchy: "Tecnologías Solubles - Acondicionadores del suelo",
    supplier: "MERISTEM",
    countries: ["Colombia"],
    image: "productsImg/optimized/cta-humus-nk.webp",
  },
  {
    name: "Nitra K 46",
    description:
      "El Nitrato de Potasio Ultrasol® K, es completamente soluble, diseñado para aplicaciones vía riego tecnificado.",
    benefits:
      "Aporte de Nitrógeno 100% Nítrico, fuente eficiente para la nutrición vegetal dado que genera sinergismo con la absorción de Potasio, Calcio y Magnesio. El Nitrato de Potasio, es también un fuerte antagonista de los Cloruros, evitando que sean absorbidos por las plantas cultivadas en suelos o aguas de alta salinidad. En condiciones de suelo ácido, el Nitrato de Potasio juega un rol importante evitando o neutralizando la acidificación generada por la fuente de Nitrógeno Amoniacal – ureico. Ultrasol® K asegura calidad y condición en las cosechas debido al efecto sobre el tamaño final y color en flores y frutos.",
    composition: {
      "Nitrógeno total (N)": "13.5%",
      "Potasio (K2O)": "46.0%",
      "pH solución al 1%": "7.0 - 9.0",
    },
    cultivation: [
      "Flores de corte",
      "Fresa",
      "Papa",
      "Arándanos",
      "Banano",
      "Plátano",
      "Mango",
      "Cítricos",
      "Palto/Aguacate",
      "Vid/Uva",
      "Tomate",
      "Piña",
      "Cacao",
      "Frutales",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego",
      "Uso de Drench",
    ],
    hierarchy: "Tecnologías Solubles - Potasio",
    supplier: "K+S",
    countries: ["Perú"],
    image: "productsImg/optimized/nitra-k-46.webp",
  },
  {
    name: "Solumop",
    description:
      "SOLUMOP® es un muriato de potasio con solubilidad mejorada y muy bajo contenido en insolubles.",
    benefits:
      "Solumop es un fertilizante de fertiirrigación de efecto inmediato con cloruro de potasio altamente concentrado.",
    composition: {
      "Potasio soluble en agua (K2O)": "60.0%",
    },
    cultivation: ["Cultivos de fertirriego no sensibles al cloruro"],
    hierarchy: "Tecnologías Solubles - Potasio",
    supplier: "K+S",
    countries: ["Perú"],
    image: "productsImg/optimized/solumop.webp",
  },
  {
    name: "Ácido Fosfórico 85",
    description: "Función acidificante.",
    benefits:
      "Uso en limpieza de sistemas de riego y fuentes de fósforo para sistemas de fertiirrigación.",
    composition: {
      "Ácido fosfórico H3PO4": "85.0%",
      "Fósforo soluble en agua P2O5": "61.5% Min",
    },
    cultivation: [
      "Flores de corte",
      "Fresa",
      "Arándanos",
      "Banano",
      "Mango",
      "Cítricos",
      "Espárrago",
      "Aguacate/Palto",
      "Vid/Uva",
      "Tomate",
      "Piña",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego",
    ],
    hierarchy: "Tecnologías Solubles - Fósforo",
    supplier: "",
    countries: ["Colombia"],
    image: "productsImg/optimized/Ácido-Fosfórico-85-col.webp",
  },
  {
    name: "Ácido Fosfórico 85",
    description: "Función acidificante.",
    benefits:
      "Uso en limpieza de sistemas de riego y fuentes de fósforo para sistemas de fertiirrigación.",
    composition: {
      "Ácido fosfórico H3PO4": "85.0%",
      "Fósforo soluble en agua P2O5": "61.5% Min",
    },

    cultivation: [
      "Flores de corte",
      "Fresa",
      "Arándanos",
      "Banano",
      "Mango",
      "Cítricos",
      "Espárrago",
      "Aguacate/Palto",
      "Vid/Uva",
      "Tomate",
      "Piña",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego",
    ],
    hierarchy: "Tecnologías Solubles - Fósforo",
    supplier: "",
    countries: ["Perú"],
    image: "productsImg/optimized/Ácido-Fosfórico-85-col.webp",
  },
  {
    name: "Ácido Fosfórico 85",
    description: "Función acidificante.",
    benefits:
      "Uso en limpieza de sistemas de riego y fuentes de fósforo para sistemas de fertiirrigación.",
    composition: {
      "Ácido fosfórico H3PO4": "85.0%",
      "Fósforo soluble en agua P2O5": "61.5% Min",
    },

    cultivation: [
      "Flores de corte",
      "Fresa",
      "Arándanos",
      "Banano",
      "Mango",
      "Cítricos",
      "Espárrago",
      "Aguacate/Palto",
      "Vid/Uva",
      "Tomate",
      "Piña",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego",
    ],
    hierarchy: "Tecnologías Solubles - Fósforo",
    supplier: "",
    countries: ["Ecuador"],
    image: "productsImg/optimized/Ácido-Fosfórico-85-col.webp",
  },
  {
    name: "Ácido Fosfórico Disan",
    description: "Función acidificante.",
    benefits:
      "Uso en limpieza de sistemas de riego y fuentes de Fósforo para sistemas de fertiirrigación.",
    composition: {
      "Ácido fosfórico H3PO4": "85.0%",
      "Fósforo soluble en agua P2O5": "61.5%",
    },
    cultivation: [
      "Flores de corte",
      "Fresa",
      "Arándanos",
      "Banano",
      "Mango",
      "Cítricos",
      "Espárrago",
      "Aguacate/Palto",
      "Uva/Vid",
      "Tomate",
      "Piña",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego",
    ],
    hierarchy: "Tecnologías Solubles - Fósforo",
    supplier: "DISAN",
    countries: ["Ecuador"],
    image: "productsImg/optimized/Ácido Fosfórico Disan.webp",
  },
  {
    name: "Meristem 11-40-11+2MgO+Mix",
    description:
      "MERISTEM 11-40-11+2MgO+MIX es una formulación NPK para aplicación por fertiirrigación que por su alto contenido en fósforo se recomienda para favorecer la floración y el cuajado de los frutos.",
    benefits:
      "MERISTEM 11-40-11+2MgO+MIX es altamente soluble, lo que permite que pueda aplicarse fácilmente de manera homogénea mediante riego localizado. Además del aporte alto de NPK, incorpora una mezcla de boro, manganeso, molibdeno y zinc para una nutrición más eficiente del cultivo. Utiliza materias primas de alta calidad. Los productos están exentos de cloro, evitando problemas de salinidad en el suelo.",
    composition: {
      "Nitrógeno total N": "11.0%",
      "Fósforo soluble en agua P2O5": "40.0%",
      "Potasio soluble en agua K2O": "11.0%",
      "Magnesio soluble en agua MgO": "2.0%",
      "Azufre SO3": "7.0%",
      "Boro soluble en agua B": "0.02%",
      "Manganeso soluble en agua Mn": "0.20%",
      "Zinc soluble en agua Zn": "0.15%",
    },
    cultivation: [
      "Flores de corte",
      "Fresa",
      "Papa",
      "Arándanos",
      "Banano",
      "Plátano",
      "Mango",
      "Cítricos",
      "Aguacate",
      "Uva/Vid",
      "Tomate",
      "Piña",
      "Cacao",
      "Frutales",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego o uso de Drench",
    ],
    hierarchy: "Tecnologías Solubles - Compuestos NPK",
    supplier: "MERISTEM",
    countries: ["Ecuador"],
    image: "productsImg/optimized/Meristem 11-40-11+2MgO+Mix ECU.png.webp",
  },
  {
    name: "Meristem 8-4-42-2MgO+Mix",
    description:
      "MERISTEM 8-4-42+2MgO+MIX es una formulación NPK para aplicación por fertiirrigación especialmente indicada para la fase de maduración de los cultivos, que necesita un gran aporte de potasio.",
    benefits:
      "MERISTEM 8-4-42+2MgO+MIX incorpora una mezcla de boro, manganeso, molibdeno y zinc para una nutrición más eficiente del cultivo. Es altamente soluble, lo que permite su fácil aplicación mediante riego localizado. Utiliza materias primas de alta calidad, está optimizado para una rápida absorción y está exento de cloro, evitando problemas de salinidad en el suelo.",
    composition: {
      "Nitrógeno total N": "8.50%",
      "Nitrógeno amoniacal N-NH4": "0.8%",
      "Nitrógeno nítrico N-NO3": "7.7%",
      "Fósforo asimilable P2O5": "4.0%",
      "Potasio soluble en agua K2O": "42.2%",
      "Magnesio MgO": "2.1%",
      "Boro B": "0.04%",
      "Manganeso Mn EDTA": "0.2%",
      "Zinc Zn": "0.25%",
      "pH en solución al 10%": "3.05",
    },
    cultivation: [
      "Flores de corte",
      "Fresa",
      "Papa",
      "Arándanos",
      "Banano",
      "Plátano",
      "Mango",
      "Cítricos",
      "Aguacate",
      "Uva/Vid",
      "Tomate",
      "Piña",
      "Cacao",
      "Frutales",
      "Hortalizas",
      "Plantas Aromáticas",
      "Ornamentales",
      "Cultivos en fertirriego o uso de Drench",
    ],
    hierarchy: "Tecnologías Solubles - Compuestos NPK",
    supplier: "MERISTEM",
    countries: ["Ecuador"],
    image: "productsImg/optimized/Meristem 8-4-42-2MgO+Mix ECU.webp",
  },
  {
    name: "Fertileaf 20-20-20",
    description:
      "Abono Foliar de uso agrícola, formulado con concentración debidamente balanceada en NPK, micronutrientes y aminoácidos",
    benefits:
      "Formulado con concentración debidamente balanceada en NPK, micronutrientes y aminoácidos. Diseñado para corregir las carencias de los elementos más importantes de la nutrición vegetal. Mejora la producción y calidad de las cosechas.",
    composition: {
      "Nitrógeno total N": "20.0% (p/v)",
      "Fósforo soluble en agua P2O5": "20.0% (p/v)",
      "Potasio soluble en agua K2O": "20.0% (p/v)",
      "Magnesio Mg": "0.07% (p/v)",
      "Boro B": "0.02% (p/v)",
      "Hierro Fe": "0.1% (p/v)",
      "Manganeso Mn": "0.07% (p/v)",
      "Cobre Cu": "0.05% (p/v)",
      "Zinc Zn": "0.06% (p/v)",
      "Aminoácidos totales": "1.00% (p/v)",
      pH: "7.0 - 8.0",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros",
    ],
    hierarchy: "Nutrición y bioestimulación Foliar - Nutrientes",
    supplier: "DISAN",
    countries: ["Perú"],
    image: "productsImg/optimized/Fertileaf 20 20 20 Perú.webp",
  },
  {
    name: "Fertileaf 20-20-20",
    description:
      "Abono Foliar de uso agrícola, formulado con concentración debidamente balanceada en NPK, micronutrientes y aminoácidos",
    benefits:
      "Formulado con concentración debidamente balanceada en NPK, micronutrientes y aminoácidos. Diseñado para corregir las carencias de los elementos más importantes de la nutrición vegetal. Mejora la producción y calidad de las cosechas.",
    composition: {
      "Nitrógeno total N": "20.0% (p/v)",
      "Fósforo soluble en agua P2O5": "20.0% (p/v)",
      "Potasio soluble en agua K2O": "20.0% (p/v)",
      "Magnesio Mg": "0.07% (p/v)",
      "Boro B": "0.02% (p/v)",
      "Hierro Fe": "0.1% (p/v)",
      "Manganeso Mn": "0.07% (p/v)",
      "Cobre Cu": "0.05% (p/v)",
      "Zinc Zn": "0.06% (p/v)",
      "Aminoácidos totales": "1.00% (p/v)",
      pH: "7.0 - 8.0",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros",
    ],
    hierarchy: "Nutrición y bioestimulación Foliar - Nutrientes",
    supplier: "DISAN",
    countries: ["Bolivia"],
    image: "productsImg/optimized/Fertileaf 20 20 20 Bol.webp",
  },
  {
    name: "SunRed",
    description: "Bioestimulante para la coloración de los frutos.",
    benefits:
      "Estimula la coloración del fruto. Mejora la uniformidad de maduración de los frutos. Aumenta el contenido de azúcares (grado Brix). No afecta a la firmeza de la pulpa. Estimula la acumulación de pigmentos. Estimula la acumulación de azúcar. Estimula la fortificación de la pared celular.",
    composition: {
      "Nitrógeno total N": "39.9 g/L",
      "Nitrógeno orgánico N": "26.6 g/L",
      "Nitrógeno ureico N-NH2": "13.3 g/L",
      "Potasio soluble en agua K2O": "93.1 g/L",
      "Carbono orgánico C": "186.2 g/L",
    },
    cultivation: ["Mango", "Cítricos", "Vid/Uva", "Cacao", "Frutales"],
    hierarchy: "Nutrición y bioestimulación Foliar - Bioestimulantes",
    supplier: "BIOLCHIM",
    countries: ["Perú"],
    image: "productsImg/SunRed.jpg.webp",
  },
  {
    name: "Boromin Gel",
    description:
      "Fertilizante foliar a base de Boro complejado con ETANOLAMINA.",
    benefits:
      "Corrector de carencia de Boro. Producto desarrollado para conseguir una rápida y exacta dosificación de Boro. Mejora la floración, fecundación y cuajado de frutos. Facilita la germinación del polen ayudando al desarrollo del tubo polínico. Optimiza el crecimiento de raíces y brotes nuevos. Promueve la formación de nódulos en las raíces de las leguminosas. Aumenta el contenido de azúcar en los frutos y ayuda en la firmeza de los tejidos. Ayuda en la translocación del Calcio, participando en la formación de pectinas de la pared celular.",
    composition: {
      "Boro soluble en agua B": "150.7 g/L",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros",
    ],
    hierarchy: "Nutrición y bioestimulación Foliar - Quelatos y Complejos",
    supplier: "BIOLCHIM",
    countries: ["Perú"],
    image: "productsImg/optimized/boromin gel.webp",
  },
  {
    name: "Microfol Combi",
    description:
      "Fertilizante foliar formulado en una mezcla balanceada de siete micronutrientes quelatados con EDTA.",
    benefits:
      "Otorga a las plantas una respuesta inmediata para sus procesos en el crecimiento activo y desarrollo del cultivo. Correcto balance de micronutrientes. Recomendado para cultivos sensibles a la carencia de Hierro.",
    composition: {
      "Magnesio soluble en agua MgO": "9.0%",
      "Boro soluble en agua B": "0.5%",
      "Cobre quelatado soluble en agua Cu": "1.5%",
      "Hierro quelatado soluble en agua Fe": "4.0%",
      "Manganeso soluble en agua Mn": "4.0%",
      "Manganeso quelatado soluble en agua Mn": "0.6%",
      "Molibdeno soluble en agua Mo": "0.1%",
      "Zinc quelatado soluble en agua Zn": "1.5%",
      "Agente quelatante": "EDTA",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Palto/Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros",
    ],
    hierarchy: "Nutrición y bioestimulación Foliar - Quelatos y Complejos",
    supplier: "BIOLCHIM",
    countries: ["Perú"],
    image: "productsImg/optimized/Microfol-Combi.webp",
  },
  {
    name: "Meristem CaB",
    description:
      "Fertilizante foliar corrector de carencias de Calcio y Boro que asegura un adecuado balance de los nutrientes en los cultivos.",
    benefits:
      "Fertilizante foliar formulado para asegurar un adecuado balance de nutrientes para la fertilización de los cultivos. Contiene una combinación equilibrada de nutrientes esenciales con un enfoque en Boro, micronutriente vital para el crecimiento y desarrollo adecuado de las plantas. Este fertilizante se encuentra formulado a base de heotagluconato de Calcio y Boro acomplejado en monoetanilamina para asegurar un adecuado balance de los dos nutrientes, asegurando una máxima absorción por las plantas tanto por vía foliar como radicular. La aplicación de ambos elementos al mismo tiempo permite aprovechar la sinergia en su absorción consiguiendo una asimilación óptima de ambos nutrientes.",
    composition: {
      "Calcio soluble en agua CaO": "8.80%",
      "Calcio complejado por ácido heptaglucónico": "8.80%",
      "Boro soluble en agua": "1.26%",
      "Sodio total Na2O": "1.50%",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros",
    ],
    hierarchy: "Nutrición y bioestimulación Foliar - Quelatos y Complejos",
    supplier: "MERISTEM",
    countries: ["Ecuador"],
    image: "productsImg/optimized/meristem cab-03.webp",
  },
  {
    name: "Manvert Complex",
    description:
      "Mezcla líquida de microelementos complejados diseñado para satisfacer las necesidades de estos elementos en diferentes etapas.",
    benefits:
      "Es un corrector complejado que actúa como un agente preventivo de las carencias de zinc, cobre, hierro y manganeso. Puede aplicarse tanto por vía foliar, como en fertiirrigación o aspersión y en cultivo hidropónico. Puede aplicarse también en pulverización aérea. Tiene una formulación balanceada específica para el tratamiento de carencias nutricionales en toda clase de cultivos, sobre todo en los más sensibles a la carencia de estos elementos.",
    composition: {
      "Boro soluble en agua B": "0.1% (p/v)",
      "Cobre soluble en agua Cu": "0.1% (p/v)",
      "Hierro soluble en agua Fe": "1.2% (p/v)",
      "Magnesio soluble en agua MgO": "3.0% (p/v)",
      "Manganeso soluble en agua Mn": "1.2% (p/v)",
      "Molibdeno soluble Mo": "0.06% (p/v)",
      "Zinc soluble en agua Zn": "1.2% (p/v)",
      "Agente complejante": "Ácido Lignosulfónico",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros",
    ],
    hierarchy: "Nutrición y bioestimulación Foliar - Quelatos y Complejos",
    supplier: "MANVERT",
    countries: ["Panamá"],
    image: "productsImg/optimized/Manvert Complex Panamá.webp",
  },
  {
    name: "Manvert Manganeso",
    description:
      "Fertilizante foliar complejado con ácido lignosulfónico que corrige deficiencias de manganeso, factor clave en multitud de procesos enzimáticos de la planta.",
    benefits:
      "Rico en Manganeso, catalizador o activador de muchas vías enzimáticas envueltas en la fotosíntesis y en la biosíntesis de carbohidratos. Favorece la síntesis de clorofila, asimilación de nitratos, síntesis de vitaminas (riboflavina, ácido ascórbico y carotina), síntesis de aminoácidos, síntesis de ATP, síntesis de lignina, activación hormonal y división celular. El complejamiento con ácido lignosulfónico favorece el ingreso de manganeso a la planta.",
    composition: {
      "Manganeso soluble en agua Mn": "9.6% (p/v)",
      "Agente complejante": "Ácido Lignosulfónico",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Café",
      "Palma",
      "Flores de corte",
      "Caña",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Espárragos",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros",
    ],
    hierarchy: "Nutrición y bioestimulación Foliar - Quelatos y Complejos",
    supplier: "MANVERT",
    countries: ["Colombia"],
    image: "productsImg/optimized/manvert manganeso.webp",
  },
  {
    name: "Kafom K",
    description:
      "Fertilizante foliar fosfito de potasio para uso en programas fitosanitarios de control de enfermedades como activador de defensas en las plantas.",
    benefits:
      "KAFOM es un producto sistémico a base de fosfito de potásico. La acción sinérgica entre el ion fosfito y el potasio estimula las autodefensas de la planta. KAFOM incrementa la resistencia contra oomicetos como Phytophtora spp o Pythium spp, al provocar disfunciones en su metabolismo. Evita síntomas como podredumbres o ahogamientos que dificultan el adecuado desarrollo del cultivo y perjudican su rendimiento. Promueve la síntesis de fitoalexinas, con efecto antimicrobiano, y activa los sistemas de respuesta al estrés de los cultivos. Su contenido de Potasio favorece procesos de lignificación manteniendo dura y resistente la piel de los frutos. Además, aumenta la calidad y el tamaño de los frutos, así como su color y su sabor, facilitando su transporte y conservación.",
    composition: {
      "Fósforo soluble en agua P2O5": "42.0%",
      "Potasio soluble en agua K2O": "28.0%",
    },
    cultivation: [
      "Papa",
      "Tomate",
      "Banano",
      "Plátano",
      "Palma",
      "Flores de corte",
      "Piña",
      "Aguacate",
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
      "Plantas aromáticas",
      "Semestrales",
      "Pasturas",
      "Perennes",
      "Viveros",
    ],
    hierarchy: "Nutrición y bioestimulación Foliar - Fosfitos",
    supplier: "",
    countries: ["Ecuador"],
    image: "productsImg/optimized/Kafom K ECU.webp",
  },
  {
    name: "SPRAY DUNGER GLOBAL",
    description:
      "Biorregulador para plantas frutales, vitivinícolas, hortícolas, florales y ornamentales.",
    benefits:
      "Spray Dünger Global es un abono líquido foliar con una acción muy estimulante en el crecimiento vegetativo de las plantas. Desarrollo vegetativo. Adelanta la floración y maduración de frutos. Mejora el cuajado y proporciona uniformidad de tamaño de frutos. Mejora la pigmentación de la cáscara. Promueve el cuajado de frutos. Promueve el adelgazamiento de las flores laterales en actinidia. Estimula el alargamiento del racimo de vid. Mejora el tamaño y la uniformidad del fruto. Estimula la multiplicación y distensión celular, favoreciendo el crecimiento de flores y frutos. Mejora la vascularización de los tejidos, intensificando el flujo de nutrientes hacia los órganos en crecimiento. Promueve la eficiencia metabólica, contribuyendo a la mejora de la calidad del fruto.",
    composition: {
      "Nitrógeno total N": "8.0%",
      "Fósforo soluble en agua P2O5": "11.0%",
      "Potasio soluble en agua K2O": "5.0%",
    },
    cultivation: [
      "Mango",
      "Arándanos",
      "Cítricos",
      "Vid/Uva",
      "Cacao",
      "Hortalizas",
      "Frutales",
    ],
    hierarchy:
      "Nutrición y bioestimulación Foliar - Reguladores de crecimiento",
    supplier: "BIOLCHIM",
    countries: ["Perú"],
    image: "productsImg/optimized/Spray-dunger.webp",
  },
  {
    name: "ASAP 325 SC",
    description:
      "Fungicida de Uso Agrícola que debe ser aplicado en forma preventiva o tan pronto se observen los primeros síntomas de la enfermedad.",
    benefits:
      "ASAP 325 SC, es un poderoso fungicida que incluye en su fórmula dos ingredientes activos de acción complementaria, combina la acción preventiva y antiesporulante de azoxistrobina, con el efecto erradicante de difenoconazole, contra la enfermedad del complejo manchado de grano (Fusarium sp, Curvularia sp y Gaeumannomyces graminis) en el cultivo de arroz. Se debe tener una buena cobertura del producto en todas las partes de la planta.",
    composition: {
      Azoxystrobin: "200 g/L",
      Difenoconazole: "125 g/L",
      "Ingredientes Aditivos": "c.s.p. 1 Kg",
    },
    cultivation: ["Arroz"],
    hierarchy: "Protección de Cultivos - PQUA - Fungicida",
    supplier: "EASTCHEM",
    countries: ["Colombia"],
    image: "productsImg/optimized/asap 325.webp",
  },
  {
    name: "BILLARD SC",
    description:
      "BILLARD® es un fungicida sistémico que posee un efecto lipofílico que le permite tener una mejor distribución en la hoja y mayor resistencia al lavado por lluvias.",
    benefits:
      "BILLARD® presenta doble acción: preventiva y curativa, formulado como suspensión concentrada a base de dos ingredientes de alto desempeño. El Flutriafol inhibe la vía metabólica de los esteroles fúngicos afectando las membranas celulares de los hongos. El Carbendazim se adhiere fuertemente a la tubulina durante el proceso de mitosis, causando la detención del crecimiento micelial y la división celular. Mezcla innovadora con efecto de choque por su mayor velocidad de absorción. Altamente sistémico que le permite mayor distribución dentro de la planta. Alto desempeño generando un mayor rango de control por su doble mecanismo de acción.",
    composition: {
      Carbendazim: "300 g/L",
      Flutriafol: "200 g/L",
    },
    cultivation: [],
    hierarchy: "Protección de Cultivos - PQUA - Fungicida",
    supplier: "HANSE ANDINA",
    countries: ["Colombia"],
    image: "productsImg/optimized/BILLARD  SC .webp",
  },
  {
    name: "INSIGNO 540 SC",
    description:
      "INSIGNO 540 SC es un fungicida formulado como suspensión concentrada en mezcla de dos ingredientes activos con 450 g/L de Chlorothalonil más 90 g/L de Dimetomorph.",
    benefits:
      "INSIGNO 540 SC combina los mecanismos de acción del Chlorothalonil (multisitio) y de Dimetomorph sobre la biosíntesis de la pared celular. Chlorothalonil Inhibe la respiración de las células del hongo. Se considera que el clorothalonil actúa como un fungitóxico no específico, de acción rápida, pertenece al grupo de inhibidores multisitio. Dimetomorph es un derivado del ácido cinámico. Actúa bloqueando todas las fases en las que interviene la formación de la pared celular, tales como la germinación de las esporas, la formación del tubo germinativo, la formación de los haustorios, el crecimiento de las hifas y la formación de las oosporas. Tiene acción translaminar. Después de una aplicación foliar penetra en la planta y se reparte entre la superficie y el interior.",
    composition: {
      Chlorothalonil: "450 g/L",
      Dimetomorph: "90 g/L",
    },
    cultivation: ["Tomate", "Papa", "Cebolla", "Ornamentales (Rosa)"],
    hierarchy: "Protección de Cultivos - PQUA - Fungicida",
    supplier: "JEBAGRO",
    countries: ["Colombia"],
    image: "productsImg/optimized/insigno 540.webp",
  },
  {
    name: "STROBISEN 250 SC",
    description:
      "Es un fungicida sistémico de acción preventiva y curativa con efecto erradicante y protectante.",
    benefits:
      "STROBISEN ® 250 SC es un fungicida sistémico de acción preventiva y curativa con efecto erradicante y protectante, que actúa en la interferencia de la síntesis del ergosterol por inhibición de la dimetilación de los esteroles. Está compuesto por dos ingredientes activos: El azoxystrobin, inhibe la producción y germinación de las esporas y el crecimiento del micelio del hongo, a través de la inhibición de la respiración mitocondrial de los hongos. El flutriafol, al igual que otros triazales, se fija al citograma en P-450 y evita que se una con otros productos químicos propios del hongo, bloqueando de este modo la formación de ergosterol.",
    composition: {
      Azoxystrobin: "125 g/L",
      Flutriafol: "125 g/L",
    },
    cultivation: ["Banano", "Tomate", "Arroz", "Maiz", "Café"],
    hierarchy: "Protección de Cultivos - PQUA - Fungicida",
    supplier: "JEBAGRO",
    countries: ["Colombia"],
    image: "productsImg/STROBISEN 250 SC.avif",
  },
  {
    name: "VIRTUD 250 EC",
    description:
      "VIRTUD 250 EC es un fungicida agrícola sistémico que inhibe la biosíntesis del ergosterol en las membranas celulares de los hongos patógenos, deteniendo su desarrollo.",
    benefits:
      "Actúa primordialmente en el desarrollo de las hifas secundarias del patógeno dentro de los tejidos de la planta pero también lo hace, en menor escala, sobre el desarrollo y la virulencia de los conidios de los hongos. Su acción es tanto preventiva como curativa, siendo absorbido por las hojas y traslocado acrópeta y translaminarmente dentro de la planta. No es fitotóxico si se utiliza en los cultivos y dosis recomendados. Se recomienda alternar su uso con fungicidas de distinto grupo químico o forma de acción para evitar la aparición de resistencia por parte del hongo.",
    composition: {
      Difenoconazol: "250 g/L",
    },
    cultivation: ["Clavel", "Tomate", "Arroz", "Banano"],
    hierarchy: "Protección de Cultivos - PQUA - Fungicida",
    supplier: "JEBAGRO",
    countries: ["Colombia"],
    image: "productsImg/optimized/VIRTUD-1.webp",
  },
  {
    name: "Abachem 18 EC",
    description:
      "ABACHEM 1.8 EC es un eficaz insecticida-acaricida derivado del microorganismo del suelo Streptomyces avermitilis.",
    benefits:
      "ABACHEM 18 EC posee acción translaminar, actúa por contacto e ingestión, de largo efecto residual y altamente efectivo en el control de los estados móviles de los ácaros dípteros y algunos microlepidópteros. La abamectina actúa principalmente interfiriendo en el sistema nervioso de las plagas. Cuando las plagas entran en contacto con la abamectina o la ingieren los músculos de las plagas pierden su capacidad de contraerse, mostrando síntomas de parálisis, deteniendo la actividad, no alimentándose y, finalmente, muriendo. Este mecanismo de acción único hace que no presente resistencia cruzada con otros plaguicidas, y sigue teniendo un buen efecto de control sobre las plagas que ya han desarrollado resistencia.",
    composition: {
      Abamectina: "18 g/L",
    },
    cultivation: [],
    hierarchy: "Protección de Cultivos - PQUA - Acaricida",
    supplier: "DISAN",
    countries: ["Colombia"],
    image: "productsImg/optimized/abachem.webp",
  },
  {
    name: "GRUNT 700 WG",
    description:
      "Este producto es una combinación de dos insecticidas: pimetrozina y nitenpiram: El nitenpiram es un insecticida neonicotinoide que bloquea rápidamente la transmisión nerviosa en las plagas, y la pimetrozina inhibe la alimentación de insectos con piezas bucales en forma de aguja, como las chicharritas del arroz.",
    benefits:
      "GRUNT 700 WG es una mezcla de dos insecticidas: Pymetrozina y Nitenpyram, con diferentes modos de acción. Esta combinación es eficaz contra plagas de tipo perforador-sucionador en diversos cultivos. El Pymetrozine actúa impidiendo que el insecto inserte su estilete en el tejido de la planta, lo que podría inhibir la alimentación de la plaga rápidamente. El Nitenpyram puede interferir con la transmisión nerviosa normal.",
    composition: {
      Nitenpyram: "200 g/kg",
      Pymetrozina: "500 g/kg",
      "Ingredientes Aditivos": "c.s.p. 1 Kg",
    },
    cultivation: ["Arroz"],
    hierarchy: "Protección de Cultivos - PQUA - Insecticida",
    supplier: "EASTCHEM",
    countries: ["Colombia"],
    image: "productsImg/optimized/grunt 700.webp",
  },
  {
    name: "IMIDACLOPRID CRL 350 SC",
    description:
      "IMIDACLOPRID CRL 350 SC es un insecticida sistémico del grupo de los Neonicotinoides con principal actividad en el control de insectos chupadores.",
    benefits:
      "Utilizado para el control de una amplia gama de insectos plaga. Se basa en una intervención en la transmisión de estímulos en el sistema nervioso de los insectos de manera análoga a la acetilcolina, que es un transmisor químico natural del impulso nervioso, atacando a una proteína receptora.",
    composition: {
      Imidacloprid: "350 g/L",
      "Ingredientes Aditivos": "c.s.p. 1 Litro",
    },
    cultivation: [],
    hierarchy: "Protección de Cultivos - PQUA - Insecticida",
    supplier: "EASTCHEM",
    countries: ["Colombia"],
    image: "productsImg/optimized/imidacloprid crl.webp",
  },
  {
    name: "LARIHMA 250 CS",
    description:
      "LARIHMA 250 CS es un insecticida que actúa por contacto e ingestión, con propiedades repelentes.",
    benefits:
      "Tiene una eliminación rápida y efecto residual prolongado. Actúa sobre los canales de sodio del sistema nervioso central de los insectos. Los insectos afectados dejan rápidamente de alimentarse y moverse hasta morir. Es un insecticida de amplio espectro, no sistémico, para uso vía foliar y con efecto residual, de rápida actividad. En el insecto presenta actividad estomacal y de contacto, afectando a su sistema nervioso y deteniendo inmediatamente la alimentación. Interviene en el proceso de contracción muscular de los insectos, ya que actúa como activador de los receptores de rianodina, esta activación estimula la liberación de calcio de los músculos.",
    composition: {
      "Lambda-Cyhalothrina": "250 g/L",
    },
    cultivation: [],
    hierarchy: "Protección de Cultivos - PQUA - Insecticida",
    supplier: "CAC",
    countries: ["Colombia"],
    image: "productsImg/optimized/larihma 250.webp",
  },
  {
    name: "GLUFOCHEM 200 SL",
    description:
      "GLUFOCHEM 200 SL es un herbicida eficiente de uso en presiembra al cultivo, y post-emergente a las malezas cuando estén en estado de crecimiento activo.",
    benefits:
      "Es recomendado para el control de malezas de hoja ancha y gramíneas. Este herbicida ocasiona clorosis de tres a cinco días, la cual se transforma en necrosis de las plantas tratadas en una a dos semanas. El glufosinato de amonio tiene una movilidad limitada dentro de la planta y su acción es principalmente de contacto. Al bloquear la enzima glutamina se acumula amoniaco en las plantas lo que destruye las membranas celulares.",
    composition: {
      "Glufosinato de amonio": "200 g/L",
      "Ingredientes Aditivos": "c.s.p. 1 Litro",
    },
    cultivation: [],
    hierarchy: "Protección de Cultivos - PQUA - Herbicida",
    supplier: "DISAN",
    countries: ["Colombia"],
    image: "productsImg/optimized/glufochem 200.webp",
  },
  {
    name: "KITSUMI 500 SC",
    description:
      "KITSUMI® 500 SC, es un herbicida sistémico local con acción residual que actúa por contacto selectivo para el control de malezas gramíneas, inhibiendo rápidamente su crecimiento, siendo absorbido rápidamente por raíces y hojas.",
    benefits:
      "Es un herbicida de traslocación limitada y mínima por xilema, por lo tanto, requiere de contacto con el tejido joven para su óptima acción. En aplicaciones de pre-emergencia, es fuertemente adsorbido en los 2 primeros centímetros del suelo, formando una película en la superficie. Una vez las malezas germinan, estas entran en contacto con la capa de Diflufenican absorbiendo el producto el cual penetra en las adventicias por el coleóptilo. En post-emergencia, su acción por contacto se muestra eficaz sobre los tejidos jóvenes. Diflufenican, actúa inhibiendo la enzima involucrada en la biosíntesis de carotenoides, afectando el desarrollo del cloroplasto y la biosíntesis de clorofila. La degradación de la clorofila provoca el desecamiento de las malas hierbas sensibles.",
    composition: {
      Diflufenican: "500 g/L",
    },
    cultivation: ["Arroz"],
    hierarchy: "Protección de Cultivos - PQUA - Herbicida",
    supplier: "DISAN",
    countries: ["Colombia"],
    image: "productsImg/optimized/kitsumi.webp",
  },
  {
    name: "Fertileaf Adherente",
    description:
      "FERTILEAF ADHERENTE es un producto con propiedad adherente de baja toxicidad en humanos y animales de sangre caliente, disminuye la tensión superficial de los líquidos pulverizados, permitiendo mezclas homogéneas.",
    benefits:
      "Mejora la distribución y eficacia de los diferentes agroquímicos de uso agrícola. Minimiza las pérdidas por acción de las lluvias o evaporización. Promueve una rápida adherencia y dispersión sobre los diferentes objetivos aplicados como cultivos, malezas, insectos, ácaros y demás. Se mezcla fácilmente con productos plaguicidas.",
    composition: {
      "Nonyl fenol ethylene oxide Condensado": "450 g/L",
      "Ingredientes inertes": "450 g/L",
    },
    cultivation: [],
    hierarchy: "Coadyuvantes - PQUA - Tensoactivo - Humectante",
    supplier: "DISAN",
    countries: ["Perú"],
    image: "productsImg/optimized/Fertileaf Adherente PER.webp",
  },
  {
    name: "Manvert Optim pH",
    description:
      "Manvert optim-pH es un coadyuvante destinado a la regulación y estabilización del pH.",
    benefits:
      "Ofrece un efecto acidificante, con lo que evitamos la indeseada hidrólisis alcalina de las materias activas. Se evita la pérdida de eficacia del tratamiento. Permite una ralentización de la degradación de la mezcla en la superficie de la hoja. Manvert optim pH incorpora un indicador de color, variando el color según varíe el pH de la mezcla. Tiene capacidad mojante y/o penetrante. Disminuye la tensión superficial del caldo de pulverización, mejorando la distribución de la mezcla sobre la superficie de la hoja. Mejora compatibilidad de las mezclas de varios productos en un mismo tanque de pulverización.",
    composition: {
      "Nitrógeno total N": "3.0% p/p",
      "Nitrógeno ureico N-NH2": "3.0% p/p",
      "Fósforo soluble en agua P2O5": "23.0% p/p",
    },
    cultivation: [],
    hierarchy: "Coadyuvantes - PQUA - Correctores de Agua",
    supplier: "MANVERT",
    countries: ["Bolivia"],
    image: "productsImg/optimized/Manvert Optim pH.webp",
  },
  {
    name: "Vegoil",
    description: "Coadyuvante de uso Agrícola.",
    benefits:
      "VEGOIL® es un coadyuvante orgánico de aceites vegetales concentrado emulsionable, encapsulador, adherente, surfactante, dispersante de máxima penetración, que mejora la mezcla en tanque. Ayuda a mejorar las aplicaciones de insecticidas, acaricidas, fungicidas y herbicidas, para que lleguen a su objetivo, evitando derivas, un mayor cubrimiento sobre el follaje aplicado. Mayor dispersión y adherencia, además que ayuda a la penetración de agroquímicos. VEGOIL® funciona rompiendo la tensión superficial garantizando una mejor humectación, dispersión, cobertura y rápida penetración de los agroquímicos que son utilizados para controles fitosanitarios en los cultivos. Mejora la solubilidad de las formulaciones en polvos en las mezclas y mejora la penetración y dispersión en aplicaciones realizadas al suelo.",
    composition: {
      "Extracto de Neem Azadirachta indica": "450 g/L",
      "Extracto de Ricino Ricinus communi": "450 g/L",
    },
    cultivation: [],
    hierarchy: "Coadyuvantes - PQUA - Orgánico",
    supplier: "FIRST",
    countries: ["Colombia"],
    image: "productsImg/optimized/Vegoil.webp",
  },
  {
    name: "VISILON 8083",
    description:
      "El VISILON 8083 es un coadyuvante orgánico siliconado no iónico.",
    benefits:
      "Proveniente de la cascarilla de arroz, que ayuda a mejorar las aplicaciones de insecticidas, acaricidas, fungicidas, herbicidas reguladores de crecimiento y fertilizantes foliares, para que lleguen a su objetivo evitando derivas, un mayor cubrimiento sobre el follaje aplicado, mayor dispersión y adherencia, además que ayuda a la penetración de agroquímicos. VISILON 8083 funciona rompiendo la tensión superficial garantizando una mejor humectación, dispersión, cobertura y rápida penetración de los agroquímicos que son utilizados para controles fitosanitarios en los cultivos. Mejora la solubilidad de las formulaciones en polvos en las mezclas y mejora la penetración y dispersión en aplicaciones realizadas al suelo.",
    composition: {
      "Tetraxiloxano proveniente del extracto de cascarilla de arroz": "65%",
      "Ingredientes Aditivos": "c.s.p 1 Litro",
    },
    cultivation: [],
    hierarchy: "Coadyuvantes - PQUA - Orgánico",
    supplier: "FIRST",
    countries: ["Colombia"],
    image: "productsImg/optimized/VISILON LITRO-Ver 1.webp",
  },
];

module.exports = products;
