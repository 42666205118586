import React from "react";
import styled from "styled-components";
import bannerImage from "../assets/nosotros/fondo nosotros.webp";
import ColorTab from "../components/colorTab";
import relacionamiento from "../assets/nosotros/relacionamiento.png";
import soluciones from "../assets/nosotros/soluciones.png";
import asesoria from "../assets/nosotros/asesoria.png";
import suministro from "../assets/nosotros/suministro.png";
import ContactCard from "../components/ContactCard";
import { ReactComponent as AliadosIcon } from "../assets/nosotros/aliados-icon.svg";
import { ReactComponent as ConscientesIcon } from "../assets/nosotros/conscientes-icon.svg";
import { ReactComponent as FormadoresIcon } from "../assets/nosotros/formadores-icon.svg";
import { ReactComponent as PionerosIcon } from "../assets/nosotros/pioneros-icon.svg";
import { Seo } from "../utils/Seo";

const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  font-size: ${(props) => props.theme.fontSizes.normal};
  overflow-x: hidden; /* Evita el scroll horizontal */

  @media (min-width: 1280px) {
    font-size: ${(props) =>
      props.theme.fontSizes.large}; /* Tamaño de fuente más grande */
  }
`;

const Description = styled.div`
  display: flex;
  position: relative;
  padding: 5% 10%;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.primary};
  width: 100%;
  text-align: center;

  p {
    width: 100%;
    text-align: center;

    @media (min-width: 768px) {
      max-width: 50vw;
    }
  }

  @media (min-width: 1280px) {
    font-size: ${(props) =>
      props.theme.fontSizes.large}; /* Ajustar para pantallas grandes */
  }
`;

const Hero = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${(props) => props.theme.colors.green};
  color: white;
  width: 100%;
  font-size: inherit;
  padding: 5% 10%;

  p {
    width: 100%;
    max-width: 70vw;

    @media (min-width: 768px) {
      max-width: 50vw;
    }
  }

  @media (max-width: 768px) {
    padding: 5% 5%; /* Ajuste de padding en dispositivos móviles */
  }

  @media (min-width: 1280px) {
    font-size: ${(props) =>
      props.theme.fontSizes.large}; /* Ajustar tamaño de fuente */
  }
`;

const PilaresSection = styled.div`
  display: flex;
  margin-top: 5%;
  position: relative;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.secondary};
  border-radius: 20px;
  max-width: 90vw;
  color: ${(props) => props.theme.colors.primary};
  padding: 5% 10%;
  margin-bottom: 5%;
  gap: 40px;

  span {
    color: ${(props) => props.theme.colors.green};
  }

  h2 {
    margin-bottom: 5%;
  }

  @media (max-width: 768px) {
    max-width: 100vw;
    padding: 5% 5%; /* Ajuste de padding */
    overflow-x: hidden; /* Evitar desbordamiento horizontal */
  }
`;

const BannerImg = styled.img`
  width: 100%;
  height: auto;
`;
const PilarContainer = styled.div`
  display: flex;
  gap: 50px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px; /* Reducir el espacio entre pilares */
    width: 100%; /* Asegurar que no se desborde */
  }
`;

const Pilar = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  min-height: 300px;
  gap: 5px;

  span {
    color: ${(props) => props.theme.colors.white};
    font-weight: bold;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
  .icon-container {
    width: 100px;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .description {
    background-color: ${(props) => props.theme.colors.yellow};
    color: white;
    border-radius: 20px;
    padding: 5% 10%;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  h3 {
    text-align: center;
    font-weight: bolder;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 5% 10%;
  }
`;

const PromesasSection = styled.div`
  display: flex;
  text-align: left;

  .left {
    border-right: 1px solid ${(props) => props.theme.colors.secondary};
    max-width: 50%;
    padding-right: 5%;

    h3 {
      font-size: ${(props) => props.theme.fontSizes.normal} !important;
      margin-bottom: 2%;

      @media (max-width: 768px) {
        margin-top: 10%;
      }
    }

    h3:not(:first-child) {
      margin-top: 10%;
    }

    @media (max-width: 768px) {
      border-right: none;
      max-width: 100%;
    }
  }

  .rigth {
    padding-left: 5%;
    max-width: 50%;

    h3 {
      font-size: ${(props) => props.theme.fontSizes.medium} !important;
      margin-bottom: 2%;

      @media (max-width: 768px) {
        margin-top: 10%;
      }
    }

    h3:not(:first-child) {
      margin-top: 10%;
    }

    @media (max-width: 768px) {
      max-width: 100%;
    }
  }

  .description {
    font-size: ${(props) => props.theme.fontSizes.small};
    display: flex;
    gap: 20px;
    height: 100%;
    max-height: 40%;

    img {
      width: 150px;
      height: 150px;
      margin-top: 1%;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 5%; /* Ajuste de padding para evitar desbordamiento */
    overflow-x: hidden; /* Evitar desbordamiento horizontal */
  }
`;

// Estilo genérico para los íconos SVG
const StyledIcon = styled.div`
  width: 100px; /* Tamaño fijo inicial */
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 100%;
    height: 100%;
    fill: ${(props) => props.theme.colors.green}; /* Color inicial */

    transition: fill 0.3s ease-in-out, transform 0.3s ease-in-out;

    &:hover {
      fill: ${(props) =>
        props.theme.colors.yellow}; /* Cambiar color al hover */
      transform: scale(1.1); /* Suave efecto de zoom */
    }
  }

  @media (max-width: 768px) {
    width: 60px; /* Reducir tamaño en móviles */
    height: 60px;
  }
`;

// Personalización para casos específicos (opcional)
export const ConscientesIconStyled = styled(ConscientesIcon)`
  ${StyledIcon}
`;

export const FormadoresIconStyled = styled(FormadoresIcon)`
  ${StyledIcon}
`;

export const PionerosIconStyled = styled(PionerosIcon)`
  ${StyledIcon}
`;

const About = () => {
  return (
    <>
      <Seo
        title="Nosotros"
        type="profile"
        description="Conoce más sobre DISAN Agro, nuestra misión, visión y el compromiso que tenemos de revolucionar la agricultura sostenible. Innovamos para impulsar el futuro del campo en Latinoamérica."
      />
      <AboutContainer>
        <BannerImg src={bannerImage} alt="About Us" />
        <Description>
          <ColorTab />
          <p>
            <b>DISAN Agro</b> como parte del grupo <b>DISAN</b>, nace en{" "}
            <b>2004 </b>
            con el propósito de{" "}
            <b>revolucionar la forma de hacer agricultura sostenible</b>,
            generando desarrollo y crecimiento en Latinoamérica. <br />
            <br />
            Somos una compañía que promueve el desarrollo de soluciones
            integrales innovadoras que garantiza el suministro de insumos
            agrícolas (fertilizantes, bioestimulantes, sustratos y protección de
            cultivos) de alta calidad, soportado por nuestra fuerza de ventas
            con alto conocimiento agronómico y desarrollos en campo.
          </p>
        </Description>
        <Hero>
          <p>
            Creemos en el agro como pilar de transformación para el desarrollo
            de la región latinoamericana, donde la sostenibilidad es uno de los
            factores diferenciadores que hacen parte de nuestra ruta
            estratégica.
          </p>
        </Hero>
        <PilaresSection>
          <ColorTab />
          <h2>
            Nuestros <span>Pilares</span>
          </h2>
          <PilarContainer>
            <Pilar>
              <StyledIcon>
                <AliadosIcon />
              </StyledIcon>

              <h3>Aliados</h3>
              <div className="description">
                <span>
                  Contar con una red de aliados/actores interdisciplinarios que
                  nos permita la solución integral de problemas multiplicando
                  nuestro impacto.
                </span>
              </div>
            </Pilar>
            <Pilar>
              <StyledIcon>
                <ConscientesIcon />
              </StyledIcon>
              <h3>Conscientes</h3>
              <div className="description">
                <span>
                  Somos referentes por los logros de nuestras acciones de triple
                  impacto en Latinoamérica.
                </span>
              </div>
            </Pilar>
          </PilarContainer>
          <PilarContainer>
            <Pilar>
              <StyledIcon>
                <FormadoresIcon />
              </StyledIcon>
              <h3>Formadores</h3>
              <div className="description">
                <span>
                  A partir del conocimiento proponemos nuevas soluciones y
                  capturamos oportunidades, alcanzando reconocimiento y
                  diferenciación en el mercado del agro latinoamericano.
                </span>
              </div>
            </Pilar>
            <Pilar>
              <StyledIcon>
                <PionerosIcon />
              </StyledIcon>
              <h3>Pioneros</h3>
              <div className="description">
                <span>
                  Nos atrevemos a hacer cosas diferentes e innovadoras con
                  valentía, ponderando el riesgo y ambicionando siempre grandes
                  resultados.
                </span>
              </div>
            </Pilar>
          </PilarContainer>
        </PilaresSection>
        <PilaresSection>
          <ColorTab />
          <h2>
            Nuestras <span>Promesas</span>
          </h2>
          <PromesasSection>
            <div className="left">
              <h3>RELACIONAMIENTO DE LARGO PLAZO</h3>
              <div className="description">
                <img src={relacionamiento} alt="Relacionamiento" />
                <p>
                  Identificamos y nos comprometemos con los propósitos de
                  nuestros clientes y proveedores, generamos y valoramos la
                  confianza, promovemos una comunicación efectiva y alineamos
                  estrategias con nuestros proveedores para garantizar la
                  sostenibilidad de nuestro negocio
                </p>
              </div>
              <h3>ASESORÍA ESPECIALIZADA</h3>
              <div className="description">
                <img src={asesoria} alt="Asesoria" />
                <p>
                  Desarrollamos redes de conocimiento con diferentes actores de
                  las cadenas agrícolas y agroindustriales que nos permiten
                  mantenernos actualizados respecto a las tendencias mundiales
                  de mercado, consumo, innovación técnica/tecnológica y
                  normatividad para poder ofrecer un acompañamiento integral
                </p>
              </div>
            </div>
            <div className="rigth">
              <h3>SOLUCIONES INTEGRALES PARA EL AGRO</h3>
              <div className="description">
                <img src={soluciones} alt="Soluciones" />
                <p>
                  Nos esforzamos por mantener un portafolio de productos
                  innovadores, diversos y actualizados que nos permita
                  participar en las cadenas productivas, ofreciendo soluciones
                  integrales que garanticen la mejor relación costo/beneficio
                  para nuestros clientes
                </p>
              </div>
              <h3>SUMINISTRO CONFIABLE</h3>
              <div className="description">
                <img src={suministro} alt="Suministro" />
                <p>
                  Garantizamos el cumplimiento de las necesidades de
                  aprovisionamiento de nuestros clientes, sin importar su tamaño
                  o localización, manteniendo inventarios de producto
                  suficientes, a través del trabajo compartido con nuestros
                  proveedores y servicios de logística local e internacional
                </p>
              </div>
            </div>
          </PromesasSection>
        </PilaresSection>
        <ContactCard />
      </AboutContainer>
    </>
  );
};

export default About;
