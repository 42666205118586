const nutritionPlans = [
  {
    name: "Cultivo de Aguacate",
    description:
      "Conoce el plan de nutrición y protección para el cultivo de Aguacate.\n\nPotencia el rendimiento, calidad y rentabilidad de tu cultivo de Aguacate con nuestros paquetes tecnológicos agrícolas, donde te indicamos las soluciones integrales que tu cultivo necesita. Cada programa está diseñado para brindar una combinación equilibrada de nutrición de cultivo y protección, garantizando un desarrollo saludable en cada etapa.",
    image: "/plan nutricional/aguacates.png",
    fenograma: "/files/fenogramas/FENOGRAMA-CULTIVO-DE-AGUACATE-copia (1).pdf",
    products: [
      "Oxical Mg",
      "Equilibrio Pentax",
      "Azufertil 2E",
      "Protamin Granum",
      "Urea Nutrisphere",
      "MAP AVAIL 10-50-0",
      "Solufos 44 GR",
      "Cloruro de potasio granular",
      "Korn-kali",
      "Korn-kali +B",
      "DISACAL B-ZINC",
      "Nov@ GR",
      "Silexmag GR",
      "Disan 27-6-6-2(S)",
      "NitroS 30",
      "NitroS 40",
      "Nitrosulfato Amónico NSA 26",
      "Nitra Organic",
      "Qrop KS",
      "Sulfato de potasio granular",
      "Disaphos Ca",
      "ESTA Kieserita",
      "Kieserita Potásica",
      "Firmeza Origen",
      "Azufertil 5E",
      "Disan 22-3-18-2(S)",
    ],
  },
  {
    name: "Cultivo de Arroz",
    description:
      "Conoce el plan de nutrición y protección para el cultivo de Arroz.\n\nPotencia el rendimiento, calidad y rentabilidad de tu cultivo de Arroz con nuestros paquetes tecnológicos agrícolas, donde te indicamos las soluciones integrales que tu cultivo necesita. Cada programa está diseñado para brindar una combinación equilibrada de nutrición de cultivo y protección, garantizando un desarrollo saludable en cada etapa.",
    image: "/plan nutricional/arroz-1.jpg",
    fenograma: "/files/fenogramas/1.-FENOGRAMA-ARROZ (1).pdf",
    products: ["Korn-kali", "ESTA Kieserita"],
  },
  {
    name: "Cultivo de Banano",
    description:
      "Conoce el plan de nutrición y protección para el cultivo de Banano.\n\nPotencia el rendimiento, calidad y rentabilidad de tu cultivo de Banano con nuestros paquetes tecnológicos agrícolas, donde te indicamos las soluciones integrales que tu cultivo necesita. Cada programa está diseñado para brindar una combinación equilibrada de nutrición de cultivo y protección, garantizando un desarrollo saludable en cada etapa.",
    image: "/plan nutricional/banano-1.jpg",
    fenograma: "/files/fenogramas/4.-FENOGRAMA-CULTIVO-DE-BANANO (1).pdf",
    products: [
      "Oxical Mg",
      "Equilibrio Pentax",
      "Azufertil 2E",
      "Protamin Granum",
      "Urea Nutrisphere",
      "MAP AVAIL 10-50-0",
      "Solufos 44 GR",
      "Cloruro de potasio granular",
      "Korn-kali",
      "Korn-kali +B",
      "DISACAL B-ZINC",
      "Nov@ GR",
      "Silexmag GR",
      "Disan 27-6-6-2(S)",
      "NitroS 30",
      "NitroS 40",
      "Nitrosulfato Amónico NSA 26",
      "Nitra Organic",
      "Qrop KS",
      "Sulfato de potasio granular",
      "Disaphos Ca",
      "ESTA Kieserita",
      "Kieserita Potásica",
      "Firmeza Origen",
      "Azufertil 5E",
      "Disan 22-3-18-2(S)",
    ],
  },
  {
    name: "Cultivo de Café",
    description:
      "Conoce el plan de nutrición y protección para el cultivo de Café.\n\nPotencia el rendimiento, calidad y rentabilidad de tu cultivo de Café con nuestros paquetes tecnológicos agrícolas, donde te indicamos las soluciones integrales que tu cultivo necesita. Cada programa está diseñado para brindar una combinación equilibrada de nutrición de cultivo y protección, garantizando un desarrollo saludable en cada etapa.",
    image: "/plan nutricional/cafe-1.jpg",
    fenograma: "/files/fenogramas/7.-FENOGRAMA-DE-CAFA_ (1).pdf",
    products: [
      "Oxical Mg",
      "Equilibrio Pentax",
      "Azufertil 2E",
      "Protamin Granum",
      "Urea Nutrisphere",
      "MAP AVAIL 10-50-0",
      "Solufos 44 GR",
      "Cloruro de potasio granular",
      "Korn-kali",
      "Korn-kali +B",
      "DISACAL B-ZINC",
      "Nov@ GR",
      "Silexmag GR",
      "Disan 27-6-6-2(S)",
      "NitroS 30",
      "NitroS 40",
      "Nitrosulfato Amónico NSA 26",
      "Nitra Organic",
      "Qrop KS",
      "Sulfato de potasio granular",
      "Disaphos Ca",
      "ESTA Kieserita",
      "Kieserita Potásica",
      "Firmeza Origen",
      "Azufertil 5E",
      "Disan 22-3-18-2(S)",
    ],
  },
  {
    name: "Cultivo de Cacao",
    description:
      "Conoce el plan de nutrición y protección para el cultivo de Cacao.\n\nPotencia el rendimiento, calidad y rentabilidad de tu cultivo de Cacao con nuestros paquetes tecnológicos agrícolas, donde te indicamos las soluciones integrales que tu cultivo necesita. Cada programa está diseñado para brindar una combinación equilibrada de nutrición de cultivo y protección, garantizando un desarrollo saludable en cada etapa.",
    image: "/plan nutricional/cacao-tree.png",
    fenograma: "/files/fenogramas/FENOGRAMA-CULTIVO-DE-CACAO-FINAL (1).pdf",
    products: [
      "Oxical Mg",
      "Equilibrio Pentax",
      "Azufertil 2E",
      "Protamin Granum",
      "Urea Nutrisphere",
      "MAP AVAIL 10-50-0",
      "Solufos 44 GR",
      "Cloruro de potasio granular",
      "Korn-kali",
      "Korn-kali +B",
      "DISACAL B-ZINC",
      "Nov@ GR",
      "Silexmag GR",
      "Disan 27-6-6-2(S)",
      "NitroS 30",
      "NitroS 40",
      "Nitrosulfato Amónico NSA 26",
      "Nitra Organic",
      "Qrop KS",
      "Sulfato de potasio granular",
      "Disaphos Ca",
      "ESTA Kieserita",
      "Kieserita Potásica",
      "Firmeza Origen",
      "Azufertil 5E",
      "Disan 22-3-18-2(S)",
    ],
  },
  {
    name: "Cultivo de Maíz",
    description:
      "Conoce el plan de nutrición y protección para el cultivo de Maíz.\n\nPotencia el rendimiento, calidad y rentabilidad de tu cultivo de Maíz con nuestros paquetes tecnológicos agrícolas, donde te indicamos las soluciones integrales que tu cultivo necesita. Cada programa está diseñado para brindar una combinación equilibrada de nutrición de cultivo y protección, garantizando un desarrollo saludable en cada etapa.",
    image: "/plan nutricional/maiz.png",
    fenograma: "/files/fenogramas/2.-FENOGRAMA-CULTIVO-DE-MAA_Z (1).pdf",
    products: ["Korn-kali", "ESTA Kieserita", "Kieserita Potásica"],
  },
  {
    name: "Cultivo de Palma",
    description:
      "Conoce el plan de nutrición y protección para el cultivo de Palma.\n\nPotencia el rendimiento, calidad y rentabilidad de tu cultivo de Palma con nuestros paquetes tecnológicos agrícolas, donde te indicamos las soluciones integrales que tu cultivo necesita. Cada programa está diseñado para brindar una combinación equilibrada de nutrición de cultivo y protección, garantizando un desarrollo saludable en cada etapa.",
    image: "/plan nutricional/palma.png",
    fenograma: "/files/fenogramas/3.-FENOGRAMA-CULTIVO-DE-PALMA-2 (1).pdf",
    products: [
      "Oxical Mg",
      "Equilibrio Pentax",
      "Azufertil 2E",
      "Protamin Granum",
      "Urea Nutrisphere",
      "MAP AVAIL 10-50-0",
      "Solufos 44 GR",
      "Cloruro de potasio granular",
      "Korn-kali",
      "Korn-kali +B",
      "DISACAL B-ZINC",
      "Nov@ GR",
      "Silexmag GR",
      "Disan 27-6-6-2(S)",
      "NitroS 30",
      "NitroS 40",
      "Nitrosulfato Amónico NSA 26",
      "Nitra Organic",
      "Qrop KS",
      "Sulfato de potasio granular",
      "Disaphos Ca",
      "ESTA Kieserita",
      "Kieserita Potásica",
      "Firmeza Origen",
      "Azufertil 5E",
      "Disan 22-3-18-2(S)",
    ],
  },
  {
    name: "Cultivo de Papa",
    description:
      "Conoce el plan de nutrición y protección para el cultivo de Papa.\n\nPotencia el rendimiento, calidad y rentabilidad de tu cultivo de Papa con nuestros paquetes tecnológicos agrícolas, donde te indicamos las soluciones integrales que tu cultivo necesita. Cada programa está diseñado para brindar una combinación equilibrada de nutrición de cultivo y protección, garantizando un desarrollo saludable en cada etapa.",
    image: "/plan nutricional/papa-e1.png",
    fenograma: "/files/fenogramas/6.-FENOGRAMA-CULTIVO-DE-PAPA (1).pdf",
    products: [
      "Oxical Mg",
      "Equilibrio Pentax",
      "Azufertil 2E",
      "Protamin Granum",
      "Urea Nutrisphere",
      "MAP AVAIL 10-50-0",
      "Solufos 44 GR",
      "Cloruro de potasio granular",
      "Korn-kali",
      "Korn-kali +B",
      "DISACAL B-ZINC",
      "Nov@ GR",
    ],
  },
  {
    name: "Cultivo de Plátano",
    description:
      "Conoce el plan de nutrición y protección para el cultivo de Plátano.\n\nPotencia el rendimiento, calidad y rentabilidad de tu cultivo de Plátano con nuestros paquetes tecnológicos agrícolas, donde te indicamos las soluciones integrales que tu cultivo necesita. Cada programa está diseñado para brindar una combinación equilibrada de nutrición de cultivo y protección, garantizando un desarrollo saludable en cada etapa.",
    image: "/plan nutricional/platano.png",
    fenograma: "/files/fenogramas/5.-FENOGRAMA-CULTIVO-DE-PLA_TANO (1).pdf",
    products: [
      "Oxical Mg",
      "Equilibrio Pentax",
      "Azufertil 2E",
      "Protamin Granum",
      "Urea Nutrisphere",
      "MAP AVAIL 10-50-0",
    ],
  },
  {
    name: "Cultivo de Soya",
    description:
      "Conoce el plan de nutrición y protección para el cultivo de Soya.\n\nPotencia el rendimiento, calidad y rentabilidad de tu cultivo de Soya con nuestros paquetes tecnológicos agrícolas, donde te indicamos las soluciones integrales que tu cultivo necesita. Cada programa está diseñado para brindar una combinación equilibrada de nutrición de cultivo y protección, garantizando un desarrollo saludable en cada etapa.",
    image: "/plan nutricional/iStock.png",
    fenograma: "/files/fenogramas/8.-FENOGRAMA-CULTIVO-DE-SOYA (1).pdf",
    products: ["Korn-kali", "ESTA Kieserita"],
  },
  {
    name: "Clavel",
    description:
      "Conoce el plan de nutrición y protección para el cultivo de Clavel.\n\nPotencia el rendimiento, calidad y rentabilidad de tu cultivo de Clavel con nuestros paquetes tecnológicos agrícolas, donde te indicamos las soluciones integrales que tu cultivo necesita. Cada programa está diseñado para brindar una combinación equilibrada de nutrición de cultivo y protección, garantizando un desarrollo saludable en cada etapa.",
    image: "/plan nutricional/clavel.png",
    fenograma: "/files/fenogramas/12.-FENOGRAMA-FLORES-CLAVEL (1).pdf",
    products: ["Oxical Mg", "Protamin Granum", "Nov@ GR"],
  },
  {
    name: "Rosas",
    description:
      "Conoce el plan de nutrición y protección para el cultivo de Rosas.\n\nPotencia el rendimiento, calidad y rentabilidad de tu cultivo de Rosas con nuestros paquetes tecnológicos agrícolas, donde te indicamos las soluciones integrales que tu cultivo necesita. Cada programa está diseñado para brindar una combinación equilibrada de nutrición de cultivo y protección, garantizando un desarrollo saludable en cada etapa.",
    image: "/plan nutricional/rosas-1.jpg",
    fenograma: "/files/fenogramas/11.-FENOGRAMA-FLORES-ROSAS (1).pdf",
    products: ["Oxical Mg", "Protamin Granum", "Nov@ GR"],
  },
];

module.exports = nutritionPlans;
